import { FileService } from "./file-service";

class FormDataService {
  constructor() {
    this.fileService = new FileService();
  }

  async uploadFormData(data, EncryptedRequest1, guId, basic) {
    const data1 = new FormData();
    data1.append("taskFile", data);
    data1.append("requestBody", EncryptedRequest1);
    return await this.fileService.uploadFileToServer(data1, guId, basic);
  }
}

export default new FormDataService();
