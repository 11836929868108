/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert"; // Import
import "./css/react-confirm-alert.css"; // Import css
import Spinner from "react-spinner-material"; //Import Spinner
import IdleTimer from "react-idle-timer"; // For Idle
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import addDays from "date-fns/addDays";
import "./css/style.css";
import "./css/style-responsive.css";
import { Encrypt, decryptData } from "./Encryption-Decrypytion";
import { Scrollbars } from "react-custom-scrollbars";
import disableBrowserBackButton from "disable-browser-back-navigation";
import { basicAuth } from "./BasicAuth";

const display = {
  display: "block"
};
const hide = {
  display: "none"
};

class QuestionPreviewForArchivedTask extends Component {
  constructor(props) {
    super(props);
    this.state = {
      taskId: "",
      displayMenu: false,
      loaded: this.props.location.state,
      taskStores: [],
      fileval: "Upload file",
      fileuploaded: [],
      nooffileques: 0,
      file: "",
      filestr: "",
      fileupmsg: "",
      storenumber: "",
      hasError: false, 
      viewdate: true,
      questionid: "",
      Search: "",
      SearchName: "",
      SearchStat: "",
      userid: "",
    };
    this.state = {
      TaskDropdwn: false,
      Search: "",
      SearchName: "",
      SearchStat: "",
      data: [],
      taskId: "",
      usrnm: "",
      role_id: "",
      quesid: [],
      ans: [],
      loading: false,
      taskStores: [],
      file: "",
      fileval: "Upload file",
      fileuploaded: [],
      nooffileques: 0,
      filestr: "",
      fileupmsg: "",
      storenumber: "",
      hasError: false, 
      hammenu: true,
      datapresent: false,
      startdateupdateticker: "",
      UpdatesDate: "",
      storeval: "",
      questionid: "",
      userid: "",
      displayMenu: false,
      loaded: this.props.location.state,
      viewdate: true,
    };
    this.showDropdownMenu = this.showDropdownMenu.bind(this);
    this.hideDropdownMenu = this.hideDropdownMenu.bind(this);
    this.handletickersupdate = this.handletickersupdate.bind(this);
    this.idleTimer = null;
    this.onAction = this._onAction.bind(this);
    this.onActive = this._onActive.bind(this);
    this.onIdle = this._onIdle.bind(this);
  }

  logoutnormal = () => {
    localStorage.clear();
    this.props.history.replace("/");
    disableBrowserBackButton();
  };

  onChange1 = e => {
    this.setState({
      Search: e.target.value
    });
  };

  onChange2 = e => {
    this.setState({
      SearchName: e.target.value
    });
  };

  onChange3 = e => {
    this.setState({
      SearchStat: e.target.value
    });
  };

  openproof = (param, evt) => {
    evt.preventDefault();
    var string;
    var qlen = param.length;
    if (this.state.fileuploaded.length > 0) {
      for (let index = 0; index < this.state.fileuploaded.length; index++) {
        const element = this.state.fileuploaded[index];
        if (element.includes(param, 0)) {
          var elelen = element.length;
          string = element.substring(qlen, elelen);
          if (string == "null") {
          } else {
            var iframe =
              "<iframe width='100%' height='100%' src='" +
              string +
              "'></iframe>";
            var x = window.open();
            x.document.open();
            x.document.write(iframe);
            x.document.close();
          }
        }
      }
    } else {
      confirmAlert({
        title: "Alert !",
        message: "No files uploaded yet",
        buttons: [
          {
            label: "OK"
          }
        ]
      });
    }
  };

  _onAction(e) {
  }

  _onActive(e) {
  }

  _onIdle(e) {
    localStorage.clear();
    window.location.href = "/";
    disableBrowserBackButton();
  }

  componentDidMount() {
    if (window.innerWidth <= 768) {
      this.setState({ hammenu: false });
    }
  }

  showTaskDropdwnHandler = () => {
    this.setState({
      TaskDropdwn: !this.state.TaskDropdwn
    });
  };

  showDropdownMenu(event) {
    event.preventDefault();
    this.setState({ displayMenu: true }, () => {
      document.addEventListener("click", this.hideDropdownMenu);
    });
  }

  hideDropdownMenu() {
    this.setState({ displayMenu: false }, () => {
      document.removeEventListener("click", this.hideDropdownMenu);
    });
  }
  logout = () => {
    this.setState({ loading: true });
    var Request1 = {
      userId: this.props.location.state.userid,
      guId: this.props.location.state.guId
    };
    var EncryptedRequest1 = Encrypt(Request1);
    fetch("/LogOut ", {
      method: "POST",
      headers: {
        guId: this.props.location.state.guId,
        Authorization: "Basic " + basicAuth(this.props.location.state.userid)
      },
      body: EncryptedRequest1
    })
      .then(response => response.text())
      .then(response => {
        this.setState({ loading: false });
        var DecryptedResponse = decryptData(response);
        if (DecryptedResponse.errorCode === "00") {
          this.props.history.replace("/");
          disableBrowserBackButton();
        } else {
          confirmAlert({
            message: DecryptedResponse.errorMsg,
            buttons: [
              {
                label: "Ok"
              }
            ]
          });
        }
      })
      .catch(error => {
        confirmAlert({
          title: "Alert !",
          message: "Session expired",
          buttons: [
            {
              label: "Ok",
              onClick: () => {
                this.logoutnormal();
              }
            }
          ]
        });
      });
  };


  setquesid(param, event) {
    this.state.quesid.push(param);
    alert("quesid: " + param);
  }

  componentDidCatch() {
    this.setState({ hasError: true });
  }
  Changepasswordbind = () => {
    this.props.history.push({
      pathname: "/ChangepasswordUI",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  templates = () => {
    this.props.history.push({
      pathname: "/Templates",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  toggle = () => {
    this.setState(prevState => ({
      toggle: !prevState.toggle
    }));
  };

  modalclose = () => {
    this.setState({
      toggle: false
    });
  };

  tiker = () => {
    this.props.history.push({
      pathname: "/Tiker",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };
  dropdownselect = e => {
    this.setState({ datapresent: false, data: [], storeval: e.label });
  };

  componentWillMount() {
    if (
      this.props.location.state === undefined ||
      this.props.location.state === ""
    ) {
      window.location.href = "/";
    } else {
      this.setState({ UpdatesDate: this.props.location.state.endDate });
      this.GetClusterStoreList();
    }
  }

  showTaskdwnHandler = () => {
    this.setState({
      Taskdwn: !this.state.Taskdwn
    });
  };

  usercreation = () => {
    this.props.history.push({
      pathname: "/UserCreation",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  userlist = () => {
    this.props.history.push({
      pathname: "/UserList",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  handletickersupdate(dates) {
    this.setState({ startdateupdateticker: dates });
    this.setState({ UpdatesDate: dates });
  }

  handleDateChangeRaw = e => {
    e.preventDefault();
  };


  openIns() {

    var string = this.state.file;
    var iframe =
      "<iframe width='100%' height='100%' src='" + string + "'></iframe>";
    var iframe =
      "<iframe width='100%' height='100%' src='" +
      "/IMAGES/" +
      this.state.file +
      "'></iframe>";
    var x = window.open();
    x.document.open();
    x.document.write(iframe);
    x.document.close();

  }


  DownloadIns = () => {
    document.getElementById("FileDnld").click();
  };



  render = () => {
    var that = this;
    const { Search } = this.state;
    const { SearchName } = this.state;
    const { SearchStat } = this.state;

    var ReassignTask = [];
    var monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ];

    var date =
      new Date().getDate() +
      "-" +
      monthNames[new Date().getMonth()] +
      "-" +
      new Date().getFullYear();

    //   const options = [
    //     {name: 'Swedish' },
    //     {name: 'English'},

    // ];

    const techCompanies = [
      { label: "Apple" },
      { label: "Facebook" },
      { label: "Netflix" },
      { label: "Tesla" },
      { label: "Amazon" },
      { label: "Alphabet" }
    ];

    ReassignTask.push(
      <div style={this.state.datapresent ? display : hide} className="imgalign">
        <a class="mr-50" onClick={this.toggle.bind(this)}>
          <img
            class="mr-10"
            style={{ height: "40px" }}
            src={require("./img/reassign.png")}
          ></img>
          <b>Reassign Task</b>
        </a>
      </div>
    );

    var modal = [];

    modal.push(
      <div
        className="modal"
        role="dialog"
        style={this.state.toggle ? display : hide}
      >
        <div className="modal-dialog animate">
          <div className="modal-content">
            <div className="modal-header">
              <a
                className="close"
                style={{ color: "#fff" }}
                onClick={this.modalclose}
              >
                X
              </a>
              <h4 className="modal-title">Reassign</h4>
            </div>
            <div className="modal-body">
              <div className="form-horizontal">
                <div className="form-group">
                  {new Date(this.props.location.state.endDate) >=
                    new Date(date) ? (
                    <div>
                      <h4 className="control-label col-sm-4">End Date</h4>
                      <div className="col-sm-8">
                        <input
                          type="text"
                          class="form-control"
                          value={this.props.location.state.endDate}
                          id="extend"
                          disabled
                        ></input>
                        <br />
                      </div>
                    </div>
                  ) : (
                    <div className="clearfix">
                      <h4 className="control-label col-sm-4">Extended Date</h4>
                      <div className="col-sm-8">
                        <DatePicker
                          className="form-control"
                          showTimeSelect
                          timeIntervals={15}
                          value={this.state.UpdatesDate}
                          onChange={this.handletickersupdate}
                          id="extend"
                          onChangeRaw={this.handleDateChangeRaw}
                          minDate={new Date()}
                          dateFormat="dd-MMM-yyyy HH:mm"
                          selected={this.state.startdateupdateticker}
                          autoComplete="off"
                          maxDate={addDays(new Date(), 365)}
                        // minTime={setHours(setMinutes(new Date(), new Date().getMinutes()),  new Date().getHours())}
                        // maxTime={setHours(setMinutes(new Date(), 59), 23)}
                        />
                        <br />
                      </div>
                    </div>
                  )}
                  <br />
                  <h4 className="control-label col-sm-4">Comment</h4>
                  <div className="col-sm-8">
                    <textarea
                      type="text"
                      class="form-control"
                      id="comment"
                      autoComplete="off"
                      maxLength="200"
                    ></textarea>
                  </div>
                  <h4 className="control-label col-sm-4">&nbsp;</h4>
                  <div className="col-sm-8">
                    <button
                      class="btn btn-primary min-wid-90 mt-17"
                      onClick={this.ReopenTask}
                    >
                      Reassign
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    if (this.state.hasError) {
      return (
        <div>
          <h2>Error occurred!! please contact administrator</h2>
        </div>
      );
    } else {
      return (
        <div>
          {modal}
          <div className={this.state.loading ? 'parentDisable' : ''} width="100%">
            <div className='overlay-box'>
              <Spinner
                visible={this.state.loading}
                spinnerColor={"rgba(0, 0, 0, 0.3)"}
              />
            </div>
          </div>
          <section id="container">
            <div>
              <IdleTimer
                ref={ref => {
                  this.idleTimer = ref;
                }}
                element={document}
                onActive={this.onActive}
                onIdle={this.onIdle}
                onAction={this.onAction}
                debounce={250}
                timeout={1000 * 60 * 10}
              />

            </div>
            <header class="header black-bg">

              <a
                className="mob-show"
                onClick={() =>
                  this.setState({ hammenu: !this.state.hammenu })
                }
              >
                <i
                  className="fa fa-tasks hammenu"
                  style={{ "margin-top": "25px", 'padding': '0 15px' }}
                ></i>
              </a>

              <a onClick={this.TaskactiveBind.bind(this)} className="logo" style={{ 'padding': '0 10px' }}>
                <img src={require("./img/retail-logo.png")} alt="logo" />
              </a>
              <div className="markcenter">
                <p className="marquee">{this.state.tickerList}</p>
              </div>
              <ul className="nav pull-right pos-rel">
                <li className="dropdown">
                  <a
                    className="dropdown-toggle"
                    data-toggle="dropdown"
                    onClick={this.showDropdownMenu}
                  >
                    {" "}
                    <img
                      src={require("./img/user.png")}
                      className="user-img"
                    />{" "}
                    <b className="name-show">{this.props.location.state.usrnm}</b>
                    ({this.props.location.state.storeNo})

                    <b className="caret" />
                  </a>
                </li>

                {this.state.displayMenu ? (
                  <ul className="dropdown-menuuser-dd">
                    <li>
                      Role : {this.props.location.state.roleName}
                    </li>
                    <li className="divider"></li>
                    <li>
                      <a onClick={this.Changepasswordbind}>Change Password</a>
                    </li>
                    <li className="divider"></li>
                    <li>
                      <a onClick={this.logout}>Log Out</a>
                    </li>
                  </ul>
                ) : null}
              </ul>
            </header>
            <aside>
              <div id="sidebar" className="nav-collapse">
                {this.state.hammenu ? (
                  <ul className="sidebar-menu" id="nav-accordion">
                    {this.props.location.state.menuList["401"] || this.props.location.state.menuList["402"] || this.props.location.state.menuList["403"] || this.props.location.state.menuList["404"] || this.props.location.state.menuList["405"] || this.props.location.state.menuList["408"] || this.props.location.state.menuList["411"] ? (
                      <li
                        className="sub-menu mt"
                        onClick={this.showTaskDropdwnHandler.bind(this)}
                      >
                        {" "}
                        <a href="javascript:;">
                          {" "}
                          <i className="fa fa-tasks"></i> <span>Task</span>{" "}
                        </a>
                        {this.state.TaskDropdwn ? (
                          <div>
                            <ul class="sub">
                              {this.props.location.state.menuList["401"] ? (
                                <li>
                                  <a onClick={this.TaskactiveBind.bind(this)}>
                                    {this.props.location.state.menuList["401"]}
                                  </a>
                                </li>
                              ) : null}
                              {this.props.location.state.menuList["402"] ? (
                                <li>
                                  <a onClick={this.getroles.bind(this)}>
                                    {this.props.location.state.menuList["402"]}
                                  </a>
                                </li>
                              ) : null}
                              {this.props.location.state.menuList["403"] ? (
                                <li>
                                  <a
                                    onClick={this.CompletedTaskListbind.bind(
                                      this
                                    )}
                                  >
                                    {this.props.location.state.menuList["403"]}
                                  </a>
                                </li>
                              ) : null}
                              {this.props.location.state.menuList["404"] ? (
                                <li>
                                  <a onClick={this.templates.bind(this)}>
                                    {this.props.location.state.menuList["404"]}
                                  </a>
                                </li>
                              ) : null}
                              {this.props.location.state.menuList["405"] ? (
                                <li>
                                  <a onClick={this.ArchivedTasks.bind(this)}>
                                    {this.props.location.state.menuList["405"]}
                                  </a>
                                </li>
                              ) : null}
                              {this.props.location.state.menuList["408"] ? (
                                <li>
                                  <a onClick={this.StoreTasks.bind(this)}>
                                    {this.props.location.state.menuList["408"]}
                                  </a>
                                </li>
                              ) : null}
                              {this.props.location.state.menuList["411"] ? (
                                <li>
                                  <a onClick={this.toGenDashboard.bind(this)}>
                                    {this.props.location.state.menuList["411"]}
                                  </a>
                                </li>
                              ) : null}
                            </ul>
                          </div>
                        ) : null}
                      </li>
                    ) : null}
                    {this.props.location.state.menuList["406"] ? (
                      <div>
                        <li>
                          {" "}
                          <a onClick={this.Reportsbind.bind(this)}>
                            {" "}
                            <i className="fa fa-tasks"></i>{" "}
                            <span>
                              {this.props.location.state.menuList["406"]}
                            </span>{" "}
                          </a>{" "}
                        </li>
                      </div>
                    ) : null}

                    {this.props.location.state.menuList["414"] && this.props.location.state.format_id === "102" ? (
                      <div>
                        <li>
                          {" "}
                          <a onClick={this.Defectbind.bind(this)}>
                            {" "}
                            <i className="fa fa-tasks"></i>{" "}
                            <span>
                              {this.props.location.state.menuList["414"]}
                            </span>{" "}
                          </a>{" "}
                        </li>
                      </div>
                    ) : null}

                    {this.props.location.state.menuList["415"] ? (
                      <div>
                        <li>
                          {" "}
                          <a onClick={this.SmartPointBind.bind(this)}>
                            {" "}
                            <i className="fa fa-tasks"></i>{" "}
                            <span>
                              {this.props.location.state.menuList["415"]}
                            </span>{" "}
                          </a>{" "}
                        </li>
                      </div>
                    ) : null}

                    {this.props.location.state.menuList["409"] || this.props.location.state.menuList["410"] ? (
                      <div>
                        <li
                          className="sub-menu"
                          onClick={this.showIssueDropdwnHandler.bind(this)}
                        >
                          {" "}
                          <a href="javascript:;">
                            {" "}
                            <i className="fa fa-tasks"></i>{" "}
                            <span>Issue</span>{" "}
                          </a>
                          {this.state.IssueDropdwn ? (
                            <div>
                              <ul class="sub">
                                {this.props.location.state.menuList["410"] ? (
                                  <li>
                                    <a onClick={this.IssueRaising.bind(this)}>
                                      {this.props.location.state.menuList["410"]}
                                    </a>
                                  </li>
                                ) : null}
                                {this.props.location.state.menuList["409"] ? (
                                  <li>
                                    <a onClick={this.IssueDashboard.bind(this)}>
                                      {this.props.location.state.menuList["409"]}
                                    </a>
                                  </li>
                                ) : null}
                              </ul>
                            </div>
                          ) : null}
                        </li>
                      </div>
                    ) : null}

                    {this.props.location.state.menuList["407"] ? (
                      <div>
                        <li
                          className="sub-menu"
                          onClick={this.showTaskdwnHandler.bind(this)}
                        >
                          {" "}
                          <a href="javascript:;">
                            {" "}
                            <i className="fa fa-tasks"></i>{" "}
                            <span>
                              {this.props.location.state.menuList["407"]}
                            </span>{" "}
                          </a>
                          {this.state.Taskdwn ? (
                            <div>
                              <ul class="sub">
                                <li>
                                  {" "}
                                  <a onClick={this.tiker.bind(this)}>
                                    <span>Tiker Creation</span>{" "}
                                  </a>{" "}
                                </li>
                                <li>
                                  {" "}
                                  <a onClick={this.userlist.bind(this)}>
                                    <span>User List</span>{" "}
                                  </a>{" "}
                                </li>
                                {this.props.location.state.menuList["412"] ? (
                                  <li>
                                    {" "}
                                    <a onClick={this.Storecreation.bind(this)}>
                                      <span>{this.props.location.state.menuList["412"]}</span>{" "}
                                    </a>{" "}
                                  </li>
                                ) : null}
                                {this.props.location.state.menuList["413"] ? (
                                  <li>
                                    {" "}
                                    <a onClick={this.ToStoreList.bind(this)}>
                                      <span>{this.props.location.state.menuList["413"]}</span>{" "}
                                    </a>{" "}
                                  </li>
                                ) : null}
                              </ul>
                            </div>
                          ) : null}
                        </li>
                      </div>
                    ) : null}
                  </ul>
                ) : null}
              </div>
            </aside>
            <section id="main-content">
              <section class="wrapper">
                <div class="row">
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-20">
                      <h3>
                        <i className="fa fa-angle-right"></i>
                        {" "}{this.props.location.state.taskName}
                      </h3>
                      <p>
                        Description :{" "}
                        {this.props.location.state.taskDescription}
                      </p>
                    </div>
                    <div class="col-md-6 col-lg-6 col-sm-6 col-xs-6">
                      Start Date : {this.props.location.state.startDate}{" "}
                    </div>
                    <div class="col-md-6 col-lg-6 col-sm-6 col-xs-6">
                      End Date : {this.props.location.state.endDate}
                    </div>
                    {this.state.file ? (
                      <div
                        class="col-md-6 col-lg-6 col-sm-6 col-xs-12"
                        style={{ float: "right", marginTop: "10px" }}
                      >
                        <a onClick={this.openIns.bind(this)}>
                          View Instruction
                        </a>
                        /
                        <a
                          download="instructions"
                          onClick={this.DownloadIns.bind(this)}
                        >
                          Download Instructions
                        </a>
                        <a
                          download="instructions"
                          href={"/IMAGES/" + this.state.file}
                          id="FileDnld"
                          hidden
                        />
                      </div>
                    ) : null}
                    <div class="clearfix"></div>

                    <div className="row mt">
                      <div className="col-md-12">
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                          <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
                            {" "}
                            <b>Store No./User Id</b>
                            <br />
                            <input
                              type="text"
                              placeholder="Search Store No./User Id"
                              className="form-control"
                              id="taskname"
                              onChange={this.onChange1}
                              autoComplete="off"
                            />
                          </div>
                          <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
                            {" "}
                            <b>Name</b>
                            <br />
                            <input
                              type="text"
                              placeholder="Search Name"
                              className="form-control"
                              id="taskname"
                              onChange={this.onChange2}
                              autoComplete="off"
                            />
                          </div>
                          <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
                            {" "}
                            <b>Answer Status</b>
                            <br />
                            <input
                              type="text"
                              placeholder="Search Answer Status"
                              className="form-control"
                              id="taskname"
                              onChange={this.onChange3}
                              autoComplete="off"
                            />
                          </div>
                          <div>
                            <img
                              src={require("./img/searchicon.png")}
                              className="user-img mt-20"
                              style={{ width: "25px", height: "25px" }}
                            />
                          </div>
                          <div className="clearfix"></div>
                          <div className="mt">
                            <div className="tbl-holder">
                              <table className="table table-striped table-advance table-hover table-bordered tbl-task tbl-hhide">
                                <thead>
                                  <tr>
                                    <th>Store No./User Id</th>
                                    <th>Name</th>
                                    <th>Answer Status</th>
                                  </tr>
                                </thead>
                              </table>
                              <Scrollbars style={{ height: 296 }}>
                                <table className="table table-striped table-advance table-hover table-bordered tbl-task">
                                  <tbody>
                                    {this.state.data.map(function (item, key) {
                                      if (
                                        (Search !== "" ||
                                          SearchName !== "" ||
                                          SearchStat !== "") &&
                                        (item.storeNo
                                          .toLowerCase()
                                          .indexOf(Search.toLowerCase()) ||
                                          item.storeName
                                            .toLowerCase()
                                            .indexOf(
                                              SearchName.toLowerCase()
                                            ) ||
                                          item.ansStatus
                                            .toLowerCase()
                                            .indexOf(
                                              SearchStat.toLowerCase()
                                            )) === -1
                                      ) {
                                        return null;
                                      }
                                      return (
                                        <tr key={key}>
                                          <td data-th="Store No./User Id">
                                            <a
                                              onClick={that.callquestionpaperpage.bind(
                                                that,
                                                item.storeNo,
                                                item.storeName,
                                                item.ansStatus
                                              )}
                                              className="black-text"
                                              style={{ cursor: "pointer" }}
                                            >
                                              {" "}
                                              {item.storeNo}
                                            </a>
                                          </td>

                                          <td data-th="Name">
                                            {item.storeName}
                                          </td>
                                          <td data-th="Answer Status">
                                            {item.ansStatus}
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                </table>
                              </Scrollbars>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="clearfix"></div>
                  </div>
                </div>
              </section>
            </section>
          </section>
        </div>
      );
    }
  };

  ToStoreList = () => {
    this.props.history.push({
      pathname: "/StoreList",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        formerMasterList: this.state.formerMasterList,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  Storecreation = () => {
    this.props.history.push({
      pathname: "/Storecreation",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        formerMasterList: this.state.formerMasterList,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  showIssueDropdwnHandler = () => {
    this.setState({
      IssueDropdwn: !this.state.IssueDropdwn
    });
  };

  IssueRaising = () => {
    this.props.history.push({
      pathname: "/IssueRaising",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        data: this.props.location.state.data,
        tskId: this.props.location.state.tskId,
        tskName: this.props.location.state.tskName,
        storeNo: this.props.location.state.storeNo,
        Isroffice: this.props.location.state.Isroffice,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  IssueDashboard = () => {
    this.props.history.push({
      pathname: "/IssueDashboard",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        data: this.props.location.state.data,
        tskId: this.props.location.state.tskId,
        tskName: this.props.location.state.tskName,
        storeNo: this.props.location.state.storeNo,
        Isroffice: this.props.location.state.Isroffice,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  toGenDashboard = () => {
    this.props.history.push({
      pathname: "/DashboardGeneral",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        data: this.props.location.state.data,
        tskId: this.props.location.state.tskId,
        tskName: this.props.location.state.tskName,
        storeNo: this.props.location.state.storeNo,
        Isroffice: this.props.location.state.Isroffice,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  GetClusterStoreList(e) {
    if (
      this.props.location.state !== undefined &&
      this.props.location.state.usrnm !== ""
    ) {
      this.setState({ loading: true });
      this.setState({ userid: this.props.location.state.userid });
      this.setState({ usrnm: this.props.location.state.usrnm });
      this.setState({ role_id: this.props.location.state.role_id });
      this.setState({ format_id: this.props.location.state.format_id });
      this.setState({ taskId: this.props.location.state.taskId });
      var Request = {
        taskId: this.props.location.state.taskId,
        guId: this.props.location.state.guId,
        userId: this.props.location.state.userid
      };
      var EncryptedRequest = Encrypt(Request);

      fetch("/GetClusterStoreList", {
        method: "POST",
        headers: {
          guId: this.props.location.state.guId,
          Authorization: "Basic " + basicAuth(this.props.location.state.userid)
        },
        body: EncryptedRequest
      })
        .then(response => response.text())
        .then(response => {
          var DecryptedResponse = decryptData(response);
          if (
            DecryptedResponse.errorCode === "200 OK" ||
            DecryptedResponse.errorCode === "00"
          ) {
            this.setState({ data: DecryptedResponse.clusterStoreList });

            this.setState({
              loading: false
            });
            this.setState({ usrnm: this.props.location.state.usrnm });
            this.setState({ role_id: this.props.location.state.role_id });
            this.setState({ format_id: this.props.location.state.format_id });
            this.setState({ file: DecryptedResponse.taskFile });
          } else {
            this.setState({ loading: false });

            confirmAlert({
              message: DecryptedResponse.errorMsg,
              buttons: [
                {
                  label: "Ok"
                }
              ]
            });
          }
        })
        .catch(error => {
          confirmAlert({
            title: "Alert !",
            message: "Session expired",
            buttons: [
              {
                label: "Ok",
                onClick: () => {
                  this.logoutnormal();
                }
              }
            ]
          });
        });
    } else {
      window.location.href = "/";
    }
  }

  callquestionpaperpage(
    storeNo,
    storeName,
    ansStatus,
  ) {
    this.props.history.push({
      pathname: "/QuestionPaper",
      state: {
        taskId: this.props.location.state.taskId,
        taskName: this.props.location.state.taskName,
        taskDescription: this.props.location.state.taskDescription,
        startDate: this.props.location.state.startDate,
        endDate: this.props.location.state.endDate,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        data: this.props.location.state.data,
        storeNo: storeNo,
        storeName: storeName,
        ansStatus: ansStatus,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  }

  getroles(e) {
    this.props.history.push({
      pathname: "/CreatetaskUI",
      state: {
        format_id: this.props.location.state.format_id,
        role_id: this.props.location.state.role_id,
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  }
  Astrik = props => {
    const isMandatory = props.isMandatory;
    if (isMandatory == 1) {
      return <font color="red"> *</font>;
    }
    return null;
  };

  SmartPointBind = () => {
    this.props.history.push({
      pathname: "/SmartPoint",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        data: this.props.location.state.data,
        tskId: this.props.location.state.tskId,
        tskName: this.props.location.state.tskName,
        storeNo: this.props.location.state.storeNo,
        Isroffice: this.props.location.state.Isroffice,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  Defectbind = () => {
    this.props.history.push({
      pathname: "/DefectRaising",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        data: this.props.location.state.data,
        tskId: this.props.location.state.tskId,
        tskName: this.props.location.state.tskName,
        storeNo: this.props.location.state.storeNo,
        Isroffice: this.props.location.state.Isroffice,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  Reportsbind = () => {
    this.props.history.push({
      pathname: "/Reports",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        data: this.state.data,
        tskId: this.state.tskId,
        tskName: this.state.tskName,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };
  StoreTasks = () => {
    this.props.history.push({
      pathname: "/StoreTasks",
      state: {
        data: this.state.data,
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  ArchivedTasks = () => {
    this.props.history.push({
      pathname: "/ArchivedTasks",
      state: {
        data: this.state.data,
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
        Isroffice: this.props.location.state.Isroffice,
      }
    });
  };

  CompletedTaskListbind = () => {
    this.props.history.push({
      pathname: "/CompletedTaskList",
      state: {
        data: this.state.data,
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  TaskactiveBind = () => {
    this.props.history.push({
      pathname: "/Taskactive",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };
}
export default QuestionPreviewForArchivedTask;
