/** @format */

import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert";
import "./css/react-confirm-alert.css"; // Import css
import "./css/style.css";
import { Encrypt, decryptData } from "./Encryption-Decrypytion";
import { basicAuth } from "./BasicAuth";
import Spinner from "react-spinner-material";
import moment from "moment";

class DropdownButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDropdownOpen: false,
      taskIdOfReport: props.taskId,
      lastGenRepDate: "",
      loading: false,
      reportUrl: null,
      reportStatus: "",
      reportStatusMessage: "",
    };
    this.confirmAlertRef = React.createRef();
  }

  toggleDropdown = () => {
    this.setState({ isDropdownOpen: !this.state.isDropdownOpen });
  };

  dataAndSummaryReport = (e, reportTypeValue, accessType) => {
    if (accessType === "link") {
      e.preventDefault();
    }
    confirmAlert({
      customUI: ({ onClose }) => {
        this.confirmAlertRef = { onClose };
        return (
          <>
            {this.state.loading ? (
              <div className="overlay-box">
                <Spinner
                  visible={this.state.loading}
                  spinnerColor={"rgba(0, 0, 0, 0.3)"}
                />
              </div>
            ) : (
              <div
                className="react-confirm-alert-body"
                style={{ width: "575px" }}
              >
                <button
                  onClick={() => {
                    this.confirmAlertRef.onClose();
                    this.confirmAlertRef = null;
                    this.setState({ reportStatus: "" });
                  }}
                  type="button"
                  class="close"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>

                <h1>Alert !</h1>
                <p>Last generated : {this.state.lastGenRepDate} </p>

                <p> Do you want to Generate the Report ?</p>
                <button
                  className="btn btn-primary btn-prev-report"
                  onClick={() => {
                    this.generateReport(reportTypeValue);
                  }}
                >
                  Generate Report
                </button>

                <button
                  className="btn btn-primary"
                  style={{ marginRight: "60px", width: "115px" }}
                  // disabled={!this.state.reportUrl}
                  disabled={
                    !(
                      this.state.reportStatus === "Y" ||
                      this.state.lastGenRepDate
                    ) || this.state.lastGenRepDate === "Report not generated"
                  }
                  onClick={() => {
                    this.downloadReport();
                  }}
                >
                  Download
                </button>

                <button
                  className="btn btn-primary"
                  onClick={() => {
                    this.fetchReportStatus();
                  }}
                  style={{ width: "115px" }}
                >
                  Refresh
                </button>
                {this.state.reportStatusMessage !== "" && (
                  <div style={{ marginTop: "20px", whiteSpace: "pre-line" }}>
                    <p>{this.state.reportStatusMessage}</p>
                  </div>
                )}
              </div>
            )}
          </>
        );
      },
    });
  };

  generateReport = (reportTypeValue) => {
    this.setState({
      loading: true,
    });
    this.dataAndSummaryReport("", "", "");
    let request = {
      taskId: this.state.taskIdOfReport,
      userFormatId: this.props.userRoleId,
      userId: this.props.userID,
      formatId: this.props.userFormatId,
      reportType: reportTypeValue,
    };

    var url = `/TaskReport?key=${Encrypt(request)}`;

    fetch(url, {
      method: "GET",
      headers: {
        guId: this.props.userGuid,
        Authorization: "Basic " + basicAuth(this.props.userID),
      },
    })
      // .then((response) => {
      //   // Simulate delay of 3 seconds
      //   return new Promise((resolve) =>
      //     setTimeout(() => resolve(response), 30000)
      //   );
      // })
      .then((data) => {
        console.log("DT", data);
        return data.blob();
      })
      .then((data) => {
        const a = new File([data], "", {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const blobUrl = URL.createObjectURL(a);
        this.setState({ reportUrl: blobUrl });
        console.log("Ds", data);
        this.setState({
          reportStatusMessage:
            "Report Generation is in progress.\n1) Click on refresh to fetch the report.\n2) Click on download",
        });
        this.confirmAlertRef.onClose();
        this.confirmAlertRef = null;
        this.setState({
          loading: false,
        });
        this.dataAndSummaryReport("", "", "");
      })
      .catch((error) => {
        console.log("Error is", error);
        confirmAlert({
          title: "Alert !",
          message: "Session expired",
          buttons: [
            {
              label: "Ok",
              onClick: () => {
                this.logoutnormal();
              },
            },
          ],
        });
      });
  };

  fetchReportStatus = (e) => {
    this.setState({ loading: true });
    this.dataAndSummaryReport("", "", "");
    let request = {
      taskId: this.state.taskIdOfReport,
      guId: this.props.userGuid,
    };

    var EncryptedRequest = Encrypt(request);

    fetch("/FetchReportStatus", {
      method: "POST",
      headers: {
        Authorization: "Basic " + basicAuth(this.props.userID),
        guId: this.props.userGuid,
      },
      body: EncryptedRequest,
    })
      // .then((response) => {
      //   // Simulate delay of 30 seconds
      //   return new Promise((resolve) =>
      //     setTimeout(() => resolve(response), 30000)
      //   );
      // })
      .then((response) => response.text())
      .then((response) => {
        var DecryptedResponse = decryptData(response);
        console.log("Fetch Response", DecryptedResponse);
        {
          DecryptedResponse.lastReportDate === null
            ? this.setState({ lastGenRepDate: "Report not generated" })
            : this.setState({
                lastGenRepDate: moment(DecryptedResponse.lastReportDate).format(
                  "DD-MM-YYYY HH:mm:ss"
                ),
              });
        }

        this.setState({ reportStatus: DecryptedResponse.flag });
        this.confirmAlertRef.onClose();
        this.confirmAlertRef = null;
        this.setState({ loading: false });
        this.dataAndSummaryReport("", "", "");
      })
      .catch((error) => {
        confirmAlert({
          title: "Alert !",
          message: "Session expired",
          buttons: [
            {
              label: "Ok",
              onClick: () => {
                this.props.logoutnormal();
              },
            },
          ],
        });
      });
  };

  downloadReport = () => {
    const a = document.createElement("a");
    a.href = this.state.reportUrl;
    // a.href = `http://10.21.53.125/report/KoshtaKrutiReports_${this.state.taskIdOfReport}.xlsx`;
    a.download = `${this.state.taskIdOfReport}`;
    a.hidden = true;
    document.body.appendChild(a);
    a.click();
    a.parentNode.removeChild(a);
    this.setState({
      reportStatusMessage: "",
    });
    this.confirmAlertRef.onClose();
    this.confirmAlertRef = null;
    this.setState({ reportStatus: "" });
  };

  render = () => {
    var that = this;
    return (
      <div className="dropdown">
        <button
          onClick={this.toggleDropdown}
          className="dropbtn"
          class="btn btn-primary min-wid-90 mt-57 ml-10 dropdown-toggle"
        >
          Generate
        </button>
        {this.state.isDropdownOpen && (
          <div className="dropdown-content">
            <a
              href="#"
              className="custom-option"
              onClick={(e) => {
                this.dataAndSummaryReport(e, "dataAndSummary", "link");
              }}
            >
              Summary / Detailed Summary
            </a>
            <a
              href="#"
              className="custom-option"
              onClick={(e) => {
                this.dataAndSummaryReport(e, "Data", "link");
              }}
            >
              Data
            </a>
          </div>
        )}
      </div>
    );
  };
}

export default DropdownButton;
