import disableBrowserBackButton from "disable-browser-back-navigation";
import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert"; // Import
import { Scrollbars } from "react-custom-scrollbars";
import IdleTimer from "react-idle-timer"; // For Idle
import Spinner from "react-spinner-material"; //Import Spinner
import { basicAuth } from "./BasicAuth";
import "./css/react-confirm-alert.css"; // Import css
import "./css/style-responsive.css";
import "./css/style.css";
import { decryptData, Encrypt } from "./Encryption-Decrypytion";
import addDays from "date-fns/addDays";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const margin = { top: 20, right: 20, bottom: 30, left: 40 };
const display = {
  display: "block"
};
const hide = {
  display: "none"
};
class IssueDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fromdata: [],
      totalclosed: [],
      sum1: [],
      headers: [],
      answerList: [],
      BarChartArray: [],
      percentage: "",
      draft: "",
      newTask: "",
      save: "",
      total: "",
      loading: false,
      pendCount: [],
      pendingStores: "",
      open: "",
      missed: "",
      review: "",
      closed: "",
      openStatus: false,
      onClickOpen: [],
      onClickCompleted: [],
      onClickMissed: [],
      onClickReview: [],
      onClickTotal: [],
      toggle1: false,
      taskId: "",
      taskName: "",
      endDate: "",
      enddateissue: "",
      startDate: "",
      role_id: "",
      taskDescr: ""
    }

    this.state = {
      fromdata: [],
      totalclosed: [],
      sum1: [],
      headers: [],
      answerList: [],
      BarChartArray: [],
      percentage: "",
      draft: "",
      newTask: "",
      save: "",
      total: "",
      loading: false,
      pendCount: [],
      pendingStores: "",
      open: "",
      missed: "",
      review: "",
      closed: "",
      openStatus: false,
      onClickOpen: [],
      onClickCompleted: [],
      onClickMissed: [],
      onClickReview: [],
      onClickTotal: [],
      toggle1: false,
      taskId: "",
      taskName: "",
      endDate: "",
      enddateissue: "",
      startDate: "",
      role_id: "",
      taskDescr: "",
      searchID: "",
      searchTitle: "",
      searchAssgnTo: "",
    }
    this.handletickereupdate = this.handletickereupdate.bind(this);

  };
  onChange1 = e => {
    this.setState({ searchID: e.target.value });
  };

  onChange2 = e => {
    this.setState({ searchTitle: e.target.value });
  };

  onChange3 = e => {
    this.setState({ searchAssgnTo: e.target.value });
  };

  logoutnormal = () => {
    localStorage.clear();
    this.props.history.replace("/");
    disableBrowserBackButton();
  };

  componentWillMount() {
    this.TaskPieChart();
  }
  IssueRaising = () => {
    this.props.history.push({
      pathname: "/IssueRaising",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        data: this.props.location.state.data,
        tskId: this.props.location.state.tskId,
        tskName: this.props.location.state.tskName,
        storeNo: this.props.location.state.storeNo,
        Isroffice: this.props.location.state.Isroffice,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  CompletedTaskListbind = () => {
    this.props.history.push({
      pathname: "/CompletedTaskList",
      state: {
        data: this.state.data,
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  _onAction(e) { }

  _onActive(e) { }

  _onIdle(e) {
    localStorage.clear();
    window.location.href = "/";
    disableBrowserBackButton();
  }



  toggle1 = (taskId, taskName, endDate, startDate, taskDescr) => {
    this.setState({
      toggle1: !this.state.toggle1,

      taskId: taskId,
      taskName: taskName,
      endDate: endDate,
      startDate: startDate,
      taskDescr: taskDescr
    })
  }
  modalclose = () => {
    this.setState({
      toggle1: false

    });


  };

  handletickereupdate(dates) {
    this.setState({
      enddateissue: dates,

      endDate: dates
    });
  }

  render = () => {
    var that = this


    const { searchID } = this.state;
    const { searchTitle } = this.state;
    const { searchAssgnTo } = this.state;

    var modal = [];
    modal.push(
      <div
        className="modal"
        role="dialog"
        style={this.state.toggle1 ? display : hide}
      >
        <div className="modal-dialog animate">
          <div className="modal-content">
            <div className="modal-header">
              <a
                className="close"
                style={{ color: "#fff" }}
                onClick={this.modalclose}
              >
                X
              </a>
              <h4 className="modal-title">Edit Issue</h4>
            </div>

            <div className="modal-body box-scroll">
              <div className="form-horizontal">
                <div className="form-group">
                  <h4 className="control-label col-sm-4">Issue ID</h4>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      class="form-control"
                      value={this.state.taskId}
                      //  onChange={f => this.setState({ taskId: f.target.value })}
                      id="taskupID"
                      disabled
                    ></input>
                    <br />
                  </div>

                  <h4 className="control-label col-sm-4">Issue Name</h4>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      class="form-control"
                      value={this.state.taskName}
                      onChange={f =>
                        this.setState({ taskName: f.target.value })
                      }
                      id="taskupName"
                      autoComplete="off"
                      maxLength="250"
                    ></input>
                    <br />
                  </div>

                  <h4 className="control-label col-sm-4"> Description</h4>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      class="form-control"
                      value={this.state.taskDescr}
                      onChange={f =>
                        this.setState({ taskDescr: f.target.value })
                      }
                      id="taskupDesc"
                      autoComplete="off"
                      maxLength="250"
                    ></input>
                  </div>

                  <div className="clearfix"></div>

                  <div>




                    <h4 className="control-label col-sm-4 mt-20">Issue Due Date</h4>

                    <div className="col-sm-8 mt-20">
                      <DatePicker
                        ref="enddate"
                        className="form-control"
                        size="16"
                        type="text"
                        dateFormat="dd-MMM-yyyy HH:mm"
                        showTimeSelect
                        timeIntervals={30}
                        id="updateendDate"
                        onChange={this.handletickereupdate}
                        maxDate={addDays(new Date(), 365)}
                        selected={this.state.enddateissue}
                        minDate={new Date()}
                        autoComplete="off"
                        value={this.state.endDate}
                      />
                    </div>






                  </div>

                </div>
                <div className="col-sm-8" style={{ "margin-left": "180px" }}>
                  <button
                    class="btn btn-primary min-wid-90 mt-17"
                    onClick={this.UpdateIssue.bind(this)}
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
    return (
      <div>
        <div className={this.state.loading ? 'parentDisable' : ''} width="100%">
          <div className='overlay-box'>
            <Spinner
              visible={this.state.loading}
              spinnerColor={"rgba(0, 0, 0, 0.3)"}
            />
          </div>
        </div>
        <div>
          <IdleTimer
            ref={ref => {
              this.idleTimer = ref;
            }}
            element={document}
            onActive={this.onActive}
            onIdle={this.onIdle}
            onAction={this.onAction}
            debounce={250}
            timeout={1000 * 60 * 10}
          />
          {modal}</div>
        {this.props.location.state.role_id === "510" ?
          <div className="modal-header" style={{ "border-radius": "0px" }}>
            <a
              className="close"
              style={{ "font-weight": "bold", color: "#fff" }}
              onClick={this.CompletedTaskListbind}
            >
              X
            </a>
            <h4 className="modal-title" style={{ "textAlign": "center" }}>
              Issue Dashboard
            </h4>
          </div>
          :
          <div className="modal-header" style={{ "border-radius": "0px" }}>
            <a
              className="close"
              style={{ "font-weight": "bold", color: "#fff" }}
              onClick={this.IssueRaising}
            >
              X
            </a>
            <h4 className="modal-title" style={{ "textAlign": "center" }}>
              Issue Dashboard
            </h4>
          </div>
        }
        <div className="modal-body">
          {/* <h1 className="m-5">{this.state.taskName}</h1> */}
          {/* {this.state.sum1.length > 0 ? ( */}
          <div className="mt">

            <div className="dahboard">
              <span style={{ color: "blue" }}>
                <b><a onClick={this.openTotal.bind(this)}>Total</a></b>
              </span>
              <br />
              <b>{this.state.total}</b>
            </div>

            <div className="dahboard" >
              <span style={{ color: "blue" }}>
                <b><a onClick={this.openBlock.bind(this)}>Open</a></b>
              </span>
              <br />
              <b>{this.state.open}</b>
            </div>

            <div className="dahboard">
              <span style={{ color: "PURPLE" }}>
                <b><a onClick={this.openMissed.bind(this)}>Missed</a></b>
              </span>
              <br />
              <b>{this.state.missed}</b>
            </div>

            <div className="dahboard">
              <span style={{ color: "FUCHSIA" }}>
                <b><a onClick={this.openReview.bind(this)}>Reviewed / Pending</a></b>
              </span>
              <br />
              <b> {this.state.review}</b>
            </div>

            <div className="dahboard">
              <span style={{ color: "red" }}>
                <b><a onClick={this.openClosed.bind(this)}>Closed</a></b>
              </span>
              <br />
              <b> {this.state.closed}</b>
            </div>


          </div>
        </div>
        <div className="mt">
          <div className="col-md-12">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
                  {" "}
                  <b>Issue ID</b>
                  <br />
                  <input
                    type="text"
                    placeholder="Search Task Name"
                    className="form-control"
                    id="taskname"
                    onChange={this.onChange1}
                    autoComplete="off"
                  />
                </div>

                <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
                  {" "}
                  <b>Issue Title</b>
                  <br />
                  <input
                    type="text"
                    placeholder="Search Task Name"
                    className="form-control"
                    id="taskname"
                    onChange={this.onChange2}
                    autoComplete="off"
                  />
                </div>

                <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
                  {" "}
                  <b>Assigned To</b>
                  <br />
                  <input
                    type="text"
                    placeholder="Search Task Name"
                    className="form-control"
                    id="taskname"
                    onChange={this.onChange3}
                    autoComplete="off"
                  />
                </div>
                <div className="col-xs-12 col-sm-1 col-md-1 col-lg-1 mt-xs-10">
                  <img
                    src={require("./img/searchicon.png")}
                    className="user-img mt-20"
                    style={{ width: "25px", height: "25px" }}
                  />
                </div>
                <div className="clearfix"></div>
              </div>
              <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12 tbwdth">
                <div className="mt">

                  <div className="tbl-holder" /* style={{height : "600px"}} */>

                    <table className="table table-striped table-advance table-hover table-bordered tbl-task tbl-hhide">

                      <thead>

                        <tr>
                          <th>Issue ID</th>
                          <th>Issue Title</th>
                          <th>Assigned To</th>
                          <th>Created Date</th>
                          <th>Due Date</th>
                          <th>TAT (hr:min)</th>
                          <th>Status</th>
                          <th>Created By</th>
                          <th>Location</th>

                          {this.state.role_id == "600" ?
                            <th>Action</th> : null}
                        </tr>
                      </thead>
                    </table>
                    <Scrollbars style={{ height: 296 }}>

                      <table className="table table-striped table-advance table-hover table-bordered tbl-task mob-tbl">

                        <tbody>


                          {this.state.fromdata.map(function (item, key) {
                            if (
                              (
                                searchID !== "" ||
                                searchTitle !== "" ||
                                searchAssgnTo !== "") &&
                              (
                                item.taskId
                                  .toLowerCase()
                                  .indexOf(
                                    searchID.toLowerCase()
                                  ) ||
                                item.taskName
                                  .toLowerCase()
                                  .indexOf(
                                    searchTitle.toLowerCase()
                                  ) ||
                                item.storeNo
                                  .toLowerCase()
                                  .indexOf(
                                    searchAssgnTo.toLowerCase()
                                  )) === -1
                            ) {
                              return null;
                            }
                            return (
                              <tr key={key}>
                                <td data-th="Issue ID">{item.taskId}</td>
                                <td data-th="Issue Title">{item.taskName}</td>
                                <td data-th="Assigned To">{item.storeNo}</td>
                                <td data-th="Created Date">{item.startDate}</td>
                                <td data-th="Date/Time">{item.endDate}</td>
                                <td data-th="TAT">{item.tat}</td>
                                <td data-th="Status">{item.status}</td>
                                <td data-th="Created By">{item.userName}</td>
                                <td data-th="Location">{item.location}</td>

                                {that.state.role_id == "600" ? <td data-th="Action">
                                  <a className="edit-icon" onClick={that.toggle1.bind(that, item.taskId, item.taskName, item.endDate, item.startDate, item.taskDescr)}
                                  ><i className="fa fa-pencil "></i> </a>
                                  <a className="delete-icon" onClick={that.deletetask.bind(that, item.taskId)}>
                                    <i className="fa fa-trash-o "></i>
                                  </a>

                                  <span className="d-none">
                                    {item.taskId}
                                  </span>
                                </td> : null}
                              </tr>
                            )
                          })}

                        </tbody>
                      </table>
                    </Scrollbars>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    );
  };

  UpdateIssue(e) {
    e.preventDefault();
    if (document.getElementById("taskupName").value == "" || document.getElementById("taskupDesc").value == "") {
      confirmAlert({

        message: "Please enter required fields",
        buttons: [
          {
            label: "Ok",
            onClick: () => {
              return false;
            }
          }
        ]
      });
    } else {
      confirmAlert({

        message: "Are you sure, you want to update the issue?",
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              var Request1 = {
                taskId: this.state.taskId,
                taskDesc: document.getElementById("taskupDesc").value,
                startDate: this.state.startDate,
                endDate: document.getElementById("updateendDate").value,
                createdBy: this.props.location.state.userid,
                taskName: document.getElementById("taskupName").value,
                action: "None",
                storeId: [],
                roleId: ["900"],
                guId: this.props.location.state.guId
              };
              var EncryptedRequest1 = Encrypt(Request1);

              fetch("/UpdateTask", {
                method: "POST",
                headers: {
                  guId: this.props.location.state.guId,
                  Authorization:
                    "Basic " + basicAuth(this.props.location.state.userid)
                },
                body: EncryptedRequest1
              })
                .then(response => response.text())
                .then(response => {
                  var DecryptedResponse = decryptData(response);

                  if (DecryptedResponse.errorCode === "00") {
                    confirmAlert({
                      message: "Issue updated successfully. ",
                      buttons: [
                        {
                          label: "Ok",
                          onClick: () => {
                            window.location.reload(true)
                          }
                        }
                      ]
                    });
                    this.modalclose()
                  } else {
                    confirmAlert({
                      title: "Alert !",
                      message: DecryptedResponse.errorMsg,
                      buttons: [
                        {
                          label: "Ok"
                        }
                      ]
                    });
                  }
                })
                .catch(error => {
                  confirmAlert({
                    title: "Alert !",
                    message: "Session expired",
                    buttons: [
                      {
                        label: "Ok",
                        onClick: () => {
                          this.logoutnormal();
                        }
                      }
                    ]
                  });
                });
            }
          },
          {
            label: "No",
            onClick: () => {
              return false;
            }
          }
        ]
      });


    }
  }

  deletetask(tskid) {
    confirmAlert({

      message: "Are you sure, you want to delete this issue?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            var Request2 = {
              taskId: tskid,
              createdBy: this.props.location.state.userid,
              action: "Delete",
              guId: this.props.location.state.guId
            };
            var EncryptedRequest2 = Encrypt(Request2);
            fetch("/DeleteTask", {
              method: "POST",
              headers: {
                guId: this.props.location.state.guId,
                Authorization:
                  "Basic " + basicAuth(this.props.location.state.userid)
              },
              body: EncryptedRequest2
            })
              .then(response => response.text())
              .then(response => {
                var DecryptedResponse2 = decryptData(response);
                if (DecryptedResponse2.errorCode === "00") {
                  confirmAlert({
                    message: "Issue  Deleted Successfully.",
                    buttons: [
                      {
                        label: "Ok",
                        onClick: () => {
                          window.location.reload(true);
                        }
                      }
                    ]
                  });
                  this.forceUpdate();
                } else {
                  confirmAlert({
                    title: "Alert !",
                    message: DecryptedResponse2.errorMsg,
                    buttons: [
                      {
                        label: "Ok"
                      }
                    ]
                  });
                }
              })
              .catch(error => {
                confirmAlert({
                  title: "Alert !",
                  message: "Session expired",
                  buttons: [
                    {
                      label: "Ok",
                      onClick: () => {
                        this.logoutnormal();
                      }
                    }
                  ]
                });
              });
          }
        },
        {
          label: "No",
          onClick: () => {
            return false;
          }
        }
      ]
    });
  }


  callupdatetaskpage(tskId, tskName, tskDescr, strtDate, edDate, taskRoleId, taskType) {
    this.props.history.push({
      pathname: "/Updatetasks",
      state: {
        tskId: tskId,
        tskName: tskName,
        tskDescr: tskDescr,
        strtDate: new Date(strtDate),
        edDate: new Date(edDate),
        userid: this.props.location.state.userid,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        usrnm: this.props.location.state.usrnm,
        taskRoleId: taskRoleId,
        taskType: taskType,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        dashboardHeaders: "DashboardHeaders",
        roleName: this.props.location.state.roleName,
      }
    });
  }



  openTotal = () => {

    this.setState({ fromdata: [] })
    this.setState({ fromdata: this.state.onClickTotal })


  }

  openBlock = () => {

    this.setState({ fromdata: [] })
    this.setState({ fromdata: this.state.onClickOpen })

  }

  openMissed = () => {
    this.setState({ fromdata: [] })
    this.setState({ fromdata: this.state.onClickMissed })

  }

  openReview = () => {
    this.setState({ fromdata: [] })
    this.setState({ fromdata: this.state.onClickReview })

  }

  openClosed = () => {
    this.setState({ fromdata: [] })
    this.setState({ fromdata: this.state.onClickCompleted })

  }

  TaskPieChart = () => {
    if (
      this.props.location.state !== undefined &&
      this.props.location.state.usrnm !== ""
    ) {
      this.setState({
        loading: true,
        role_id: this.props.location.state.role_id,
      });
      var title = [];
      var sum = 0;
      var Request1 = {
        userId: this.props.location.state.userid,
        guId: this.props.location.state.guId
      };
      var EncryptedRequest1 = Encrypt(Request1);

      fetch("/IssueListByUser", {
        method: "POST",
        headers: {
          guId: this.props.location.state.guId,
          Authorization: "Basic " + basicAuth(this.props.location.state.userid)
        },
        body: EncryptedRequest1
      })
        .then(response => response.text())
        .then(response => {
          var DecryptedResponse = decryptData(response);
          if (DecryptedResponse.errorCode === "00") {

            for (let p = 0; p < DecryptedResponse.issueList.length; p++) {
              if (DecryptedResponse.issueList[p].status === "Open") {
                this.state.onClickOpen.push(DecryptedResponse.issueList[p])
              }
              else {
                if (DecryptedResponse.issueList[p].status === "Completed") {
                  this.state.onClickCompleted.push(DecryptedResponse.issueList[p])
                }
                else {
                  if (DecryptedResponse.issueList[p].status === "Missed") {

                    this.state.onClickMissed.push(DecryptedResponse.issueList[p])
                  }
                  else {
                    this.state.onClickReview.push(DecryptedResponse.issueList[p])
                  }
                }
              }
            }
            this.setState({ fromdata: DecryptedResponse.issueList })
            this.setState({ onClickTotal: DecryptedResponse.issueList })
            this.setState({ total: DecryptedResponse.totalIssue });
            this.setState({ open: DecryptedResponse.openIssue });
            this.setState({ missed: DecryptedResponse.missedIssue });
            this.setState({ review: DecryptedResponse.reviewIssue });
            this.setState({ closed: DecryptedResponse.closedIssue });
            var issuetotal = {
              title: "",
              value: ""
            };

            issuetotal.title = "totalIssue"
            issuetotal.value = DecryptedResponse.totalIssue
            var issueclose = {
              title: "",
              value: ""
            };

            issueclose.title = "closedIssue"
            issueclose.value = (DecryptedResponse.closedIssue / issuetotal.value)
            var issueremain = {
              title: "",
              value: ""
            };

            issueremain.title = "issueremain"
            issueremain.value = (issuetotal.value - issueclose.value / issuetotal.value)

            this.state.totalclosed.push(issueremain)
            this.state.totalclosed.push(issueclose)

            this.setState(prevState => ({
              toggle: !prevState.toggle,
              loading: false
            }));
            this.setState({
              taskName: this.props.location.state.taskName,
              taskDescr: this.props.location.state.taskDescr
            });
          } else {
            confirmAlert({
              title: "Alert !",
              message: DecryptedResponse.errorMsg,
              buttons: [
                {
                  label: "Ok"
                }
              ]
            });
            this.setState({ loading: false });
          }
        })
        .catch(error => {
          confirmAlert({
            title: "Alert !",
            message: "Session expired",
            buttons: [
              {
                label: "Ok",
                onClick: () => {
                  this.logoutnormal();
                }
              }
            ]
          });
        });
    } else {
      window.location.href = "/";
    }
  };
}
export default IssueDashboard;


