import React, { Component } from "react";
import "jquery/dist/jquery";
import "./css/style.css";
import "./css/style-responsive.css";
import { decryptData } from "./Encryption-Decrypytion";

class Menu extends Component {
  state = {
    TaskDropdwn: false,
    hasError: false 
  };

  constructor(props) {
    super(props);
    this.state = {
      hammenu: true,
      TaskDropdwn: false,
      displayMenu: false,
      Taskdwn: false,
      menuListFrmStorage: {},
      propsFrmStorage: {}
    };
    this.showDropdownMenu = this.showDropdownMenu.bind(this);
    this.hideDropdownMenu = this.hideDropdownMenu.bind(this);
  }

  componentWillMount(){
    this.state.propsFrmStorage = JSON.parse(decryptData(localStorage.getItem('propsObj')));
    this.props = this.state.propsFrmStorage;
  }
  
  componentDidMount() {
    if (window.innerWidth <= 768) {
      this.setState({ hammenu: false });
    }
  }

  Reportsbind = () => {
    
    this.props.history.push({
      pathname: "/Reports",
      state: {
        userid: this.state.propsFrmStorage.location.state.userid,
        usrnm: this.state.propsFrmStorage.location.state.usrnm,
        role_id: this.state.propsFrmStorage.location.state.role_id,
        format_id: this.state.propsFrmStorage.location.state.format_id,
        data: this.state.propsFrmStorage.location.state.data,
        tskId: this.state.propsFrmStorage.location.state.tskId,
        tskName: this.state.propsFrmStorage.location.state.tskName,
        storeNo: this.state.propsFrmStorage.location.state.storeNo,
        Isroffice: this.state.propsFrmStorage.location.state.Isroffice,
        guId: this.state.propsFrmStorage.location.state.guId,
        menuList: this.state.propsFrmStorage.location.state.menuList,
        roleName: this.state.propsFrmStorage.location.state.roleName,
      }
    });
  };


  render() {
    return (
      <>
            {this.state.propsFrmStorage.location.state.menuList["401"] || this.state.propsFrmStorage.location.state.menuList["402"] || this.state.propsFrmStorage.location.state.menuList["403"] || this.state.propsFrmStorage.location.state.menuList["404"] || this.state.propsFrmStorage.location.state.menuList["405"] || this.state.propsFrmStorage.location.state.menuList["408"] || this.state.propsFrmStorage.location.state.menuList["411"] ? (
              <li
                className="sub-menu mt"
                onClick={this.showTaskDropdwnHandler.bind(this)}
              >
                {" "}
                <a href="javascript:;">
                  {" "}
                  <i className="fa fa-tasks"></i> <span>Task</span>{" "}
                </a>
                {this.state.TaskDropdwn ? (
                  <div>
                    <ul class="sub">
                      {this.state.propsFrmStorage.location.state.menuList["401"] ? (
                        <li>
                          <a onClick={this.TaskactiveBind.bind(this)}>
                            {this.state.propsFrmStorage.location.state.menuList["401"]}
                          </a>
                        </li>
                      ) : null}
                      {this.state.propsFrmStorage.location.state.menuList["402"] ? (
                        <li>
                          <a onClick={this.getroles.bind(this)}>
                            {this.state.propsFrmStorage.location.state.menuList["402"]}
                          </a>
                        </li>
                      ) : null}
                      {this.state.propsFrmStorage.location.state.menuList["403"] ? (
                        <li>
                          <a
                            onClick={this.CompletedTaskListbind.bind(
                              this
                            )}
                          >
                            {this.state.propsFrmStorage.location.state.menuList["403"]}
                          </a>
                        </li>
                      ) : null}
                      {this.state.propsFrmStorage.location.state.menuList["404"] ? (
                        <li>
                          <a onClick={this.templates.bind(this)}>
                            {this.state.propsFrmStorage.location.state.menuList["404"]}
                          </a>
                        </li>
                      ) : null}
                      {this.state.propsFrmStorage.location.state.menuList["405"] ? (
                        <li>
                          <a onClick={this.ArchivedTasks.bind(this)}>
                            {this.state.propsFrmStorage.location.state.menuList["405"]}
                          </a>
                        </li>
                      ) : null}
                      {this.state.propsFrmStorage.location.state.menuList["408"] ? (
                        <li>
                          <a onClick={this.StoreTasks.bind(this)}>
                            {this.state.propsFrmStorage.location.state.menuList["408"]}
                          </a>
                        </li>
                      ) : null}
                      {this.state.propsFrmStorage.location.state.menuList["411"] ? (
                        <li>
                          <a onClick={this.toGenDashboard.bind(this)}>
                            {this.state.propsFrmStorage.location.state.menuList["411"]}
                          </a>
                        </li>
                      ) : null}
                    </ul>
                  </div>
                ) : null}
              </li>
            ) : null}
            {this.state.propsFrmStorage.location.state.menuList["406"] ? (
              <div>
                <li>
                  {" "}
                  <a onClick={this.Reportsbind.bind(this)}>
                    {" "}
                    <i className="fa fa-tasks"></i>{" "}
                    <span>
                      {this.state.propsFrmStorage.location.state.menuList["406"]}
                    </span>{" "}
                  </a>{" "}
                </li>
              </div>
            ) : null}

            {this.state.propsFrmStorage.location.state.menuList["414"]? (
              <div>
                <li>
                  {" "}
                  <a onClick={this.Defectbind.bind(this)}>
                    {" "}
                    <i className="fa fa-tasks"></i>{" "}
                    <span>
                      {this.state.propsFrmStorage.location.state.menuList["414"]}
                    </span>{" "}
                  </a>{" "}
                </li>
              </div>
            ) : null}

            {this.state.propsFrmStorage.location.state.menuList["415"] ? (
            <div>
                <li>
                  {" "}
                  <a onClick={this.SmartPointBind.bind(this)}>
                    {" "}
                    <i className="fa fa-tasks"></i>{" "}
                    <span>
                    {this.state.propsFrmStorage.location.state.menuList["415"]}
                    </span>{" "}
                  </a>{" "}
                </li>
              </div>
            ) : null }

            {this.state.propsFrmStorage.location.state.menuList["409"] || this.state.propsFrmStorage.location.state.menuList["410"] ? (
              <div>
                <li
                  className="sub-menu"
                  onClick={this.showIssueDropdwnHandler.bind(this)}
                >
                  {" "}
                  <a href="javascript:;">
                    {" "}
                    <i className="fa fa-tasks"></i>{" "}
                    <span>Issue</span>{" "}
                  </a>
                  {this.state.IssueDropdwn ? (
                    <div>
                      <ul class="sub">
                        {this.state.propsFrmStorage.location.state.menuList["410"] ? (
                          <li>
                            <a onClick={this.IssueRaising.bind(this)}>
                              {this.state.propsFrmStorage.location.state.menuList["410"]}
                            </a>
                          </li>
                        ) : null}
                        {this.state.propsFrmStorage.location.state.menuList["409"] ? (
                          <li>
                            <a onClick={this.IssueDashboard.bind(this)}>
                              {this.state.propsFrmStorage.location.state.menuList["409"]}
                            </a>
                          </li>
                        ) : null}
                      </ul>
                    </div>
                  ) : null}
                </li>
              </div>
            ) : null}

            {this.state.propsFrmStorage.location.state.menuList["407"] ? (
              <div>
                <li
                  className="sub-menu"
                  onClick={this.showTaskdwnHandler.bind(this)}
                >
                  {" "}
                  <a href="javascript:;">
                    {" "}
                    <i className="fa fa-tasks"></i>{" "}
                    <span>
                      {this.state.propsFrmStorage.location.state.menuList["407"]}
                    </span>{" "}
                  </a>
                  {this.state.Taskdwn ? (
                    <div>
                      <ul class="sub">
                        <li>
                          {" "}
                          <a onClick={this.tiker.bind(this)}>
                            <span>Tiker Creation</span>{" "}
                          </a>{" "}
                        </li>
                        <li>
                          {" "}
                          <a onClick={this.userlist.bind(this)}>
                            <span>User List</span>{" "}
                          </a>{" "}
                        </li>
                        {this.state.propsFrmStorage.location.state.menuList["412"] ? (
                          <li>
                            {" "}
                            <a onClick={this.Storecreation.bind(this)}>
                              <span>{this.state.propsFrmStorage.location.state.menuList["412"]}</span>{" "}
                            </a>{" "}
                          </li>
                        ) : null}
                        {this.state.propsFrmStorage.location.state.menuList["413"] ? (
                          <li>
                            {" "}
                            <a onClick={this.ToStoreList.bind(this)}>
                              <span>{this.state.propsFrmStorage.location.state.menuList["413"]}</span>{" "}
                            </a>{" "}
                          </li>
                        ) : null}
                      </ul>
                    </div>
                  ) : null}
                </li>
              </div>
            ) : null}
            </>
    );
  }

  showTaskdwnHandler = () => {
    this.setState({
      Taskdwn: !this.state.Taskdwn
    });
  };

  showIssueDropdwnHandler = () => {
    this.setState({
      IssueDropdwn: !this.state.IssueDropdwn
    });
  };

  TaskactiveBind = () => {
    this.props.history.push({
      pathname: "/Taskactive",
      state: {
        userid: this.state.propsFrmStorage.location.state.userid,
        usrnm: this.state.propsFrmStorage.location.state.usrnm,
        role_id: this.state.propsFrmStorage.location.state.role_id,
        format_id: this.state.propsFrmStorage.location.state.format_id,
        storeNo: this.state.propsFrmStorage.location.state.storeNo,
        guId: this.state.propsFrmStorage.location.state.guId,
        menuList: this.state.propsFrmStorage.location.state.menuList,
        roleName: this.state.propsFrmStorage.location.state.roleName,
      }
    });
  };

  showTaskDropdwnHandler = () => {
    this.setState({
      TaskDropdwn: !this.state.TaskDropdwn
    });
  };

  getroles(e) {
    this.props.history.push({
      pathname: "/CreatetaskUI",
      state: {
        format_id: this.state.propsFrmStorage.location.state.format_id,
        role_id: this.state.propsFrmStorage.location.state.role_id,
        userid: this.state.propsFrmStorage.location.state.userid,
        usrnm: this.state.propsFrmStorage.location.state.usrnm,
        storeNo: this.state.propsFrmStorage.location.state.storeNo,
        guId: this.state.propsFrmStorage.location.state.guId,
        menuList: this.state.propsFrmStorage.location.state.menuList,
        roleName: this.state.propsFrmStorage.location.state.roleName,
      }
    });
  }

  CompletedTaskListbind = () => {
    this.props.history.push({
      pathname: "/CompletedTaskList",
      state: {
        data: this.state.propsFrmStorage.location.state.data,
        userid: this.state.propsFrmStorage.location.state.userid,
        usrnm: this.state.propsFrmStorage.location.state.usrnm,
        role_id: this.state.propsFrmStorage.location.state.role_id,
        format_id: this.state.propsFrmStorage.location.state.format_id,
        storeNo: this.state.propsFrmStorage.location.state.storeNo,
        guId: this.state.propsFrmStorage.location.state.guId,
        menuList: this.state.propsFrmStorage.location.state.menuList,
        roleName: this.state.propsFrmStorage.location.state.roleName,
      }
    });
  };

  templates = () => {
    this.props.history.push({
      pathname: "/Templates",
      state: {
        userid: this.state.propsFrmStorage.location.state.userid,
        usrnm: this.state.propsFrmStorage.location.state.usrnm,
        role_id: this.state.propsFrmStorage.location.state.role_id,
        format_id: this.state.propsFrmStorage.location.state.format_id,
        storeNo: this.state.propsFrmStorage.location.state.storeNo,
        guId: this.state.propsFrmStorage.location.state.guId,
        menuList: this.state.propsFrmStorage.location.state.menuList,
        roleName: this.state.propsFrmStorage.location.state.roleName,
      }
    });
  };

  ArchivedTasks = () => {
    this.props.history.push({
      pathname: "/ArchivedTasks",
      state: {
        data: this.state.propsFrmStorage.location.state.data,
        userid: this.state.propsFrmStorage.location.state.userid,
        usrnm: this.state.propsFrmStorage.location.state.usrnm,
        role_id: this.state.propsFrmStorage.location.state.role_id,
        format_id: this.state.propsFrmStorage.location.state.format_id,
        storeNo: this.state.propsFrmStorage.location.state.storeNo,
        guId: this.state.propsFrmStorage.location.state.guId,
        menuList: this.state.propsFrmStorage.location.state.menuList,
        roleName: this.state.propsFrmStorage.location.state.roleName,
        Isroffice: this.props.location.state.Isroffice,
      }
    });
  };

  StoreTasks = () => {
    this.props.history.push({
      pathname: "/StoreTasks",
      state: {
        data: this.state.propsFrmStorage.location.state.data,
        userid: this.state.propsFrmStorage.location.state.userid,
        usrnm: this.state.propsFrmStorage.location.state.usrnm,
        role_id: this.state.propsFrmStorage.location.state.role_id,
        format_id: this.state.propsFrmStorage.location.state.format_id,
        storeNo: this.state.propsFrmStorage.location.state.storeNo,
        guId: this.state.propsFrmStorage.location.state.guId,
        menuList: this.state.propsFrmStorage.location.state.menuList,
        roleName: this.state.propsFrmStorage.location.state.roleName,
      }
    });
  };

  toGenDashboard = () => {
    this.props.history.push({
      pathname: "/DashboardGeneral",
      state: {
        userid: this.state.propsFrmStorage.location.state.userid,
        usrnm: this.state.propsFrmStorage.location.state.usrnm,
        role_id: this.state.propsFrmStorage.location.state.role_id,
        format_id: this.state.propsFrmStorage.location.state.format_id,
        data: this.state.propsFrmStorage.location.state.data,
        tskId: this.state.propsFrmStorage.location.state.tskId,
        tskName: this.state.propsFrmStorage.location.state.tskName,
        storeNo: this.state.propsFrmStorage.location.state.storeNo,
        Isroffice: this.state.propsFrmStorage.location.state.Isroffice,
        guId: this.state.propsFrmStorage.location.state.guId,
        menuList: this.state.propsFrmStorage.location.state.menuList,
        roleName: this.state.propsFrmStorage.location.state.roleName,
      }
    });
  };


  Defectbind = () => {
    this.props.history.push({
      pathname: "/DefectRaising",
      state: {
        userid: this.state.propsFrmStorage.location.state.userid,
        usrnm: this.state.propsFrmStorage.location.state.usrnm,
        role_id: this.state.propsFrmStorage.location.state.role_id,
        format_id: this.state.propsFrmStorage.location.state.format_id,
        data: this.state.propsFrmStorage.location.state.data,
        tskId: this.state.propsFrmStorage.location.state.tskId,
        tskName: this.state.propsFrmStorage.location.state.tskName,
        storeNo: this.state.propsFrmStorage.location.state.storeNo,
        Isroffice: this.state.propsFrmStorage.location.state.Isroffice,
        guId: this.state.propsFrmStorage.location.state.guId,
        menuList: this.state.propsFrmStorage.location.state.menuList,
        roleName: this.state.propsFrmStorage.location.state.roleName,
      }
    });
  };

  SmartPointBind = () => {
    this.props.history.push({
      pathname: "/SmartPoint",
      state: {
        userid: this.state.propsFrmStorage.location.state.userid,
        usrnm: this.state.propsFrmStorage.location.state.usrnm,
        role_id: this.state.propsFrmStorage.location.state.role_id,
        format_id: this.state.propsFrmStorage.location.state.format_id,
        data: this.state.propsFrmStorage.location.state.data,
        tskId: this.state.propsFrmStorage.location.state.tskId,
        tskName: this.state.propsFrmStorage.location.state.tskName,
        storeNo: this.state.propsFrmStorage.location.state.storeNo,
        Isroffice: this.state.propsFrmStorage.location.state.Isroffice,
        guId: this.state.propsFrmStorage.location.state.guId,
        menuList: this.state.propsFrmStorage.location.state.menuList,
        roleName: this.state.propsFrmStorage.location.state.roleName,
      }
    });
  };

  IssueRaising = () => {
    this.props.history.push({
      pathname: "/IssueRaising",
      state: {
        userid: this.state.propsFrmStorage.location.state.userid,
        usrnm: this.state.propsFrmStorage.location.state.usrnm,
        role_id: this.state.propsFrmStorage.location.state.role_id,
        format_id: this.state.propsFrmStorage.location.state.format_id,
        data: this.state.propsFrmStorage.location.state.data,
        tskId: this.state.propsFrmStorage.location.state.tskId,
        tskName: this.state.propsFrmStorage.location.state.tskName,
        storeNo: this.state.propsFrmStorage.location.state.storeNo,
        Isroffice: this.state.propsFrmStorage.location.state.Isroffice,
        guId: this.state.propsFrmStorage.location.state.guId,
        menuList: this.state.propsFrmStorage.location.state.menuList,
        roleName: this.state.propsFrmStorage.location.state.roleName,
      }
    });
  };

  IssueDashboard = () => {
    this.props.history.push({
      pathname: "/IssueDashboard",
      state: {
        userid: this.state.propsFrmStorage.location.state.userid,
        usrnm: this.state.propsFrmStorage.location.state.usrnm,
        role_id: this.state.propsFrmStorage.location.state.role_id,
        format_id: this.state.propsFrmStorage.location.state.format_id,
        data: this.state.propsFrmStorage.location.state.data,
        tskId: this.state.propsFrmStorage.location.state.tskId,
        tskName: this.state.propsFrmStorage.location.state.tskName,
        storeNo: this.state.propsFrmStorage.location.state.storeNo,
        Isroffice: this.state.propsFrmStorage.location.state.Isroffice,
        guId: this.state.propsFrmStorage.location.state.guId,
        menuList: this.state.propsFrmStorage.location.state.menuList,
        roleName: this.state.propsFrmStorage.location.state.roleName,
      }
    });
  };

  tiker = () => {
    this.props.history.push({
      pathname: "/Tiker",
      state: {
        userid: this.state.propsFrmStorage.location.state.userid,
        usrnm: this.state.propsFrmStorage.location.state.usrnm,
        role_id: this.state.propsFrmStorage.location.state.role_id,
        format_id: this.state.propsFrmStorage.location.state.format_id,
        storeNo: this.state.propsFrmStorage.location.state.storeNo,
        guId: this.state.propsFrmStorage.location.state.guId,
        menuList: this.state.propsFrmStorage.location.state.menuList,
        roleName: this.state.propsFrmStorage.location.state.roleName,
      }
    });
  };

  userlist = () => {
    this.props.history.push({
      pathname: "/UserList",
      state: {
        userid: this.state.propsFrmStorage.location.state.userid,
        usrnm: this.state.propsFrmStorage.location.state.usrnm,
        role_id: this.state.propsFrmStorage.location.state.role_id,
        format_id: this.state.propsFrmStorage.location.state.format_id,
        storeNo: this.state.propsFrmStorage.location.state.storeNo,
        guId: this.state.propsFrmStorage.location.state.guId,
        menuList: this.state.propsFrmStorage.location.state.menuList,
        roleName: this.state.propsFrmStorage.location.state.roleName,
      }
    });
  };

  Storecreation = () => {
    this.props.history.push({
      pathname: "/Storecreation",
      state: {
        userid: this.state.propsFrmStorage.location.state.userid,
        usrnm: this.state.propsFrmStorage.location.state.usrnm,
        role_id: this.state.propsFrmStorage.location.state.role_id,
        format_id: this.state.propsFrmStorage.location.state.format_id,
        formerMasterList: this.state.propsFrmStorage.location.state.formerMasterList,
        storeNo: this.state.propsFrmStorage.location.state.storeNo,
        guId: this.state.propsFrmStorage.location.state.guId,
        menuList: this.state.propsFrmStorage.location.state.menuList,
        roleName: this.state.propsFrmStorage.location.state.roleName,
      }
    });
  };

  ToStoreList = () => {
    this.props.history.push({
      pathname: "/StoreList",
      state: {
        userid: this.state.propsFrmStorage.location.state.userid,
        usrnm: this.state.propsFrmStorage.location.state.usrnm,
        role_id: this.state.propsFrmStorage.location.state.role_id,
        format_id: this.state.propsFrmStorage.location.state.format_id,
        formerMasterList: this.state.propsFrmStorage.location.state.formerMasterList,
        storeNo: this.state.propsFrmStorage.location.state.storeNo,
        guId: this.state.propsFrmStorage.location.state.guId,
        menuList: this.state.propsFrmStorage.location.state.menuList,
        roleName: this.state.propsFrmStorage.location.state.roleName,
      }
    });
  };

  showDropdownMenu(event) {
    event.preventDefault();
    this.setState({ displayMenu: true }, () => {
      document.addEventListener("click", this.hideDropdownMenu);
    });
  }

  hideDropdownMenu() {
    this.setState({ displayMenu: false }, () => {
      document.removeEventListener("click", this.hideDropdownMenu);
    });
  }
}

export default Menu;
