import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert"; // Import
import "./css/react-confirm-alert.css"; // Import css
import Spinner from "react-spinner-material"; //Import Spinner
import IdleTimer from "react-idle-timer"; // For Idle
import "./css/style.css";
import "./css/style-responsive.css";
import { Encrypt, decryptData } from "./Encryption-Decrypytion";
import disableBrowserBackButton from "disable-browser-back-navigation";
import { basicAuth } from "./BasicAuth";

class QuestionPreviewFortemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayMenu: false,
      loaded: this.props.location.state,
      hasError: false 
    };
    this.state = {
      TaskDropdwn: false,
      data: [],
      taskId: "",
      usrnm: "",
      role_id: "",
      quesid: [],
      ans: [],
      userid: "",
      tskId: "",
      tskName: "",
      tskDescr: "",
      strtDate: "",
      edDate: "",
      loading: false,
      publish: true,
      publishStatus: "New",
      isMandatory: "",
      hasError: false, 
      hammenu: true,
      displayMenu: false,
      loaded: this.props.location.state,
    };
    this.showDropdownMenu = this.showDropdownMenu.bind(this);
    this.hideDropdownMenu = this.hideDropdownMenu.bind(this);
    this.idleTimer = null;
    this.onAction = this._onAction.bind(this);
    this.onActive = this._onActive.bind(this);
    this.onIdle = this._onIdle.bind(this);
  }

  logoutnormal = () => {
    localStorage.clear();
    this.props.history.replace("/");
    disableBrowserBackButton();
  };

  showTaskDropdwnHandler = () => {
    this.setState({
      TaskDropdwn: !this.state.TaskDropdwn
    });
  };

  showDropdownMenu(event) {
    event.preventDefault();
    this.setState({ displayMenu: true }, () => {
      document.addEventListener("click", this.hideDropdownMenu);
    });
  }

  hideDropdownMenu() {
    this.setState({ displayMenu: false }, () => {
      document.removeEventListener("click", this.hideDropdownMenu);
    });
  }
  logout = () => {
    this.setState({ loading: true });
    var Request1 = {
      userId: this.props.location.state.userid,
      guId: this.props.location.state.guId
    };
    var EncryptedRequest1 = Encrypt(Request1);
    fetch("/LogOut ", {
      method: "POST",
      headers: {
        guId: this.props.location.state.guId,
        Authorization: "Basic " + basicAuth(this.props.location.state.userid)
      },
      body: EncryptedRequest1
    })
      .then(response => response.text())
      .then(response => {
        this.setState({ loading: false });
        var DecryptedResponse = decryptData(response);
        if (DecryptedResponse.errorCode === "00") {
          this.props.history.replace("/");
          disableBrowserBackButton();
        } else {
          confirmAlert({
            message: DecryptedResponse.errorMsg,
            buttons: [
              {
                label: "Ok"
              }
            ]
          });
        }
      })
      .catch(error => {
        confirmAlert({
          title: "Alert !",
          message: "Session expired",
          buttons: [
            {
              label: "Ok",
              onClick: () => {
                this.logoutnormal();
              }
            }
          ]
        });
      });
  };

  _onAction(e) {
  }

  _onActive(e) {
  }

  componentDidMount() {
    if (window.innerWidth <= 768) {
      this.setState({ hammenu: false });
    }
  }
  _onIdle(e) {
    localStorage.clear();
    window.location.href = "/";
    disableBrowserBackButton();
  }

  handleChange(param, event) {
    this.state.ans.push(event.currentTarget.value);
    this.state.quesid.push(param);

    confirmAlert({
      title: "Alert",
      message: "quesid: " + param,
      buttons: [
        {
          label: "Ok"
        }
      ]
    });

    confirmAlert({
      title: "Alert",
      message: "ans: " + event.currentTarget.value,
      buttons: [
        {
          label: "Ok"
        }
      ]
    });
  }

  setquesid(param, event) {
    this.state.quesid.push(param);
    alert("quesid: " + param);
  }

  componentDidCatch() {
    this.setState({ hasError: true });
  }

  templates = () => {
    this.props.history.push({
      pathname: "/Templates",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  componentWillMount() {
    this.fetchQuestionPaper();
  }

  showTaskdwnHandler = () => {
    this.setState({
      Taskdwn: !this.state.Taskdwn
    });
  };

  usercreation = () => {
    this.props.history.push({
      pathname: "/UserCreation",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  tiker = () => {
    this.props.history.push({
      pathname: "/Tiker",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  userlist = () => {
    this.props.history.push({
      pathname: "/UserList",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  render = () => {
    var that = this;
    if (this.state.hasError) {
      return (
        <div>
          <h2>Error occurred!! please contact administrator</h2>
        </div>
      );
    } else {
      return (
        <div>
          <div className={this.state.loading ? 'parentDisable' : ''} width="100%">
            <div className='overlay-box'>
              <Spinner
                visible={this.state.loading}
                spinnerColor={"rgba(0, 0, 0, 0.3)"}
              />
            </div>
          </div>
          <section id="container">
            <div>
              <IdleTimer
                ref={ref => {
                  this.idleTimer = ref;
                }}
                element={document}
                onActive={this.onActive}
                onIdle={this.onIdle}
                onAction={this.onAction}
                debounce={250}
                timeout={1000 * 60 * 10}
              />

            </div>
            <header class="header black-bg">

              <a
                className="mob-show"
                onClick={() =>
                  this.setState({ hammenu: !this.state.hammenu })
                }
              >
                <i
                  className="fa fa-tasks hammenu"
                  style={{ "margin-top": "25px", 'padding': '0 15px' }}
                ></i>
              </a>

              <a onClick={this.TaskactiveBind.bind(this)} className="logo" style={{ 'padding': '0 10px' }}>
                <img src={require("./img/retail-logo.png")} alt="logo" />
              </a>
              <div className="markcenter">
                <p className="marquee">{this.state.tickerList}</p>
              </div>
              <ul className="nav pull-right pos-rel">
                <li className="dropdown">
                  <a
                    className="dropdown-toggle"
                    data-toggle="dropdown"
                    onClick={this.showDropdownMenu}
                  >
                    {" "}
                    <img
                      src={require("./img/user.png")}
                      className="user-img"
                    />{" "}
                    <b className="name-show">{this.props.location.state.usrnm}</b>
                    ({this.props.location.state.storeNo})

                    <b className="caret" />
                  </a>
                </li>

                {this.state.displayMenu ? (
                  <ul className="dropdown-menuuser-dd">
                    <li>
                      Role : {this.props.location.state.roleName}
                    </li>
                    <li className="divider"></li>
                    <li>
                      <a onClick={this.Changepasswordbind}>Change Password</a>
                    </li>
                    <li className="divider"></li>
                    <li>
                      <a onClick={this.logout}>Log Out</a>
                    </li>
                  </ul>
                ) : null}
              </ul>
            </header>
            <aside>
              <div id="sidebar" className="nav-collapse">
                {this.state.hammenu ? (
                  <ul className="sidebar-menu" id="nav-accordion">
                    {this.props.location.state.menuList["401"] || this.props.location.state.menuList["402"] || this.props.location.state.menuList["403"] || this.props.location.state.menuList["404"] || this.props.location.state.menuList["405"] || this.props.location.state.menuList["408"] || this.props.location.state.menuList["411"] ? (
                      <li
                        className="sub-menu mt"
                        onClick={this.showTaskDropdwnHandler.bind(this)}
                      >
                        {" "}
                        <a href="javascript:;">
                          {" "}
                          <i className="fa fa-tasks"></i> <span>Task</span>{" "}
                        </a>
                        {this.state.TaskDropdwn ? (
                          <div>
                            <ul class="sub">
                              {this.props.location.state.menuList["401"] ? (
                                <li>
                                  <a onClick={this.TaskactiveBind.bind(this)}>
                                    {this.props.location.state.menuList["401"]}
                                  </a>
                                </li>
                              ) : null}
                              {this.props.location.state.menuList["402"] ? (
                                <li>
                                  <a onClick={this.getroles.bind(this)}>
                                    {this.props.location.state.menuList["402"]}
                                  </a>
                                </li>
                              ) : null}
                              {this.props.location.state.menuList["403"] ? (
                                <li>
                                  <a
                                    onClick={this.CompletedTaskListbind.bind(
                                      this
                                    )}
                                  >
                                    {this.props.location.state.menuList["403"]}
                                  </a>
                                </li>
                              ) : null}
                              {this.props.location.state.menuList["404"] ? (
                                <li>
                                  <a onClick={this.templates.bind(this)}>
                                    {this.props.location.state.menuList["404"]}
                                  </a>
                                </li>
                              ) : null}
                              {this.props.location.state.menuList["405"] ? (
                                <li>
                                  <a onClick={this.ArchivedTasks.bind(this)}>
                                    {this.props.location.state.menuList["405"]}
                                  </a>
                                </li>
                              ) : null}
                              {this.props.location.state.menuList["408"] ? (
                                <li>
                                  <a onClick={this.StoreTasks.bind(this)}>
                                    {this.props.location.state.menuList["408"]}
                                  </a>
                                </li>
                              ) : null}
                              {this.props.location.state.menuList["411"] ? (
                                <li>
                                  <a onClick={this.toGenDashboard.bind(this)}>
                                    {this.props.location.state.menuList["411"]}
                                  </a>
                                </li>
                              ) : null}
                            </ul>
                          </div>
                        ) : null}
                      </li>
                    ) : null}
                    {this.props.location.state.menuList["406"] ? (
                      <div>
                        <li>
                          {" "}
                          <a onClick={this.Reportsbind.bind(this)}>
                            {" "}
                            <i className="fa fa-tasks"></i>{" "}
                            <span>
                              {this.props.location.state.menuList["406"]}
                            </span>{" "}
                          </a>{" "}
                        </li>
                      </div>
                    ) : null}

                    {this.props.location.state.menuList["414"] && this.props.location.state.format_id === "102" ? (
                      <div>
                        <li>
                          {" "}
                          <a onClick={this.Defectbind.bind(this)}>
                            {" "}
                            <i className="fa fa-tasks"></i>{" "}
                            <span>
                              {this.props.location.state.menuList["414"]}
                            </span>{" "}
                          </a>{" "}
                        </li>
                      </div>
                    ) : null}

                    {this.props.location.state.menuList["415"] ? (
                      <div>
                        <li>
                          {" "}
                          <a onClick={this.SmartPointBind.bind(this)}>
                            {" "}
                            <i className="fa fa-tasks"></i>{" "}
                            <span>
                              {this.props.location.state.menuList["415"]}
                            </span>{" "}
                          </a>{" "}
                        </li>
                      </div>
                    ) : null}

                    {this.props.location.state.menuList["409"] || this.props.location.state.menuList["410"] ? (
                      <div>
                        <li
                          className="sub-menu"
                          onClick={this.showIssueDropdwnHandler.bind(this)}
                        >
                          {" "}
                          <a href="javascript:;">
                            {" "}
                            <i className="fa fa-tasks"></i>{" "}
                            <span>Issue</span>{" "}
                          </a>
                          {this.state.IssueDropdwn ? (
                            <div>
                              <ul class="sub">
                                {this.props.location.state.menuList["410"] ? (
                                  <li>
                                    <a onClick={this.IssueRaising.bind(this)}>
                                      {this.props.location.state.menuList["410"]}
                                    </a>
                                  </li>
                                ) : null}
                                {this.props.location.state.menuList["409"] ? (
                                  <li>
                                    <a onClick={this.IssueDashboard.bind(this)}>
                                      {this.props.location.state.menuList["409"]}
                                    </a>
                                  </li>
                                ) : null}
                              </ul>
                            </div>
                          ) : null}
                        </li>
                      </div>
                    ) : null}

                    {this.props.location.state.menuList["407"] ? (
                      <div>
                        <li
                          className="sub-menu"
                          onClick={this.showTaskdwnHandler.bind(this)}
                        >
                          {" "}
                          <a href="javascript:;">
                            {" "}
                            <i className="fa fa-tasks"></i>{" "}
                            <span>
                              {this.props.location.state.menuList["407"]}
                            </span>{" "}
                          </a>
                          {this.state.Taskdwn ? (
                            <div>
                              <ul class="sub">
                                <li>
                                  {" "}
                                  <a onClick={this.tiker.bind(this)}>
                                    <span>Tiker Creation</span>{" "}
                                  </a>{" "}
                                </li>
                                <li>
                                  {" "}
                                  <a onClick={this.userlist.bind(this)}>
                                    <span>User List</span>{" "}
                                  </a>{" "}
                                </li>
                                {this.props.location.state.menuList["412"] ? (
                                  <li>
                                    {" "}
                                    <a onClick={this.Storecreation.bind(this)}>
                                      <span>{this.props.location.state.menuList["412"]}</span>{" "}
                                    </a>{" "}
                                  </li>
                                ) : null}
                                {this.props.location.state.menuList["413"] ? (
                                  <li>
                                    {" "}
                                    <a onClick={this.ToStoreList.bind(this)}>
                                      <span>{this.props.location.state.menuList["413"]}</span>{" "}
                                    </a>{" "}
                                  </li>
                                ) : null}
                              </ul>
                            </div>
                          ) : null}
                        </li>
                      </div>
                    ) : null}
                  </ul>
                ) : null}
              </div>
            </aside>
            <section id="main-content">
              <section class="wrapper">
                <div class="row">
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-20">
                      <h3>
                        <i className="fa fa-angle-right"></i>
                        {" "}{this.props.location.state.tskName}
                      </h3>
                      <p>Description : {this.props.location.state.tskDescr}</p>
                    </div>

                    <div class="clearfix"></div>
                    <hr />

                    <ul class="ques">
                      {this.state.data.map(function (item, key) {

                        switch (item.questiontype) {
                          case "1":
                            return (
                              <li key={key}>
                                <div class="custom-selection ml-0 pl-0">
                                  <p>
                                    {item.questionText}
                                    <that.Astrik
                                      isMandatory={item.isMandatory}
                                    />
                                  </p>
                                  {item.options.map((optele, index) => (
                                    <span class="nameloc">
                                      <input
                                        type="radio"
                                        class="custom-control-input"
                                        id={item.questionId + index}
                                        name="MCS1"
                                        value={optele}
                                        //checked={that.state.tempquesid === optele.quesid}
                                        onChange={that.handleChange.bind(
                                          that,
                                          item.questionId
                                        )}
                                        disabled="disabled"
                                      />
                                      <label
                                        class="custom-control-label"
                                        for={item.questionId + index}
                                      >
                                        {optele}
                                      </label>
                                    </span>
                                  ))}
                                </div>
                              </li>
                            );
                            break;
                          case "2":
                            return (
                              <li key={key}>
                                <div class="custom-selection ml-0 pl-0 ">
                                  <p>
                                    {item.questionText}
                                    <that.Astrik
                                      isMandatory={item.isMandatory}
                                    />
                                  </p>
                                  {item.options.map(optele => (
                                    <span class="nameloc ">
                                      <input
                                        type="checkbox"
                                        class="custom-control-input "
                                        id="customCheck-1"
                                        name="ques-2"
                                        disabled="disabled"
                                      />
                                      <label
                                        class="custom-control-label"
                                        for="customCheck-1"
                                      >
                                        {optele}
                                      </label>
                                    </span>
                                  ))}
                                </div>
                              </li>
                            );
                            break;
                          case "3":
                            return (
                              <li key={key}>
                                <div class="custom-selection ml-0 pl-0">
                                  <p>
                                    {item.questionText}
                                    <that.Astrik
                                      isMandatory={item.isMandatory}
                                    />
                                  </p>
                                  <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 pl-0">
                                    <textarea
                                      type="text"
                                      disabled="disabled"
                                      class="form-control"
                                      placeholder="Some Text"
                                    />
                                  </div>
                                  <div class="clearfix"></div>
                                </div>
                              </li>
                            );
                            break;
                          case "4":
                            return (
                              <li key={key}>
                                <div class="custom-selection ml-0 pl-0">
                                  <p>
                                    {item.questionText}
                                    <that.Astrik
                                      isMandatory={item.isMandatory}
                                    />
                                  </p>
                                  <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 pl-0">
                                    <input
                                      type="text"
                                      disabled="disabled"
                                      class="form-control"
                                      placeholder="Number Only"
                                    />
                                  </div>
                                  <div class="clearfix"></div>
                                </div>
                              </li>
                            );
                            break;
                          case "5":
                            return (
                              <li key={key}>
                                <div class="custom-selection ml-0 pl-0">
                                  <p>
                                    {item.questionText}
                                    <that.Astrik
                                      isMandatory={item.isMandatory}
                                    />
                                  </p>
                                  <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 pl-0">
                                    <input
                                      type="email"
                                      disabled="disabled"
                                      class="form-control"
                                      placeholder="Email-ID"
                                    />
                                  </div>
                                  <div class="clearfix"></div>
                                </div>
                              </li>
                            );
                            break;
                          case "6":
                            return (
                              <li key={key}>
                                <div class="custom-selection ml-0 pl-0">
                                  <p>
                                    {item.questionText}
                                    <that.Astrik
                                      isMandatory={item.isMandatory}
                                    />
                                  </p>
                                  <span class="mlr-10">
                                    <input
                                      type="radio"
                                      class="custom-control-input"
                                      id="customRadio1-11"
                                      name="ques-1"
                                      disabled="disabled"
                                    />
                                    <label
                                      class="custom-control-label"
                                      for="customRadio1-11"
                                    >
                                      Yes
                                    </label>
                                  </span>{" "}
                                  <span>
                                    <input
                                      type="radio"
                                      class="custom-control-input"
                                      id="customRadio2-22"
                                      name="ques-1"
                                      disabled="disabled"
                                    />
                                    <label
                                      class="custom-control-label"
                                      for="customRadio2-22"
                                    >
                                      No
                                    </label>
                                  </span>{" "}
                                </div>
                              </li>
                            );
                            break;
                          case "7":
                            return (
                              <li key={key}>
                                <div class="custom-selection ml-0 pl-0">
                                  <p>
                                    {item.questionText}
                                    <that.Astrik
                                      isMandatory={item.isMandatory}
                                    />
                                  </p>
                                  <span class="mlr-10">
                                    <input
                                      type="radio"
                                      class="custom-control-input"
                                      id="customRadio1-1"
                                      name="ques-1"
                                      disabled="disabled"
                                    />
                                    <label
                                      class="custom-control-label"
                                      for="customRadio1-1"
                                    >
                                      Yes
                                    </label>
                                  </span>{" "}
                                  <span>
                                    <input
                                      type="radio"
                                      class="custom-control-input"
                                      id="customRadio2-2"
                                      name="ques-1"
                                      disabled="disabled"
                                    />
                                    <label
                                      class="custom-control-label"
                                      for="customRadio2-2"
                                    >
                                      No
                                    </label>
                                  </span>{" "}
                                  <span>
                                    <input
                                      type="radio"
                                      class="custom-control-input"
                                      id="customRadio3-3"
                                      name="ques-1"
                                      disabled="disabled"
                                    />
                                    <label
                                      class="custom-control-label"
                                      for="customRadio3-3"
                                    >
                                      NA
                                    </label>
                                  </span>{" "}
                                </div>
                              </li>
                            );
                            break;
                          case "8":
                            return (
                              <li key={key}>
                                <div class="custom-selection ml-0 pl-0">
                                  <p>
                                    {item.questionText}
                                    <that.Astrik
                                      isMandatory={item.isMandatory}
                                    />
                                  </p>
                                  <div class="clearfix">
                                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 pl-0">
                                      <input
                                        id="store-list"
                                        type="text"
                                        class="form-control input-upload"
                                        value="Upload file"
                                        disabled="disabled"
                                      />
                                      <span
                                        class="btn-upload"
                                        style={{ padding: "7px 15px" }}
                                      >
                                        Upload
                                      </span>
                                      <input
                                        id="upload-storedata"
                                        type="file"
                                        name="Store-list-data"
                                        class="input-hidden"
                                        disabled="disabled"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </li>
                            );
                            break;
                          case "9":
                            return (
                              <li key={key}>
                                <div class="custom-selection ml-0 pl-0">
                                  <p>
                                    {item.questionText}
                                    <that.Astrik
                                      isMandatory={item.isMandatory}
                                    />
                                  </p>

                                  {item.options.map((optele, index) => (
                                    <span className="pl-0 ml-0">
                                      <p for={item.questionId + index}>
                                        <b>{optele}</b>
                                      </p>
                                      <input
                                        type="text"
                                        disabled="disabled"
                                        class="form-control col-lg-1 col-md-1 col-sm-1 col-xs-4 pl-0  "
                                      />
                                    </span>
                                  ))}
                                </div>
                              </li>
                            );
                            break;
                          case "10":
                            return (
                              <li key={key}>
                                <div class="custom-selection ml-0 pl-0">
                                  <p>
                                    {item.questionText}{" "}
                                    <that.Astrik
                                      isMandatory={item.isMandatory}
                                    />
                                  </p>
                                  <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 pl-0">
                                    <input
                                      type="date"
                                      disabled="disabled"
                                      class="form-control"
                                    />
                                  </div>
                                  <div class="clearfix"></div>
                                </div>
                              </li>
                            );
                            break;
                            case "11":
                            return (
                              <li key={key}>
                                <div class="custom-selection ml-0 pl-0">
                                  <p>
                                    {item.questionText}
                                    <that.Astrik
                                      isMandatory={item.isMandatory}
                                    />
                                  </p>
                                  <div class="clearfix">
                                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 pl-0">
                                      <input
                                        id="store-list"
                                        type="text"
                                        class="form-control input-upload"
                                        value="Upload Live Image"
                                        disabled="disabled"
                                      />
                                      <span
                                        class="btn-upload"
                                        style={{ padding: "7px 15px" }}
                                      >
                                        Upload
                                      </span>
                                      <input
                                        id="upload-storedata"
                                        type="file"
                                        name="Store-list-data"
                                        class="input-hidden"
                                        disabled="disabled"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </li>
                            );
                            break;
                            case "12":
                            return (
                              <li key={key}>
                                <div class="custom-selection ml-0 pl-0 ">
                                  <p>
                                    {item.questionText}
                                    <that.Astrik
                                      isMandatory={item.isMandatory}
                                    />
                                  </p>
                                  {item.options.map(optele => (
                                    <span class="nameloc ">
                                      <input
                                        type="checkbox"
                                        class="custom-control-input "
                                        id="customCheck-1"
                                        name="ques-2"
                                        disabled="disabled"
                                      />
                                      <label
                                        class="custom-control-label"
                                        for="customCheck-1"
                                      >
                                        {optele}
                                      </label>
                                    </span>
                                  ))}
                                </div>
                              </li>
                            );
                            break;
                          
                          default:
                            confirmAlert({
                              title: "Alert !",
                              message: "Error displaying preview.",
                              buttons: [
                                {
                                  label: "Ok"
                                  // onClick: () =>
                                }
                              ]
                            });
                            break;
                        }
                      })}
                    </ul>
                    <div class="clearfix"></div>
                    <div class="text-center mt">
                      <a
                        onClick={this.addquestionbind.bind(this)}
                        class="btn btn-primary min-wid-90 "
                      >
                        Back
                      </a>

                    </div>
                    <div class="clearfix"></div>
                  </div>
                </div>
              </section>
            </section>
          </section>
        </div>
      );
    }
  };

  ToStoreList = () => {
    this.props.history.push({
      pathname: "/StoreList",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        formerMasterList: this.state.formerMasterList,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  Storecreation = () => {
    this.props.history.push({
      pathname: "/Storecreation",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        formerMasterList: this.state.formerMasterList,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  showIssueDropdwnHandler = () => {
    this.setState({
      IssueDropdwn: !this.state.IssueDropdwn
    });
  };

  IssueRaising = () => {
    this.props.history.push({
      pathname: "/IssueRaising",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        data: this.props.location.state.data,
        tskId: this.props.location.state.tskId,
        tskName: this.props.location.state.tskName,
        storeNo: this.props.location.state.storeNo,
        Isroffice: this.props.location.state.Isroffice,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  toGenDashboard = () => {
    this.props.history.push({
      pathname: "/DashboardGeneral",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        data: this.props.location.state.data,
        tskId: this.props.location.state.tskId,
        tskName: this.props.location.state.tskName,
        storeNo: this.props.location.state.storeNo,
        Isroffice: this.props.location.state.Isroffice,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  IssueDashboard = () => {
    this.props.history.push({
      pathname: "/IssueDashboard",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        data: this.props.location.state.data,
        tskId: this.props.location.state.tskId,
        tskName: this.props.location.state.tskName,
        storeNo: this.props.location.state.storeNo,
        Isroffice: this.props.location.state.Isroffice,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  renderSwitch(e) {
    switch (e) {
      case "1":
        return (
          <div>
            <h1>Sameer</h1>
          </div>
        );
      default:
        return "foo";
    }
  }

  CompletedTaskListbind = () => {
    this.props.history.push({
      pathname: "/CompletedTaskList",
      state: {
        data: this.state.data,
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  Astrik = props => {
    const isMandatory = props.isMandatory;
    if (isMandatory == 1) {
      return <font color="red"> *</font>;
    }
    return null;
  };

  fetchQuestionPaper = () => {
    this.setState({ loading: true });
    if (
      this.props.location.state !== undefined &&
      this.props.location.state.usrnm !== ""
    ) {
      if (this.props.location.state) {
        this.setState({
          role_id: this.props.location.state.role_id,
          usrnm: this.props.location.state.usrnm,
          userid: this.props.location.state.userid,
          tskId: this.props.location.state.tskId,
          tskName: this.props.location.state.tskName,
          tskDescr: this.props.location.state.tskDescr,
          strtDate: this.props.location.state.strtDate,
          edDate: this.props.location.state.edDate,
          publishStatus: this.props.location.state.publishStatus,
          isMandatory: this.props.location.state.check
        });

        var Request = {
          taskId: this.props.location.state.tskId,
          guId: this.props.location.state.guId
        };
        var EncryptedRequest = Encrypt(Request);

        fetch("/FetchQuestionsTempByTask", {
          method: "POST",
          headers: {
            guId: this.props.location.state.guId,
            Authorization:
              "Basic " + basicAuth(this.props.location.state.userid)
          },

          body: EncryptedRequest
        })
          .then(response => response.text())
          .then(response => {
            var DecryptedResponse = decryptData(response);
            if (DecryptedResponse.errorCode === "00") {
              this.setState({ data: DecryptedResponse.listQuestions });
              this.setState({ loading: false });
            }
          })
          .catch(error => {
            confirmAlert({
              title: "Alert !",
              message: "Session expired",
              buttons: [
                {
                  label: "Ok",
                  onClick: () => {
                    this.logoutnormal();
                  }
                }
              ]
            });
          });
      } else {
        this.props.history.push({
          pathname: "/"
        });
      }
    } else {
      window.location.href = "/";
    }
  };

  SmartPointBind = () => {
    this.props.history.push({
      pathname: "/SmartPoint",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        data: this.props.location.state.data,
        tskId: this.props.location.state.tskId,
        tskName: this.props.location.state.tskName,
        storeNo: this.props.location.state.storeNo,
        Isroffice: this.props.location.state.Isroffice,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  Defectbind = () => {
    this.props.history.push({
      pathname: "/DefectRaising",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        data: this.props.location.state.data,
        tskId: this.props.location.state.tskId,
        tskName: this.props.location.state.tskName,
        storeNo: this.props.location.state.storeNo,
        Isroffice: this.props.location.state.Isroffice,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  Reportsbind = () => {
    this.props.history.push({
      pathname: "/Reports",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  getroles(e) {
    this.props.history.push({
      pathname: "/CreatetaskUI",
      state: {
        format_id: this.props.location.state.format_id,
        role_id: this.props.location.state.role_id,
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  }

  Changepasswordbind = () => {
    this.props.history.push({
      pathname: "/ChangepasswordUI",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  addquestionbind = () => {
    this.props.history.push({
      pathname: "/Addtemplate",
      state: {
        //tskId: this.state.tskId,
        //tskName:this.state.tskName,
        role_id: this.state.role_id,
        format_id: this.props.location.state.format_id,
        userid: this.props.location.state.userid,
        tskId: this.state.tskId,
        tskName: this.state.tskName,
        tskDescr: this.state.tskDescr,
        strtDate: this.state.strtDate,
        edDate: this.state.edDate,
        usrnm: this.props.location.state.usrnm,
        publishStatus: this.state.publishStatus,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  StoreTasks = () => {
    this.props.history.push({
      pathname: "/StoreTasks",
      state: {
        data: this.state.data,
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  ArchivedTasks = () => {
    this.props.history.push({
      pathname: "/ArchivedTasks",
      state: {
        data: this.state.data,
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
        Isroffice: this.props.location.state.Isroffice,
      }
    });
  };

  TaskactiveBind = () => {
    this.props.history.push({
      pathname: "/Taskactive",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };
}
export default QuestionPreviewFortemplate;
