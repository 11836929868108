import React, { Component } from "react";
import "./css/style.css";
import "./css/style-responsive.css";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import readXlsxFile from "read-excel-file";
import { Encrypt, decryptData } from "./Encryption-Decrypytion";
import disableBrowserBackButton from "disable-browser-back-navigation";
import { withRouter } from "react-router-dom";
import {
  BrowserRouter as Router
} from "react-router-dom";
import IdleTimer from "react-idle-timer"; // For Idle
import { basicAuth } from "./BasicAuth";
import { Scrollbars } from "react-custom-scrollbars";
import Spinner from "react-spinner-material"; //Import Spinner
import { showSuccess, showAlert } from "./CommonAlert";

class Storecreation extends Component {
  state = {
    value: "",
    userId: "",
    storeName: "",
    storeCity: "",
    storeState: "",
    storeZone: "",
    ecDataArr: [],
    ecId: [],
    ecAddData: "",
    tableVisible: false,
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    formatId: "",
    loading: false,
    loadingForUpload: false,
    uploadFlag: false,
    formerMasterList: [],
    roleMasterList: [],
    rformatlist: [],
    rformatlisted: [],
    rformatId: [],
    rrole: [],
    lstformat: [],
    formatDesc: [],
    formarMasterList: [],
    roleMasterList1: [],
    storeList: [],
    valueid: [],
    fmtid: [],
    userid: "",
    subfmtList: [],
    subfmtListId: [],
    creationStatusList: [],
    randomNumber: "",

  };

  constructor(props) {
    super(props);

    this.state = {
      value: "",
      username: "",
      valueid: [],
      fmtid: [],
      role_id: "",
      firstName: "",
      ecDataArr: [],
      ecId: [],
      FileName: "",
      middleName: "",
      lastName: "",
      email: "",
      formatId: null,
      userId: "",
      loading: false,
      loadingForUpload: false,
      uploadFlag: false,
      formerMasterList: [],
      roleMasterList: [],
      rformatlist: [],
      rformatlisted: [],
      rformatId: [],
      rrole: [],
      rlistid: [],
      lstformat: [],
      storeList: [],
      displayMenu: false,
      showAction: false,
      storeNo: "",
      former: false,
      userid: "",
      hammenu: true,
      formatDesc: [],
      formarMasterList: [],
      roleMasterList1: [],
      IssueDropdwn: false,
      subfmtList: [],
      subfmtListId: [],
      creationStatusList: [],

    };
    this.onChangeUserId = this.onChangeUserId.bind(this);
    this.showDropdownMenu = this.showDropdownMenu.bind(this);
    this.hideDropdownMenu = this.hideDropdownMenu.bind(this);
    this.idleTimer = null;
    this.onAction = this._onAction.bind(this);
    this.onActive = this._onActive.bind(this);
    this.onIdle = this._onIdle.bind(this);
  }

  logoutnormal = () => {
    localStorage.clear();
    this.props.history.replace("/");
    disableBrowserBackButton();
  };

  _onAction(e) { }

  _onActive(e) {
  }

  _onIdle(e) {
    localStorage.clear();
    window.location.href = "/";
    disableBrowserBackButton();
  }

  logout = () => {
    this.setState({ loading: true });
    var Request1 = {
      userId: this.props.location.state.userid,
      guId: this.props.location.state.guId
    };
    var EncryptedRequest1 = Encrypt(Request1);
    fetch("/LogOut ", {
      method: "POST",
      headers: {
        guId: this.props.location.state.guId,
        Authorization: "Basic " + basicAuth(this.props.location.state.userid)
      },
      body: EncryptedRequest1
    })
      .then(response => response.text())
      .then(response => {
        this.setState({ loading: false });
        var DecryptedResponse = decryptData(response);
        if (DecryptedResponse.errorCode === "00") {
          this.props.history.replace("/");
          disableBrowserBackButton();
        } else {
          confirmAlert({
            message: DecryptedResponse.errorMsg,
            buttons: [
              {
                label: "Ok"
              }
            ]
          });
        }
      })
      .catch(error => {
        confirmAlert({
          title: "Alert !",
          message: "Session expired",
          buttons: [
            {
              label: "Ok",
              onClick: () => {
                this.logoutnormal();
              }
            }
          ]
        });
      });
  };
  handleSetList([]) {
    this.setState({
      formarMasterList: []
    });
  }

  componentWillMount() {
    if (
      this.props.location.state === undefined ||
      this.props.location.state === ""
    ) {
      window.location.href = "/";
    } else {
    }
  }
  showTaskDropdwnHandler = () => {
    this.setState({
      TaskDropdwn: !this.state.TaskDropdwn
    });
  };

  componentDidMount() {
    if (window.innerWidth <= 768) {
      this.setState({ hammenu: false });
    }
  }

  showDropdownMenu(event) {
    event.preventDefault();
    this.setState({ displayMenu: true }, () => {
      document.addEventListener("click", this.hideDropdownMenu);
    });
  }
  hideDropdownMenu() {
    this.setState({ displayMenu: false }, () => {
      document.removeEventListener("click", this.hideDropdownMenu);
    });
  }

  onChangeUserId(e) {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      this.setState({ value: e.target.value });
    }
  }

  userEcAdd = () => {


    if (this.state.subfmtList.length > 0 && document.getElementById("Subfmt").value == "Select") {
      // if(){
      showAlert("Please select sub format.")

      // }
    }

    else if (!document.getElementById("StoreId").value || !document.getElementById("StoreName").value ||

      !document.getElementById("Mobile").value || !document.getElementById("Admin_EmailId").value ||

      !document.getElementById("StoreCity").value || !document.getElementById("StoreState").value ||

      !document.getElementById("StoreZone").value || !document.getElementById("ECNO").value
    ) {
      showAlert("Please enter all required fields.")
    }

    else if (this.state.ecAddData) {
      var request = {
        userId: this.state.ecAddData,
        guId: this.props.location.state.guId,
        formatId: this.props.location.state.format_id

      };
      var EncryptedRequest = Encrypt(request);

      fetch("/checkUser", {
        method: "POST",
        headers: {
          guId: this.props.location.state.guId,
          Authorization:
            "Basic " + basicAuth(this.props.location.state.userid)
        },

        body: EncryptedRequest
      })
        .then(response => response.text())
        .then(response => {
          var DecryptedResponse = decryptData(response)
          if (DecryptedResponse.errorCode === "00") {
            this.setState({ loading: false })

            if (this.state.ecDataArr.filter((a) => a.userId === DecryptedResponse.userId).length === 0) {

              this.state.ecDataArr.push(DecryptedResponse)
              this.state.ecId.push(DecryptedResponse.userId)
              showSuccess("User added successfully")

            }
            else {
              showAlert("User is alreay added.")
            }

            this.setState({ tableVisible: true })
          } else {
            confirmAlert({
              message: DecryptedResponse.errorMsg,
              buttons: [
                {
                  label: "Ok",
                  onClick: () => {
                    window.location.reload(true);
                  }
                }
              ]
            });
          }
        })
        .catch(error => {
          confirmAlert({
            title: "Alert !",
            message: "Session expired",
            buttons: [
              {
                label: "Ok",
                onClick: () => {
                  this.logoutnormal();
                }
              }
            ]
          });
        });



    }
  }

  formatSelection = e => {
    var that = this;
    if (document.getElementById("SelQType1").value == "Select") {
      confirmAlert({
        title: "Alert !",
        message: "Please select action",
        buttons: [
          {
            label: "Ok",
            onClick: () => {
              return false;
            }
          }
        ]
      });
    } else
      if (this.state.subfmtList.length > 0) {
        if (document.getElementById("Subfmt").value == "Select") {
          showAlert("Please select sub format")

        }
      }


      else if (document.getElementById("SelQType1").value == "Add/Update") {
        if (that.props.location.state.format_id === "1") {
          if (document.getElementById("SelQType2").value == "Select") {
            confirmAlert({
              title: "Alert !",
              message: "Please select format type.",
              buttons: [
                {
                  label: "Ok",
                  onClick: () => {
                    return false;
                  }
                }
              ]
            });
          } else {

            this.setState({ loading: true });

            var request = {
              storeList: that.state.storeList,
              formatId: this.props.location.state.format_id,
              subFormatId: that.state.fmtid.toString(),
              userId: that.props.location.state.userid,
              guId: that.props.location.state.guId

            };
            var EncryptedRequest = Encrypt(request);
            fetch("/CreateStore", {
              method: "POST",
              headers: {
                guId: that.props.location.state.guId,
                Authorization:
                  "Basic " + basicAuth(that.props.location.state.userid)
              },

              body: EncryptedRequest
            })
              .then(response => response.text())
              .then(response => {
                var DecryptedResponse = decryptData(response);
                if (
                  DecryptedResponse.errorCode === "00"
                ) {
                  this.setState({ creationStatusList: DecryptedResponse.StoreDetails });
                  this.setState({ loading: false });
                  this.setState({ randomNumber: DecryptedResponse.randomNo })

                } else {
                  confirmAlert({
                    title: "Alert",
                    message: DecryptedResponse.errorMsg,
                    buttons: [
                      {
                        label: "Ok",
                        onClick: () => {
                          window.location.reload(true);
                        }
                      }
                    ]
                  });
                  this.setState({ loading: false });

                }
              })
              .catch(error => {
                confirmAlert({
                  title: "Alert !",
                  message: "Session expired",
                  buttons: [
                    {
                      label: "Ok",
                      onClick: () => {
                        that.logoutnormal();
                      }
                    }
                  ]
                });
              });


          }


        } else {

          this.setState({ loadingForUpload: true, loading: true });


          var request = {
            storeList: that.state.storeList,
            formatId: this.props.location.state.format_id,
            subFormatId: that.state.fmtid.toString(),
            userId: that.props.location.state.userid,
            guId: that.props.location.state.guId
          };
          var EncryptedRequest = Encrypt(request);

          fetch("/CreateStore", {
            method: "POST",
            headers: {
              guId: that.props.location.state.guId,
              Authorization:
                "Basic " + basicAuth(that.props.location.state.userid)
            },

            body: EncryptedRequest
          })
            .then(response => response.text())
            .then(response => {
              var DecryptedResponse = decryptData(response);

              if (
                DecryptedResponse.errorCode === "00"
              ) {
                this.setState({ creationStatusList: DecryptedResponse.StoreDetails });
                this.setState({ loading: false });
                this.setState({ randomNumber: DecryptedResponse.randomNo })
                this.setState({ loadingForUpload: false, loading: false });

                confirmAlert({
                  title: "Success!",
                  message: "Click on Display Stores to view the store list",
                  buttons: [
                    {
                      label: "Ok"
                    }
                  ]
                })

              } else {
                confirmAlert({
                  title: "Alert",
                  message: DecryptedResponse.errorMsg,
                  buttons: [
                    {
                      label: "Ok",
                      onClick: () => {
                        window.location.reload(true);
                      }
                    }
                  ]
                });
                this.setState({ loadingForUpload: false, loading: false });

              }
            })
            .catch(error => {
              this.setState({ loadingForUpload: false, loading: false });

              confirmAlert({
                title: "Alert !",
                message: "Session expired",
                buttons: [
                  {
                    label: "Ok",
                    onClick: () => {
                      that.logoutnormal();
                    }
                  }
                ]
              });
            });

        }


      } else {
        var storep = [];
        for (let l = 0; l < that.state.storeList.length; l++) {
          storep.push(that.state.storeList[l].storeNo);
        }
        confirmAlert({
          message: "Are you sure, you want to delete this store?",
          buttons: [
            {
              label: "Yes",
              onClick: () => {
                var Request2 = {
                  storeNo: storep,
                  userId: that.state.userid,
                  guId: that.props.location.state.guId
                };
                var EncryptedRequest2 = Encrypt(Request2);
                fetch("/DeleteStore", {
                  method: "POST",
                  headers: {
                    guId: that.props.location.state.guId,
                    Authorization:
                      "Basic " + basicAuth(that.props.location.state.userid)
                  },

                  body: EncryptedRequest2
                })
                  .then(response => response.text())
                  .then(response => {
                    var DecryptedResponse2 = decryptData(response);
                    if (DecryptedResponse2.errorCode === "00") {
                      confirmAlert({
                        message: "Store  deleted Successfully.",
                        buttons: [
                          {
                            label: "Ok",
                            onClick: () => {
                              window.location.reload(true);
                            }
                          }
                        ]
                      });
                      that.forceUpdate();
                    } else {
                      confirmAlert({
                        title: "Alert !",
                        message: DecryptedResponse2.errorMsg,
                        buttons: [
                          {
                            label: "Ok"
                          }
                        ]
                      });
                    }
                  })
                  .catch(error => {
                    confirmAlert({
                      title: "Alert !",
                      message: "Session expired",
                      buttons: [
                        {
                          label: "Ok",
                          onClick: () => {
                            that.logoutnormal();
                          }
                        }
                      ]
                    });
                  });
              }
            },
            {
              label: "No",
              onClick: () => {
                return false;
              }
            }
          ]
        });
      }



  };

  getStoreList = e => {
    var request = {
      randomNo: this.state.randomNumber,
      userId: this.props.location.state.userid,
      guId: this.props.location.state.guId,
    };
    var EncryptedRequest = Encrypt(request);

    fetch("/GetStoreUserMapping", {
      method: "POST",
      headers: {
        guId: this.props.location.state.guId,
        Authorization:
          "Basic " + basicAuth(this.props.location.state.userid),
        "Content-Type": "text/plain"
      },

      body: EncryptedRequest
    })
      .then(response => response.text())
      .then(response => {
        let DecryptedResponse = decryptData(response);
        if (DecryptedResponse.errorCode === "00") {
          this.setState({ creationStatusList: DecryptedResponse.StoreDetails });
          this.setState({ loading: false });
        }
        else {
          confirmAlert({
            title: "Alert",
            message: DecryptedResponse.errorMsg,
            buttons: [
              {
                label: "Ok",
                onClick: () => {
                  window.location.reload(true);
                }
              }
            ]
          });
          this.setState({ loading: false });

        }
      })
  }

  hrSelection = () => {
    var that = this;

    if (this.state.ecDataArr.length < 1) {
      showAlert("Please add user's.")
    }

    else {
      var storeprop = [{
        "storeNo": document.getElementById("StoreId").value,
        "storeName": document.getElementById("StoreName").value,
        "storeMobile": document.getElementById("Mobile").value,
        "storeEmail": document.getElementById("Admin_EmailId").value,
        "city": document.getElementById("StoreCity").value,
        "state": document.getElementById("StoreState").value,
        "zone ": document.getElementById("StoreZone").value,
        "ecList": this.state.ecId
      }]

      var request = {
        storeList: storeprop,

        subFormatId: that.state.fmtid.toString(),
        userId: that.props.location.state.userid,
        guId: that.props.location.state.guId

      };
      var EncryptedRequest = Encrypt(request);



      fetch("/CreateStore", {
        method: "POST",
        headers: {
          guId: that.props.location.state.guId,
          Authorization:
            "Basic " + basicAuth(that.props.location.state.userid)
        },

        body: EncryptedRequest
      })
        .then(response => response.text())
        .then(response => {
          var DecryptedResponse = decryptData(response);
          if (DecryptedResponse.errorCode === "00") {
            confirmAlert({
              title: "Success",
              message: "Stores added successfully",
              buttons: [
                {
                  label: "Ok",
                  onClick: () => {
                    window.location.reload(true);
                  }
                }
              ]
            });
          } else {
            confirmAlert({
              title: "Success",
              message:
                "Stores added successfully, " +
                " below uploaded stores are already present " +
                DecryptedResponse.existingStore,
              buttons: [
                {
                  label: "Ok",
                  onClick: () => {
                    window.location.reload(true);
                  }
                }
              ]
            });
          }
        })
        .catch(error => {

          confirmAlert({
            title: "Alert !",
            message: "Session expired",
            buttons: [
              {
                label: "Ok",
                onClick: () => {
                  that.logoutnormal();
                }
              }
            ]
          });
        });

    }





  }


  addformat = () => {
    if (document.getElementById("SelQType1").value == "Add/Update") {
      this.setState({ former: true, showAction: true });
    } else {
      this.setState({ former: false, showAction: true });
    }
  };


  subfmtformat = (e) => {

    let flag = [];
    for (let k = 0; k < this.state.subfmtList.length; k++) {
      this.state.subfmtList[k] === e.target.value
        ? flag.push(this.state.subfmtListId[k])
        : this.setState({ fmtid: "" });
    }
    this.setState({ fmtid: flag });
  }

  MobilechangeId = e => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      this.setState({ mobile: e.target.value });
    }
  };

  MenuRights = () => {
    this.props.history.push({
      pathname: "/MenuRights",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.roleId,
        format_id: this.props.location.state.format_id,
        formerMasterList: this.state.formerMasterList,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  CreateHo = () => {
    this.props.history.push({
      pathname: "/CreateHo",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.state.roleId,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  Storemodification = () => {
    this.props.history.push({
      pathname: "/Storemodification",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.state.roleId,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        roleName: this.props.location.state.roleName,
      }
    });
  };


  render = () => {
    var that = this;
    return (
      < Router >
        <div>

          <div>
            <section id="container">
              <div>
                <IdleTimer
                  ref={ref => {
                    this.idleTimer = ref;
                  }}
                  element={document}
                  onActive={this.onActive}
                  onIdle={this.onIdle}
                  onAction={this.onAction}
                  debounce={250}
                  timeout={1000 * 60 * 10}
                />
              </div>
              <header class="header black-bg">
                <a
                  className="mob-show"
                  onClick={() =>
                    this.setState({ hammenu: !this.state.hammenu })
                  }
                >
                  <i
                    className="fa fa-tasks hammenu"
                    style={{ "margin-top": "25px", 'padding': '0 15px' }}
                  ></i>
                </a>

                <a onClick={this.TaskactiveBind.bind(this)} className="logo" style={{ 'padding': '0 10px' }}>
                  <img src={require("./img/retail-logo.png")} alt="logo" />
                </a>
                <div className="markcenter">
                  <p className="marquee">{this.state.tickerList}</p>
                </div>
                <ul className="nav pull-right pos-rel">
                  <li className="dropdown">
                    <a
                      className="dropdown-toggle"
                      data-toggle="dropdown"
                      onClick={this.showDropdownMenu}
                    >
                      {" "}
                      <img
                        src={require("./img/user.png")}
                        className="user-img"
                      />{" "}
                      <b className="name-show">{this.props.location.state.usrnm}</b>
                      ({this.props.location.state.storeNo})

                      <b className="caret" />
                    </a>
                  </li>

                  {this.state.displayMenu ? (
                    <ul className="dropdown-menuuser-dd">
                      <li>
                        Role : {this.props.location.state.roleName}
                      </li>
                      <li className="divider"></li>
                      <li>
                        <a onClick={this.Changepasswordbind}>Change Password</a>
                      </li>
                      <li className="divider"></li>
                      <li>
                        <a onClick={this.logout}>Log Out</a>
                      </li>
                    </ul>
                  ) : null}
                </ul>
              </header>
              {this.props.location.state.format_id === "1" ? (
                <aside>
                  <div id="sidebar" class="nav-collapse ">
                    {this.state.hammenu ? (
                      <ul class="sidebar-menu" id="nav-accordion">
                        <li className="sub-menu mt" onClick={this.CreateHo}>
                          {" "}
                          <a href="javascript:;">
                            {" "}
                            <i className="fa fa-tasks"></i>{" "}
                            <span>Ho Creation</span>{" "}
                          </a>

                        </li>
                        <li>
                          {" "}
                          <a>
                            {" "}
                            <i class="fa fa-tasks"></i>{" "}
                            <span>Store Creation</span>{" "}
                          </a>{" "}
                        </li>
                        {/*   <li>
                        {" "}
                        <a onClick={this.MenuRights}>
                          {" "}
                          <i class="fa fa-tasks"></i>{" "}
                          <span>Menu Rights</span>{" "}
                        </a>{" "}
                      </li> */}
                        {/* <li> <a onClick={this.Storemodification}> <i class="fa fa-tasks"></i> <span>Store Modification</span> </a> </li> */}
                      </ul>
                    ) : null}
                  </div>
                </aside>
              ) :
                <aside>
                  <div id="sidebar" className="nav-collapse">
                    {this.state.hammenu ? (
                      <ul className="sidebar-menu" id="nav-accordion">
                        {this.props.location.state.menuList["401"] || this.props.location.state.menuList["402"] || this.props.location.state.menuList["403"] || this.props.location.state.menuList["404"] || this.props.location.state.menuList["405"] || this.props.location.state.menuList["408"] || this.props.location.state.menuList["411"] ? (
                          <li
                            className="sub-menu mt"
                            onClick={this.showTaskDropdwnHandler.bind(this)}
                          >
                            {" "}
                            <a href="javascript:;">
                              {" "}
                              <i className="fa fa-tasks"></i> <span>Task</span>{" "}
                            </a>
                            {this.state.TaskDropdwn ? (
                              <div>
                                <ul class="sub">
                                  {this.props.location.state.menuList["401"] ? (
                                    <li>
                                      <a onClick={this.TaskactiveBind.bind(this)}>
                                        {this.props.location.state.menuList["401"]}
                                      </a>
                                    </li>
                                  ) : null}
                                  {this.props.location.state.menuList["402"] ? (
                                    <li>
                                      <a onClick={this.getroles.bind(this)}>
                                        {this.props.location.state.menuList["402"]}
                                      </a>
                                    </li>
                                  ) : null}
                                  {this.props.location.state.menuList["403"] ? (
                                    <li>
                                      <a
                                        onClick={this.CompletedTaskListbind.bind(
                                          this
                                        )}
                                      >
                                        {this.props.location.state.menuList["403"]}
                                      </a>
                                    </li>
                                  ) : null}
                                  {this.props.location.state.menuList["404"] ? (
                                    <li>
                                      <a onClick={this.templates.bind(this)}>
                                        {this.props.location.state.menuList["404"]}
                                      </a>
                                    </li>
                                  ) : null}
                                  {this.props.location.state.menuList["405"] ? (
                                    <li>
                                      <a onClick={this.ArchivedTasks.bind(this)}>
                                        {this.props.location.state.menuList["405"]}
                                      </a>
                                    </li>
                                  ) : null}
                                  {this.props.location.state.menuList["408"] ? (
                                    <li>
                                      <a onClick={this.StoreTasks.bind(this)}>
                                        {this.props.location.state.menuList["408"]}
                                      </a>
                                    </li>
                                  ) : null}
                                  {this.props.location.state.menuList["411"] ? (
                                    <li>
                                      <a onClick={this.toGenDashboard.bind(this)}>
                                        {this.props.location.state.menuList["411"]}
                                      </a>
                                    </li>
                                  ) : null}
                                </ul>
                              </div>
                            ) : null}
                          </li>
                        ) : null}
                        {this.props.location.state.menuList["406"] ? (
                          <div>
                            <li>
                              {" "}
                              <a onClick={this.Reportsbind.bind(this)}>
                                {" "}
                                <i className="fa fa-tasks"></i>{" "}
                                <span>
                                  {this.props.location.state.menuList["406"]}
                                </span>{" "}
                              </a>{" "}
                            </li>
                          </div>
                        ) : null}

                        {this.props.location.state.menuList["414"] && this.props.location.state.format_id === "102" ? (
                          <div>
                            <li>
                              {" "}
                              <a onClick={this.Defectbind.bind(this)}>
                                {" "}
                                <i className="fa fa-tasks"></i>{" "}
                                <span>
                                  {this.props.location.state.menuList["414"]}
                                </span>{" "}
                              </a>{" "}
                            </li>
                          </div>
                        ) : null}

                        {this.props.location.state.menuList["415"] ? (
                          <div>
                            <li>
                              {" "}
                              <a onClick={this.SmartPointBind.bind(this)}>
                                {" "}
                                <i className="fa fa-tasks"></i>{" "}
                                <span>
                                  {this.props.location.state.menuList["415"]}
                                </span>{" "}
                              </a>{" "}
                            </li>
                          </div>
                        ) : null}

                        {this.props.location.state.menuList["409"] || this.props.location.state.menuList["410"] ? (
                          <div>
                            <li
                              className="sub-menu"
                              onClick={this.showIssueDropdwnHandler.bind(this)}
                            >
                              {" "}
                              <a href="javascript:;">
                                {" "}
                                <i className="fa fa-tasks"></i>{" "}
                                <span>Issue</span>{" "}
                              </a>
                              {this.state.IssueDropdwn ? (
                                <div>
                                  <ul class="sub">
                                    {this.props.location.state.menuList["410"] ? (
                                      <li>
                                        <a onClick={this.IssueRaising.bind(this)}>
                                          {this.props.location.state.menuList["410"]}
                                        </a>
                                      </li>
                                    ) : null}
                                    {this.props.location.state.menuList["409"] ? (
                                      <li>
                                        <a onClick={this.IssueDashboard.bind(this)}>
                                          {this.props.location.state.menuList["409"]}
                                        </a>
                                      </li>
                                    ) : null}
                                  </ul>
                                </div>
                              ) : null}
                            </li>
                          </div>
                        ) : null}

                        {this.props.location.state.menuList["407"] ? (
                          <div>
                            <li
                              className="sub-menu"
                              onClick={this.showTaskdwnHandler.bind(this)}
                            >
                              {" "}
                              <a href="javascript:;">
                                {" "}
                                <i className="fa fa-tasks"></i>{" "}
                                <span>
                                  {this.props.location.state.menuList["407"]}
                                </span>{" "}
                              </a>
                              {this.state.Taskdwn ? (
                                <div>
                                  <ul class="sub">
                                    <li>
                                      {" "}
                                      <a onClick={this.tiker.bind(this)}>
                                        <span>Tiker Creation</span>{" "}
                                      </a>{" "}
                                    </li>
                                    <li>
                                      {" "}
                                      <a onClick={this.userlist.bind(this)}>
                                        <span>User List</span>{" "}
                                      </a>{" "}
                                    </li>
                                    {this.props.location.state.menuList["412"] ? (
                                      <li>
                                        {" "}
                                        <a onClick={this.Storecreation.bind(this)}>
                                          <span>{this.props.location.state.menuList["412"]}</span>{" "}
                                        </a>{" "}
                                      </li>
                                    ) : null}
                                    {this.props.location.state.menuList["413"] ? (
                                      <li>
                                        {" "}
                                        <a onClick={this.ToStoreList.bind(this)}>
                                          <span>{this.props.location.state.menuList["413"]}</span>{" "}
                                        </a>{" "}
                                      </li>
                                    ) : null}
                                  </ul>
                                </div>
                              ) : null}
                            </li>
                          </div>
                        ) : null}
                      </ul>
                    ) : null}
                  </div>
                </aside>
              }

              <section id="main-content">
                <section class="wrapper">
                  <div class="mt">
                    {/* <h3><i class="fa fa-angle-right"></i>Home page</h3>
                  <hr /> */}
                    <h3>
                      <i class="fa fa-angle-right"></i>Store Creation
                    </h3>
                    <hr />

                    <div class="row mt">
                      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                        <label>Upload File</label>
                        <div class="upload-holder">
                          <input
                            id="store-list"
                            type="text"
                            class="form-control input-upload"
                            value={this.state.FileName}
                          />

                          <span
                            class="btn-upload"
                            style={{ padding: "7px 25px" }}
                          >
                            Upload
                          </span>
                          <input
                            id="upload-storedata"
                            type="file"
                            name="Store-list-data"
                            class="input-hidden"
                            onChange={this.fileHandler.bind(this)}
                            accept=".xlsx,.xls"
                          />
                        </div>

                        <span class="help-block">
                          Sample File{" "}
                          <a href={require("./doc/StoreCreation.xlsx")}>
                            StoreCreation.xls
                          </a>
                        </span>


                      </div>





                      {this.state.subfmtList.length > 0 ?
                        <div class="col-lg-2 col-md-2 col-sm-2 col-xs-12">
                          <label>Sub Format</label>
                          <select
                            class="form-control"
                            id="Subfmt"
                            onChange={this.subfmtformat}
                          >
                            <option>Select</option>
                            {this.state.subfmtList.map((item, key) => <option key={key}>{item}</option>)}
                          </select>
                        </div> : ''}


                      {this.state.uploadFlag ?
                        <div class="col-lg-2 col-md-2 col-sm-2 col-xs-12">

                          <label>Action </label>
                          <select
                            class="form-control"
                            id="SelQType1"
                            onChange={this.addformat}
                          >
                            <option id="select">Select</option>
                            <option id="add">Add/Update</option>
                            <option id="delete">Delete</option>
                          </select>
                        </div>
                        : null}

                      {this.props.location.state.format_id === "1" ? (
                        <div class="col-lg-2 col-md-2 col-sm-2 col-xs-12">
                          <label>Select Format Type</label>
                          <span class="required-mark">*</span>
                          <br />

                          <select
                            class="form-control"
                            id="SelQType2"
                            onChange={this.roleType.bind(this)}
                          >
                            <option>Select</option>
                            {this.state.formerMasterList.map(function (
                              item,
                              key
                            ) {
                              return <option>{item.formatDesc}</option>;
                            })}
                          </select>
                        </div>
                      )
                        : null
                      }



                      {this.state.showAction ? (
                        <div class="col-lg-2 col-md-2 col-sm-2 col-xs-12">
                          <button
                            class="btn btn-primary min-wid-90 mt-25"
                            onClick={this.formatSelection.bind(this)}
                          >
                            Submit
                          </button>
                        </div>
                      ) : null}

                      <div class="col-lg-2 col-md-2 col-sm-2 col-xs-12">
                        {this.state.randomNumber ? (
                          <button
                            onClick={this.getStoreList.bind(this)}
                            class="btn btn-primary min-wid-90 mt-25"
                          >Display Stores</button>
                        ) : null}
                      </div>
                    </div>
                  </div>



                  {this.state.creationStatusList ?
                    <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12 tbwdth">
                      <div className="mt">
                        <div className="tbl-holder">
                          <div className={this.state.loading ? 'parentDisable' : ''} width="100%">
                            <div className='overlay-box'>
                              <Spinner
                                visible={this.state.loadingForUpload}
                                spinnerColor={"rgba(0, 0, 0, 0.3)"}
                              />
                            </div>
                          </div>


                          <table className="table table-striped table-advance table-hover table-bordered tbl-ticker tbl-hhide">
                            <thead>
                              <tr>
                                <th>Creation Status</th>
                                <th>Store Code</th>
                                <th>Employee Code</th>
                              </tr>
                            </thead>
                          </table>

                          <Scrollbars style={{ height: 296 }}>
                            <table className="table table-striped table-advance table-hover table-bordered tbl-ticker mob-tbl">
                              <tbody>
                                {this.state.creationStatusList.map(function (item, key) {
                                  return (
                                    <tr key={key}>
                                      <td data-th="Status">
                                        {item.status}
                                      </td>
                                      <td data-th="Store Code">
                                        {item.storeCode}
                                      </td>
                                      <td data-th="Emp Code">
                                        {item.empCode}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </Scrollbars>
                        </div>
                      </div>
                    </div>
                    : null}
                </section>
              </section>
            </section>
          </div>
        </div >
      </Router >
    );
  };

  roleType = e => {
    this.setState({ value: e.target.value });

    let flag = [];

    for (var i = 0; i < this.state.rformatlist.length; i++) {
      this.state.rformatlist[i] === e.target.value
        ? flag.push(this.state.rformatId[i])
        : this.setState({ valueid: "" });
    }
    this.setState({ valueid: flag });
  };

  fileHandler = event => {

    try {
      this.setState({ storeList: [], loadingForUpload: true, uploadFlag: false });
      const input = document.getElementById("upload-storedata");

      readXlsxFile(input.files[0]).then((rows, files) => {
        var storedata = [];
        for (let i = 1; i < rows.length; i++) {
          storedata.push(rows[i]);
          for (let j = 0; j < storedata.length; j++) {
            var store = {
              storeNo: "",
              storeName: "",
              city: "",
              state: "",
              zone: "",
              storeManagerName: "",
              storeMobile: "",
              storeEmail: "",
              storeSubFormat: "",
              ecCode: ""
            };
            store.storeNo = storedata[j][0];
            store.storeName = storedata[j][1];
            store.city = storedata[j][2];
            store.state = storedata[j][3];
            store.zone = storedata[j][4];
            store.storeManagerName = storedata[j][5];
            store.storeMobile = storedata[j][6];
            store.storeEmail = storedata[j][7];
            store.storeSubFormat = storedata[j][8];
            store.ecCode = storedata[j][9];
          }
          {
            this.state.storeList.push(store);

          }
        }
        this.setState({
          FileName: "File Uploaded Successfully",
          loadingForUpload: false,
          uploadFlag: true
        });
      });
    }
    catch (error) {
      this.setState({
        FileName: "File Uploaded Successfully",
        loadingForUpload: false,
        uploadFlag: true
      });
    }


  };

  ToStoreList = () => {
    this.props.history.push({
      pathname: "/StoreList",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        formerMasterList: this.state.formerMasterList,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  Storecreation = () => {
    this.props.history.push({
      pathname: "/Storecreation",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        formerMasterList: this.state.formerMasterList,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  showTaskdwnHandler = () => {
    this.setState({
      Taskdwn: !this.state.Taskdwn
    });
  };

  templates = () => {
    this.props.history.push({
      pathname: "/Templates",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  getroles(e) {
    this.props.history.push({
      pathname: "/CreatetaskUI",
      state: {
        format_id: this.props.location.state.format_id,
        role_id: this.props.location.state.role_id,
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  }
  tiker = () => {
    this.props.history.push({
      pathname: "/Tiker",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  usercreation = () => {
    this.props.history.push({
      pathname: "/UserCreation",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };
  TaskactiveBind = () => {
    this.props.history.push({
      pathname: "/Taskactive",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  userlist = () => {
    this.props.history.push({
      pathname: "/UserList",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  Changepasswordbind = () => {
    this.props.history.push({
      pathname: "/ChangepasswordUI",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  SmartPointBind = () => {
    this.props.history.push({
      pathname: "/SmartPoint",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        data: this.props.location.state.data,
        tskId: this.props.location.state.tskId,
        tskName: this.props.location.state.tskName,
        storeNo: this.props.location.state.storeNo,
        Isroffice: this.props.location.state.Isroffice,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  Defectbind = () => {
    this.props.history.push({
      pathname: "/DefectRaising",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        data: this.props.location.state.data,
        tskId: this.props.location.state.tskId,
        tskName: this.props.location.state.tskName,
        storeNo: this.props.location.state.storeNo,
        Isroffice: this.props.location.state.Isroffice,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  Reportsbind = () => {
    this.props.history.push({
      pathname: "/Reports",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        data: this.props.location.state.data,
        tskId: this.props.location.state.tskId,
        tskName: this.props.location.state.tskName,
        storeNo: this.props.location.state.storeNo,
        Isroffice: this.props.location.state.Isroffice,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  showIssueDropdwnHandler = () => {
    this.setState({
      IssueDropdwn: !this.state.IssueDropdwn
    });
  };

  IssueRaising = () => {
    this.props.history.push({
      pathname: "/IssueRaising",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        data: this.props.location.state.data,
        tskId: this.props.location.state.tskId,
        tskName: this.props.location.state.tskName,
        storeNo: this.props.location.state.storeNo,
        Isroffice: this.props.location.state.Isroffice,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  toGenDashboard = () => {
    this.props.history.push({
      pathname: "/DashboardGeneral",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        data: this.props.location.state.data,
        tskId: this.props.location.state.tskId,
        tskName: this.props.location.state.tskName,
        storeNo: this.props.location.state.storeNo,
        Isroffice: this.props.location.state.Isroffice,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  IssueDashboard = () => {
    this.props.history.push({
      pathname: "/IssueDashboard",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        data: this.props.location.state.data,
        tskId: this.props.location.state.tskId,
        tskName: this.props.location.state.tskName,
        storeNo: this.props.location.state.storeNo,
        Isroffice: this.props.location.state.Isroffice,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  CompletedTaskListbind = () => {
    this.props.history.push({
      pathname: "/CompletedTaskList",
      state: {
        data: this.props.location.state.data,
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  ArchivedTasks = () => {
    this.props.history.push({
      pathname: "/ArchivedTasks",
      state: {
        data: this.props.location.state.data,
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
        Isroffice: this.props.location.state.Isroffice,
      }
    });
  };

  StoreTasks = () => {
    this.props.history.push({
      pathname: "/StoreTasks",
      state: {
        data: this.props.location.state.data,
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };


}

export default withRouter(Storecreation);
