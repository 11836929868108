import React, { Component } from "react";
import "jquery/dist/jquery";
import { withRouter } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert"; // Import
import "./css/react-confirm-alert.css"; // Import css
import "react-datepicker/dist/react-datepicker.css";
import IdleTimer from "react-idle-timer"; // For Idle
import "./css/style.css";
import "./css/style-responsive.css";
import { Encrypt, decryptData } from "./Encryption-Decrypytion";
import disableBrowserBackButton from "disable-browser-back-navigation";
import { basicAuth } from "./BasicAuth";

class StoreDetails extends Component {
  state = {
    TaskDropdwn: false,
    hasError: false   
  };
  showTaskDropdwnHandler = () => {
    this.setState({
      TaskDropdwn: !this.state.TaskDropdwn
    });
  };

  componentDidMount() {
    if (window.innerWidth <= 768) {
      this.setState({ hammenu: false });
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      TaskDropdwn: false,
      hasError: false, 
      displayMenu: false,
      hasError: false  
    };
    this.state = {
      startDate: "",
      endDate: "",
      role_id: "",
      format_id: "",
      searchEDate: "",
      resptaskname: [],
      respstartdate: [],
      respenddate: [],
      resptaskdesc: [],
      data: [],
      storeList: [],
      usrnm: "",
      userid: "",
      tickerList: [],
      Search: "",
      searchSDate: "",
      searchName: "",
      tskId: "",
      tskName: "",
      loading: false,
      hammenu: true,
      displayMenu: false,
    };
    this.idleTimer = null;
    this.onAction = this._onAction.bind(this);
    this.onActive = this._onActive.bind(this);
    this.onIdle = this._onIdle.bind(this);
    this.handleChangeStart = this.handleChangeStart.bind(this);
    this.handleChangeEnd = this.handleChangeEnd.bind(this);

    this.showDropdownMenu = this.showDropdownMenu.bind(this);
    this.hideDropdownMenu = this.hideDropdownMenu.bind(this);
  }

  logoutnormal = () => {
    localStorage.clear();
    this.props.history.replace("/");
    disableBrowserBackButton();
  };

  handleChangeStart(date) {
    var monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ];
    this.setState({
      searchSDate:
        date.getDate() +
        "-" +
        monthNames[date.getMonth()] +
        "-" +
        date.getFullYear(),
      startDate: date
    });
  }
  handleChangeEnd(date) {
    var monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ];
    this.setState({
      searchEDate:
        date.getDate() +
        "-" +
        monthNames[date.getMonth()] +
        "-" +
        date.getFullYear(),
      endDate: date
    });
  }
  logout = () => {
    this.setState({ loading: true });
    var Request1 = {
      userId: this.props.location.state.userid,
      guId: this.props.location.state.guId
    };
    var EncryptedRequest1 = Encrypt(Request1);
    fetch("/LogOut ", {
      method: "POST",
      headers: {
        guId: this.props.location.state.guId,
        Authorization: "Basic " + basicAuth(this.props.location.state.userid)
      },
      body: EncryptedRequest1
    })
      .then(response => response.text())
      .then(response => {
        this.setState({ loading: false });
        var DecryptedResponse = decryptData(response);
        if (DecryptedResponse.errorCode === "00") {
          this.props.history.replace("/");
          disableBrowserBackButton();
        } else {
          confirmAlert({
            message: DecryptedResponse.errorMsg,
            buttons: [
              {
                label: "Ok"
              }
            ]
          });
        }
      })
      .catch(error => {
        confirmAlert({
          title: "Alert !",
          message: "Session expired",
          buttons: [
            {
              label: "Ok",
              onClick: () => {
                this.logoutnormal();
              }
            }
          ]
        });
      });
  };

  showDropdownMenu(event) {
    event.preventDefault();
    this.setState({ displayMenu: true }, () => {
      document.addEventListener("click", this.hideDropdownMenu);
    });
  }

  hideDropdownMenu() {
    this.setState({ displayMenu: false }, () => {
      document.removeEventListener("click", this.hideDropdownMenu);
    });
  }

  onChange = e => {
    this.setState({
      Search: e.target.value
    });
  };

  onChange1 = e => {
    this.setState({ searchName: e.target.value });
  };

  showTaskdwnHandler = () => {
    this.setState({
      Taskdwn: !this.state.Taskdwn
    });
  };

  handleDateChangeRaws = e => {
    e.preventDefault();
  };
  handleDateChangeRawe = e => {
    e.preventDefault();
  };

  _onAction(e) {
  }

  _onActive(e) {
  }

  _onIdle(e) {
    localStorage.clear();
    window.location.href = "/";
    disableBrowserBackButton();
  }

  componentWillMount() {
    this.GetStoreList();
  }

  GetStoreList(e) {
    this.setState({ loading: true });

    if (
      this.props.location.state !== undefined &&
      this.props.location.state.usrnm !== ""
    ) {
      this.setState({ usrnm: this.props.location.state.usrnm });
      this.setState({ userid: this.props.location.state.userid });
      this.setState({ tskId: this.props.location.state.tskId });
      this.setState({ tskName: this.props.location.state.tskName });
      this.setState({ userid: this.props.location.state.userid });
      var Request1 = {
        formatId: this.props.location.state.format_id,
        guId: this.props.location.state.guId
      };
      var EncryptedRequest1 = Encrypt(Request1);

      fetch("/GetStoreList", {
        method: "POST",
        headers: {
          guId: this.props.location.state.guId,
          Authorization: "Basic " + basicAuth(this.props.location.state.userid)
        },
        body: EncryptedRequest1
      })
        .then(response => response.text())
        .then(response => {
          var DecryptedResponse1 = decryptData(response);

          if (DecryptedResponse1.errorCode === "00") {

            this.setState({ storeList: DecryptedResponse1.storeList });
            this.setState({
              loading: false
            });
          } else {
            this.setState({ loading: false });
            confirmAlert({
              message: DecryptedResponse1.errorMsg,
              buttons: [
                {
                  label: "Ok"
                }
              ]
            });
          }
        })
        .catch(error => {
          confirmAlert({
            title: "Alert !",
            message: "Session expired",
            buttons: [
              {
                label: "Ok",
                onClick: () => {
                  this.logoutnormal();
                }
              }
            ]
          });
        });
    } else {
      window.location.href = "/";
    }
  }

  componentDidCatch() {
    this.setState({ hasError: true });
  }

  render = () => {
    if (this.state.hasError) {
      return (
        <div>
          <h2>Error occurred!! please contact administrator</h2>
        </div>
      );
    } else {
      return (
        <div>
          <section id="container">
            <div>
              <IdleTimer
                ref={ref => {
                  this.idleTimer = ref;
                }}
                element={document}
                onActive={this.onActive}
                onIdle={this.onIdle}
                onAction={this.onAction}
                debounce={250}
                timeout={1000 * 60 * 10}
              />
            </div>
            <header class="header black-bg">
              <a
                className="mob-show"
                onClick={() =>
                  this.setState({ hammenu: !this.state.hammenu })
                }
              >
                <i
                  className="fa fa-tasks hammenu"
                  style={{ "margin-top": "25px", 'padding': '0 15px' }}
                ></i>
              </a>

              <a onClick={this.TaskactiveBind.bind(this)} className="logo" style={{ 'padding': '0 10px' }}>
                <img src={require("./img/retail-logo.png")} alt="logo" />
              </a>
              <div className="markcenter">
                <p className="marquee">{this.state.tickerList}</p>
              </div>
              <ul className="nav pull-right pos-rel">
                <li className="dropdown">
                  <a
                    className="dropdown-toggle"
                    data-toggle="dropdown"
                    onClick={this.showDropdownMenu}
                  >
                    {" "}
                    <img
                      src={require("./img/user.png")}
                      className="user-img"
                    />{" "}
                    <b className="name-show">{this.props.location.state.usrnm}</b>
                    ({this.props.location.state.storeNo})

                    <b className="caret" />
                  </a>
                </li>

                {this.state.displayMenu ? (
                  <ul className="dropdown-menuuser-dd">
                    <li>
                      Role : {this.props.location.state.roleName}
                    </li>
                    <li className="divider"></li>
                    <li>
                      <a onClick={this.Changepasswordbind}>Change Password</a>
                    </li>
                    <li className="divider"></li>
                    <li>
                      <a onClick={this.logout}>Log Out</a>
                    </li>
                  </ul>
                ) : null}
              </ul>
            </header>
            {this.props.location.state.passwordFlag !== "1" ? (
              <aside>
                <div id="sidebar" className="nav-collapse">
                  {this.state.hammenu ? (
                    <ul className="sidebar-menu" id="nav-accordion">
                      {this.props.location.state.menuList["401"] || this.props.location.state.menuList["402"] || this.props.location.state.menuList["403"] || this.props.location.state.menuList["404"] || this.props.location.state.menuList["405"] || this.props.location.state.menuList["408"] || this.props.location.state.menuList["411"] ? (
                        <li
                          className="sub-menu mt"
                          onClick={this.showTaskDropdwnHandler.bind(this)}
                        >
                          {" "}
                          <a href="javascript:;">
                            {" "}
                            <i className="fa fa-tasks"></i> <span>Task</span>{" "}
                          </a>
                          {this.state.TaskDropdwn ? (
                            <div>
                              <ul class="sub">
                                {this.props.location.state.menuList["401"] ? (
                                  <li>
                                    <a onClick={this.TaskactiveBind.bind(this)}>
                                      {this.props.location.state.menuList["401"]}
                                    </a>
                                  </li>
                                ) : null}
                                {this.props.location.state.menuList["402"] ? (
                                  <li>
                                    <a onClick={this.getroles.bind(this)}>
                                      {this.props.location.state.menuList["402"]}
                                    </a>
                                  </li>
                                ) : null}
                                {this.props.location.state.menuList["403"] ? (
                                  <li>
                                    <a
                                      onClick={this.CompletedTaskListbind.bind(
                                        this
                                      )}
                                    >
                                      {this.props.location.state.menuList["403"]}
                                    </a>
                                  </li>
                                ) : null}
                                {this.props.location.state.menuList["404"] ? (
                                  <li>
                                    <a onClick={this.templates.bind(this)}>
                                      {this.props.location.state.menuList["404"]}
                                    </a>
                                  </li>
                                ) : null}
                                {this.props.location.state.menuList["405"] ? (
                                  <li>
                                    <a onClick={this.ArchivedTasks.bind(this)}>
                                      {this.props.location.state.menuList["405"]}
                                    </a>
                                  </li>
                                ) : null}
                                {this.props.location.state.menuList["408"] ? (
                                  <li>
                                    <a onClick={this.StoreTasks.bind(this)}>
                                      {this.props.location.state.menuList["408"]}
                                    </a>
                                  </li>
                                ) : null}
                                {this.props.location.state.menuList["411"] ? (
                                  <li>
                                    <a onClick={this.toGenDashboard.bind(this)}>
                                      {this.props.location.state.menuList["411"]}
                                    </a>
                                  </li>
                                ) : null}
                              </ul>
                            </div>
                          ) : null}
                        </li>
                      ) : null}
                      {this.props.location.state.menuList["406"] ? (
                        <div>
                          <li>
                            {" "}
                            <a onClick={this.Reportsbind.bind(this)}>
                              {" "}
                              <i className="fa fa-tasks"></i>{" "}
                              <span>
                                {this.props.location.state.menuList["406"]}
                              </span>{" "}
                            </a>{" "}
                          </li>
                        </div>
                      ) : null}

                      {this.props.location.state.menuList["414"] && this.props.location.state.format_id === "102" ? (
                        <div>
                          <li>
                            {" "}
                            <a onClick={this.Defectbind.bind(this)}>
                              {" "}
                              <i className="fa fa-tasks"></i>{" "}
                              <span>
                                {this.props.location.state.menuList["414"]}
                              </span>{" "}
                            </a>{" "}
                          </li>
                        </div>
                      ) : null}

                      {this.props.location.state.menuList["415"] ? (
                        <div>
                          <li>
                            {" "}
                            <a onClick={this.SmartPointBind.bind(this)}>
                              {" "}
                              <i className="fa fa-tasks"></i>{" "}
                              <span>
                                {this.props.location.state.menuList["415"]}
                              </span>{" "}
                            </a>{" "}
                          </li>
                        </div>
                      ) : null}

                      {this.props.location.state.menuList["409"] || this.props.location.state.menuList["410"] ? (
                        <div>
                          <li
                            className="sub-menu"
                            onClick={this.showIssueDropdwnHandler.bind(this)}
                          >
                            {" "}
                            <a href="javascript:;">
                              {" "}
                              <i className="fa fa-tasks"></i>{" "}
                              <span>Issue</span>{" "}
                            </a>
                            {this.state.IssueDropdwn ? (
                              <div>
                                <ul class="sub">
                                  {this.props.location.state.menuList["410"] ? (
                                    <li>
                                      <a onClick={this.IssueRaising.bind(this)}>
                                        {this.props.location.state.menuList["410"]}
                                      </a>
                                    </li>
                                  ) : null}
                                  {this.props.location.state.menuList["409"] ? (
                                    <li>
                                      <a onClick={this.IssueDashboard.bind(this)}>
                                        {this.props.location.state.menuList["409"]}
                                      </a>
                                    </li>
                                  ) : null}
                                </ul>
                              </div>
                            ) : null}
                          </li>
                        </div>
                      ) : null}

                      {this.props.location.state.menuList["407"] ? (
                        <div>
                          <li
                            className="sub-menu"
                            onClick={this.showTaskdwnHandler.bind(this)}
                          >
                            {" "}
                            <a href="javascript:;">
                              {" "}
                              <i className="fa fa-tasks"></i>{" "}
                              <span>
                                {this.props.location.state.menuList["407"]}
                              </span>{" "}
                            </a>
                            {this.state.Taskdwn ? (
                              <div>
                                <ul class="sub">
                                  <li>
                                    {" "}
                                    <a onClick={this.tiker.bind(this)}>
                                      <span>Tiker Creation</span>{" "}
                                    </a>{" "}
                                  </li>
                                  <li>
                                    {" "}
                                    <a onClick={this.userlist.bind(this)}>
                                      <span>User List</span>{" "}
                                    </a>{" "}
                                  </li>
                                  {this.props.location.state.menuList["412"] ? (
                                    <li>
                                      {" "}
                                      <a onClick={this.Storecreation.bind(this)}>
                                        <span>{this.props.location.state.menuList["412"]}</span>{" "}
                                      </a>{" "}
                                    </li>
                                  ) : null}
                                  {this.props.location.state.menuList["413"] ? (
                                    <li>
                                      {" "}
                                      <a onClick={this.ToStoreList.bind(this)}>
                                        <span>{this.props.location.state.menuList["413"]}</span>{" "}
                                      </a>{" "}
                                    </li>
                                  ) : null}
                                </ul>
                              </div>
                            ) : null}
                          </li>
                        </div>
                      ) : null}
                    </ul>
                  ) : null}
                </div>
              </aside>
            ) : null}
            <section id="main-content">
              <section class="wrapper">
                <div class="row">
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <h3>Store Details</h3>
                    </div>
                    <div class="clearfix"></div>
                    <hr />
                    <form className="form-horizontal" autoComplete="off">
                      <div className="col-lg-8 col-md-8 col-sm-10 col-xs-12">
                        <div className="form-group">
                          <label className="control-label col-lg-3 col-md-4 col-sm-4 col-xs-12">
                            Store No.
                          </label>
                          <div class="col-lg-9 col-md-8 col-sm-8 col-xs-12">
                            <input
                              type="text"
                              class="form-control"
                              value={this.props.location.state.store_No}
                              id="taskName"
                              maxlength="100"
                              autoComplete="off"
                              disabled={true}
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="control-label col-lg-3 col-md-4 col-sm-4 col-xs-12">
                            Store Name
                          </label>
                          <div class="col-lg-9 col-md-8 col-sm-8 col-xs-12">
                            <input
                              type="text"
                              class="form-control"
                              value={this.props.location.state.store_Name}
                              id="taskName"
                              maxlength="100"
                              autoComplete="off"
                              disabled={true}
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="control-label col-lg-3 col-md-4 col-sm-4 col-xs-12">
                            Mobile No.
                          </label>
                          <div class="col-lg-9 col-md-8 col-sm-8 col-xs-12">
                            <input
                              type="text"
                              class="form-control"
                              value={this.props.location.state.storeMobile}
                              id="taskName"
                              maxlength="100"
                              autoComplete="off"
                              disabled={true}
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="control-label col-lg-3 col-md-4 col-sm-4 col-xs-12">
                            Email
                          </label>
                          <div class="col-lg-9 col-md-8 col-sm-8 col-xs-12">
                            <input
                              type="text"
                              class="form-control"
                              value={this.props.location.state.storeEmail}
                              id="taskName"
                              maxlength="100"
                              autoComplete="off"
                              disabled={true}
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="control-label col-lg-3 col-md-4 col-sm-4 col-xs-12">
                            City
                          </label>
                          <div class="col-lg-9 col-md-8 col-sm-8 col-xs-12">
                            <input
                              type="text"
                              class="form-control"
                              value={this.props.location.state.city}
                              id="taskName"
                              maxlength="100"
                              autoComplete="off"
                              disabled={true}
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="control-label col-lg-3 col-md-4 col-sm-4 col-xs-12">
                            State
                          </label>
                          <div class="col-lg-9 col-md-8 col-sm-8 col-xs-12">
                            <input
                              type="text"
                              class="form-control"
                              value={this.props.location.state.state}
                              id="taskName"
                              maxlength="100"
                              autoComplete="off"
                              disabled={true}
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="control-label col-lg-3 col-md-4 col-sm-4 col-xs-12">
                            Cluster Manager
                          </label>
                          <div class="col-lg-9 col-md-8 col-sm-8 col-xs-12">
                            <input
                              type="text"
                              class="form-control"
                              value={this.props.location.state.clusterEcno}
                              id="taskName"
                              maxlength="100"
                              autoComplete="off"
                              disabled={true}
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="control-label col-lg-3 col-md-4 col-sm-4 col-xs-12">
                            Regional Manager
                          </label>
                          <div class="col-lg-9 col-md-8 col-sm-8 col-xs-12">
                            <input
                              type="text"
                              class="form-control"
                              value={this.props.location.state.regionalEcno}
                              id="taskName"
                              maxlength="100"
                              autoComplete="off"
                              disabled={true}
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="control-label col-lg-3 col-md-4 col-sm-4 col-xs-12">
                            Zonal Manager
                          </label>
                          <div class="col-lg-9 col-md-8 col-sm-8 col-xs-12">
                            <input
                              type="text"
                              class="form-control"
                              value={this.props.location.state.zonalEcno}
                              id="taskName"
                              maxlength="100"
                              autoComplete="off"
                              disabled={true}
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="control-label col-lg-3 col-md-4 col-sm-4 col-xs-12">
                            {" "}
                          </label>
                          <div class="col-lg-9 col-md-8 col-sm-8 col-xs-12">
                            <button
                              class="btn btn-primary min-wid-90"
                              onClick={this.ToStoreList.bind(this)}
                            >
                              Back
                            </button>

                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </section>
            </section>
          </section>
        </div>
      );
    }
  };

  ToStoreList = () => {
    this.props.history.push({
      pathname: "/StoreList",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        formerMasterList: this.state.formerMasterList,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  Storecreation = () => {
    this.props.history.push({
      pathname: "/Storecreation",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        formerMasterList: this.state.formerMasterList,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  showIssueDropdwnHandler = () => {
    this.setState({
      IssueDropdwn: !this.state.IssueDropdwn
    });
  };

  toGenDashboard = () => {
    this.props.history.push({
      pathname: "/DashboardGeneral",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        data: this.props.location.state.data,
        tskId: this.props.location.state.tskId,
        tskName: this.props.location.state.tskName,
        storeNo: this.props.location.state.storeNo,
        Isroffice: this.props.location.state.Isroffice,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  IssueRaising = () => {
    this.props.history.push({
      pathname: "/IssueRaising",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        data: this.props.location.state.data,
        tskId: this.props.location.state.tskId,
        tskName: this.props.location.state.tskName,
        storeNo: this.props.location.state.storeNo,
        Isroffice: this.props.location.state.Isroffice,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  IssueDashboard = () => {
    this.props.history.push({
      pathname: "/IssueDashboard",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        data: this.props.location.state.data,
        tskId: this.props.location.state.tskId,
        tskName: this.props.location.state.tskName,
        storeNo: this.props.location.state.storeNo,
        Isroffice: this.props.location.state.Isroffice,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };



  templates = () => {
    this.props.history.push({
      pathname: "/Templates",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  callupdatetaskpage(tskId, tskName, tskDescr, strtDate, edDate, taskRoleId) {
    this.props.history.push({
      pathname: "/Updatetasks",
      state: {
        tskId: tskId,
        tskName: tskName,
        tskDescr: tskDescr,
        strtDate: new Date(strtDate),
        edDate: new Date(edDate),
        userid: this.props.location.state.userid,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        usrnm: this.props.location.state.usrnm,
        taskRoleId: taskRoleId,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  }
  calladdquestionpage(
    tskId,
    tskName,
    tskDescr,
    strtDate,
    edDate,
    publishStatus
  ) {
    this.props.history.push({
      pathname: "/Addquestion",
      state: {
        tskId: tskId,
        tskName: tskName,
        tskDescr: tskDescr,
        strtDate: strtDate,
        edDate: edDate,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        storeNo: this.props.location.state.storeNo,
        publishStatus: publishStatus,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  }

  getroles(e) {
    this.props.history.push({
      pathname: "/CreatetaskUI",
      state: {
        format_id: this.props.location.state.format_id,
        role_id: this.props.location.state.role_id,
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  }
  tiker = () => {
    this.props.history.push({
      pathname: "/Tiker",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  usercreation = () => {
    this.props.history.push({
      pathname: "/UserCreation",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };
  TaskactiveBind = () => {
    this.props.history.push({
      pathname: "/Taskactive",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  userlist = () => {
    this.props.history.push({
      pathname: "/UserList",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  Changepasswordbind = () => {
    this.props.history.push({
      pathname: "/ChangepasswordUI",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  SmartPointBind = () => {
    this.props.history.push({
      pathname: "/SmartPoint",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        data: this.props.location.state.data,
        tskId: this.props.location.state.tskId,
        tskName: this.props.location.state.tskName,
        storeNo: this.props.location.state.storeNo,
        Isroffice: this.props.location.state.Isroffice,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  Defectbind = () => {
    this.props.history.push({
      pathname: "/DefectRaising",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        data: this.props.location.state.data,
        tskId: this.props.location.state.tskId,
        tskName: this.props.location.state.tskName,
        storeNo: this.props.location.state.storeNo,
        Isroffice: this.props.location.state.Isroffice,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  Reportsbind = () => {
    this.props.history.push({
      pathname: "/Reports",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        data: this.state.data,
        tskId: this.state.tskId,
        tskName: this.state.tskName,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  ArchivedTasks = () => {
    this.props.history.push({
      pathname: "/ArchivedTasks",
      state: {
        data: this.state.data,
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
        Isroffice: this.props.location.state.Isroffice,
      }
    });
  };

  CompletedTaskListbind = () => {
    this.props.history.push({
      pathname: "/CompletedTaskList",
      state: {
        data: this.state.data,
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  StoreTasks = () => {
    this.props.history.push({
      pathname: "/StoreTasks",
      state: {
        data: this.state.data,
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };
}
export default withRouter(StoreDetails);
