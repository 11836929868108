import React, { Component } from "react";
import "./css/style.css";
import "./css/style-responsive.css";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { Encrypt, decryptData } from "./Encryption-Decrypytion";
import disableBrowserBackButton from "disable-browser-back-navigation";
import { withRouter } from "react-router-dom";
import {
    BrowserRouter as Router
} from "react-router-dom";
import IdleTimer from "react-idle-timer"; // For Idle
import { basicAuth } from "./BasicAuth";
import { Scrollbars } from "react-custom-scrollbars";
import Spinner from "react-spinner-material"; //Import Spinner

class MenuRights extends Component {
    state = {
        value: "",
        userId: "",
        firstName: "",
        middleName: "",
        lastName: "",
        email: "",
        formatId: "",
        formerMasterList: [],
        roleMasterList: [],
        rformatlist: [],
        rformatlisted: [],
        rformatId: [],
        rrole: [],
        lstformat: [],
        formatDesc: [],
        formarMasterList: [],
        roleMasterList1: [],
        storeList: [],
        values: "",
        hasError: false, 
        userid: "",

    };

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            value: "",
            username: "",
            valueid: [],
            role_id: [],
            firstName: "",
            FileName: "",
            middleName: "",
            lastName: "",
            StoreList: "StoreList",
            email: "",
            formatId: null,
            userId: "",
            formerMasterList: [],
            roleMasterList: [],
            rformatlist: [],
            rformatlisted: [],
            rformatId: [],
            rrole: [],
            rlistid: [],
            lstformat: [],
            storeList: [],
            displayMenu: false,
            values: "",
            hasError: false, 
            userid: "",
            hammenu: true,
            formatDesc: [],
            formarMasterList: [],
            roleMasterList1: [],
        };

        this.showDropdownMenu = this.showDropdownMenu.bind(this);
        this.hideDropdownMenu = this.hideDropdownMenu.bind(this);
        this.idleTimer = null;
        this.onAction = this._onAction.bind(this);
        this.onActive = this._onActive.bind(this);
        this.onIdle = this._onIdle.bind(this);
        // this.onChange = this.onChange.bind(this)
    }

    logoutnormal = () => {
        localStorage.clear();
        this.props.history.replace("/");
        disableBrowserBackButton();
    };

    logout = () => {
        this.setState({ loading: true });
        var Request1 = {
            userId: this.props.location.state.userid,
            guId: this.props.location.state.guId
        };
        var EncryptedRequest1 = Encrypt(Request1);
        fetch("/LogOut ", {
            method: "POST",
            headers: {
                guId: this.props.location.state.guId,
                Authorization: "Basic " + basicAuth(this.props.location.state.userid)
            },
            body: EncryptedRequest1
        })
            .then(response => response.text())
            .then(response => {
                this.setState({ loading: false });
                var DecryptedResponse = decryptData(response);
                if (DecryptedResponse.errorCode === "00") {
                    this.props.history.replace("/");
                    disableBrowserBackButton();
                } else {
                    confirmAlert({
                        message: DecryptedResponse.errorMsg,
                        buttons: [
                            {
                                label: "Ok"
                            }
                        ]
                    });
                }
            })
            .catch(error => {
                confirmAlert({
                    title: "Alert !",
                    message: "Session expired",
                    buttons: [
                        {
                            label: "Ok",
                            onClick: () => {
                                this.logoutnormal();
                            }
                        }
                    ]
                });
            });
    };
    handleSetList([]) {
        this.setState({
            formarMasterList: []
        });
    }

    showTaskDropdwnHandler = () => {
        this.setState({
            TaskDropdwn: !this.state.TaskDropdwn
        });
    };

    showDropdownMenu(event) {
        event.preventDefault();
        this.setState({ displayMenu: true }, () => {
            document.addEventListener("click", this.hideDropdownMenu);
        });
    }
    hideDropdownMenu() {
        this.setState({ displayMenu: false }, () => {
            document.removeEventListener("click", this.hideDropdownMenu);
        });
    }

    componentDidMount() {
        if (window.innerWidth <= 768) {
            this.setState({ hammenu: false });
        }
    }
    _onAction(e) {
    }

    _onActive(e) {
    }

    _onIdle(e) {
        localStorage.clear();
        window.location.href = "/";
        disableBrowserBackButton();
    }

    CreateHo = () => {
        this.props.history.push({
            pathname: "/CreateHo",
            state: {
                userid: this.props.location.state.userid,
                username: this.props.location.state.username,
                role_id: this.state.roleId,
                format_id: this.props.location.state.format_id,
                storeNo: this.props.location.state.storeNo,
                guId: this.props.location.state.guId
            }
        });
    };

    Storecreation = () => {
        this.props.history.push({
            pathname: "/Storecreation",
            state: {
                userid: this.props.location.state.userid,
                username: this.props.location.state.username,
                role_id: this.state.roleId,
                format_id: this.props.location.state.format_id,
                formerMasterList: this.state.formerMasterList,
                storeNo: this.props.location.state.storeNo,
                guId: this.props.location.state.guId
            }
        });
    };

    Storemodification = () => {
        this.props.history.push({
            pathname: "/Storemodification",
            state: {
                userid: this.props.location.state.userid,
                username: this.props.location.state.username,
                role_id: this.state.roleId,
                format_id: this.props.location.state.format_id,
                storeNo: this.props.location.state.storeNo,
                guId: this.props.location.state.guId
            }
        });
    };
    componentWillMount() {
        if (
            this.props.location.state === undefined ||
            this.props.location.state === ""
        ) {
            window.location.href = "/";
        } else {
            this.GetFormatMaster();
        }
    }

    componentDidCatch() {
        this.setState({ hasError: true });
    }
    render = () => {
        var that = this;
        if (this.state.hasError) {
            return (
                <div>
                    <h2>Error occurred!! please contact administrator</h2>
                </div>
            );
        } else {
            return (
                <Router>
                    <div>
                        <div>
                            <section id="container">
                                <div>
                                    <IdleTimer
                                        ref={ref => {
                                            this.idleTimer = ref;
                                        }}
                                        element={document}
                                        onActive={this.onActive}
                                        onIdle={this.onIdle}
                                        onAction={this.onAction}
                                        debounce={250}
                                        timeout={1000 * 60 * 10}
                                    />
                                    {/* your app here */}
                                </div>
                                <header class="header black-bg">
                                    <a
                                        className="mob-show"
                                        onClick={() =>
                                            this.setState({ hammenu: !this.state.hammenu })
                                        }
                                    >
                                        <i
                                            className="fa fa-tasks hammenu"
                                            style={{ "margin-top": "30px" }}
                                        ></i>
                                    </a>

                                    <a className="logo">
                                        <img src={require("./img/retail-logo.png")} alt="logo" />
                                    </a>

                                    <ul className="nav pull-right pos-rel">
                                        <li className="dropdown">
                                            <a
                                                className="dropdown-toggle"
                                                data-toggle="dropdown"
                                                onClick={this.showDropdownMenu}
                                            >
                                                <img
                                                    src={require("./img/user.png")}
                                                    className="user-img"
                                                />
                                                <b>{this.props.location.state.usrnm}</b> (
                                                {this.props.location.state.storeNo})
                                                <b className="caret" />
                                            </a>
                                        </li>

                                        {this.state.displayMenu ? (
                                            <ul className="dropdown-menuuser-dd">
                                                <li>
                                                    <a onClick={() => this.logout()}>Log Out</a>
                                                </li>
                                            </ul>
                                        ) : null}
                                    </ul>
                                </header>
                                <aside>
                                    <div id="sidebar" class="nav-collapse ">
                                        {this.state.hammenu ? (
                                            <ul class="sidebar-menu" id="nav-accordion">
                                                <li className="sub-menu mt" onClick={this.CreateHo}>
                                                    {" "}
                                                    <a href="javascript:;">
                                                        {" "}
                                                        <i className="fa fa-tasks"></i>{" "}
                                                        <span>Ho Creation</span>{" "}
                                                    </a>

                                                </li>
                                                <li>
                                                    {" "}
                                                    <a onClick={this.Storecreation}>
                                                        {" "}
                                                        <i class="fa fa-tasks"></i>{" "}
                                                        <span>Store Creation</span>{" "}
                                                    </a>{" "}
                                                </li>
                                                <li>
                                                    {" "}
                                                    <a href="javascript:;">
                                                        {" "}
                                                        <i class="fa fa-tasks"></i>{" "}
                                                        <span>Menu Rights</span>{" "}
                                                    </a>{" "}
                                                </li>
                                                {/* <li> <a onClick={this.Storemodification}> <i class="fa fa-tasks"></i> <span>Store Modification</span> </a> </li> */}
                                            </ul>
                                        ) : null}
                                    </div>
                                </aside>
                                <section id="main-content">
                                    <section class="wrapper">
                                        <div class="mt">
                                            {/* <h3><i class="fa fa-angle-right"></i>Home page</h3>
                  <hr /> */}
                                            <h3>
                                                <i class="fa fa-angle-right"></i> Menu Rights
                                            </h3>
                                            <hr />
                                            <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12 tbwdth">
                                                <div className="mt">
                                                    <div className="tbl-holder">
                                                        <div className={this.state.loading ? 'parentDisable' : ''} width="100%">
                                                            <div className='overlay-box'>
                                                                <Spinner
                                                                    visible={this.state.loading}
                                                                    spinnerColor={"rgba(0, 0, 0, 0.3)"}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <table className="table table-striped table-advance table-hover table-bordered tbl-genDash tbl-hhide">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Roles</th>
                                                                        <th>Active Task(s)</th>
                                                                        <th>Create Task</th>
                                                                        <th>Task Answers</th>
                                                                        <th>Templates</th>
                                                                        <th>Archived Tasks</th>
                                                                        <th>Reports</th>
                                                                        <th>Utilities</th>
                                                                    </tr>
                                                                </thead>
                                                            </table>

                                                            <Scrollbars style={{ height: 296 }}>
                                                                <table className="table table-striped table-advance table-hover table-bordered tbl-genDash">
                                                                    <tbody>

                                                                        <tr id="Zonal">
                                                                            <td class="tbl-hhide">
                                                                                {"Zonal Manager"}
                                                                            </td>
                                                                            <td>
                                                                                <input type="checkbox"></input>
                                                                            </td>
                                                                            <td>
                                                                                <input type="checkbox"></input>
                                                                            </td>
                                                                            <td>
                                                                                <input type="checkbox"></input>
                                                                            </td>
                                                                            <td>
                                                                                <input type="checkbox"></input>
                                                                            </td>
                                                                            <td>
                                                                                <input type="checkbox"></input>
                                                                            </td>
                                                                            <td>
                                                                                <input type="checkbox"></input>
                                                                            </td>
                                                                            <td>
                                                                                <input type="checkbox"></input>
                                                                            </td>
                                                                        </tr>
                                                                        <tr id="Regional">
                                                                            <td>
                                                                                {"Regional Manager"}
                                                                            </td>
                                                                            <td>
                                                                                <input type="checkbox"></input>
                                                                            </td>
                                                                            <td>
                                                                                <input type="checkbox"></input>
                                                                            </td>
                                                                            <td>
                                                                                <input type="checkbox"></input>
                                                                            </td>
                                                                            <td>
                                                                                <input type="checkbox"></input>
                                                                            </td>
                                                                            <td>
                                                                                <input type="checkbox"></input>
                                                                            </td>
                                                                            <td>
                                                                                <input type="checkbox"></input>
                                                                            </td>
                                                                            <td>
                                                                                <input type="checkbox"></input>
                                                                            </td>
                                                                        </tr>


                                                                    </tbody>
                                                                </table>
                                                            </Scrollbars>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </section>
                                </section>
                            </section>
                        </div>
                    </div>
                </Router>
            );
        }
    };



    GetFormatMaster = e => {
        this.setState({ userid: this.props.location.state.userid });
        fetch("/GetFormatMaster", {
            method: "GET",
            headers: {
                guId: this.props.location.state.guId,
                Authorization: "Basic " + basicAuth(this.props.location.state.userid)
            }
        })
            .then(response => response.text())
            .then(response => {
                var DecryptedResponse = decryptData(response);
                if (DecryptedResponse.errorCode === "00") {
                    this.setState({
                        formerMasterList: DecryptedResponse.formarMasterList
                    });
                    this.setState({ roleMasterList: DecryptedResponse.roleMasterList });
                    let list = [];
                    let list1 = [];

                    for (var i = 0; i < DecryptedResponse.formarMasterList.length; i++) {
                        list.push(DecryptedResponse.formarMasterList[i].formatDesc);
                        list1.push(DecryptedResponse.formarMasterList[i].formatId);
                    }
                    this.setState({ rformatlist: list });
                    this.setState({ rformatId: list1 });

                } else {
                    confirmAlert({
                        title: "Alert !",
                        message: DecryptedResponse.errorMsg,
                        buttons: [
                            {
                                label: "Ok",
                                onClick: () => {
                                    return false;
                                }
                            }
                        ]
                    });
                }
            })
            .catch(error => {
                confirmAlert({
                    title: "Alert !",
                    message: "Session expired",
                    buttons: [
                        {
                            label: "Ok",
                            onClick: () => {
                                this.logoutnormal();
                            }
                        }
                    ]
                });
            });
    };



}

export default withRouter(MenuRights);
