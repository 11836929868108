import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { confirmAlert } from "react-confirm-alert"; // Import
import "./css/react-confirm-alert.css"; // Import css
//import readXlsxFile from 'read-excel-file';
import { withRouter } from "react-router-dom";
import { Encrypt, decryptData } from "./Encryption-Decrypytion";
import disableBrowserBackButton from "disable-browser-back-navigation";
import IdleTimer from "react-idle-timer"; // For Idle
import "./css/style.css";
import "./css/style-responsive.css";
import { basicAuth } from "./BasicAuth";
import { BrowserRouter as Router } from "react-router-dom";

const ParentComponent = props => (
  <div id="children-pane">
    {props.children}
    <div class="col-lg-2 col-md-2 col-sm-2 col-xs-12 mt-17">
      <button
        id="add-opt-btn"
        type="button"
        class="btn btn-primary"
        onClick={props.addChild}
      >
        +
      </button>
    </div>
  </div>
);

const ChildComponent = props => (
  <div>
    <input
      type="text"
      class="form-control"
      placeholder={"Option " + props.number}
      onChange={props.OptChange}
      id={"uptd" + props.number}
      autoComplete="off"
    />
  </div>
);

class UpdateTemplates extends Component {
  state = {
    TaskDropdwn: false,
    // rlistopt : [] ,

    endDate: "",
    startDate: "",
    updatedopt: [],
    option: "",
    checked: true,
    numChildren: 0,

    Addoptbutton: false,
    hasError: false
  };
  showTaskDropdwnHandler = () => {
    this.setState({
      TaskDropdwn: !this.state.TaskDropdwn
    });
  };
  optionChangedHandler = event => {
    this.setState({
      option: event.target.value
    });
  };

  constructor(props) {
    super(props);
    this.state = {
      displayMenu: false,
      hasError: false
    };
    this.state = {
      value: "",
      questionTypeId: this.props.location.state.questiontype,
      startDate: "",
      endDate: "",
      StoreList: "StoreList",
      FileName: "",
      loaded: this.props.location.state,
      opt: [],
      tskName: "",
      tskId: "",
      tskDescr: "",
      strtDate: "",
      edDate: "",
      usrnm: "",
      checked: true,
      addClickbutton: false,
      show: true,
      publishStatus: "New",
      hasError: false,
      hammenu: true,
      TaskDropdwn: false,
      updatedopt: [],
      option: "",
      numChildren: 0,
      Addoptbutton: false,
      displayMenu: false,
    };
    this.handleChangeStart = this.handleChangeStart.bind(this);
    this.handleChangeEnd = this.handleChangeEnd.bind(this);
    this.handleoptchange = this.handleoptchange.bind(this);
    this.showDropdownMenu = this.showDropdownMenu.bind(this);
    this.hideDropdownMenu = this.hideDropdownMenu.bind(this);
    this.idleTimer = null;
    this.onAction = this._onAction.bind(this);
    this.onActive = this._onActive.bind(this);
    this.onIdle = this._onIdle.bind(this);
  }

  logoutnormal = () => {
    localStorage.clear();
    this.props.history.replace("/");
    disableBrowserBackButton();
  };

  handleoptchange(param, event) {
    this.state.opt[param] = event.target.value;
    this.setState({});
  }

  FetchQuestionFromTaskActive() {
    if (
      this.props.location.state !== undefined &&
      this.props.location.state.usrnm !== ""
    ) {
      if (
        this.props.location.state.questiontype == "1" ||
        this.props.location.state.questiontype == "2" ||
        this.props.location.state.questiontype == "9"
      ) {
        this.setState({ addClickbutton: true });
      } else {
        this.setState({ addClickbutton: false });
      }
      this.setState({
        optlength: this.props.location.state.options,
        opt: this.props.location.state.options,
        tskId: this.props.location.state.tskId,
        role_id: this.props.location.state.role_id,
        userid: this.props.location.state.userid,
        tskName: this.props.location.state.tskName,
        tskId: this.props.location.state.tskId,
        tskName: this.props.location.state.tskName,
        tskDescr: this.props.location.state.tskDescr,
        strtDate: this.props.location.state.strtDate,
        edDate: this.props.location.state.edDate,
        usrnm: this.props.location.state.usrnm,
        publishStatus: this.props.location.state.publishStatus
      });
    } else {
      window.location.href = "/";
    }
  }

  handleChangeStart(date) {
    this.setState({
      startDate: date
    });
  }

  handleChangeEnd(date) {
    this.setState({
      endDate: date
    });
  }

  _onAction(e) {
  }

  _onActive(e) {
  }

  _onIdle(e) {
    localStorage.clear();
    window.location.href = "/";
    disableBrowserBackButton();
  }

  componentDidMount() {
    if (window.innerWidth <= 768) {
      this.setState({ hammenu: false });
    }
  }

  handleChangeChk = e => {
    if (document.getElementById("chkbox").checked === false) {
      this.setState({ check: 0 });
      this.setState({ checked: false });
    } else {
      this.setState({ check: 1 });
      this.setState({ checked: true });
    }
  };

  logout = () => {
    this.setState({ loading: true });
    var Request1 = {
      userId: this.props.location.state.userid,
      guId: this.props.location.state.guId
    };
    var EncryptedRequest1 = Encrypt(Request1);
    fetch("/LogOut ", {
      method: "POST",
      headers: {
        guId: this.props.location.state.guId,
        Authorization: "Basic " + basicAuth(this.props.location.state.userid)
      },
      body: EncryptedRequest1
    })
      .then(response => response.text())
      .then(response => {
        this.setState({ loading: false });
        var DecryptedResponse = decryptData(response);
        if (DecryptedResponse.errorCode === "00") {
          this.props.history.replace("/");
          disableBrowserBackButton();
        } else {
          confirmAlert({
            message: DecryptedResponse.errorMsg,
            buttons: [
              {
                label: "Ok"
              }
            ]
          });
        }
      })
      .catch(error => {
        confirmAlert({
          title: "Alert !",
          message: "Session expired",
          buttons: [
            {
              label: "Ok",
              onClick: () => {
                this.logoutnormal();
              }
            }
          ]
        });
      });
  };

  showDropdownMenu(event) {
    event.preventDefault();
    this.setState({ displayMenu: true }, () => {
      document.addEventListener("click", this.hideDropdownMenu);
    });
  }

  hideDropdownMenu() {
    this.setState({ displayMenu: false }, () => {
      document.removeEventListener("click", this.hideDropdownMenu);
    });
  }
  removeClick(index) {
    let opt = [...this.state.opt];
    opt.splice(index, 1);
    this.setState({ opt });
  }
  componentWillMount() {
    if (
      this.props.location.state === undefined ||
      this.props.location.state === ""
    ) {
      window.location.href = "/";
    } else {
      this.setState({ questionText: this.props.location.state.questionText });
      this.setState({ numChildren: this.props.location.state.length });
      this.setState({ checked: this.props.location.state.isMandatory === "1" ? true : false })
      this.FetchQuestionFromTaskActive();
    }
  }

  changeqtype = e => {
    this.setState({ value: e.target.value });
    let flag = "";

    for (var i = 0; i < this.props.location.state.description.length; i++) {
      this.props.location.state.description[i] === e.target.value
        ? flag = this.props.location.state.qtypeId[i]
        : this.setState({ valueid: "" });
    }

    this.setState({ questionTypeId: flag });

    if (
      flag == "1" ||
      flag == "2" ||
      flag == "9"
    ) {
      this.setState({ addClickbutton: true });
    } else {
      this.setState({ opt: [] });
      this.setState({ addClickbutton: false });
    }
  };

  tiker = () => {
    this.props.history.push({
      pathname: "/Tiker",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  showTaskdwnHandler = () => {
    this.setState({
      Taskdwn: !this.state.Taskdwn
    });
  };

  userlist = () => {
    this.props.history.push({
      pathname: "/UserList",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  usercreation = () => {
    this.props.history.push({
      pathname: "/UserCreation",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  addClick() {
    this.setState(prevState => ({ opt: [...prevState.opt, ""] }));
    this.setState({ show: true });
  }

  componentDidCatch() {
    this.setState({ hasError: true });
  }

  render = () => {
    var that = this;

    const children = [];

    for (var i = 0; i < this.state.numChildren; i += 1) {
      children.push(<ChildComponent key={i} number={i} />);
    }
    var i = 1;

    if (this.state.hasError) {
      return (
        <div>
          <h2>Error occurred!! please contact administrator</h2>
        </div>
      );
    } else {
      return (
        <Router>
          {this.state.loaded ? (
            <div>
              <section id="container">
                <div>
                  <IdleTimer
                    ref={ref => {
                      this.idleTimer = ref;
                    }}
                    element={document}
                    onActive={this.onActive}
                    onIdle={this.onIdle}
                    onAction={this.onAction}
                    debounce={250}
                    timeout={1000 * 60 * 10}
                  />
                </div>
                <header class="header black-bg">
                  <a
                    className="mob-show"
                    onClick={() =>
                      this.setState({ hammenu: !this.state.hammenu })
                    }
                  >
                    <i
                      className="fa fa-tasks hammenu"
                      style={{ "margin-top": "25px", 'padding': '0 15px' }}
                    ></i>
                  </a>

                  <a onClick={this.TaskactiveBind.bind(this)} className="logo" style={{ 'padding': '0 10px' }}>
                    <img src={require("./img/retail-logo.png")} alt="logo" />
                  </a>
                  <div className="markcenter">
                    <p className="marquee">{this.state.tickerList}</p>
                  </div>
                  <ul className="nav pull-right pos-rel">
                    <li className="dropdown">
                      <a
                        className="dropdown-toggle"
                        data-toggle="dropdown"
                        onClick={this.showDropdownMenu}
                      >
                        {" "}
                        <img
                          src={require("./img/user.png")}
                          className="user-img"
                        />{" "}
                        <b className="name-show">{this.props.location.state.usrnm}</b>
                        ({this.props.location.state.storeNo})

                        <b className="caret" />
                      </a>
                    </li>

                    {this.state.displayMenu ? (
                      <ul className="dropdown-menuuser-dd">
                        <li>
                          Role : {this.props.location.state.roleName}
                        </li>
                        <li className="divider"></li>
                        <li>
                          <a onClick={this.Changepasswordbind}>Change Password</a>
                        </li>
                        <li className="divider"></li>
                        <li>
                          <a onClick={this.logout}>Log Out</a>
                        </li>
                      </ul>
                    ) : null}
                  </ul>
                </header>
                <aside>
                  <div id="sidebar" className="nav-collapse">
                    {this.state.hammenu ? (
                      <ul className="sidebar-menu" id="nav-accordion">
                        {this.props.location.state.menuList["401"] || this.props.location.state.menuList["402"] || this.props.location.state.menuList["403"] || this.props.location.state.menuList["404"] || this.props.location.state.menuList["405"] || this.props.location.state.menuList["408"] || this.props.location.state.menuList["411"] ? (
                          <li
                            className="sub-menu mt"
                            onClick={this.showTaskDropdwnHandler.bind(this)}
                          >
                            {" "}
                            <a href="javascript:;">
                              {" "}
                              <i className="fa fa-tasks"></i> <span>Task</span>{" "}
                            </a>
                            {this.state.TaskDropdwn ? (
                              <div>
                                <ul class="sub">
                                  {this.props.location.state.menuList["401"] ? (
                                    <li>
                                      <a onClick={this.TaskactiveBind.bind(this)}>
                                        {this.props.location.state.menuList["401"]}
                                      </a>
                                    </li>
                                  ) : null}
                                  {this.props.location.state.menuList["402"] ? (
                                    <li>
                                      <a onClick={this.getroles.bind(this)}>
                                        {this.props.location.state.menuList["402"]}
                                      </a>
                                    </li>
                                  ) : null}
                                  {this.props.location.state.menuList["403"] ? (
                                    <li>
                                      <a
                                        onClick={this.CompletedTaskListbind.bind(
                                          this
                                        )}
                                      >
                                        {this.props.location.state.menuList["403"]}
                                      </a>
                                    </li>
                                  ) : null}
                                  {this.props.location.state.menuList["404"] ? (
                                    <li>
                                      <a onClick={this.templates.bind(this)}>
                                        {this.props.location.state.menuList["404"]}
                                      </a>
                                    </li>
                                  ) : null}
                                  {this.props.location.state.menuList["405"] ? (
                                    <li>
                                      <a onClick={this.ArchivedTasks.bind(this)}>
                                        {this.props.location.state.menuList["405"]}
                                      </a>
                                    </li>
                                  ) : null}
                                  {this.props.location.state.menuList["408"] ? (
                                    <li>
                                      <a onClick={this.StoreTasks.bind(this)}>
                                        {this.props.location.state.menuList["408"]}
                                      </a>
                                    </li>
                                  ) : null}
                                  {this.props.location.state.menuList["411"] ? (
                                    <li>
                                      <a onClick={this.toGenDashboard.bind(this)}>
                                        {this.props.location.state.menuList["411"]}
                                      </a>
                                    </li>
                                  ) : null}
                                </ul>
                              </div>
                            ) : null}
                          </li>
                        ) : null}
                        {this.props.location.state.menuList["406"] ? (
                          <div>
                            <li>
                              {" "}
                              <a onClick={this.Reportsbind.bind(this)}>
                                {" "}
                                <i className="fa fa-tasks"></i>{" "}
                                <span>
                                  {this.props.location.state.menuList["406"]}
                                </span>{" "}
                              </a>{" "}
                            </li>
                          </div>
                        ) : null}

                        {this.props.location.state.menuList["414"] && this.props.location.state.format_id === "102" ? (
                          <div>
                            <li>
                              {" "}
                              <a onClick={this.Defectbind.bind(this)}>
                                {" "}
                                <i className="fa fa-tasks"></i>{" "}
                                <span>
                                  {this.props.location.state.menuList["414"]}
                                </span>{" "}
                              </a>{" "}
                            </li>
                          </div>
                        ) : null}

                        {this.props.location.state.menuList["415"] ? (
                          <div>
                            <li>
                              {" "}
                              <a onClick={this.SmartPointBind.bind(this)}>
                                {" "}
                                <i className="fa fa-tasks"></i>{" "}
                                <span>
                                  {this.props.location.state.menuList["415"]}
                                </span>{" "}
                              </a>{" "}
                            </li>
                          </div>
                        ) : null}

                        {this.props.location.state.menuList["409"] || this.props.location.state.menuList["410"] ? (
                          <div>
                            <li
                              className="sub-menu"
                              onClick={this.showIssueDropdwnHandler.bind(this)}
                            >
                              {" "}
                              <a href="javascript:;">
                                {" "}
                                <i className="fa fa-tasks"></i>{" "}
                                <span>Issue</span>{" "}
                              </a>
                              {this.state.IssueDropdwn ? (
                                <div>
                                  <ul class="sub">
                                    {this.props.location.state.menuList["410"] ? (
                                      <li>
                                        <a onClick={this.IssueRaising.bind(this)}>
                                          {this.props.location.state.menuList["410"]}
                                        </a>
                                      </li>
                                    ) : null}
                                    {this.props.location.state.menuList["409"] ? (
                                      <li>
                                        <a onClick={this.IssueDashboard.bind(this)}>
                                          {this.props.location.state.menuList["409"]}
                                        </a>
                                      </li>
                                    ) : null}
                                  </ul>
                                </div>
                              ) : null}
                            </li>
                          </div>
                        ) : null}

                        {this.props.location.state.menuList["407"] ? (
                          <div>
                            <li
                              className="sub-menu"
                              onClick={this.showTaskdwnHandler.bind(this)}
                            >
                              {" "}
                              <a href="javascript:;">
                                {" "}
                                <i className="fa fa-tasks"></i>{" "}
                                <span>
                                  {this.props.location.state.menuList["407"]}
                                </span>{" "}
                              </a>
                              {this.state.Taskdwn ? (
                                <div>
                                  <ul class="sub">
                                    <li>
                                      {" "}
                                      <a onClick={this.tiker.bind(this)}>
                                        <span>Tiker Creation</span>{" "}
                                      </a>{" "}
                                    </li>
                                    <li>
                                      {" "}
                                      <a onClick={this.userlist.bind(this)}>
                                        <span>User List</span>{" "}
                                      </a>{" "}
                                    </li>
                                    {this.props.location.state.menuList["412"] ? (
                                      <li>
                                        {" "}
                                        <a onClick={this.Storecreation.bind(this)}>
                                          <span>{this.props.location.state.menuList["412"]}</span>{" "}
                                        </a>{" "}
                                      </li>
                                    ) : null}
                                    {this.props.location.state.menuList["413"] ? (
                                      <li>
                                        {" "}
                                        <a onClick={this.ToStoreList.bind(this)}>
                                          <span>{this.props.location.state.menuList["413"]}</span>{" "}
                                        </a>{" "}
                                      </li>
                                    ) : null}
                                  </ul>
                                </div>
                              ) : null}
                            </li>
                          </div>
                        ) : null}
                      </ul>
                    ) : null}
                  </div>
                </aside>
                <section id="main-content">
                  <section class="wrapper">
                    <div class="mt">
                      <h3>
                        <i class="fa fa-angle-right"></i> Update Question
                      </h3>
                      <hr />
                      <div>
                        <input
                          type="checkbox"
                          onChange={this.handleChangeChk.bind(this)}
                          id="chkbox"
                          checked={that.state.checked}
                        />{" "}
                        Mandatory
                      </div>
                      <div class="row mt">

                        <form method="post">
                          <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                            <label>Question Type</label>
                            <br />
                            {/* <input
                              ref="taskid"
                              type="text"
                              className="form-control"
                              size="25"
                              placeholder={
                                this.props.location.state.questionTypeValue
                              }
                              id="taskID"
                              disabled="disabled"
                            /> */}
                            <select
                              class="form-control"
                              onChange={this.changeqtype.bind(this)}
                              id="SelQType"
                              defaultValue={
                                this.props.location.state.questionTypeValue
                              }
                            >
                              <option id="select">Select</option>
                              {this.props.location.state.description.map(element => (
                                <option>{element}</option>
                              ))}
                            </select>
                          </div>
                          <div class="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                            <label>Question</label>
                            <br />
                            <input
                              ref="taskname"
                              onChange={e =>
                                this.setState({ questionText: e.target.value })
                              }
                              type="text"
                              class="form-control"
                              value={this.state.questionText}
                              id="taskName"
                              autoComplete="off"
                            />
                          </div>

                          <div
                            id="Remove"
                            class="col-lg-6 col-md-6 col-sm-6 col-xs-12"
                          >
                            {this.state.addClickbutton ? (
                              <label>Options</label>
                            ) : null}

                            {/* <label>Options</label> */}
                            {/* <br /> */}
                            {this.state.opt.map((optele, index) => (
                              <div class="d-flex flex-wrap flex-row justify-content-between mb-10">
                                <input
                                  ref="taskname"
                                  onChange={that.handleoptchange.bind(
                                    that,
                                    index
                                  )}
                                  type="text"
                                  placeholder="option"
                                  class="form-control fbasis-94"
                                  value={this.state.opt[index]}
                                  onKeyPress={this.fullstop}
                                  maxLength="255"
                                  id={"uptd"}
                                  autoComplete="off"
                                />

                                <input
                                  type="button"
                                  class="remove-icon"
                                  value="x"
                                  onClick={this.removeClick.bind(this, index)}
                                />
                              </div>
                            ))}
                          </div>

                          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                            {this.state.addClickbutton ? (
                              <input
                                type="button"
                                class="btn btn-primary min-wid-90  mt-17"
                                value="Add More"
                                onClick={this.addClick.bind(this)}
                              />
                            ) : null}

                            {this.state.show ? (
                              <button
                                class="btn btn-primary min-wid-90 ml-10 mt-17"
                                onClick={this.UpdateQuestion.bind(this)}
                              >
                                Update
                              </button>
                            ) : null}
                            <button
                              class="btn btn-primary min-wid-90 ml-10 mt-17"
                              onClick={this.addquestionbind.bind(this)}
                            >
                              Cancel
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </section>
                </section>
              </section>
            </div>
          ) : (
            this.props.history.push({
              pathname: "/"
            })
          )}
        </Router>
      );
    }
  };

  ToStoreList = () => {
    this.props.history.push({
      pathname: "/StoreList",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        formerMasterList: this.state.formerMasterList,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  Storecreation = () => {
    this.props.history.push({
      pathname: "/Storecreation",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        formerMasterList: this.state.formerMasterList,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  showIssueDropdwnHandler = () => {
    this.setState({
      IssueDropdwn: !this.state.IssueDropdwn
    });
  };

  IssueRaising = () => {
    this.props.history.push({
      pathname: "/IssueRaising",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        data: this.props.location.state.data,
        tskId: this.props.location.state.tskId,
        tskName: this.props.location.state.tskName,
        storeNo: this.props.location.state.storeNo,
        Isroffice: this.props.location.state.Isroffice,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  toGenDashboard = () => {
    this.props.history.push({
      pathname: "/DashboardGeneral",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        data: this.props.location.state.data,
        tskId: this.props.location.state.tskId,
        tskName: this.props.location.state.tskName,
        storeNo: this.props.location.state.storeNo,
        Isroffice: this.props.location.state.Isroffice,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  IssueDashboard = () => {
    this.props.history.push({
      pathname: "/IssueDashboard",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        data: this.props.location.state.data,
        tskId: this.props.location.state.tskId,
        tskName: this.props.location.state.tskName,
        storeNo: this.props.location.state.storeNo,
        Isroffice: this.props.location.state.Isroffice,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  onAddChild = () => {
    this.setState({
      numChildren: this.state.numChildren + 1
    });
  };

  //   removeClick(param){
  //     let opt = [...this.state.opt];
  //     opt.splice(param,1);
  //     this.setState({ opt });
  //  }

  fullstop = e => {
    var theEvent = e || window.event;
    var key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
    var regex = /[^,;]+$/;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) {
        theEvent.preventDefault();
      }
    }
  };

  addquestionbind = e => {
    e.preventDefault();

    confirmAlert({
      //  title: 'Confirm to submit',
      message: "Are you sure, you want to cancel?  ",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            this.props.history.push({
              pathname: "/Addquestion",
              state: {
                //tskId: this.state.tskId,
                //tskName:this.state.tskName,
                role_id: this.state.role_id,
                format_id: this.props.location.state.format_id,
                userid: this.props.location.state.userid,
                tskId: this.state.tskId,
                tskName: this.state.tskName,
                tskDescr: this.state.tskDescr,
                strtDate: this.state.strtDate,
                edDate: this.state.edDate,
                usrnm: this.props.location.state.usrnm,
                publishStatus: this.state.publishStatus,
                storeNo: this.props.location.state.storeNo,
                guId: this.props.location.state.guId,
                menuList: this.props.location.state.menuList,
                roleName: this.props.location.state.roleName,
              }
            });
          }
        },
        {
          label: "No",
          onClick: () => {
            return false;
          }
        }
      ]
    });
  };

  UpdateQuestion(e) {
    e.preventDefault();

    if (this.state.opt.toString().length >= 255) {
      confirmAlert({
        title: "Alert !",
        message: "Total Option length cannot be greater than 255 ",
        buttons: [
          {
            label: "Ok"
          }
        ]
      });
    } else {
      let otval = [];
      if (document.getElementById("uptd") !== null) {
        for (var i = 0; i <= this.state.numChildren; i++) {

          otval.push(document.getElementById("uptd").value);

        }


      }

      if (document.getElementById("taskName").value === "") {
        confirmAlert({
          title: "Alert !",
          message: "Question Cannot be Empty",
          buttons: [
            {
              label: "Ok"
            }
          ]
        });
      } else {
        if (this.state.opt.length === 0 && this.state.addClickbutton === true) {
          confirmAlert({
            title: "Alert !",
            message: "Options Cannot be Empty",
            buttons: [
              {
                label: "Ok"
              }
            ]
          });
          this.setState({ show: false });
        }


        else {
          e.preventDefault();
          confirmAlert({
            message: "Are you sure,you want to update question?",
            buttons: [
              {
                label: "Yes",
                onClick: () => {
                  var Request = {
                    userId: this.props.location.state.userid,
                    guId: this.props.location.state.guId,
                    question: {
                      questionId: this.props.location.state.questionId,
                      questionText: document.getElementById("taskName").value,
                      questiontype: this.state.questionTypeId,
                      minValue: this.state.loaded.minValue,
                      maxValue: this.state.loaded.maxValue,
                      options: this.state.opt.filter(function (el) {
                        return el != "";
                      })
                    }
                  };
                  var EncryptedRequest = Encrypt(Request);

                  fetch("/UpdateQuestionTemplate", {
                    method: "POST",
                    headers: {
                      guId: this.props.location.state.guId,
                      Authorization:
                        "Basic " + basicAuth(this.props.location.state.userid)
                    },
                    body: EncryptedRequest
                  })
                    .then(response => response.text())
                    .then(response => {
                      var DecryptedResponse = decryptData(response);
                      if (DecryptedResponse.errorCode === "00") {
                        confirmAlert({
                          message: "Question updated successfully.",
                          buttons: [
                            {
                              label: "Ok",
                              onClick: () => {
                                this.props.history.push({
                                  pathname: "/Addtemplate",
                                  state: {
                                    role_id: this.state.role_id,
                                    format_id: this.props.location.state.format_id,
                                    userid: this.props.location.state.userid,
                                    tskId: this.state.tskId,
                                    tskName: this.state.tskName,
                                    tskDescr: this.state.tskDescr,
                                    strtDate: this.state.strtDate,
                                    edDate: this.state.edDate,
                                    usrnm: this.props.location.state.usrnm,
                                    publishStatus: this.state.publishStatus,
                                    storeNo: this.props.location.state.storeNo,
                                    guId: this.props.location.state.guId,
                                    menuList: this.props.location.state.menuList,
                                    roleName: this.props.location.state.roleName,
                                  }
                                });
                              }
                            }
                          ]
                        });
                      } else {
                        confirmAlert({
                          title: "Alert !",
                          message: DecryptedResponse.errorMsg,
                          buttons: [
                            {
                              label: "Ok"
                            }
                          ]
                        });
                      }
                    })
                    .catch(error => {
                      confirmAlert({
                        title: "Alert !",
                        message: "Session expired",
                        buttons: [
                          {
                            label: "Ok",
                            onClick: () => {
                              this.logoutnormal();
                            }
                          }
                        ]
                      });
                    });
                }
              },
              {
                label: "No",
                onClick: () => {
                  return false;
                }
              }
            ]
          });
        }
      }
    }
  }

  templates = () => {
    this.props.history.push({
      pathname: "/Templates",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  TaskactiveBind = () => {
    this.props.history.push({
      pathname: "/Taskactive",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  StoreTasks = () => {
    this.props.history.push({
      pathname: "/StoreTasks",
      state: {
        data: this.state.data,
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  CompletedTaskListbind = () => {
    this.props.history.push({
      pathname: "/CompletedTaskList",
      state: {
        data: this.state.data,
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  SmartPointBind = () => {
    this.props.history.push({
      pathname: "/SmartPoint",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        data: this.props.location.state.data,
        tskId: this.props.location.state.tskId,
        tskName: this.props.location.state.tskName,
        storeNo: this.props.location.state.storeNo,
        Isroffice: this.props.location.state.Isroffice,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  Defectbind = () => {
    this.props.history.push({
      pathname: "/DefectRaising",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        data: this.props.location.state.data,
        tskId: this.props.location.state.tskId,
        tskName: this.props.location.state.tskName,
        storeNo: this.props.location.state.storeNo,
        Isroffice: this.props.location.state.Isroffice,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  Reportsbind = () => {
    this.props.history.push({
      pathname: "/Reports",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  ArchivedTasks = () => {
    this.props.history.push({
      pathname: "/ArchivedTasks",
      state: {
        data: this.state.data,
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
        Isroffice: this.props.location.state.Isroffice,
      }
    });
  };

  Changepasswordbind = () => {
    this.props.history.push({
      pathname: "/ChangepasswordUI",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  getroles(e) {
    this.props.history.push({
      pathname: "/CreatetaskUI",
      state: {
        format_id: this.props.location.state.format_id,
        role_id: this.props.location.state.role_id,
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  }
}

export default withRouter(UpdateTemplates);
