/**
 * eslint-disable jsx-a11y/alt-text
 *
 * @format
 */

/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-lone-blocks */
import React, { Component } from "react";
import "jquery/dist/jquery";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { confirmAlert } from "react-confirm-alert"; // Import
import "./css/react-confirm-alert.css"; // Import css
import Spinner from "react-spinner-material"; //Import Spinner
import { Scrollbars } from "react-custom-scrollbars";
import readXlsxFile from "read-excel-file";
import ReactExport from "react-data-export";
import "./css/style.css";
import "./css/style-responsive.css";
import { Encrypt, decryptData } from "./Encryption-Decrypytion";
import IdleTimer from "react-idle-timer"; // For Idle
import disableBrowserBackButton from "disable-browser-back-navigation";
import { basicAuth } from "./BasicAuth";
import DropdownButton from "./DropdownButton";
import moment from "moment";

const display = {
  display: "block",
};

const hide = {
  display: "none",
};

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
class Reports extends Component {
  state = {
    TaskDropdwn: false,
    userid: "",
    role_id: "",
    format_id: "",
    userid: "",
    storeDetail: [],
    listQueAnsDetails: [],
    headers: [],
    sumheaders: [],
    answerList: [],
    taskSummaryList: [],
    taskName: "",
    taskDescr: "",
    hasError: false,
    stateName: "",
    sum1: [],
    statepercentage: "",
    pendingStores: "",
    isPendingVisible: false,
    storeList: [],
    templetId: "",
    imageArray: [],
    question: [],
    imgtaskid: "",
    searchQuestn: "",
    searchName: "",
    // repState:false,
  };
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      displayMenu: false,
      usrnm: "",
      data: [],
      fromdata: [],
      titlename: [],
      taskId: "",
      Search: "",
      searchSDate: "",
      searchedDate: "",
      searchQuestn: "",
      loading: false,
      toggle: false,
      taskName: "",
      taskDescr: "",
      hasError: false,
      stateName: "",
      sum1: [],
      statepercentage: "",
      isPendingVisible: false,
      pendingStores: "",
      hammenu: true,
      appear: false,
      immodal: false,
      enimg: false,
      eddate: "",
      UpdatesDate: "",
      startdateupdateticker: "",
      startDateForReport: "",
      endDateForReport: "",
      storeList: [],
      templetId: "",
      imageArray: [],
      key: "",
      question: [],
      questionId: "",
      questionText: "",
      searchName: "",
      TaskDropdwn: false,
      userid: "",
      role_id: "",
      format_id: "",
      storeDetail: [],
      listQueAnsDetails: [],
      headers: [],
      sumheaders: [],
      answerList: [],
      taskSummaryList: [],
      imgtaskid: "",
    };
    this.handleChangeStart = this.handleChangeStart.bind(this);
    this.handleChangeEnd = this.handleChangeEnd.bind(this);
    this.showDropdownMenu = this.showDropdownMenu.bind(this);
    this.hideDropdownMenu = this.hideDropdownMenu.bind(this);
    this.handletickersupdate = this.handletickersupdate.bind(this);
    this.handleReportStartDate = this.handleReportStartDate.bind(this);
    this.handleReportEndDate = this.handleReportEndDate.bind(this);
    this.idleTimer = null;
    this.onAction = this._onAction.bind(this);
    this.onActive = this._onActive.bind(this);
    this.onIdle = this._onIdle.bind(this);
  }

  logoutnormal = () => {
    localStorage.clear();
    this.props.history.replace("/");
    disableBrowserBackButton();
  };

  componentDidMount() {
    let recStarDate = sessionStorage.getItem("sDate");
    let recEndDate = sessionStorage.getItem("eDate");
    if (recStarDate && recEndDate) {
      this.setState({ startDateForReport: new Date(recStarDate) });
      this.setState({ endDateForReport: new Date(recEndDate) });
      this.ReportsCall();
    }

    if (window.innerWidth <= 768) {
      this.setState({ hammenu: false });
    }
  }

  componentWillMount() {
    if (
      this.props.location.state === undefined ||
      this.props.location.state === ""
    ) {
      window.location.href = "/";
    }
  }

  _onAction(e) {}

  _onActive(e) {}

  _onIdle(e) {
    localStorage.clear();
    window.location.href = "/";
    disableBrowserBackButton();
  }

  showTaskDropdwnHandler = () => {
    this.setState({
      TaskDropdwn: !this.state.TaskDropdwn,
    });
  };
  showDropdownMenu(event) {
    event.preventDefault();
    this.setState({ displayMenu: true }, () => {
      document.addEventListener("click", this.hideDropdownMenu);
    });
  }
  hideDropdownMenu() {
    this.setState({ displayMenu: false }, () => {
      document.removeEventListener("click", this.hideDropdownMenu);
    });
  }
  handleChangeStart(date) {
    var monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    this.setState({
      searchSDate:
        date.getDate() +
        "-" +
        monthNames[date.getMonth()] +
        "-" +
        date.getFullYear(),
      startDate: date,
    });
  }
  handleChangeEnd(date) {
    var monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    this.setState({
      searchedDate:
        date.getDate() +
        "-" +
        monthNames[date.getMonth()] +
        "-" +
        date.getFullYear(),
      endDate: date,
    });
  }
  onChange = (e) => {
    this.setState({
      Search: e.target.value,
    });
  };

  onChange1 = (e) => {
    this.setState({ searchName: e.target.value });
  };

  onChangequestion = (e) => {
    this.setState({
      searchQuestn: e.target.value,
    });
  };

  logout = () => {
    if (
      this.props.location.state.Isroffice == true ||
      this.props.location.state.role_id == "900"
    ) {
      this.props.history.replace("/");
      this.props.history.block();
      // document.getElementById("rofficelogin").click();
    } else {
      this.setState({ loading: true });
      var Request1 = {
        userId: this.props.location.state.userid,
        guId: this.props.location.state.guId,
      };
      var EncryptedRequest1 = Encrypt(Request1);
      fetch("/LogOut ", {
        method: "POST",
        headers: {
          guId: this.props.location.state.guId,
          Authorization: "Basic " + basicAuth(this.props.location.state.userid),
        },
        body: EncryptedRequest1,
      })
        .then((response) => response.text())
        .then((response) => {
          this.setState({ loading: false });
          var DecryptedResponse = decryptData(response);
          if (DecryptedResponse.errorCode === "00") {
            this.props.history.replace("/");
            disableBrowserBackButton();
          } else {
            confirmAlert({
              message: DecryptedResponse.errorMsg,
              buttons: [
                {
                  label: "Ok",
                },
              ],
            });
          }
        })
        .catch((error) => {
          confirmAlert({
            title: "Alert !",
            message: "Session expired",
            buttons: [
              {
                label: "Ok",
                onClick: () => {
                  this.logoutnormal();
                },
              },
            ],
          });
        });
    }
  };

  ArchivedTasks = () => {
    sessionStorage.removeItem("sDate");
    sessionStorage.removeItem("eDate");
    this.props.history.push({
      pathname: "/ArchivedTasks",
      state: {
        data: this.state.data,
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
        Isroffice: this.props.location.state.Isroffice,
      },
    });
  };

  duplicate(taskName, taskDescr, taskId, edDate) {
    this.setState((prevState) => ({
      appear: !prevState.appear,
    }));
    this.setState({
      taskName: taskName,
      taskDescr: taskDescr,
      templetId: taskId,
      edDate: edDate,
      UpdatesDate: edDate,
    });
  }
  callImageGallery = (questionId, questionText) => {
    this.props.history.push({
      pathname: "/ImageGallery",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        questionId: questionId,
        taskId: this.state.imgtaskid,
        questionText: questionText,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      },
    });
  };

  imagemodalopen = (taskId) => {
    {
      this.setState((prevState) => ({ immodal: !prevState.immodal }));
    }

    var Request1 = { taskId: taskId, guId: this.props.location.state.guId };
    var EncryptedRequest1 = Encrypt(Request1);

    fetch("/GetImagesQuestions", {
      method: "POST",
      headers: {
        guId: this.props.location.state.guId,
        Authorization: "Basic " + basicAuth(this.props.location.state.userid),
      },
      body: EncryptedRequest1,
    })
      .then((response) => response.text())
      .then((response) => {
        var DecryptedResponse1 = decryptData(response);

        if (DecryptedResponse1.errorCode === "00") {
          this.setState({ question: DecryptedResponse1.questionList });
          this.setState({ imgtaskid: taskId });

          this.setState({
            loading: false,
          });
        } else {
          this.setState({ loading: false });
          confirmAlert({
            message: DecryptedResponse1.errorMsg,
            buttons: [
              {
                label: "Ok",
              },
            ],
          });
        }
      })
      .catch((error) => {
        confirmAlert({
          title: "Alert !",
          message: "Session expired",
          buttons: [
            {
              label: "Ok",
              onClick: () => {
                this.logoutnormal();
              },
            },
          ],
        });
      });
  };

  enlargeimage = (item) => {
    this.setState((prevState) => ({ enimg: !prevState.enimg }));
    this.setState({ key: item });
  };

  tiker = () => {
    sessionStorage.removeItem("sDate");
    sessionStorage.removeItem("eDate");
    this.props.history.push({
      pathname: "/Tiker",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      },
    });
  };

  templates = () => {
    sessionStorage.removeItem("sDate");
    sessionStorage.removeItem("eDate");
    this.props.history.push({
      pathname: "/Templates",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      },
    });
  };

  boxclose = () => {
    this.setState({
      appear: false,
      FileName: "",
      storeList: [],
    });
  };

  imagemodalclose = () => {
    this.setState({
      immodal: false,
    });
  };

  enlargeimgclose = () => {
    this.setState({
      enimg: false,
    });
  };

  fileHandler = (event) => {
    this.setState({ storeList: [] });
    const input = document.getElementById("upload-storedata");
    var stores = [];
    //just pass the fileObj as parameter
    readXlsxFile(input.files[0]).then((rows, files) => {
      var storedata = [];
      for (let i = 1; i < rows.length; i++) {
        storedata.push(rows[i]);
        for (let j = 0; j < storedata.length; j++) {
          var store = {
            storeNo: "",
          };
          store.storeNo = storedata[j][0];
        }

        {
          this.state.storeList.push(store);
        }
      }

      this.setState({
        FileName: "File Uploaded Successfully",
      });
    });
  };

  handletickersupdate(dates) {
    this.setState({ startdateupdateticker: dates });
    this.setState({ UpdatesDate: dates });
  }

  handleReportStartDate(dates) {
    sessionStorage.setItem("sDate", dates);
    this.setState({ startDateForReport: dates });
    this.setState({ UpdatesDateForReport: dates });
    this.setState({ endDateForReport: "" });
  }

  handleReportEndDate(dates) {
    sessionStorage.setItem("eDate", dates);
    this.setState({ endDateForReport: dates });
    this.setState({ UpdatesDateForReportEd: dates });
  }

  handleDateChangeRaw = (e) => {
    e.preventDefault();
  };

  modalclose = () => {
    this.setState({
      toggle: false,
    });
  };

  toggle(taskId, taskName, taskDescr) {
    this.props.history.push({
      pathname: "/Dashboard",
      state: {
        taskId: taskId,
        taskName: taskName,
        taskDescr: taskDescr,
        data: this.state.data,
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      },
    });
  }

  showTaskdwnHandler = () => {
    sessionStorage.removeItem("sDate");
    sessionStorage.removeItem("eDate");
    this.setState({
      Taskdwn: !this.state.Taskdwn,
    });
  };

  userlist = () => {
    sessionStorage.removeItem("sDate");
    sessionStorage.removeItem("eDate");
    this.props.history.push({
      pathname: "/UserList",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      },
    });
  };

  handleDateChangeRaws = (e) => {
    e.preventDefault();
  };

  handleDateChangeRawe = (e) => {
    e.preventDefault();
  };

  usercreation = () => {
    this.props.history.push({
      pathname: "/UserCreation",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      },
    });
  };

  componentDidCatch() {
    this.setState({ hasError: true });
  }
  render = () => {
    const { searchQuestn } = this.state;
    var that = this;
    var box = [];
    var imagemodal = [];
    var enlargeimg = [];
    var monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    var date =
      new Date().getDate() +
      "-" +
      monthNames[new Date().getMonth()] +
      "-" +
      new Date().getFullYear();

    box.push(
      <div
        className="modal"
        role="dialog"
        style={this.state.appear ? display : hide}
      >
        <div className="modal-dialog animate">
          <div className="modal-content">
            <div className="modal-header">
              <a
                className="close"
                style={{ color: "#fff" }}
                onClick={this.boxclose}
              >
                X
              </a>
              <h4 className="modal-title">Bulk Reassign</h4>
            </div>
            <div className="modal-body">
              <div
                className="form-horizontal"
                style={{ "margin-right": "10px" }}
              >
                <div className="form-group">
                  <div className="col-sm-8">
                    <h3 style={{ "margin-top": "0px" }}>
                      {" "}
                      {this.state.taskName}
                    </h3>
                  </div>
                  <hr />
                  <div className="clearfix"></div>
                  {new Date(this.state.edDate) >= new Date(date) ? (
                    <h4 className="control-label col-sm-4">Task End Date </h4>
                  ) : (
                    <h4 className="control-label col-sm-4">
                      Task Extended Date{" "}
                    </h4>
                  )}

                  {new Date(this.state.edDate) > new Date(date) ? (
                    <div className="col-sm-8">
                      <input
                        type="text"
                        class="form-control"
                        value={this.state.edDate}
                        id="extdate"
                        autoComplete="off"
                        disabled
                      />
                      <br />
                      <br />
                    </div>
                  ) : (
                    <div className="col-sm-8">
                      <DatePicker
                        className="form-control"
                        showTimeSelect
                        timeIntervals={15}
                        value={this.state.UpdatesDate}
                        onChange={this.handletickersupdate}
                        id="extdate"
                        onChangeRaw={this.handleDateChangeRaw}
                        minDate={new Date()}
                        dateFormat="dd-MMM-yyyy HH:mm"
                        selected={this.state.startdateupdateticker}
                        autoComplete="off"
                      />
                      <br />
                    </div>
                  )}
                  <div className="clearfix"></div>
                  <h4 className="control-label col-sm-4">Comment</h4>
                  <div className="col-sm-8">
                    <textarea
                      type="text"
                      class="form-control"
                      id="comment"
                      autoComplete="off"
                      maxLength="200"
                    ></textarea>
                  </div>
                  <div className="clearfix"></div>
                  <div className="mt-20">
                    <h4 className="control-label col-sm-4">Upload Data</h4>
                    <div className="col-sm-8">
                      <input
                        id="store-list"
                        type="text"
                        class="form-control input-upload"
                        value={this.state.FileName}
                      />
                      <span class="btn-upload">Upload</span>
                      <input
                        id="upload-storedata"
                        type="file"
                        name="Store-list-data"
                        class="input-hidden"
                        onChange={this.fileHandler.bind(this)}
                        value=""
                        accept=".xlsx,.xls"
                      />
                    </div>
                    <div className="col-sm-8">
                      <p class="text-right samp-mg">
                        Sample File{" "}
                        <a href={require("./doc/Bulk_Reassign.xlsx")}>
                          Bulk_Reassign.xlsx
                        </a>
                      </p>
                    </div>
                  </div>

                  <h4 className="control-label col-sm-4">&nbsp;</h4>
                  <div className="col-sm-8">
                    <button
                      class="btn btn-primary min-wid-90 mt-17"
                      onClick={this.ReportTask.bind(this)}
                    >
                      Reassign
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    enlargeimg.push(
      <div
        className="modal"
        role="dialog"
        style={this.state.enimg ? display : hide}
      >
        <div className="modal-dialog animate">
          <div className="modal-content" style={{ width: "800px" }}>
            <div className="modal-header">
              <a
                className="close"
                style={{ color: "#fff" }}
                onClick={this.enlargeimgclose}
              >
                X
              </a>
              <h4 className="modal-title">Enlarge Image</h4>
            </div>
            <div className="modal-body">
              <div
                className="form-horizontal"
                style={{
                  "margin-right": "10px",
                  "overflow-y": "scroll",
                  height: "500px",
                }}
              >
                <div className="form-group">
                  <h4 className="control-label">
                    Monthly Activity - To be performed by the store{" "}
                  </h4>
                </div>
                <div></div>

                <img src={this.state.key} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    imagemodal.push(
      <div
        className="modal"
        role="dialog"
        style={this.state.immodal ? display : hide}
      >
        <div className="modal-dialog animate">
          <div className="modal-content">
            <div className="modal-header">
              <a
                className="close"
                style={{ color: "#fff" }}
                onClick={this.imagemodalclose}
              >
                X
              </a>
              <h4 className="modal-title">Image Gallery</h4>
            </div>
            <div className="modal-body">
              <div
                className="form-horizontal"
                style={{ "margin-right": "5px", height: "400px" }}
              >
                <div className="form-group">
                  <div className="col-xs-10 col-sm-10 col-md-10 col-lg-10">
                    <input
                      type="text"
                      placeholder="Search Question"
                      className="form-control"
                      id="quesname"
                      onChange={this.onChangequestion}
                      autoComplete="off"
                    />
                  </div>

                  <div>
                    <img
                      src={require("./img/searchicon.png")}
                      className="user-img"
                      style={{ width: "25px", height: "25px" }}
                    />
                  </div>

                  <div className="col-sm-10">
                    <div className="tbl-holder mt-20">
                      <table className="table table-striped table-advance table-hover table-bordered tbl-task tbl-hhide ">
                        <thead>
                          <tr>
                            <th style={{ width: "3%" }}>Sr No</th>
                            <th>Question</th>
                          </tr>
                        </thead>
                      </table>

                      <Scrollbars style={{ height: 296 }}>
                        <table className="table table-striped table-advance table-hover table-bordered tbl-task mob-tbl ">
                          <tbody>
                            {this.state.question.map(function (item, key) {
                              if (
                                searchQuestn !== "" &&
                                item.questionText
                                  .toLowerCase()
                                  .indexOf(searchQuestn.toLowerCase()) === -1
                              ) {
                                return null;
                              }

                              return (
                                <tr key={key}>
                                  <td data-th="Sr No" style={{ width: "3%" }}>
                                    {key + 1}
                                  </td>
                                  <td data-th="Question">
                                    {" "}
                                    <a
                                      href="javascript:void(0)"
                                      onClick={that.callImageGallery.bind(
                                        that,
                                        item.questionId,
                                        item.questionText
                                      )}
                                      className="black-text"
                                    >
                                      {" "}
                                      {item.questionText}
                                    </a>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </Scrollbars>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    const { Search } = this.state;
    const { searchSDate } = this.state;
    const { searchedDate } = this.state;
    const { searchName } = this.state;
    const ReportDataSet = [
      {
        columns: this.state.headers,
        data: this.state.answerList,
      },
    ];

    const SummaryDataset = [
      {
        columns: this.state.sumheaders,
        data: this.state.taskSummaryList,
      },
    ];

    if (this.state.hasError) {
      return (
        <div>
          <h2>Error occurred!! please contact administrator</h2>
        </div>
      );
    } else {
      return (
        <div>
          {box}
          {imagemodal}
          {enlargeimg}
          <div
            className={this.state.loading ? "parentDisable" : ""}
            width="100%"
          >
            <div className="overlay-box">
              <Spinner
                visible={this.state.loading}
                spinnerColor={"rgba(0, 0, 0, 0.3)"}
              />
            </div>
          </div>
          <section id="container">
            <div>
              <IdleTimer
                ref={(ref) => {
                  this.idleTimer = ref;
                }}
                element={document}
                onActive={this.onActive}
                onIdle={this.onIdle}
                onAction={this.onAction}
                debounce={250}
                timeout={1000 * 60 * 10}
              />
              {/* your app here */}
            </div>
            <header class="header black-bg">
              <a
                className="mob-show"
                onClick={() => this.setState({ hammenu: !this.state.hammenu })}
              >
                <i
                  className="fa fa-tasks hammenu"
                  style={{ "margin-top": "25px", padding: "0 15px" }}
                ></i>
              </a>

              <a
                onClick={this.TaskactiveBind.bind(this)}
                className="logo"
                style={{ padding: "0 10px" }}
              >
                <img src={require("./img/retail-logo.png")} alt="logo" />
              </a>
              <div className="markcenter">
                <p className="marquee">{this.state.tickerList}</p>
              </div>
              <ul className="nav pull-right pos-rel">
                <li className="dropdown">
                  <a
                    className="dropdown-toggle"
                    data-toggle="dropdown"
                    onClick={this.showDropdownMenu}
                  >
                    {" "}
                    <img
                      src={require("./img/user.png")}
                      className="user-img"
                    />{" "}
                    <b className="name-show">
                      {this.props.location.state.usrnm}
                    </b>
                    ({this.props.location.state.storeNo})
                    <b className="caret" />
                  </a>
                </li>

                {this.state.displayMenu ? (
                  <ul className="dropdown-menuuser-dd">
                    <li>Role : {this.props.location.state.roleName}</li>
                    <li className="divider"></li>
                    <li>
                      <a onClick={this.Changepasswordbind}>Change Password</a>
                    </li>
                    <li className="divider"></li>
                    <li>
                      <a onClick={this.logout}>Log Out</a>
                    </li>
                  </ul>
                ) : null}
              </ul>
            </header>
            <aside>
              <div id="sidebar" className="nav-collapse">
                {this.state.hammenu ? (
                  <ul className="sidebar-menu" id="nav-accordion">
                    {this.props.location.state.menuList["401"] ||
                    this.props.location.state.menuList["402"] ||
                    this.props.location.state.menuList["403"] ||
                    this.props.location.state.menuList["404"] ||
                    this.props.location.state.menuList["405"] ||
                    this.props.location.state.menuList["408"] ||
                    this.props.location.state.menuList["411"] ? (
                      <li
                        className="sub-menu mt"
                        onClick={this.showTaskDropdwnHandler.bind(this)}
                      >
                        {" "}
                        <a href="javascript:;">
                          {" "}
                          <i className="fa fa-tasks"></i> <span>Task</span>{" "}
                        </a>
                        {this.state.TaskDropdwn ? (
                          <div>
                            <ul class="sub">
                              {this.props.location.state.menuList["401"] ? (
                                <li>
                                  <a onClick={this.TaskactiveBind.bind(this)}>
                                    {this.props.location.state.menuList["401"]}
                                  </a>
                                </li>
                              ) : null}
                              {this.props.location.state.menuList["402"] ? (
                                <li>
                                  <a onClick={this.getroles.bind(this)}>
                                    {this.props.location.state.menuList["402"]}
                                  </a>
                                </li>
                              ) : null}
                              {this.props.location.state.menuList["403"] ? (
                                <li>
                                  <a
                                    onClick={this.CompletedTaskListbind.bind(
                                      this
                                    )}
                                  >
                                    {this.props.location.state.menuList["403"]}
                                  </a>
                                </li>
                              ) : null}
                              {this.props.location.state.menuList["404"] ? (
                                <li>
                                  <a onClick={this.templates.bind(this)}>
                                    {this.props.location.state.menuList["404"]}
                                  </a>
                                </li>
                              ) : null}
                              {this.props.location.state.menuList["405"] ? (
                                <li>
                                  <a onClick={this.ArchivedTasks.bind(this)}>
                                    {this.props.location.state.menuList["405"]}
                                  </a>
                                </li>
                              ) : null}
                              {this.props.location.state.menuList["408"] ? (
                                <li>
                                  <a onClick={this.StoreTasks.bind(this)}>
                                    {this.props.location.state.menuList["408"]}
                                  </a>
                                </li>
                              ) : null}
                              {this.props.location.state.menuList["411"] ? (
                                <li>
                                  <a onClick={this.toGenDashboard.bind(this)}>
                                    {this.props.location.state.menuList["411"]}
                                  </a>
                                </li>
                              ) : null}
                            </ul>
                          </div>
                        ) : null}
                      </li>
                    ) : null}
                    {this.props.location.state.menuList["406"] ? (
                      <div>
                        <li>
                          {" "}
                          <a onClick={this.Reportsbind.bind(this)}>
                            {" "}
                            <i className="fa fa-tasks"></i>{" "}
                            <span>
                              {this.props.location.state.menuList["406"]}
                            </span>{" "}
                          </a>{" "}
                        </li>
                      </div>
                    ) : null}

                    {this.props.location.state.menuList["414"] &&
                    this.props.location.state.format_id === "102" ? (
                      <div>
                        <li>
                          {" "}
                          <a onClick={this.Defectbind.bind(this)}>
                            {" "}
                            <i className="fa fa-tasks"></i>{" "}
                            <span>
                              {this.props.location.state.menuList["414"]}
                            </span>{" "}
                          </a>{" "}
                        </li>
                      </div>
                    ) : null}

                    {this.props.location.state.menuList["415"] ? (
                      <div>
                        <li>
                          {" "}
                          <a onClick={this.SmartPointBind.bind(this)}>
                            {" "}
                            <i className="fa fa-tasks"></i>{" "}
                            <span>
                              {this.props.location.state.menuList["415"]}
                            </span>{" "}
                          </a>{" "}
                        </li>
                      </div>
                    ) : null}

                    {this.props.location.state.menuList["409"] ||
                    this.props.location.state.menuList["410"] ? (
                      <div>
                        <li
                          className="sub-menu"
                          onClick={this.showIssueDropdwnHandler.bind(this)}
                        >
                          {" "}
                          <a href="javascript:;">
                            {" "}
                            <i className="fa fa-tasks"></i> <span>Issue</span>{" "}
                          </a>
                          {this.state.IssueDropdwn ? (
                            <div>
                              <ul class="sub">
                                {this.props.location.state.menuList["410"] ? (
                                  <li>
                                    <a onClick={this.IssueRaising.bind(this)}>
                                      {
                                        this.props.location.state.menuList[
                                          "410"
                                        ]
                                      }
                                    </a>
                                  </li>
                                ) : null}
                                {this.props.location.state.menuList["409"] ? (
                                  <li>
                                    <a onClick={this.IssueDashboard.bind(this)}>
                                      {
                                        this.props.location.state.menuList[
                                          "409"
                                        ]
                                      }
                                    </a>
                                  </li>
                                ) : null}
                              </ul>
                            </div>
                          ) : null}
                        </li>
                      </div>
                    ) : null}

                    {this.props.location.state.menuList["407"] ? (
                      <div>
                        <li
                          className="sub-menu"
                          onClick={this.showTaskdwnHandler.bind(this)}
                        >
                          {" "}
                          <a href="javascript:;">
                            {" "}
                            <i className="fa fa-tasks"></i>{" "}
                            <span>
                              {this.props.location.state.menuList["407"]}
                            </span>{" "}
                          </a>
                          {this.state.Taskdwn ? (
                            <div>
                              <ul class="sub">
                                <li>
                                  {" "}
                                  <a onClick={this.tiker.bind(this)}>
                                    <span>Tiker Creation</span>{" "}
                                  </a>{" "}
                                </li>
                                <li>
                                  {" "}
                                  <a onClick={this.userlist.bind(this)}>
                                    <span>User List</span>{" "}
                                  </a>{" "}
                                </li>
                                {this.props.location.state.menuList["412"] ? (
                                  <li>
                                    {" "}
                                    <a onClick={this.Storecreation.bind(this)}>
                                      <span>
                                        {
                                          this.props.location.state.menuList[
                                            "412"
                                          ]
                                        }
                                      </span>{" "}
                                    </a>{" "}
                                  </li>
                                ) : null}
                                {this.props.location.state.menuList["413"] ? (
                                  <li>
                                    {" "}
                                    <a onClick={this.ToStoreList.bind(this)}>
                                      <span>
                                        {
                                          this.props.location.state.menuList[
                                            "413"
                                          ]
                                        }
                                      </span>{" "}
                                    </a>{" "}
                                  </li>
                                ) : null}
                              </ul>
                            </div>
                          ) : null}
                        </li>
                      </div>
                    ) : null}
                  </ul>
                ) : null}
              </div>
            </aside>
            <section id="main-content">
              <section class="wrapper">
                <div class="mt">
                  <h3>
                    <i className="fa fa-angle-right"></i> Reports
                    <a
                      className="pull-right btn btn-theme"
                      onClick={this.cumulativeReport.bind(this)}
                    >
                      Cumulative Reports
                    </a>{" "}
                  </h3>

                  <div class="row mt" style={{ "padding-left": "30px" }}>
                    <div className="col-sm-2">
                      <b>Start Date</b>
                      <DatePicker
                        className="form-control"
                        value={this.state.UpdatesDateForReport}
                        onChange={this.handleReportStartDate}
                        id="streprtdate"
                        onChangeRaw={this.handleDateChangeRaw}
                        // maxDate={addDays(this.state.UpdatesDateForReportEd, 90)}
                        dateFormat="dd-MMM-yyyy"
                        selected={this.state.startDateForReport}
                        autoComplete="off"
                        // maxDate={addDays(new Date(), 365)}
                        // minTime={setHours(setMinutes(new Date(), new Date().getMinutes()),  new Date().getHours())}
                        // maxTime={setHours(setMinutes(new Date(), 59), 23)}
                      />
                      <br />
                    </div>

                    <div className="col-sm-2">
                      <b>End Date</b>
                      <DatePicker
                        className="form-control"
                        value={this.state.UpdatesDateForReportEd}
                        onChange={this.handleReportEndDate}
                        id="edreprtdate"
                        onChangeRaw={this.handleDateChangeRaw}
                        minDate={this.state.UpdatesDateForReport}
                        dateFormat="dd-MMM-yyyy"
                        selected={this.state.endDateForReport}
                        autoComplete="off"
                        maxDate={new Date()}
                        // minTime={setHours(setMinutes(new Date(), new Date().getMinutes()),  new Date().getHours())}
                        // maxTime={setHours(setMinutes(new Date(), 59), 23)}
                      />
                      <br />
                    </div>
                    <button
                      class="btn btn-primary min-wid-90 mt-20 ml-10"
                      type="button"
                      onClick={this.ReportsCall.bind(this)}
                    >
                      Get Reports
                    </button>
                  </div>
                </div>
                <hr />
                <div class="row mt">
                  <div class="col-md-12">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
                        {" "}
                        <b>Task ID</b>
                        <br />
                        <input
                          type="text"
                          placeholder="Search Task ID"
                          className="form-control"
                          id="taskId"
                          onChange={this.onChange}
                          autoComplete="off"
                        />
                      </div>

                      <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
                        {" "}
                        <b>Task Name</b>
                        <br />
                        <input
                          type="text"
                          placeholder="Search Task Name"
                          className="form-control"
                          id="taskName"
                          onChange={this.onChange1}
                          autoComplete="off"
                        />
                      </div>

                      <div className="col-xs-12 col-sm-3 col-md-2 col-lg-2 mt-xs-10 w-sm-110">
                        {" "}
                        <b>Start Date</b>
                        <br />
                        <DatePicker
                          autoComplete="off"
                          className="form-control form-control-inline input-medium default-date-picker"
                          size="16"
                          type="text"
                          placeholderText="Select Start Date "
                          dateFormat="dd-MMM-yyyy"
                          id="startDate"
                          selected={this.state.startDate}
                          onChange={this.handleChangeStart}
                          onChangeRaw={this.handleDateChangeRaws}
                        />
                      </div>
                      <div className="col-xs-12 col-sm-3 col-md-2 col-lg-2 mt-xs-10 w-sm-110">
                        <b> End Date</b>
                        <br />
                        <DatePicker
                          autoComplete="off"
                          className="form-control form-control-inline input-medium default-date-picker"
                          size="16"
                          type="text"
                          placeholderText="Select End Date"
                          dateFormat="dd-MMM-yyyy"
                          id="endDate"
                          selected={this.state.endDate}
                          //  minDate={this.state.startDate }
                          // maxDate={addDays(new Date(),365)}
                          onChange={this.handleChangeEnd}
                          onChangeRaw={this.handleDateChangeRawe}
                        />
                      </div>
                      <div className="col-xs-12 col-sm-1 col-md-1 col-lg-1 mt-xs-10">
                        <img
                          src={require("./img/searchicon.png")}
                          className="user-img mt-20"
                          style={{ width: "25px", height: "25px" }}
                        />
                      </div>
                      <div className="clearfix"></div>
                    </div>
                    <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12 tbwdth">
                      <div className="mt">
                        <div className="tbl-holder">
                          <table className="table table-striped table-advance table-hover table-bordered tbl-task tbl-hhide">
                            <thead>
                              <tr>
                                <th>Task ID</th>
                                <th>Task Name</th>
                                <th>Created By</th>
                                <th>Start Date</th>
                                <th>End Date</th>
                                <th class="gnt-report">Report</th>
                                <th>Reassign</th>
                              </tr>
                            </thead>
                          </table>
                          <Scrollbars style={{ height: 296 }}>
                            <table className="table table-striped table-advance table-hover table-bordered tbl-task ">
                              <tbody>
                                {this.state.data.map(function (item, key) {
                                  if (
                                    (Search !== "" ||
                                      searchSDate !== "" ||
                                      searchedDate !== "" ||
                                      searchName !== "") &&
                                    (item.taskId
                                      .toLowerCase()
                                      .indexOf(Search.toLowerCase()) ||
                                      item.taskName
                                        .toLowerCase()
                                        .indexOf(searchName.toLowerCase()) ||
                                      item.startDate
                                        .toLowerCase()
                                        .indexOf(searchSDate.toLowerCase()) ||
                                      item.endDate
                                        .toLowerCase()
                                        .indexOf(
                                          searchedDate.toLowerCase()
                                        )) === -1
                                  ) {
                                    return null;
                                  }

                                  return (
                                    <tr key={key}>
                                      <td data-th="Task ID"> {item.taskId}</td>

                                      <td data-th="Task Name">
                                        {item.taskName}
                                      </td>
                                      <td data-th="Created By">
                                        {item.createdBy}
                                      </td>
                                      <td data-th="Start Date">
                                        {item.startDate}
                                      </td>
                                      <td data-th="End Date">{item.endDate}</td>

                                      <td data-th="Report" class="gnt-report">
                                        {that.props.location.state.role_id ===
                                        "300" ? (
                                          <a
                                            onClick={that.toggle.bind(
                                              that,
                                              item.taskId,
                                              item.taskName,
                                              item.taskDescr
                                            )}
                                          >
                                            <b>View</b>
                                          </a>
                                        ) : null}
                                        {/* <button
                                            class="btn btn-primary min-wid-90 mt-57 ml-10 dropdown-toggle"
                                            type="button"
                                            onClick={that.genrate.bind(
                                              that,
                                              item.taskId
                                            )}
                                          >Generate
                                          </button> */}
                                        <DropdownButton
                                          taskId={item.taskId}
                                          userID={
                                            that.props.location.state.userid
                                          }
                                          userGuid={
                                            that.props.location.state.guId
                                          }
                                          userRoleId={
                                            that.props.location.state.role_id
                                          }
                                          userFormatId={
                                            that.props.location.state.format_id
                                          }
                                          logoutnormal={that.logoutnormal}
                                        />
                                        <iframe
                                          style={{ display: "none" }}
                                          name="hiddenIframe"
                                          id="hiddenIframe"
                                        ></iframe>

                                        {item.imageFlag === "1" ? (
                                          <a
                                            class="ml-10"
                                            onClick={that.imagemodalopen.bind(
                                              that,
                                              item.taskId
                                            )}
                                          >
                                            <img
                                              style={{ height: "30px" }}
                                              src={require("./img/gallery.png")}
                                            ></img>
                                          </a>
                                        ) : (
                                          <a
                                            class="ml-10"
                                            style={{ cursor: "default" }}
                                          >
                                            <img
                                              style={{
                                                height: "30px",
                                                visibility: "hidden",
                                              }}
                                              src={require("./img/gallery.png")}
                                            ></img>
                                          </a>
                                        )}
                                        <span className="d-none">
                                          {item.taskId}
                                        </span>
                                      </td>
                                      <td
                                        data-th="Reassign "
                                        style={{ textAlign: "center" }}
                                      >
                                        <a
                                          onClick={that.duplicate.bind(
                                            that,
                                            item.taskName,
                                            item.taskDescr,
                                            item.taskId,
                                            item.endDate
                                          )}
                                        >
                                          <img
                                            style={{ height: "30px" }}
                                            src={require("./img/reassign.png")}
                                          ></img>
                                        </a>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </Scrollbars>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12" hidden>
                      <ExcelFile
                        element={
                          <button
                            class="btn btn-primary min-wid-90 mt-17"
                            ref="download"
                          >
                            {" "}
                            Download
                          </button>
                        }
                      >
                        <ExcelSheet dataSet={SummaryDataset} name="Summary" />
                        <ExcelSheet dataSet={ReportDataSet} name="Reports" />
                      </ExcelFile>
                    </div>
                  </div>
                </div>
              </section>
            </section>
          </section>
        </div>
      );
    }
  };

  ToStoreList = () => {
    sessionStorage.removeItem("sDate");
    sessionStorage.removeItem("eDate");
    this.props.history.push({
      pathname: "/StoreList",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        formerMasterList: this.state.formerMasterList,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      },
    });
  };

  Storecreation = () => {
    sessionStorage.removeItem("sDate");
    sessionStorage.removeItem("eDate");
    this.props.history.push({
      pathname: "/Storecreation",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        formerMasterList: this.state.formerMasterList,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      },
    });
  };

  showIssueDropdwnHandler = () => {
    sessionStorage.removeItem("sDate");
    sessionStorage.removeItem("eDate");
    this.setState({
      IssueDropdwn: !this.state.IssueDropdwn,
    });
  };

  IssueRaising = () => {
    sessionStorage.removeItem("sDate");
    sessionStorage.removeItem("eDate");
    this.props.history.push({
      pathname: "/IssueRaising",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        data: this.props.location.state.data,
        tskId: this.props.location.state.tskId,
        tskName: this.props.location.state.tskName,
        storeNo: this.props.location.state.storeNo,
        Isroffice: this.props.location.state.Isroffice,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      },
    });
  };

  IssueDashboard = () => {
    sessionStorage.removeItem("sDate");
    sessionStorage.removeItem("eDate");
    this.props.history.push({
      pathname: "/IssueDashboard",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        data: this.props.location.state.data,
        tskId: this.props.location.state.tskId,
        tskName: this.props.location.state.tskName,
        storeNo: this.props.location.state.storeNo,
        Isroffice: this.props.location.state.Isroffice,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      },
    });
  };

  TaskactiveBind = () => {
    sessionStorage.removeItem("sDate");
    sessionStorage.removeItem("eDate");
    this.props.history.push({
      pathname: "/Taskactive",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      },
    });
  };

  toGenDashboard = () => {
    sessionStorage.removeItem("sDate");
    sessionStorage.removeItem("eDate");
    this.props.history.push({
      pathname: "/DashboardGeneral",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        data: this.props.location.state.data,
        tskId: this.props.location.state.tskId,
        tskName: this.props.location.state.tskName,
        storeNo: this.props.location.state.storeNo,
        Isroffice: this.props.location.state.Isroffice,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
        mainuser: this.props.location.state.mainuser,
      },
    });
  };

  CompletedTaskListbind = () => {
    sessionStorage.removeItem("sDate");
    sessionStorage.removeItem("eDate");
    this.props.history.push({
      pathname: "/CompletedTaskList",
      state: {
        data: this.state.data,
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
        mainuser: this.props.location.state.mainuser,
      },
    });
  };

  SmartPointBind = () => {
    sessionStorage.removeItem("sDate");
    sessionStorage.removeItem("eDate");
    this.props.history.push({
      pathname: "/SmartPoint",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        data: this.props.location.state.data,
        tskId: this.props.location.state.tskId,
        tskName: this.props.location.state.tskName,
        storeNo: this.props.location.state.storeNo,
        Isroffice: this.props.location.state.Isroffice,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      },
    });
  };

  Defectbind = () => {
    sessionStorage.removeItem("sDate");
    sessionStorage.removeItem("eDate");
    this.props.history.push({
      pathname: "/DefectRaising",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        data: this.props.location.state.data,
        tskId: this.props.location.state.tskId,
        tskName: this.props.location.state.tskName,
        storeNo: this.props.location.state.storeNo,
        Isroffice: this.props.location.state.Isroffice,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      },
    });
  };

  Reportsbind = () => {
    sessionStorage.removeItem("sDate");
    sessionStorage.removeItem("eDate");
    this.props.history.push({
      pathname: "/Reports",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      },
    });
  };

  StoreTasks = () => {
    sessionStorage.removeItem("sDate");
    sessionStorage.removeItem("eDate");
    this.props.history.push({
      pathname: "/StoreTasks",
      state: {
        data: this.state.data,
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      },
    });
  };

  getroles(e) {
    sessionStorage.removeItem("sDate");
    sessionStorage.removeItem("eDate");
    this.props.history.push({
      pathname: "/CreatetaskUI",
      state: {
        format_id: this.props.location.state.format_id,
        role_id: this.props.location.state.role_id,
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      },
    });
  }

  // genrate(taskId, reportTypeValue) {
  //   // this.setState({
  //   //   loading: true,
  //   // });
  //   let request = {
  //     taskId: taskId,
  //     userFormatId: this.props.location.state.role_id,
  //     userId: this.props.location.state.userid,
  //     formatId: this.props.location.state.format_id,
  //     reportType: reportTypeValue,
  //   };

  //   var url = `/TaskReport?key=${Encrypt(request)}`;

  //   fetch(url, {
  //     method: "GET",
  //     headers: {
  //       guId: this.props.location.state.guId,
  //       Authorization: "Basic " + basicAuth(this.props.location.state.userid),
  //     },
  //   })
  //     .then((data) => {
  //       console.log("DT", data);
  //       return data.blob();
  //     })
  //     .then((data) => {
  //       // const a = document.createElement("a");
  //       const a = new File([data], "", {
  //         type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  //       });
  //       const blobUrl = URL.createObjectURL(a);
  //       this.setState({ reportURL: blobUrl });
  //       console.log("BLOB", blobUrl);
  //       // a.hidden = true;
  //       // a.download = taskId;
  //       // document.body.appendChild(a);
  //       // a.click();
  //       // a.parentNode.removeChild(a);
  //       // this.setState({
  //       //   loading: false,
  //       // });
  //     })
  //     .catch((error) => {
  //       console.log("Error is", error);
  //       confirmAlert({
  //         title: "Alert !",
  //         message: "Session expired",
  //         buttons: [
  //           {
  //             label: "Ok",
  //             onClick: () => {
  //               this.logoutnormal();
  //             },
  //           },
  //         ],
  //       });
  //     });
  // }

  cumulativeReport = () => {
    this.props.history.push({
      pathname: "/CumulativeReports",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        data: this.props.location.state.data,
        tskId: this.props.location.state.tskId,
        tskName: this.props.location.state.tskName,
        storeNo: this.props.location.state.storeNo,
        Isroffice: this.props.location.state.Isroffice,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      },
    });
  };

  Changepasswordbind = () => {
    this.props.history.push({
      pathname: "/ChangepasswordUI",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      },
    });
  };
  selectOption = (e) => {};

  ReportsCall() {
    if (this.props.location.state.role_id === "300") {
      this.ReportsHo();
    } else {
      this.ReportsCluster();
    }
  }

  ReportsCluster(e) {
    var tickerdata = [];
    if (this.props.location.state.tickerList !== undefined) {
      for (let t = 0; t < this.props.location.state.tickerList.length; t++) {
        tickerdata.push(
          this.props.location.state.tickerList[t].tickerText + " || "
        );
      }
    }

    if (this.props.location.state.usrnm !== "") {
      if (
        (document.getElementById("streprtdate").value &&
          document.getElementById("edreprtdate").value) ||
        (sessionStorage.getItem("sDate") && sessionStorage.getItem("eDate"))
      ) {
        var Request = {
          userId: this.props.location.state.userid,
          guId: this.props.location.state.guId,
          startDate:
            document.getElementById("streprtdate").value ||
            this.getReportDates(sessionStorage.getItem("sDate")),
          endDate:
            document.getElementById("edreprtdate").value ||
            this.getReportDates(sessionStorage.getItem("eDate")),
        };

        var EncryptedRequest = Encrypt(Request);
        fetch("/IndividualUserTaskList", {
          method: "POST",
          headers: {
            guId: this.props.location.state.guId,
            Authorization:
              "Basic " + basicAuth(this.props.location.state.userid),
          },
          body: EncryptedRequest,
        })
          .then((response) => response.text())
          .then((response) => {
            var DecryptedResponse = decryptData(response);
            if (DecryptedResponse.errorCode === "00") {
              this.setState({ loading: true });
              this.setState({ data: DecryptedResponse.taskList });
              this.setState({ usrnm: this.props.location.state.usrnm });
              this.setState({ loading: false });
              this.setState({ storeNo: this.props.location.state.storeNo });
              this.setState({ userid: this.props.location.state.userid });
            } else {
              confirmAlert({
                title: "Alert !",
                message: DecryptedResponse.errorMsg,
                buttons: [
                  {
                    label: "Ok",
                  },
                ],
              });
            }
          })
          .catch((error) => {
            confirmAlert({
              title: "Alert !",
              message: "Session expired",
              buttons: [
                {
                  label: "Ok",
                  onClick: () => {
                    this.logoutnormal();
                  },
                },
              ],
            });
          });
      } else {
        confirmAlert({
          title: "Alert !",
          message: "Please select Start Date or End Date ",
          buttons: [
            {
              label: "Ok",
            },
          ],
        });
      }
    } else {
      window.location.href = "/";
    }
  }

  getReportDates = (date) => {
    date = new Date(date);
    var monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return `${date.getDate()}-${
      monthNames[date.getMonth()]
    } + ${date.getFullYear()}`;
  };

  ReportsHo() {
    if (
      this.props.location.state !== undefined &&
      this.props.location.state.usrnm !== ""
    ) {
      this.setState({
        loading: true,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        userid: this.props.location.state.userid,
      });

      var Request1 = {
        userId: this.props.location.state.userid,
        guId: this.props.location.state.guId,
        startDate:
          document.getElementById("streprtdate").value ||
          this.getReportDates(sessionStorage.getItem("sDate")),
        endDate:
          document.getElementById("edreprtdate").value ||
          this.getReportDates(sessionStorage.getItem("eDate")),
      };

      var EncryptedRequest1 = Encrypt(Request1);
      fetch("/TaskListByUser ", {
        method: "POST",
        headers: {
          guId: this.props.location.state.guId,
          Authorization: "Basic " + basicAuth(this.props.location.state.userid),
        },
        body: EncryptedRequest1,
      })
        .then((response) => response.text())
        .then((response) => {
          var DecryptedResponse = decryptData(response);
          if (DecryptedResponse.errorCode === "00") {
            this.setState({ data: DecryptedResponse.taskList });
            this.setState({ loading: false });
          } else {
            this.setState({ loading: false });
            confirmAlert({
              message: DecryptedResponse.errorMsg,
              buttons: [
                {
                  label: "Ok",
                },
              ],
            });
          }
        })
        .catch((error) => {
          confirmAlert({
            title: "Alert !",
            message: "Session expired",
            buttons: [
              {
                label: "Ok",
                onClick: () => {
                  this.logoutnormal();
                },
              },
            ],
          });
        });
    } else {
      window.location.href = "/";
    }
  }

  ReportTask = () => {
    var that = this;
    var monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    var date =
      new Date().getDate() +
      "-" +
      monthNames[new Date().getMonth()] +
      "-" +
      new Date().getFullYear();

    var systemtime =
      date + " " + new Date().getHours() + ":" + new Date().getMinutes();
    var windowtime = document.getElementById("extdate").value;

    if (this.state.storeList.length == 0) {
      confirmAlert({
        title: "Alert !",
        message: "Please upload excel file",
        buttons: [
          {
            label: "Ok",
            onClick: () => {
              that.setState({ FileName: "" });
              return false;
            },
          },
        ],
      });
    } else {
      this.state.storeList.map(function (item, key) {
        if (item.storeNo == undefined) {
          confirmAlert({
            title: "Alert !",
            message: "Please upload the correct excel",
            buttons: [
              {
                label: "Ok",
                onClick: () => {
                  that.setState({ FileName: "" });
                  return false;
                },
              },
            ],
          });
        } else if (!document.getElementById("comment").value) {
          confirmAlert({
            message: "Please enter comment.",
            buttons: [
              {
                label: "Ok",
              },
            ],
          });
        } else if (
          new Date(document.getElementById("extdate").value) >=
            new Date(date) &&
          new Date(windowtime) > new Date(systemtime)
        ) {
          confirmAlert({
            message: "Are you sure, you want to reassign task?",
            buttons: [
              {
                label: "Yes",
                onClick: () => {
                  var Request1 = {
                    taskId: that.state.templetId,
                    extendedDate: document.getElementById("extdate").value,
                    storeList: that.state.storeList,
                    comment: document.getElementById("comment").value,
                    guId: that.props.location.state.guId,
                  };
                  var EncryptedRequest1 = Encrypt(Request1);
                  fetch("/ReopenTaskMultiStore", {
                    method: "POST",
                    headers: {
                      guId: that.props.location.state.guId,
                      Authorization:
                        "Basic " + basicAuth(that.props.location.state.userid),
                    },
                    body: EncryptedRequest1,
                  })
                    .then((response) => response.text())
                    .then((response) => {
                      var DecryptedResponse = decryptData(response);
                      if (DecryptedResponse.errorCode === "00") {
                        confirmAlert({
                          message: "Task reassigned successfully.",
                          buttons: [
                            {
                              label: "Ok",
                              onClick: () => {
                                that.boxclose();
                              },
                            },
                          ],
                        });
                      } else {
                        confirmAlert({
                          message: DecryptedResponse.errorMsg,
                          buttons: [
                            {
                              label: "Ok",
                              onClick: () => {
                                that.boxclose();
                              },
                            },
                          ],
                        });
                      }
                    })
                    .catch((error) => {
                      confirmAlert({
                        title: "Alert !",
                        message: "Session expired",
                        buttons: [
                          {
                            label: "Ok",
                            onClick: () => {
                              that.logoutnormal();
                            },
                          },
                        ],
                      });
                    });
                },
              },
              {
                label: "No",
                onClick: () => {
                  return false;
                },
              },
            ],
          });
        } else {
          confirmAlert({
            message:
              "Extended date/time  should be greater than or equal to current date/time.",
            buttons: [
              {
                label: "Ok",
                onClick: () => {
                  return false;
                },
              },
            ],
          });
        }
      });
    }
  };
}

export default Reports;
