/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert"; // Import
import "./css/react-confirm-alert.css"; // Import css
import Spinner from "react-spinner-material"; //Import Spinner
import { Scrollbars } from "react-custom-scrollbars";
import disableBrowserBackButton from "disable-browser-back-navigation";
import IdleTimer from "react-idle-timer"; // For Idle
import "./css/style.css";
import "./css/style-responsive.css";
import { Encrypt, decryptData } from "./Encryption-Decrypytion";
import { basicAuth } from "./BasicAuth";
import readXlsxFile from "read-excel-file";

const display = {
  display: "block"
};
const hide = {
  display: "none"
};

const ParentComponent = props => (
  <div id="children-pane">
    {props.children}
    <div class="col-lg-2 col-md-2 col-sm-2 col-xs-12 mt-17">
      <button
        id="add-opt-btn"
        type="button"
        class="btn btn-primary"
        onClick={props.addChild}
      >
        +
      </button>
    </div>
  </div>
);

const ChildComponent = props => (
  <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12 mt-17">
    <input
      type="text"
      class="form-control"
      placeholder={"Option " + props.number}
      onChange={props.OptChange}
      id={"Opts" + props.number}
      onKeyPress={props.OptStop}
      autoComplete="off"
      maxLength="250"
    />

    {props.showScoreOpt ?
      <select
        class="form-control"
        id={"Score" + props.number}
      >
        <option value="0">Score Value: 0</option>
        <option value="1">Score Value: 1</option>
        <option value="2">Score Value: 2</option>
        <option value="3">Score Value: 3</option>
        <option value="4">Score Value: 4</option>
        <option value="5">Score Value: 5</option>
        <option value="6">Score Value: 6</option>
        <option value="7">Score Value: 7</option>
        <option value="8">Score Value: 8</option>
        <option value="9">Score Value: 9</option>
        <option value="10">Score Value: 10</option>
      </select>
      : null}
  </div>
);

class Addquestion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayMenu: false,
      loaded: this.props.location.state,
      check: '1',
      checked: true,
      value1: "",
      value2: "",
      hasError: false,
      uploadFlag: false,
      viewChat: false,
      FileName: "",
      questionList: [],
      newQueryString: '',
      queryChatArr: [
        { from: 'Service Manager(6494)', message: 'Unable to understand the task performance details', roleId: '900' },
        { from: 'C. Chauhan(500160)', message: 'What particulars do you need ?', roleId: '300' },
        { from: 'Service Manager(6494)', message: 'Kindly provide the details', roleId: '900' }
      ],
      queryChatCommonArr: [],
      queryChatCommonArrFlag: false,
      commonChatViewFlag: false
    };

    this.state = {
      tskId: "",
      tskName: "",
      tskDescr: "",
      strtDate: "",
      edDate: "",
      numChildren: 0,
      TaskDropdwn: false,
      description: [],
      qtypeId: [],
      value: "",
      valueid: [],
      question: "",
      option: "",
      score: "",
      QType: "",
      QuestionText: "",
      OptsValues: [],
      data: [],
      role_id: "",
      userid: "",
      usrnm: "",
      optns: [],
      Addoptbutton: false,
      loading: false,
      show: true,
      showed: false,
      isMandatory: "",
      check: "1",
      checked: true,
      value1: "",
      value2: "",
      status: true,
      hasError: false,
      hammenu: true,
      uploadFlag: false,
      viewChat: false,
      FileName: "",
      questionList: [],
      newQueryString: '',
      queryChatArr: [
        { from: 'Service Manager(6494)', message: 'Unable to understand the task performance details', roleId: '900' },
        { from: 'C. Chauhan(500160)', message: 'What particulars do you need ?', roleId: '300' },
        { from: 'Service Manager(6494)', message: 'Kindly provide the details', roleId: '900' }
      ],
      queryChatCommonArr: [],
      queryChatCommonArrFlag: false,
      commonChatViewFlag: false
    };

    this.showDropdownMenu = this.showDropdownMenu.bind(this);
    this.hideDropdownMenu = this.hideDropdownMenu.bind(this);
    this.idleTimer = null;
    this.onAction = this._onAction.bind(this);
    this.onActive = this._onActive.bind(this);
    this.onIdle = this._onIdle.bind(this);
  }

  logoutnormal = () => {
    localStorage.clear();
    this.props.history.replace("/");
    disableBrowserBackButton();
  };


  componentDidCatch() {
    this.setState({ hasError: true });
  }

  showTaskDropdwnHandler = () => {
    this.setState({
      TaskDropdwn: !this.state.TaskDropdwn
    });
  };
  inputChangedHandler = (param, evt) => {
    if (param === "min") {
      if (evt.target.value.length === 1) {
        const value1c = evt.target.validity.valid
          ? evt.target.value
          : this.state.value1;
        this.setState({ numchange: true, value1: value1c });
        document.getElementById(param).value = value1c;
      } else {
        const value1c = evt.target.validity.valid
          ? evt.target.value
          : this.state.value1;
        this.setState({ numchange: true, value1: value1c });
        document.getElementById(param).value = value1c;
      }
    } else if (param === "max") {
      if (evt.target.value.length === 1) {
        const value2c = evt.target.validity.valid
          ? evt.target.value
          : this.state.value2;
        this.setState({ numchange: true, value2: value2c });
        document.getElementById(param).value = this.state.value2;
      } else {
        const value2c = evt.target.validity.valid
          ? evt.target.value
          : evt.target.value.substring(0, evt.target.value.length - 1);
        this.setState({ numchange: true, value2: value2c });
        document.getElementById(param).value = value2c;
      }
    }
  };

  componentDidMount() {
    if (window.innerWidth <= 768) {
      this.setState({ hammenu: false });
    }
  }

  fullStop = e => {
    var theEvent = e || window.event;
    var key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
    var regex = /[^,;]+$/;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) {
        theEvent.preventDefault();
      }
    }
  };

  fullStopScore = e => {
    e = (e) ? e : window.event;
    var charCode = (e.which) ? e.which : e.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  };

  inputChangedHandlertxt = event => {
    this.setState({
      question: event.target.value
    });
  };
  optionChangedHandler = event => {
    this.setState({
      option: event.target.value
    });
  };

  scoreChangedHandler = event => {
    this.setState({
      score: event.target.value
    });
  };

  showDropdownMenu(event) {
    event.preventDefault();
    this.setState({ displayMenu: true }, () => {
      document.addEventListener("click", this.hideDropdownMenu);
    });
  }

  hideDropdownMenu() {
    this.setState({ displayMenu: false }, () => {
      document.removeEventListener("click", this.hideDropdownMenu);
    });
  }
  logout = () => {
    if (this.props.location.state.Isroffice == true || this.props.location.state.role_id == '900') {
      this.props.history.replace("/");
      disableBrowserBackButton();

      // document.getElementById("rofficelogin").click();
    } else {
      var Request1 = {
        userId: this.props.location.state.userid,
        guId: this.props.location.state.guId
      };
      this.setState({ loading: true });
      var EncryptedRequest1 = Encrypt(Request1);
      fetch("/LogOut ", {
        method: "POST",
        headers: {
          guId: this.props.location.state.guId,
          Authorization: "Basic " + basicAuth(this.props.location.state.userid)
        },
        body: EncryptedRequest1
      })
        .then(response => response.text())
        .then(response => {
          this.setState({ loading: false });
          var DecryptedResponse = decryptData(response);
          if (DecryptedResponse.errorCode === "00") {
            this.props.history.replace("/");
            disableBrowserBackButton();
          } else {
            confirmAlert({
              message: DecryptedResponse.errorMsg,
              buttons: [
                {
                  label: "Ok"
                }
              ]
            });
          }
        })

        .catch(error => {
          confirmAlert({
            title: "Alert !",
            message: "Session expired",
            buttons: [
              {
                label: "Ok",
                onClick: () => {
                  this.logoutnormal();
                }
              }
            ]

            // console.error(error);
          });
          // console.error(error);
        });
    }
  };

  showTaskdwnHandler = () => {
    this.setState({
      Taskdwn: !this.state.Taskdwn
    });
  };

  usercreation = () => {
    this.props.history.push({
      pathname: "/UserCreation",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  userlist = () => {
    this.props.history.push({
      pathname: "/UserList",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  handleChangeChk = e => {
    if (document.getElementById("chkbox").checked === false) {
      this.setState({ check: 0 });
      this.setState({ checked: false });
    } else {
      this.setState({ check: 1 });
      this.setState({ checked: true });
    }
  };

  onChanged(e) {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      this.setState({ value: e.target.value });
    }
  }

  _onAction(e) {
  }

  _onActive(e) {
  }

  componentWillMount() {
    this.FetchQuestionsByTask();
  }

  _onIdle(e) {
    localStorage.clear();
    window.location.href = "/";
    disableBrowserBackButton();
  }

  enter = event => {
    if (event.which === 13) {
      event.preventDefault();
    }
  };

  changeqtype = e => {
    this.setState({ value: e.target.value });
    // alert("select QType on select : "+ e.target.value)
    // alert("rlistid of on change: "+ this.state.qtypeId)
    let flag = [];

    for (var i = 0; i < this.state.description.length; i++) {
      this.state.description[i] === e.target.value
        ? flag.push(this.state.qtypeId[i])
        : this.setState({ valueid: "" });
    }
    this.setState({ valueid: flag });
    this.setState({ Addoptbutton: false });
    //alert("flag : "+ flag)
    if (flag == "1") {
      this.setState({ Addoptbutton: true });
      this.setState({ showed: false });
    } else {
      if (flag == "2") {
        this.setState({ Addoptbutton: true });
        this.setState({ showed: false });
      } else {
        if (flag == "4") {
          this.setState({ showed: true });
        } else {
          if (flag == "9") {
            this.setState({ Addoptbutton: true });
            this.setState({ showed: false });
          } else {
            if (flag == "10") {
              this.setState({ Addoptbutton: false });
              this.setState({ showed: false });
            } else {
              if (flag == "11") {
                this.setState({ Addoptbutton: false });
                this.setState({ showed: false });
              } else {
                if (flag == "12") {
                  this.setState({ Addoptbutton: true });
                  this.setState({ showed: false });
                } else {
                  this.setState({ Addoptbutton: false });
                  this.setState({ showed: false });
                }
              }
            }
          }
        }
      }
    }
  };


  handleAddNewQueryString() {
    this.setState({ loading: true });
    this.state.queryChatArr.push({ from: this.props.location.state.usrnm, message: this.props.location.state.storeNo, roleId: this.props.location.state.role_id })
    this.setState({ loading: false });
  }

  chatBoxclose = () => {
    this.setState({
      viewChat: false
    });
  };

  createChatmodal() {
    console.log('inside.........');
    this.setState(prevState => ({
      viewChat: !prevState.viewChat
    }));
  }

  render = () => {
    var that = this;
    const children = [];

    var chatBox = [];
    chatBox.push(
      <div
        className="modal"
        role="dialog"
        style={this.state.viewChat ? display : hide}
      >
        <div className="modal-dialog animate">
          <div className="modal-content">
            <div className="modal-header">
              <a
                className="close"
                style={{ color: "#fff" }}
                onClick={this.chatBoxclose}
              >
                X
              </a>
              <h4 className="modal-title">Query Chat</h4>
              <div class="row">
                <div class="col-xs-6">
                  <label style={{ color: 'white' }}>
                    <input
                      type="radio"
                      name="upload"
                      checked={!this.state.commonChatViewFlag}
                      onChange={() => this.setState({ commonChatViewFlag: false })}
                    />
                    {""} Personal Chat
                  </label>
                </div>
                <div class="col-xs-6">
                  <label style={{ color: 'white' }}>
                    <input
                      type="radio"
                      name="upload"
                      checked={this.state.commonChatViewFlag}
                      onChange={() => this.setState({ commonChatViewFlag: true })}
                    />
                    {""} Common Chat
                  </label>
                </div>
              </div>
            </div>
            {this.state.commonChatViewFlag === false ?
              <div className="modal-body" style={{ padding: '25px' }}>
                <div className="form-horizontal">
                  <div className="min-wid-90 mt-17">
                    <Scrollbars style={{ height: 200 }}>
                      <p>
                        {this.state.queryChatArr.map((element) =>
                          <>
                            {element.roleId === '300' ?
                              <span style={{ display: 'block', margin: 'inherit', color: 'blue' }}>{element.from} : {element.message}</span>
                              :
                              <span style={{ display: 'block', margin: 'inherit' }}>{element.from} : {element.message}</span>
                            }
                          </>
                        )}
                      </p>
                    </Scrollbars>
                  </div>

                  <div class="upload-holder">
                    <input
                      placeholder="Enter text here"
                      class="form-control"
                      id="newQueryText"
                      autoComplete="off"
                      maxLength="500"
                      onChange={(e) => this.setState({ newQueryString: e.target.value })}
                    ></input>
                    <span
                      class="btn-upload"
                      style={{ padding: "7px 25px" }}
                      onClick={() => this.handleAddNewQueryString()}
                    >
                      Send
                    </span>
                  </div>

                </div>
              </div>
              :
              <div className="modal-body" style={{ padding: '25px' }}>
                <div className="form-horizontal">
                  {this.state.queryChatCommonArrFlag ?
                    <>
                      <div className="min-wid-90 mt-17">
                        <Scrollbars style={{ height: 200 }}>
                          <p>
                            {this.state.queryChatArr.map(element =>
                              <span style={{ display: 'block', margin: 'inherit' }}>{element}</span>
                            )}
                          </p>
                        </Scrollbars>
                      </div>

                      <div class="upload-holder">
                        <input
                          placeholder="Enter text here"
                          class="form-control"
                          id="newQueryText"
                          autoComplete="off"
                          maxLength="500"
                          onChange={(e) => this.setState({ newQueryString: e.target.value })}
                        ></input>
                        <span
                          class="btn-upload"
                          style={{ padding: "7px 25px" }}
                          onClick={() => this.handleAddNewQueryString()}
                        >
                          Send
                        </span>
                      </div>
                    </>
                    :
                    <>
                      Click Publish to publish this chat for common discussion
                      <nbsp />
                      <button
                        class="btn btn-theme"
                        style={{ padding: "7px 25px" }}
                        onClick={() => this.handleAddNewQueryString()}
                      >
                        Publish
                      </button>
                    </>
                  }
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    );

    for (var i = 0; i < this.state.numChildren; i += 1) {
      if (i < 15) {
        children.push(<ChildComponent key={i} number={i} showScoreOpt={this.state.valueid[0] == '12' ? true : false} />);
      } else {
        confirmAlert({
          title: "Alert !",
          message: "You have reached the maximum limit for options",
          buttons: [
            {
              label: "Ok"
            }
          ]
        });
      }
    }

    if (this.state.hasError) {
      return (
        <div>
          <h2>Error occurred!! please contact administrator</h2>
        </div>
      );
    }

    else {
      return (
        <Router>
          <div>
            {chatBox}
            <section id="container">
              <header class="header black-bg">

                <a
                  className="mob-show"
                  onClick={() =>
                    this.setState({ hammenu: !this.state.hammenu })
                  }
                >
                  <i
                    className="fa fa-tasks hammenu"
                    style={{ "margin-top": "25px", 'padding': '0 15px' }}
                  ></i>
                </a>

                <a onClick={this.TaskactiveBind.bind(this)} className="logo" style={{ 'padding': '0 10px' }}>
                  <img src={require("./img/retail-logo.png")} alt="logo" />
                </a>
                <div className="markcenter">
                  <p className="marquee">{this.state.tickerList}</p>
                </div>
                <ul className="nav pull-right pos-rel">
                  <li className="dropdown">
                    <a
                      className="dropdown-toggle"
                      data-toggle="dropdown"
                      onClick={this.showDropdownMenu}
                    >
                      {" "}
                      <img
                        src={require("./img/user.png")}
                        className="user-img"
                      />{" "}
                      <b className="name-show">{this.props.location.state.usrnm}</b>
                      ({this.props.location.state.storeNo})

                      <b className="caret" />
                    </a>
                  </li>

                  <div>
                    <IdleTimer
                      ref={ref => {
                        this.idleTimer = ref;
                      }}
                      element={document}
                      onActive={this.onActive}
                      onIdle={this.onIdle}
                      onAction={this.onAction}
                      debounce={250}
                      timeout={1000 * 60 * 10}
                    />

                  </div>
                  {this.state.displayMenu ? (
                    <ul className="dropdown-menuuser-dd">
                      <li>
                        Role : {this.props.location.state.roleName}
                      </li>
                      <li className="divider"></li>
                      <li>
                        <a onClick={this.Changepasswordbind}>Change Password</a>
                      </li>
                      <li className="divider"></li>
                      <li>
                        <a onClick={this.logout}>Log Out</a>
                      </li>
                    </ul>
                  ) : null}
                </ul>
              </header>
              <aside>
                <div id="sidebar" className="nav-collapse">
                  {this.state.hammenu ? (
                    <ul className="sidebar-menu" id="nav-accordion">
                      {this.props.location.state.menuList["401"] || this.props.location.state.menuList["402"] || this.props.location.state.menuList["403"] || this.props.location.state.menuList["404"] || this.props.location.state.menuList["405"] || this.props.location.state.menuList["408"] || this.props.location.state.menuList["411"] ? (
                        <li
                          className="sub-menu mt"
                          onClick={this.showTaskDropdwnHandler.bind(this)}
                        >
                          {" "}
                          <a href="javascript:;">
                            {" "}
                            <i className="fa fa-tasks"></i> <span>Task</span>{" "}
                          </a>
                          {this.state.TaskDropdwn ? (
                            <div>
                              <ul class="sub">
                                {this.props.location.state.menuList["401"] ? (
                                  <li>
                                    <a onClick={this.TaskactiveBind.bind(this)}>
                                      {this.props.location.state.menuList["401"]}
                                    </a>
                                  </li>
                                ) : null}
                                {this.props.location.state.menuList["402"] ? (
                                  <li>
                                    <a onClick={this.getroles.bind(this)}>
                                      {this.props.location.state.menuList["402"]}
                                    </a>
                                  </li>
                                ) : null}
                                {this.props.location.state.menuList["403"] ? (
                                  <li>
                                    <a
                                      onClick={this.CompletedTaskListbind.bind(
                                        this
                                      )}
                                    >
                                      {this.props.location.state.menuList["403"]}
                                    </a>
                                  </li>
                                ) : null}
                                {this.props.location.state.menuList["404"] ? (
                                  <li>
                                    <a onClick={this.templates.bind(this)}>
                                      {this.props.location.state.menuList["404"]}
                                    </a>
                                  </li>
                                ) : null}
                                {this.props.location.state.menuList["405"] ? (
                                  <li>
                                    <a onClick={this.ArchivedTasks.bind(this)}>
                                      {this.props.location.state.menuList["405"]}
                                    </a>
                                  </li>
                                ) : null}
                                {this.props.location.state.menuList["408"] ? (
                                  <li>
                                    <a onClick={this.StoreTasks.bind(this)}>
                                      {this.props.location.state.menuList["408"]}
                                    </a>
                                  </li>
                                ) : null}
                                {this.props.location.state.menuList["411"] ? (
                                  <li>
                                    <a onClick={this.toGenDashboard.bind(this)}>
                                      {this.props.location.state.menuList["411"]}
                                    </a>
                                  </li>
                                ) : null}
                              </ul>
                            </div>
                          ) : null}
                        </li>
                      ) : null}
                      {this.props.location.state.menuList["406"] ? (
                        <div>
                          <li>
                            {" "}
                            <a onClick={this.Reportsbind.bind(this)}>
                              {" "}
                              <i className="fa fa-tasks"></i>{" "}
                              <span>
                                {this.props.location.state.menuList["406"]}
                              </span>{" "}
                            </a>{" "}
                          </li>
                        </div>
                      ) : null}

                      {this.props.location.state.menuList["414"] && this.props.location.state.format_id === "102" ? (
                        <div>
                          <li>
                            {" "}
                            <a onClick={this.Defectbind.bind(this)}>
                              {" "}
                              <i className="fa fa-tasks"></i>{" "}
                              <span>
                                {this.props.location.state.menuList["414"]}
                              </span>{" "}
                            </a>{" "}
                          </li>
                        </div>
                      ) : null}

                      {this.props.location.state.menuList["415"] ? (
                        <div>
                          <li>
                            {" "}
                            <a onClick={this.SmartPointBind.bind(this)}>
                              {" "}
                              <i className="fa fa-tasks"></i>{" "}
                              <span>
                                {this.props.location.state.menuList["415"]}
                              </span>{" "}
                            </a>{" "}
                          </li>
                        </div>
                      ) : null}

                      {this.props.location.state.menuList["409"] || this.props.location.state.menuList["410"] ? (
                        <div>
                          <li
                            className="sub-menu"
                            onClick={this.showIssueDropdwnHandler.bind(this)}
                          >
                            {" "}
                            <a href="javascript:;">
                              {" "}
                              <i className="fa fa-tasks"></i>{" "}
                              <span>Issue</span>{" "}
                            </a>
                            {this.state.IssueDropdwn ? (
                              <div>
                                <ul class="sub">
                                  {this.props.location.state.menuList["410"] ? (
                                    <li>
                                      <a onClick={this.IssueRaising.bind(this)}>
                                        {this.props.location.state.menuList["410"]}
                                      </a>
                                    </li>
                                  ) : null}
                                  {this.props.location.state.menuList["409"] ? (
                                    <li>
                                      <a onClick={this.IssueDashboard.bind(this)}>
                                        {this.props.location.state.menuList["409"]}
                                      </a>
                                    </li>
                                  ) : null}
                                </ul>
                              </div>
                            ) : null}
                          </li>
                        </div>
                      ) : null}

                      {this.props.location.state.menuList["407"] ? (
                        <div>
                          <li
                            className="sub-menu"
                            onClick={this.showTaskdwnHandler.bind(this)}
                          >
                            {" "}
                            <a href="javascript:;">
                              {" "}
                              <i className="fa fa-tasks"></i>{" "}
                              <span>
                                {this.props.location.state.menuList["407"]}
                              </span>{" "}
                            </a>
                            {this.state.Taskdwn ? (
                              <div>
                                <ul class="sub">
                                  <li>
                                    {" "}
                                    <a onClick={this.tiker.bind(this)}>
                                      <span>Tiker Creation</span>{" "}
                                    </a>{" "}
                                  </li>
                                  <li>
                                    {" "}
                                    <a onClick={this.userlist.bind(this)}>
                                      <span>User List</span>{" "}
                                    </a>{" "}
                                  </li>
                                  {this.props.location.state.menuList["412"] ? (
                                    <li>
                                      {" "}
                                      <a onClick={this.Storecreation.bind(this)}>
                                        <span>{this.props.location.state.menuList["412"]}</span>{" "}
                                      </a>{" "}
                                    </li>
                                  ) : null}
                                  {this.props.location.state.menuList["413"] ? (
                                    <li>
                                      {" "}
                                      <a onClick={this.ToStoreList.bind(this)}>
                                        <span>{this.props.location.state.menuList["413"]}</span>{" "}
                                      </a>{" "}
                                    </li>
                                  ) : null}
                                </ul>
                              </div>
                            ) : null}
                          </li>
                        </div>
                      ) : null}
                    </ul>
                  ) : null}
                </div>
              </aside>
              <section id="main-content">
                <section class="wrapper">
                  <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-20">

                        <h3 class="mt-5">
                          <i className="fa fa-angle-right"></i>
                          {" "}{this.state.tskName}
                          {this.state.publishStatus === "Published" ? (
                            <div>
                              {/* <a
                                style={{ margin: '5px' }}
                                className="pull-right btn btn-theme"
                                onClick={this.createChatmodal.bind(this)}
                              >
                                Check Query
                              </a> */}
                              <a
                                style={{ margin: '5px' }}
                                class="pull-right btn btn-theme"
                                onClick={this.reminder.bind(this)}
                              >
                                Send Reminder
                              </a>
                            </div>
                          ) : null}
                        </h3>

                        <div class="col-md-6 col-lg-6 col-sm-6 col-xs-6">
                          Description : {this.state.tskDescr}
                        </div>
                        <div class="col-md-6 col-lg-6 col-sm-6 col-xs-6">
                          Start Date : {this.state.strtDate}
                        </div>
                        <div class="clearfix"></div>
                        <div class="col-md-6 col-lg-6 col-sm-6 col-xs-6">
                          Functions Involved : {this.props.location.state.functionalList}
                        </div>
                        <div class="col-md-6 col-lg-6 col-sm-6 col-xs-6">
                          End Date : {this.state.edDate}
                        </div>

                        <div class="clearfix"></div>
                        <hr />
                        {this.state.publishStatus == "New" ? (
                          <>
                            <label>Upload File for Bulk Addition of Questions</label>

                            <div class="row">
                              <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                <div class="upload-holder">
                                  <input
                                    id="store-list"
                                    type="text"
                                    class="form-control input-upload"
                                    value={this.state.FileName}
                                  />
                                  <span
                                    class="btn-upload"
                                    style={{ padding: "7px 25px" }}
                                  >
                                    Upload
                                  </span>
                                  <input
                                    id="upload-storedata"
                                    type="file"
                                    name="Store-list-data"
                                    class="input-hidden"
                                    onChange={this.fileHandler.bind(this)}
                                    accept=".xlsx,.xls"
                                  />
                                </div>

                                <span class="help-block">
                                  Template File :{" "}
                                  <a href={require("./doc/BulkAddQuestions.xlsx")}>
                                    BulkAddQuestions.xls
                                  </a>
                                </span>

                                <span class="help-block">
                                  Question ID Ref File :{" "}
                                  <a href={require("./doc/QuestionIdRefFile.xlsx")}>
                                    QuestionIdRefFile.xls
                                  </a>
                                </span>
                              </div>

                              {this.state.uploadFlag ? (
                                <div class="col-lg-2 col-md-2 col-sm-2 col-xs-12">
                                  <button
                                    class="btn btn-primary min-wid-90"
                                    onClick={this.CreateQuestionsFromBulkAdd.bind(this)}
                                  >
                                    Add
                                  </button>
                                </div>
                              ) : null}
                            </div>

                            <div class="ques-box">
                              <div>
                                <input
                                  type="checkbox"
                                  onChange={this.handleChangeChk.bind(this)}
                                  id="chkbox"
                                  checked={that.state.checked}
                                />{" "}
                                Mandatory
                              </div>

                              <br />
                              <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                                {this.state.publishStatus == "New" ? (
                                  <select
                                    class="form-control"
                                    onChange={this.changeqtype.bind(this)}
                                    id="SelQType"
                                  >
                                    <option id="select">Select</option>
                                    {this.state.description.map(element => (
                                      <option>{element}</option>
                                    ))}
                                  </select>
                                ) : null}
                              </div>
                              <div class="col-lg-9 col-md-8 col-sm-8 col-xs-12 mt-cs-10">
                                {this.state.publishStatus === "New" ? (
                                  <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Enter Question"
                                    onChange={this.inputChangedHandlertxt.bind(
                                      this
                                    )}
                                    id="QText"
                                    autoComplete="off"
                                    maxLength="800"
                                  />
                                ) : null}{" "}
                              </div>
                              {this.state.Addoptbutton ? (
                                <div>
                                  <ParentComponent
                                    addChild={this.onAddChild}
                                    OptStop={this.fullStop}
                                    ScoreStop={this.fullStopScore}
                                    OptChange={this.optionChangedHandler}
                                    ScoreChange={this.scoreChangedHandler}
                                  >
                                    {children}
                                  </ParentComponent>
                                </div>
                              ) : null}

                              {this.state.showed ? (
                                <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12 mt-17">
                                  <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Min Length"
                                    onInput={this.inputChangedHandler.bind(
                                      this,
                                      "min"
                                    )}

                                    id="min"
                                    pattern="[0-9]*"
                                    autoComplete="off"
                                  />
                                  <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Max Length"
                                    onInput={this.inputChangedHandler.bind(
                                      this,
                                      "max"
                                    )}

                                    id="max"
                                    pattern="[0-9]*"
                                    autoComplete="off"
                                  />
                                </div>
                              ) : null}
                            </div>
                          </>
                        ) : null}
                        {this.state.publishStatus == "New" ? (
                          <div class="text-center">
                            <button
                              class="btn btn-primary min-wid-90"
                              onClick={this.CreateQuestions.bind(this)}
                              onKeyPress={this.enter}
                            >
                              Add Question
                            </button>{" "}
                          </div>
                        ) : null}
                        <div class="clearfix"></div>
                        <div class="mt">
                          <div className="tbl-holder">
                            <div>
                              <div className={this.state.loading ? 'parentDisable' : ''} width="100%">
                                <div className='overlay-box'>
                                  <Spinner
                                    visible={this.state.loading}
                                    spinnerColor={"rgba(0, 0, 0, 0.3)"}
                                  />
                                </div>
                              </div>

                              <table className="table table-striped table-advance table-hover table-bordered tbl-task tbl-hhide">
                                <thead>
                                  <tr>
                                    <th>Question Type</th>
                                    <th>Question</th>
                                    <th>Options</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                              </table>
                              <Scrollbars style={{ height: 296 }}>
                                <table className="table table-striped table-advance table-hover table-bordered tbl-task">
                                  <tbody>
                                    {this.state.data.map(function (item, key) {
                                      return (
                                        <tr key={key}>
                                          <td data-th="Question Type">
                                            <span className="d-none">
                                              {item.questionId}
                                            </span>
                                            {item.questionTypeValue}
                                          </td>
                                          <td data-th="Question" id="item">
                                            {item.questionText}
                                          </td>
                                          <td data-th="Options">
                                            <ul class="opt-ans">
                                              {item.options.map(optele => (
                                                <li>{optele}</li>
                                              ))}
                                            </ul>
                                          </td>
                                          <td data-th="Action">
                                            {that.state.publishStatus == "New" ? (
                                              <a
                                                className="edit-icon"
                                                onClick={that.callupdatequestionpage.bind(
                                                  that,
                                                  item.questionId,
                                                  item.questionText,
                                                  item.questionTypeValue,
                                                  item.questiontype,
                                                  item.minValue,
                                                  item.maxValue,
                                                  item.options,
                                                  item.updatedBy,
                                                  item.isMandatory
                                                )}
                                              >
                                                {" "}
                                                <i className="fa fa-pencil "></i>{" "}
                                              </a>
                                            ) : null}

                                            {that.state.publishStatus == "New" ? (
                                              <a
                                                className="delete-icon"
                                                value="Delete"
                                                onClick={that.DeleteQuestion.bind(
                                                  that,
                                                  item.questionId
                                                )}
                                              >
                                                {" "}
                                                <i className="fa fa-trash-o "></i>{" "}
                                              </a>
                                            ) : null}
                                          </td>
                                        </tr>
                                      );
                                    })}

                                  </tbody>
                                </table>
                              </Scrollbars>
                            </div>
                          </div>
                        </div>

                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                          <div class="text-center mt ">
                            {this.props.location.state.role_id === "300" ? (
                              <button
                                class="btn btn-primary min-wid-90 "
                                onClick={this.TaskactiveBind.bind(this)}
                              >
                                Back
                              </button>
                            ) : (
                              <button
                                class="btn btn-primary min-wid-90 "
                                onClick={this.StoreTasks.bind(this)}
                              >
                                Back
                              </button>
                            )}
                            {this.state.show ? (
                              <button
                                class="btn btn-primary min-wid-90 ml-10 "
                                onClick={this.PreviewQuestion.bind(this)}
                              >
                                Preview
                              </button>
                            ) : null}
                          </div>
                          <div class="clearfix"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </section>
            </section>
          </div>
        </Router>
      );
    }
  };
  onAddChild = () => {
    this.setState({
      numChildren: this.state.numChildren + 1
    });
  };

  Changepasswordbind = () => {
    this.props.history.push({
      pathname: "/ChangepasswordUI",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };
  CompletedTaskListbind = () => {
    this.props.history.push({
      pathname: "/CompletedTaskList",
      state: {
        data: this.state.data,
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  TaskactiveBind = () => {
    this.props.history.push({
      pathname: "/Taskactive",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  toGenDashboard = () => {
    this.props.history.push({
      pathname: "/DashboardGeneral",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        data: this.props.location.state.data,
        tskId: this.props.location.state.tskId,
        tskName: this.props.location.state.tskName,
        storeNo: this.props.location.state.storeNo,
        Isroffice: this.props.location.state.Isroffice,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  getroles(e) {
    this.props.history.push({
      pathname: "/CreatetaskUI",
      state: {
        format_id: this.props.location.state.format_id,
        role_id: this.props.location.state.role_id,
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  }

  Defectbind = () => {
    this.props.history.push({
      pathname: "/DefectRaising",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        data: this.props.location.state.data,
        tskId: this.props.location.state.tskId,
        tskName: this.props.location.state.tskName,
        storeNo: this.props.location.state.storeNo,
        Isroffice: this.props.location.state.Isroffice,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  SmartPointBind = () => {
    this.props.history.push({
      pathname: "/SmartPoint",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        data: this.props.location.state.data,
        tskId: this.props.location.state.tskId,
        tskName: this.props.location.state.tskName,
        storeNo: this.props.location.state.storeNo,
        Isroffice: this.props.location.state.Isroffice,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  Reportsbind = () => {
    this.props.history.push({
      pathname: "/Reports",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  tiker = () => {
    this.props.history.push({
      pathname: "/Tiker",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };


  fileHandler = event => {

    this.setState({ loading: true, questionList: [], uploadFlag: false });
    const input = document.getElementById("upload-storedata");

    readXlsxFile(input.files[0]).then((rows, files) => {
      var quesData = [];
      for (let i = 1; i < rows.length; i++) {
        quesData.push(rows[i]);
        for (let j = 0; j < quesData.length; j++) {

          if (quesData[j][0] > 12 || quesData[j][0] < 0 || isNaN(quesData[j][0])) {
            this.setState({ loading: false });
            confirmAlert({

              message: `Please add appropriate Question ID at line no. ${i + 1}`,
              buttons: [
                {
                  label: "Ok",
                  onClick: () => { window.location.reload(true) }
                }
              ]
            });
            return;
          }

          if (!quesData[j][1]) {
            this.setState({ loading: false });
            confirmAlert({

              message: `Please add Question Text at line no. ${i + 1}`,
              buttons: [
                {
                  label: "Ok",
                  onClick: () => { window.location.reload(true) }
                }
              ]
            });
            return;
          }

          if (quesData[j][5] !== 1 && quesData[j][5] !== 0) {
            this.setState({ loading: false });
            confirmAlert({

              message: `Please check Mandatory status at line no. ${i + 1}. Must be either 1 or 0`,
              buttons: [
                {
                  label: "Ok",
                  onClick: () => { window.location.reload(true) }
                }
              ]
            });
            return;
          }

          if (!quesData[j][4] && (quesData[j][0] === 1 || quesData[j][0] === 2 || quesData[j][0] === 9)) {
            this.setState({ loading: false });
            confirmAlert({

              message: `Please add Options at line no. ${i + 1}`,
              buttons: [
                {
                  label: "Ok",
                  onClick: () => { window.location.reload(true) }
                }
              ]
            });
            return;
          }

          if (quesData[j][0] == 4 && (isNaN(quesData[j][2]) || isNaN(quesData[j][3]))) {
            this.setState({ loading: false });
            confirmAlert({

              message: `Please add only numeric Min & Max values at line no. ${i + 1}`,
              buttons: [
                {
                  label: "Ok",
                  onClick: () => { window.location.reload(true) }
                }
              ]
            });
            return;
          }

          if (quesData[j][0] == 4 && ((quesData[j][2]) === null || (quesData[j][3]) === null)) {
            this.setState({ loading: false });
            confirmAlert({

              message: `Please add numeric Min & Max values at line no. ${i + 1}`,
              buttons: [
                {
                  label: "Ok",
                  onClick: () => { window.location.reload(true) }
                }
              ]
            });
            return;
          }

          if (quesData[j][0] == 4 && ((quesData[j][2]) >= (quesData[j][3]))) {
            this.setState({ loading: false });
            confirmAlert({

              message: `Min value cannot be greater than or equal to max value at line no. ${i + 1}`,
              buttons: [
                {
                  label: "Ok",
                  onClick: () => { window.location.reload(true) }
                }
              ]
            });
            return;
          }

          var quesOptArr = [];
          if (quesData[j][4]) {
            quesOptArr = quesData[j][4].split(";");
          }

          var questionObj = {
            questiontype: quesData[j][0].toString(),
            questionText: quesData[j][1].toString(),
            minValue: quesData[j][0] == 4 ? quesData[j][2] ? quesData[j][2].toString() : "" : "",
            maxValue: quesData[j][0] == 4 ? quesData[j][3] ? quesData[j][3].toString() : "" : "",
            options: quesData[j][0] == 12 || quesData[j][0] == 9 || quesData[j][0] == 1 || quesData[j][0] == 2 ? quesOptArr : [],
            isMandatory: quesData[j][5].toString()
          };

        }
        {
          this.state.questionList.push(questionObj);
        }
      }
      this.setState({
        loading: false,
        FileName: "File Uploaded Successfully",
        uploadFlag: true
      });
    });
  };

  CreateQuestionsFromBulkAdd = () => {

    this.setState({ loading: true });

    var Request = {
      taskId: this.props.location.state.tskId,
      userId: this.props.location.state.userid,
      guId: this.props.location.state.guId,
      // listQuestions: this.state.questionList
      listQuestions: this.state.questionList.map(question => {
        return {
          ...question,
          options: question.options.map((optValue, index) => {
            return {
              opt: optValue,
              score: index.toString(),
            };
          }),
        };
      }),
    };

    var EncryptedRequest = Encrypt(Request);

    fetch("/CreateQuestions", {
      method: "POST",
      headers: {
        guId: this.props.location.state.guId,
        Authorization:
          "Basic " + basicAuth(this.props.location.state.userid)
      },
      body: EncryptedRequest
    })
      .then(response => response.text())
      .then(response => {
        var DecryptedResponse = decryptData(response);
        this.setState({ loading: false });
        if (DecryptedResponse.errorCode === "00") {

          confirmAlert({

            message: "Questions added successfully.",
            buttons: [
              {
                label: "Ok",
                onClick: () => window.location.reload(true)
              }
            ]
          });

          this.props.history.push({
            pathname: "/Addquestion",
            state: {
              tskName: this.state.tskName,
              role_id: this.state.role_id,
              format_id: this.props.location.state.format_id,
              tskId: this.state.tskId,
              userid: this.props.location.state.userid,
              usrnm: this.props.location.state.usrnm,
              tskDescr: this.state.tskDescr,
              strtDate: this.state.strtDate,
              edDate: this.state.edDate,
              publishStatus: this.state.publishStatus,
              storeNo: this.props.location.state.storeNo,
              guId: this.props.location.state.guId,
              menuList: this.props.location.state.menuList,
              roleName: this.props.location.state.roleName,
            }
          });
        }
      })
      .catch(error => {
        confirmAlert({
          title: "Alert !",
          message: "Session expired",
          buttons: [
            {
              label: "Ok",
              onClick: () => {
                this.logoutnormal();
              }
            }
          ]
        });
      });


  }

  CreateQuestions(e) {


    if (document.getElementById("SelQType").value === "Select") {
      confirmAlert({
        title: "Alert !",
        message: "Please select option",
        buttons: [
          {
            label: "Ok"
            //onClick: () => { this.logout.bind(this) }
          }
        ]
      });
    } else {
      if (document.getElementById("QText").value === "") {
        confirmAlert({
          title: "Alert !",
          message: "Please enter Question",
          buttons: [
            {
              label: "Ok"
            }
          ]
        });
      } else {
        if (
          this.state.valueid[0] != "1" &&
          this.state.valueid[0] != "2" &&
          this.state.valueid[0] != "9" &&
          this.state.valueid[0] != "4" &&
          this.state.valueid[0] != "12"
        ) {
          var Request = {
            taskId: this.props.location.state.tskId,
            userId: this.props.location.state.userid,
            guId: this.props.location.state.guId,
            listQuestions: [
              {
                questionText: document.getElementById("QText").value,
                questiontype: this.state.valueid.toString(),
                minValue: "",
                maxValue: "",
                options: [],
                isMandatory: this.state.check
              }
            ]
          };
          var EncryptedRequest = Encrypt(Request);

          fetch("/CreateQuestions", {
            method: "POST",
            headers: {
              guId: this.props.location.state.guId,
              Authorization:
                "Basic " + basicAuth(this.props.location.state.userid)
            },
            body: EncryptedRequest
          })
            .then(response => response.text())
            .then(response => {
              var DecryptedResponse = decryptData(response);

              if (DecryptedResponse.errorCode === "00") {

                confirmAlert({

                  message: "Question added successfully.",
                  buttons: [
                    {
                      label: "Ok",
                      onClick: () => window.location.reload(true)
                    }
                  ]
                });

                this.props.history.push({
                  pathname: "/Addquestion",
                  state: {
                    tskName: this.state.tskName,
                    role_id: this.state.role_id,
                    format_id: this.props.location.state.format_id,
                    tskId: this.state.tskId,
                    userid: this.props.location.state.userid,
                    usrnm: this.props.location.state.usrnm,
                    tskDescr: this.state.tskDescr,
                    strtDate: this.state.strtDate,
                    edDate: this.state.edDate,
                    publishStatus: this.state.publishStatus,
                    storeNo: this.props.location.state.storeNo,
                    guId: this.props.location.state.guId,
                    menuList: this.props.location.state.menuList,
                    roleName: this.props.location.state.roleName,
                  }
                });
              } else {
                confirmAlert({
                  title: "Alert !",
                  message: DecryptedResponse.errorMsg,
                  buttons: [
                    {
                      label: "Ok"
                    }
                  ]
                });

              }
            })
            .catch(error => {
              confirmAlert({
                title: "Alert !",
                message: "Session expired",
                buttons: [
                  {
                    label: "Ok",
                    onClick: () => {
                      this.logoutnormal();
                    }
                  }
                ]

                // console.error(error);
              });
              // console.error(error);
            });
        } else {
          if (this.state.valueid[0] == "4") {
            if (!document.getElementById("min").value) {
              confirmAlert({
                message: "Minimum value cannot be null.",
                buttons: [
                  {
                    label: "Ok"
                    //onClick: () => {this.CreateQuestions.bind(this)}
                  }
                ]
              });
            } else if (!document.getElementById("max").value) {
              confirmAlert({
                message: "Maximum value cannot be null.",
                buttons: [
                  {
                    label: "Ok"
                    //onClick: () => {this.CreateQuestions.bind(this)}
                  }
                ]
              });
            } else if (
              parseInt(document.getElementById("min").value) >=
              parseInt(document.getElementById("max").value)
            ) {
              confirmAlert({
                message: "Minimum value cannot be greater than Maximum value .",
                buttons: [
                  {
                    label: "Ok"
                    //onClick: () => {this.CreateQuestions.bind(this)}
                  }
                ]
              });
            } else {
              var Request1 = {
                taskId: this.props.location.state.tskId,
                userId: this.props.location.state.userid,
                guId: this.props.location.state.guId,
                listQuestions: [
                  {
                    questionText: document.getElementById("QText").value,
                    questiontype: this.state.valueid.toString(),
                    minValue: document.getElementById("min").value,
                    maxValue: document.getElementById("max").value,
                    options: [],
                    isMandatory: this.state.check
                  }
                ]
              };
              var EncryptedRequest1 = Encrypt(Request1);

              fetch("/CreateQuestions", {
                method: "POST",
                headers: {
                  guId: this.props.location.state.guId,
                  Authorization:
                    "Basic " + basicAuth(this.props.location.state.userid)
                },
                body: EncryptedRequest1
              })
                .then(response => response.text())
                .then(response => {
                  var DecryptedResponse = decryptData(response);
                  if (DecryptedResponse.errorCode === "00") {

                    confirmAlert({
                      message: "Question added successfully.",
                      buttons: [
                        {
                          label: "Ok",
                          onClick: () => window.location.reload(true)
                        }
                      ]
                    });

                    this.props.history.push({
                      pathname: "/Addquestion",
                      state: {
                        tskName: this.state.tskName,
                        role_id: this.state.role_id,
                        format_id: this.props.location.state.format_id,
                        tskId: this.state.tskId,
                        userid: this.props.location.state.userid,
                        usrnm: this.props.location.state.usrnm,
                        tskDescr: this.state.tskDescr,
                        strtDate: this.state.strtDate,
                        edDate: this.state.edDate,
                        publishStatus: this.state.publishStatus,
                        storeNo: this.props.location.state.storeNo,
                        guId: this.props.location.state.guId,
                        menuList: this.props.location.state.menuList,
                        roleName: this.props.location.state.roleName,
                      }
                    });
                  } else {
                    confirmAlert({
                      title: "Alert !",
                      message: DecryptedResponse.errorMsg,
                      buttons: [
                        {
                          label: "Ok"
                        }
                      ]
                    });
                  }
                })
                .catch(error => {
                  confirmAlert({
                    title: "Alert !",
                    message: "Session expired",
                    buttons: [
                      {
                        label: "Ok",
                        onClick: () => {
                          this.logoutnormal();
                        }
                      }
                    ]

                  });
                });
            }
          } else {
            if (this.state.numChildren === 0) {
              confirmAlert({
                title: "Alert !",
                message: "Please create option ",
                buttons: [
                  {
                    label: "Ok"
                  }
                ]
              });
            } else {
              this.setState({
                QType: document.getElementById("SelQType").value,
                QuestionText: document.getElementById("QText").value,
                OptsValues: document.getElementById("Opts" + 0).value,
                ScoreValues: document.getElementById("Score" + 0) ? document.getElementById("Score" + 0).value : "0"
              });

              let otval = [];
              for (var i = 0; i < this.state.numChildren; i++) {
                if (
                  document.getElementById("Opts" + i).value.indexOf(",") >= 0 &&
                  document.getElementById("Score" + i).value.indexOf(",") >= 0
                ) {
                  if (document.getElementById("Opts" + i).value.includes(",") || document.getElementById("Score" + i).value.includes(",")) {
                    otval.push(
                      {
                        "opt": document
                          .getElementById("Opts" + i)
                          .value.replace(/,/g, ""),
                        "score": document
                          .getElementById("Score" + i)
                          .value.replace(/,/g, "")
                      }

                    );
                  }
                } else {
                  otval.push(
                    {
                      "opt": document.getElementById("Opts" + i).value,
                      "score": document.getElementById("Score" + i) ? document.getElementById("Score" + i).value : "0"
                    }
                  );
                  this.setState({ OptsValues: otval });
                }
              }

              if (document.getElementById("Opts" + 0).value == "") {
                confirmAlert({
                  title: "Alert !",
                  message: "Options can not be blank",
                  buttons: [
                    {
                      label: "Ok"
                    }
                  ]
                });
              } else {

                var Request2 = {
                  taskId: this.props.location.state.tskId,
                  userId: this.props.location.state.userid,
                  guId: this.props.location.state.guId,
                  listQuestions: [
                    {
                      questionText: document.getElementById("QText").value,
                      questiontype: this.state.valueid.toString(),
                      minValue: "",
                      maxValue: "",
                      options: otval.filter(function (el) {
                        return el !== "";
                      }),
                      isMandatory: this.state.check
                    }
                  ]
                };
                var EncryptedRequest2 = Encrypt(Request2);

                fetch("/CreateQuestions", {
                  method: "POST",
                  headers: {
                    guId: this.props.location.state.guId,
                    Authorization:
                      "Basic " + basicAuth(this.props.location.state.userid)
                  },
                  body: EncryptedRequest2
                })
                  .then(response => response.text())
                  .then(response => {
                    var DecryptedResponse = decryptData(response);
                    if (DecryptedResponse.errorCode === "00") {

                      confirmAlert({
                        message: "Question added successfully",
                        buttons: [
                          {
                            label: "Ok",
                            onClick: () => window.location.reload(true)
                          }
                        ]
                      });

                      this.props.history.push({
                        pathname: "/Addquestion",
                        state: {
                          tskName: this.state.tskName,
                          role_id: this.state.role_id,
                          format_id: this.props.location.state.format_id,
                          tskId: this.state.tskId,
                          userid: this.props.location.state.userid,
                          usrnm: this.props.location.state.usrnm,
                          tskDescr: this.state.tskDescr,
                          strtDate: this.state.strtDate,
                          edDate: this.state.edDate,
                          publishStatus: this.state.publishStatus,
                          storeNo: this.props.location.state.storeNo,
                          guId: this.props.location.state.guId,
                          menuList: this.props.location.state.menuList,
                          roleName: this.props.location.state.roleName,
                        }
                      });
                    } else {
                      confirmAlert({
                        title: "Alert !",
                        message: DecryptedResponse.errorMsg,
                        buttons: [
                          {
                            label: "Ok"
                          }
                        ]
                      });
                    }
                  })
                  .catch(error => {
                    confirmAlert({
                      title: "Alert !",
                      message: "Session expired",
                      buttons: [
                        {
                          label: "Ok",
                          onClick: () => {
                            this.logoutnormal();
                          }
                        }
                      ]

                      // console.error(error);
                    });
                    // console.error(error);
                  });
              }
            }
          }
        }
      }
    }
  }

  ToStoreList = () => {
    this.props.history.push({
      pathname: "/StoreList",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        formerMasterList: this.state.formerMasterList,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  Storecreation = () => {
    this.props.history.push({
      pathname: "/Storecreation",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        formerMasterList: this.state.formerMasterList,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  showIssueDropdwnHandler = () => {
    this.setState({
      IssueDropdwn: !this.state.IssueDropdwn
    });
  };

  IssueRaising = () => {
    this.props.history.push({
      pathname: "/IssueRaising",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        data: this.props.location.state.data,
        tskId: this.props.location.state.tskId,
        tskName: this.props.location.state.tskName,
        storeNo: this.props.location.state.storeNo,
        Isroffice: this.props.location.state.Isroffice,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  IssueDashboard = () => {
    this.props.history.push({
      pathname: "/IssueDashboard",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        data: this.props.location.state.data,
        tskId: this.props.location.state.tskId,
        tskName: this.props.location.state.tskName,
        storeNo: this.props.location.state.storeNo,
        Isroffice: this.props.location.state.Isroffice,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  reminder() {
    var Request = {
      taskId: this.state.tskId,
      userId: this.props.location.state.userid,
      guId: this.props.location.state.guId
    };
    var EncryptedRequest = Encrypt(Request);

    fetch("/ReminderMail", {
      method: "POST",
      headers: {
        guId: this.props.location.state.guId,
        Authorization: "Basic " + basicAuth(this.props.location.state.userid)
      },
      body: EncryptedRequest
    })
      .then(response => response.text())
      .then(response => {
        var DecryptedResponse = decryptData(response);
        if (DecryptedResponse.errorCode === "00") {
          confirmAlert({
            message:
              "Reminder send successfully. Store will receive notification mail within upcoming 3 hours .",
            buttons: [
              {
                label: "Ok",
                onClick: () => window.location.reload(true)
              }
            ]
          });

          // this.setState({ state: this.state });
        } else {
          confirmAlert({
            title: "Alert !",
            message: DecryptedResponse.errorMsg,
            buttons: [
              {
                label: "Ok"
                // onClick: () =>
              }
            ]
          });
        }
      })
      .catch(error => {
        confirmAlert({
          title: "Alert !",
          message: "Session expired",
          buttons: [
            {
              label: "Ok",
              onClick: () => {
                this.logoutnormal();
              }
            }
          ]

          // console.error(error);
        });
        // console.error(error);
      });
  }

  DeleteQuestion(questionId) {
    confirmAlert({
      //  title: 'Confirm to submit',
      message: "Are you sure, you want to delete this question?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            var Request3 = {
              questionId: questionId,
              userId: this.props.location.state.userid,
              guId: this.props.location.state.guId
            };
            var EncryptedRequest3 = Encrypt(Request3);

            fetch("/DeleteQuestion", {
              method: "POST",
              headers: {
                guId: this.props.location.state.guId,
                Authorization:
                  "Basic " + basicAuth(this.props.location.state.userid)
              },
              body: EncryptedRequest3
            })
              .then(response => response.text())
              .then(response => {
                var DecryptedResponse = decryptData(response);
                if (DecryptedResponse.errorCode === "00") {
                  confirmAlert({
                    message: "Question deleted successfully.",
                    buttons: [
                      {
                        label: "Ok",
                        onClick: () => window.location.reload(true)
                      }
                    ]
                  });

                } else {
                  confirmAlert({
                    title: "Alert !",
                    message: DecryptedResponse.errorMsg,
                    buttons: [
                      {
                        label: "Ok"
                        // onClick: () =>
                      }
                    ]
                  });
                }
              })
              .catch(error => {
                confirmAlert({
                  title: "Alert !",
                  message: "Session expired",
                  buttons: [
                    {
                      label: "Ok",
                      onClick: () => {
                        this.logoutnormal();
                      }
                    }
                  ]

                  // console.error(error);
                });
                // console.error(error);
              });
          }
        },
        {
          label: "No",
          onClick: () => {
            return false;
          }
        }
      ]
    });

    //window.location.reload(true);
  }

  FetchQuestionsByTask(e) {
    this.setState({ loading: true });

    if (
      this.props.location.state !== undefined &&
      this.props.location.state.usrnm !== ""
    ) {
      var Requested = {
        formatId: this.props.location.state.format_id,
        guId: this.props.location.state.guId
      };
      var EncryptedRequested = Encrypt(Requested);

      fetch("/GetQtypesByFormat", {
        method: "POST",
        headers: {
          guId: this.props.location.state.guId,
          Authorization: "Basic " + basicAuth(this.props.location.state.userid)
        },
        body: EncryptedRequested
      })
        .then(response => response.text())
        .then(response => {
          var DecryptedResponse = decryptData(response);
          if (DecryptedResponse.errorCode === "00") {
            this.setState({ queDesc: DecryptedResponse.lstQTypes });
            let temp = [];
            let temp1 = [];
            for (var i = 0; i < DecryptedResponse.lstQTypes.length; i++) {
              temp.push(DecryptedResponse.lstQTypes[i].qtypeId);
              temp1.push(DecryptedResponse.lstQTypes[i].description);
            }
            this.setState({ qtypeId: temp });
            this.setState({ description: temp1 });
          } else {
            confirmAlert({
              title: "Alert !",
              message: DecryptedResponse.errorMsg,
              buttons: [
                {
                  label: "Ok"
                }
              ]
            });
          }
        })
        .catch(error => {
          confirmAlert({
            title: "Alert !",
            message: "Session expired",
            buttons: [
              {
                label: "Ok",
                onClick: () => {
                  this.logoutnormal();
                }
              }
            ]

            // console.error(error);
          });
          // console.error(error);
        });

      this.setState({ tskName: this.props.location.state.tskName });
      this.setState({ tskDescr: this.props.location.state.tskDescr });
      this.setState({ strtDate: this.props.location.state.strtDate });
      this.setState({ edDate: this.props.location.state.edDate });
      this.setState({ tskId: this.props.location.state.tskId });
      this.setState({ role_id: this.props.location.state.role_id });
      this.setState({ userid: this.props.location.state.userid });
      this.setState({ usrnm: this.props.location.state.usrnm });
      this.setState({ publishStatus: this.props.location.state.publishStatus });

      var Request4 = {
        taskId: this.props.location.state.tskId,
        guId: this.props.location.state.guId
      };
      var EncryptedRequest4 = Encrypt(Request4);

      fetch("/FetchQuestionsByTask", {
        method: "POST",
        headers: {
          guId: this.props.location.state.guId,
          Authorization: "Basic " + basicAuth(this.props.location.state.userid)
        },
        body: EncryptedRequest4
      })
        .then(response => response.text())
        .then(response => {
          var DecryptedResponse = decryptData(response);
          if (DecryptedResponse.errorCode === "00") {
            this.setState({ data: DecryptedResponse.listQuestions });
            this.setState({ loading: false });
          } else {
            this.setState({ loading: false });

            confirmAlert({
              title: "Alert !",
              message: DecryptedResponse.errorMsg,
              buttons: [
                {
                  label: "Ok",
                  onClick: () => {
                    this.setState({ show: false });
                  }
                }
              ]
            });
          }
        })
        .catch(error => {
          confirmAlert({
            title: "Alert !",
            message: "Session expired",
            buttons: [
              {
                label: "Ok",
                onClick: () => {
                  this.logoutnormal();
                }
              }
            ]

            // console.error(error);
          });
          // console.error(error);
        });
    } else {
      window.location.href = "/";
    }
  }

  callupdatequestionpage(
    questionId,
    questionText,
    questionTypeValue,
    questiontype,
    minValue,
    maxValue,
    options,
    updatedBy,
    isMandatory
  ) {
    this.props.history.push({
      pathname: "/Updatequestions",
      state: {
        questionId: questionId,
        questionText: questionText,
        questiontype: questiontype,
        questionTypeValue: questionTypeValue,
        minValue: minValue,
        maxValue: maxValue,
        options: options,
        updatedBy: updatedBy,
        isMandatory: isMandatory,
        userid: this.props.location.state.userid,
        role_id: this.state.role_id,
        format_id: this.props.location.state.format_id,
        tskName: this.state.tskName,
        tskId: this.state.tskId,
        tskDescr: this.state.tskDescr,
        strtDate: this.state.strtDate,
        edDate: this.state.edDate,
        usrnm: this.props.location.state.usrnm,
        publishStatus: this.state.publishStatus,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
        description: this.state.description,
        qtypeId: this.state.qtypeId
      }
    });
  }

  StoreTasks = () => {
    this.props.history.push({
      pathname: "/StoreTasks",
      state: {
        data: this.state.data,
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  templates = () => {
    this.props.history.push({
      pathname: "/Templates",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  ArchivedTasks = () => {
    this.props.history.push({
      pathname: "/ArchivedTasks",
      state: {
        data: this.state.data,
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
        Isroffice: this.props.location.state.Isroffice,
      }
    });
  };

  PreviewQuestion = () => {
    this.props.history.push({
      pathname: "/Questionpreview",
      state: {
        tskId: this.state.tskId,
        tskName: this.state.tskName,
        tskDescr: this.state.tskDescr,
        strtDate: this.state.strtDate,
        edDate: this.state.edDate,
        usrnm: this.props.location.state.usrnm,
        role_id: this.state.role_id,
        format_id: this.props.location.state.format_id,
        userid: this.props.location.state.userid,
        publishStatus: this.state.publishStatus,
        isMandatory: this.state.check,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };
}
export default withRouter(Addquestion);
