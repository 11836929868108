/**
 * eslint-disable no-lone-blocks
 *
 * @format
 */

/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert"; // Import
import "./css/react-confirm-alert.css"; // Import css
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import "./css/style.css";
import "./css/style-responsive.css";
import { Encrypt, decryptData } from "./Encryption-Decrypytion";
import $ from "jquery";
import { BrowserRouter as Router } from "react-router-dom";
import { basicAuth } from "./BasicAuth";

class Login extends Component {
  handleKeyPress(target) {
    if (target.charCode === 13) {
      document.getElementById("loginbtn").click();
    }
  }
  submit = () => {
    confirmAlert({
      // title: 'SAVE!!!',
      message: "Please enter User Id & Password.",
      buttons: [
        {
          label: "Ok",
          onClick: () => {
            this.login.bind(this);
          },
        },
      ],
    });
  };
  constructor(props) {
    super(props);

    this.state = {
      enteredName: null,
      enteredPwd: null,
      rescode: null,
      userName: null,
      formatId: null,
      roleId: null,
      success: false,
      responseJson: null,
      usrname: "",
      password: "",
      hasError: false,
      tickerList: [],
      guId: "",
      role_id: "",
      menuList: [],
      mainuser: "",
    };
    this.state = { value: "" };
    this.state = { loading: false };
    this.onChange = this.onChange.bind(this);
  }
  state = {
    enteredName: null,
    enteredPwd: null,
    rescode: null,
    userName: null,
    formatId: null,
    roleId: null,
    success: false,
    responseJson: null,
    hasError: false,
    tickerList: [],
    role_id: "",
    guId: "",
    menuList: [],
    mainuser: "",
  };
  componentWillMount() {
    if (
      this.props.location.search != "" &&
      this.props.location.search != undefined
    ) {
      const values = queryString.parse(this.props.location.search);
      this.setState({ roffice: true });
      {
        this.RofficeLogin(values.storeNo.trim(), values.userName.trim());
      }
    }
  }

  onChange(e) {
    this.setState({ value: e.target.value });
  }

  OnCopyFunction() {
    $("#txtCompare").bind("paste", function (e) {
      e.preventDefault();
    });
    $("#txtCaptcha").bind("copy", function (e) {
      e.preventDefault();
    });
  }

  componentDidMount() {
    sessionStorage.removeItem("sDate");
    sessionStorage.removeItem("eDate");
    sessionStorage.removeItem("stDate");
    sessionStorage.removeItem("enDate");
    this.GenerateCaptcha();
    localStorage.setItem("role_id", JSON.stringify(this.state.role_id));
  }

  ValidCaptcha() {
    var str1 = document.getElementById("txtCaptcha").value;
    var str2 = document.getElementById("txtCompare").value;

    if (str1 === str2) {
      return true;
    } else {
      return false;
    }
  }

  GenerateCaptcha() {
    var val = Math.floor(1000 + Math.random() * 9000);
    var captchaCode = val;
    document.getElementById("txtCaptcha").value = captchaCode;
  }

  componentDidCatch() {
    this.setState({ hasError: true });
  }

  render = () => {
    if (this.state.hasError) {
      return (
        <div>
          <h2>Error occurred New!! please contact administrator</h2>
        </div>
      );
    } else {
      return (
        <Router>
          <div
            id="login-page"
            className="back-image"
            style={{ height: "100vh" }}
          >
            <div className="container">
              <form
                className="form-login animate"
                action="index.html"
                autoComplete="off"
              >
                {/* <h2 className="form-login-heading">sign in now</h2> */}
                <div style={{ "text-align": "center" }} class="row">
                  <img
                    src={require("./img/RilLogo-nb.png")}
                    alt="Store-X"
                    style={{ "margin-top": "15px", width: "12%" }}
                  />
                  <div
                    style={{
                      "font-size": "large",
                      color: "#3da0de",
                      "font-weight": "bold",
                    }}
                  >
                    Store - X
                  </div>
                </div>
                <div className="login-wrap">
                  <label>User ID</label>
                  <input
                    ref="usrname"
                    type="text"
                    value={this.state.value}
                    id="userLogin"
                    onChange={this.onChange}
                    className="form-control"
                    placeholder="User ID"
                    maxLength="18"
                    autoFocus={true}
                    onKeyPress={this.handleKeyPress}
                    autoComplete="off"
                  />
                  <br />
                  <label>Password</label>
                  <input
                    ref="password"
                    type="password"
                    className="form-control"
                    placeholder="Password"
                    id="userPassword"
                    maxLength="20"
                    onKeyPress={this.handleKeyPress}
                    autoComplete="off"
                  />
                  <br />
                  <div className="row">
                    <div className="col-xs-4">
                      <label className="ml-5 d-inline m-1 float-left">
                        Captcha
                      </label>
                    </div>
                    <div className="col-xs-4">
                      <output
                        readOnly
                        style={{ marginLeft: "22px" }}
                        position="center"
                        id="txtCaptcha"
                        onClick={this.OnCopyFunction.bind(this)}
                        className="ml-5 d-inline m-1 float-center"
                      />
                    </div>
                    <div className="col-xs-4 float-sm-right">
                      <a
                        onClick={this.GenerateCaptcha.bind(this)}
                        id="btnrefresh"
                      >
                        <div
                          className="col-mr-0"
                          style={{ textAlign: "right", "padding-right": "2px" }}
                        >
                          <img
                            style={{ height: "30px", marginLeft: "40px" }}
                            src={require("./img/refresh.png")}
                          ></img>
                        </div>
                      </a>
                    </div>
                  </div>

                  <br />
                  <input
                    ref="captcha"
                    type="text"
                    className="form-control"
                    id="txtCompare"
                    placeholder="Captcha"
                    onKeyPress={this.handleKeyPress}
                    onClick={this.OnCopyFunction.bind(this)}
                  />
                  <br />

                  {this.state.loading ? (
                    <div style={{ textAlign: "-webkit-center" }}>
                      <div className="loader"></div>
                    </div>
                  ) : (
                    <>
                      <a
                        className="btn btn-theme btn-block"
                        onClick={this.login.bind(this)}
                        id="loginbtn"
                      >
                        <i className="fa fa-lock mr-10" />
                        SIGN IN
                      </a>
                      <div style={{ textAlign: "center" }}>
                        <a onClick={this.forgotPass.bind(this)} id="loginbtn">
                          Forgot Password
                        </a>
                      </div>
                    </>
                  )}
                </div>
              </form>
            </div>
          </div>
        </Router>
      );
    }
  };

  RofficeLogin = (storeNo, userName) => {
    var Request = {
      storeNo: storeNo,
    };

    var EncryptedRequest = Encrypt(Request);
    fetch("/ROfficeLogin", {
      method: "POST",
      body: EncryptedRequest,
    })
      .then(response => response.text())
      .then(response => {
        var DecryptedResponse = decryptData(response);
        if (DecryptedResponse.errorCode === "00") {
          this.props.history.push({
            pathname: "/Taskactive",
            state: {
              role_id: DecryptedResponse.roleId,
              format_id: DecryptedResponse.userFormat,
              usrnm: userName,
              userName: userName,
              userid: userName,
              data: DecryptedResponse.taskList,
              storeNo: storeNo,
              tickerList: DecryptedResponse.tickerList,
              Isroffice: true,
              guId: DecryptedResponse.guId,
              menuList: DecryptedResponse.menuList,
            },
          });
        }
      });
  };

  forgotPass = e => {
    if (!document.getElementById("userLogin").value) {
      confirmAlert({
        message: "Please enter User Id",
        buttons: [
          {
            label: "Ok",
          },
        ],
      });
    } else {
      confirmAlert({
        message: "Are you Sure you want to reset the Password ?",
        buttons: [
          {
            label: "Yes",
            onClick: () => this.resetPass(),
          },
          {
            label: "No",
          },
        ],
      });
    }
  };

  resetPass = () => {
    var Request = {
      userId: this.refs.usrname.value,
    };
    var EncryptedRequest = Encrypt(Request);

    fetch("/PasswordReset", {
      method: "POST",
      headers: {
        Authorization: "Basic " + basicAuth(this.refs.usrname.value),
        userId: this.refs.usrname.value,
      },
      body: EncryptedRequest,
    })
      .then(response => response.text())
      .then(response => {
        var DecryptedResponse = decryptData(response);

        if (DecryptedResponse.errorCode === "00") {
          confirmAlert({
            message:
              "New temporary password generated & sent to the enrolled mail ID",
            buttons: [
              {
                label: "Ok",
                onClick: () => {
                  this.login.bind(this);
                },
              },
            ],
          });
        } else {
          confirmAlert({
            message: DecryptedResponse.errorMsg,
            buttons: [
              {
                label: "Ok",
                onClick: () => {
                  this.login.bind(this);
                },
              },
            ],
          });
        }
      })
      .catch(error => {
        confirmAlert({
          title: "Alert !",
          message: "Password Reset Unsuccessful!",
          buttons: [
            {
              label: "Ok",
              onClick: () => {
                this.login.bind(this);
              },
            },
          ],
        });
      });
  };

  login = e => {
    let check = this.ValidCaptcha();
    this.setState({ loading: true });

    if (
      !document.getElementById("userLogin").value ||
      !document.getElementById("userPassword").value
    ) {
      this.submit();
      this.setState({ loading: false });

      return false;
    }
    if (check) {
      e.preventDefault();
      this.setState({ enteredName: this.refs.usrname });
      var Request = {
        userId: this.refs.usrname.value,
        password: this.refs.password.value,
      };
      var EncryptedRequest = Encrypt(Request);

      fetch("/UserLogin", {
        method: "POST",
        headers: {
          Authorization: "Basic " + basicAuth(this.refs.usrname.value),
          userId: this.refs.usrname.value,
        },
        body: EncryptedRequest,
      })
        .then(response => response.text())
        .then(response => {
          this.setState({ loading: false });
          var DecryptedResponse = decryptData(response);

          if (DecryptedResponse.errorCode === "00") {
            this.setState({ rescode: DecryptedResponse.errorCode });
            this.setState({ userName: DecryptedResponse.userName });
            this.setState({ formatId: DecryptedResponse.formatId });
            this.setState({ roleId: DecryptedResponse.roleId });
            this.setState({ passwordFlag: DecryptedResponse.passwordFlag });
            this.setState({ success: true });
            this.setState({ responseJson: DecryptedResponse });
            this.setState({ guId: DecryptedResponse.guId });
            this.setState({ menuList: DecryptedResponse.menuList });

            if (DecryptedResponse.passwordFlag === "1") {
              this.props.history.push({
                pathname: "/ChangepasswordUI",
                state: {
                  userid: this.refs.usrname.value,
                  passwordFlag: DecryptedResponse.passwordFlag,
                  usrnm: DecryptedResponse.userName,
                  guId: DecryptedResponse.guId,
                  menuList: DecryptedResponse.menuList,
                  roleName: DecryptedResponse.roleName,
                },
              });
            } else if (DecryptedResponse.roleId === "1") {
              this.props.history.push({
                pathname: "/CreateHo",
                state: {
                  userid: this.refs.usrname.value,
                  usrnm: DecryptedResponse.userName,
                  guId: DecryptedResponse.guId,
                  menuList: DecryptedResponse.menuList,
                  format_id: DecryptedResponse.formatId,
                  roleName: DecryptedResponse.roleName,
                  roleId: DecryptedResponse.roleId,
                },
              });
            } else if (DecryptedResponse.mainUser == "N") {
              this.props.history.push({
                pathname: "/CompletedTaskList",
                state: {
                  format_id: DecryptedResponse.formatId,
                  role_id: DecryptedResponse.roleId,
                  usrnm: DecryptedResponse.userName,
                  userName: this.userName,
                  userid: this.refs.usrname.value,
                  storeNo: DecryptedResponse.storeNo,
                  tickerList: DecryptedResponse.tickerList,
                  guId: DecryptedResponse.guId,
                  menuList: DecryptedResponse.menuList,
                  mainuser: DecryptedResponse.mainUser,
                  roleName: DecryptedResponse.roleName,
                },
              });
            } else if (DecryptedResponse.roleId === "510") {
              this.props.history.push({
                pathname: "/CompletedTaskList",
                state: {
                  format_id: DecryptedResponse.formatId,
                  role_id: DecryptedResponse.roleId,
                  usrnm: DecryptedResponse.userName,
                  userName: this.userName,
                  userid: this.refs.usrname.value,
                  storeNo: DecryptedResponse.storeNo,
                  tickerList: DecryptedResponse.tickerList,
                  guId: DecryptedResponse.guId,
                  menuList: DecryptedResponse.menuList,
                  mainuser: DecryptedResponse.mainUser,
                  roleName: DecryptedResponse.roleName,
                },
              });
            } else if (DecryptedResponse.roleId === "511") {
              this.props.history.push({
                pathname: "/Taskactive",
                state: {
                  format_id: DecryptedResponse.formatId,
                  role_id: DecryptedResponse.roleId,
                  usrnm: DecryptedResponse.userName,
                  userName: this.userName,
                  userid: this.refs.usrname.value,
                  storeNo: DecryptedResponse.storeNo,
                  tickerList: DecryptedResponse.tickerList,
                  guId: DecryptedResponse.guId,
                  menuList: DecryptedResponse.menuList,
                  roleName: DecryptedResponse.roleName,
                },
              });
            } else if (DecryptedResponse.roleId === "600") {
              this.props.history.push({
                pathname: "/IssueRaising",
                state: {
                  format_id: DecryptedResponse.formatId,
                  role_id: DecryptedResponse.roleId,
                  usrnm: DecryptedResponse.userName,
                  userName: this.userName,
                  userid: this.refs.usrname.value,
                  storeNo: DecryptedResponse.storeNo,
                  tickerList: DecryptedResponse.tickerList,
                  guId: DecryptedResponse.guId,
                  menuList: DecryptedResponse.menuList,
                  roleName: DecryptedResponse.roleName,
                },
              });
            } else if (DecryptedResponse.passwordFlag === "0") {
              this.props.history.push({
                pathname: "/Taskactive",
                state: {
                  format_id: DecryptedResponse.formatId,
                  role_id: DecryptedResponse.roleId,
                  usrnm: DecryptedResponse.userName,
                  userName: this.userName,
                  userid: this.refs.usrname.value,
                  storeNo: DecryptedResponse.storeNo,
                  tickerList: DecryptedResponse.tickerList,
                  guId: DecryptedResponse.guId,
                  menuList: DecryptedResponse.menuList,
                  roleName: DecryptedResponse.roleName,
                },
              });
            }

            // this.refs.usrname.value = "";
            // this.refs.password.value = "";
          } else {
            this.GenerateCaptcha();

            confirmAlert({
              title: "Alert !!",
              message: DecryptedResponse.errorMsg,
              buttons: [
                {
                  label: "Ok",
                  onClick: () => {
                    this.login.bind(this);
                  },
                },
              ],
            });
            this.refs.usrname.value = "";
            this.refs.password.value = "";
            this.refs.captcha.value = "";
          }
        })
        .catch(error => {
          confirmAlert({
            title: "Alert !",
            message: "User Authorisation Unsuccessful!",
            buttons: [
              {
                label: "Ok",
                onClick: () => {
                  this.login.bind(this);
                },
              },
            ],
          });
        });

      //})
    } else {
      this.setState({ loading: false });

      confirmAlert({
        message: "Enter Captcha Correctly!",
        buttons: [
          {
            label: "Ok",
          },
        ],
      });
    }
  };
}
export default withRouter(Login);
