import React, { Component } from "react";
import "jquery/dist/jquery";
import { withRouter } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert"; // Import
import "./css/react-confirm-alert.css"; // Import css
import Spinner from "react-spinner-material"; //Import Spinner
import "react-datepicker/dist/react-datepicker.css";
import { Scrollbars } from "react-custom-scrollbars";
import ReactExport from "react-data-export";
import IdleTimer from "react-idle-timer"; // For Idle
import "./css/style.css";
import "./css/style-responsive.css";
import { Encrypt, decryptData } from "./Encryption-Decrypytion";
import disableBrowserBackButton from "disable-browser-back-navigation";
import { basicAuth } from "./BasicAuth";


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class StoreList extends Component {
  state = {
    TaskDropdwn: false,
    hasError: false   
  };
  showTaskDropdwnHandler = () => {
    this.setState({
      TaskDropdwn: !this.state.TaskDropdwn
    });
  };

  componentDidMount() {
    if (window.innerWidth <= 768) {
      this.setState({ hammenu: false });
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      TaskDropdwn: false,
      hasError: false, 
      displayMenu: false,
      hasError: false   
    };
    this.state = {
      startDate: "",
      endDate: "",
      role_id: "",
      format_id: "",
      searchEDate: "",
      resptaskname: [],
      respstartdate: [],
      respenddate: [],
      resptaskdesc: [],
      data: [],
      storeList: [],
      usrnm: "",
      userid: "",
      tickerList: [],
      Search: "",
      searchSDate: "",
      searchName: "",
      tskId: "",
      tskName: "",
      loading: false,
      hammenu: true,
      displayMenu: false,
      storeListForDownload: []
    };
    this.idleTimer = null;
    this.onAction = this._onAction.bind(this);
    this.onActive = this._onActive.bind(this);
    this.onIdle = this._onIdle.bind(this);
    this.handleChangeStart = this.handleChangeStart.bind(this);
    this.handleChangeEnd = this.handleChangeEnd.bind(this);

    this.showDropdownMenu = this.showDropdownMenu.bind(this);
    this.hideDropdownMenu = this.hideDropdownMenu.bind(this);
  }

  logoutnormal = () => {
    localStorage.clear();
    this.props.history.replace("/");
    disableBrowserBackButton();
  };

  handleChangeStart(date) {
    var monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ];
    this.setState({
      searchSDate:
        date.getDate() +
        "-" +
        monthNames[date.getMonth()] +
        "-" +
        date.getFullYear(),
      startDate: date
    });
  }
  handleChangeEnd(date) {
    var monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ];
    this.setState({
      searchEDate:
        date.getDate() +
        "-" +
        monthNames[date.getMonth()] +
        "-" +
        date.getFullYear(),
      endDate: date
    });
  }
  logout = () => {
    this.setState({ loading: true });
    var Request1 = {
      userId: this.props.location.state.userid,
      guId: this.props.location.state.guId
    };
    var EncryptedRequest1 = Encrypt(Request1);
    fetch("/LogOut ", {
      method: "POST",
      headers: {
        guId: this.props.location.state.guId,
        Authorization: "Basic " + basicAuth(this.props.location.state.userid)
      },
      body: EncryptedRequest1
    })
      .then(response => response.text())
      .then(response => {
        this.setState({ loading: false });
        var DecryptedResponse = decryptData(response);
        if (DecryptedResponse.errorCode === "00") {
          this.props.history.replace("/");
          disableBrowserBackButton();
        } else {
          confirmAlert({
            message: DecryptedResponse.errorMsg,
            buttons: [
              {
                label: "Ok"
              }
            ]
          });
        }
      })
      .catch(error => {
        confirmAlert({
          title: "Alert !",
          message: "Session expired",
          buttons: [
            {
              label: "Ok",
              onClick: () => {
                this.logoutnormal();
              }
            }
          ]
        });
      });
  };

  showDropdownMenu(event) {
    event.preventDefault();
    this.setState({ displayMenu: true }, () => {
      document.addEventListener("click", this.hideDropdownMenu);
    });
  }

  hideDropdownMenu() {
    this.setState({ displayMenu: false }, () => {
      document.removeEventListener("click", this.hideDropdownMenu);
    });
  }

  onChange = e => {
    this.setState({
      Search: e.target.value
    });
  };

  onChange1 = e => {
    this.setState({ searchName: e.target.value });
  };

  showTaskdwnHandler = () => {
    this.setState({
      Taskdwn: !this.state.Taskdwn
    });
  };

  handleDateChangeRaws = e => {
    e.preventDefault();
  };
  handleDateChangeRawe = e => {
    e.preventDefault();
  };

  _onAction(e) {
  }

  _onActive(e) {
  }

  _onIdle(e) {
    localStorage.clear();
    window.location.href = "/";
    disableBrowserBackButton();
  }

  componentWillMount() {
    this.GetStoreList();
  }

  GetStoreList(e) {
    this.setState({ loading: true });

    if (
      this.props.location.state !== undefined &&
      this.props.location.state.usrnm !== ""
    ) {
      this.setState({ usrnm: this.props.location.state.usrnm });
      this.setState({ userid: this.props.location.state.userid });
      this.setState({ tskId: this.props.location.state.tskId });
      this.setState({ tskName: this.props.location.state.tskName });
      this.setState({ userid: this.props.location.state.userid });
      var Request1 = {
        formatId: this.props.location.state.format_id,
        guId: this.props.location.state.guId
      };
      var EncryptedRequest1 = Encrypt(Request1);

      fetch("/GetStoreList", {
        method: "POST",
        headers: {
          guId: this.props.location.state.guId,
          Authorization: "Basic " + basicAuth(this.props.location.state.userid)
        },
        body: EncryptedRequest1
      })
        .then(response => response.text())
        .then(response => {
          var DecryptedResponse1 = decryptData(response);

          if (DecryptedResponse1.errorCode === "00") {

            this.setState({ storeList: DecryptedResponse1.storeList });
            this.setState({
              loading: false
            });
          } else {
            this.setState({ loading: false });
            confirmAlert({
              message: DecryptedResponse1.errorMsg,
              buttons: [
                {
                  label: "Ok"
                }
              ]
            });
          }
        })
        .catch(error => {
          confirmAlert({
            title: "Alert !",
            message: "Session expired",
            buttons: [
              {
                label: "Ok",
                onClick: () => {
                  this.logoutnormal();
                }
              }
            ]
          });
        });


      var Request2 = {
        "formatId": this.props.location.state.format_id,
        "guId": this.props.location.state.guId
      };
      var EncryptedRequest2 = Encrypt(Request2);
      fetch("/GetStoreListFormat", {
        method: "POST",
        headers: {
          guId: this.props.location.state.guId,
          Authorization:
            "Basic " + basicAuth(this.props.location.state.userid)
        },
        body: EncryptedRequest2
      })
        .then(response => response.text())
        .then(response => {
          var DecryptedResponse2 = decryptData(response);
          if (DecryptedResponse2.errorCode === "00") {

            this.setState({ storeListForDownload: DecryptedResponse2.storeList });

          } else {
            confirmAlert({
              title: "Alert !",
              message: DecryptedResponse2.errorMsg,
              buttons: [
                {
                  label: "Ok"
                }
              ]
            });
          }
        })

        .catch(error => {
          confirmAlert({
            title: "Alert !",
            message: "Session expired",
            buttons: [
              {
                label: "Ok",
                onClick: () => {
                  this.logoutnormal();
                }
              }
            ]
          });
        });

    } else {
      window.location.href = "/";
    }
  }

  componentDidCatch() {
    this.setState({ hasError: true });
  }

  render = () => {
    var that = this;

    const { Search } = this.state;
    const { searchSDate } = this.state;
    const { searchEDate } = this.state;
    const { searchName } = this.state;

    if (this.state.hasError) {
      return (
        <div>
          <h2>Error occurred!! please contact administrator</h2>
        </div>
      );
    } else {
      return (
        <div>
          <section id="container">
            <header className="header black-bg">


              <div>
                <IdleTimer
                  ref={ref => {
                    this.idleTimer = ref;
                  }}
                  element={document}
                  onActive={this.onActive}
                  onIdle={this.onIdle}
                  onAction={this.onAction}
                  debounce={250}
                  timeout={1000 * 60 * 10}
                />
              </div>
              <a
                className="mob-show"
                onClick={() =>
                  this.setState({ hammenu: !this.state.hammenu })
                }
              >
                <i
                  className="fa fa-tasks hammenu"
                  style={{ "margin-top": "25px", 'padding': '0 15px' }}
                ></i>
              </a>

              <a onClick={this.TaskactiveBind.bind(this)} className="logo" style={{ 'padding': '0 10px' }}>
                <img src={require("./img/retail-logo.png")} alt="logo" />
              </a>
              <div className="markcenter">
                <p className="marquee">{this.state.tickerList}</p>
              </div>
              <ul className="nav pull-right pos-rel">
                <li className="dropdown">
                  <a
                    className="dropdown-toggle"
                    data-toggle="dropdown"
                    onClick={this.showDropdownMenu}
                  >
                    {" "}
                    <img
                      src={require("./img/user.png")}
                      className="user-img"
                    />{" "}
                    <b className="name-show">{this.props.location.state.usrnm}</b>
                    ({this.props.location.state.storeNo})

                    <b className="caret" />
                  </a>
                </li>

                {this.state.displayMenu ? (
                  <ul className="dropdown-menuuser-dd">
                    <li>
                      Role : {this.props.location.state.roleName}
                    </li>
                    <li className="divider"></li>
                    <li>
                      <a onClick={this.Changepasswordbind}>Change Password</a>
                    </li>
                    <li className="divider"></li>
                    <li>
                      <a onClick={this.logout}>Log Out</a>
                    </li>
                  </ul>
                ) : null}
              </ul>
            </header>
            <aside>
              <div id="sidebar" className="nav-collapse">
                {this.state.hammenu ? (
                  <ul className="sidebar-menu" id="nav-accordion">
                    {this.props.location.state.menuList["401"] || this.props.location.state.menuList["402"] || this.props.location.state.menuList["403"] || this.props.location.state.menuList["404"] || this.props.location.state.menuList["405"] || this.props.location.state.menuList["408"] || this.props.location.state.menuList["411"] ? (
                      <li
                        className="sub-menu mt"
                        onClick={this.showTaskDropdwnHandler.bind(this)}
                      >
                        {" "}
                        <a href="javascript:;">
                          {" "}
                          <i className="fa fa-tasks"></i> <span>Task</span>{" "}
                        </a>
                        {this.state.TaskDropdwn ? (
                          <div>
                            <ul class="sub">
                              {this.props.location.state.menuList["401"] ? (
                                <li>
                                  <a onClick={this.TaskactiveBind.bind(this)}>
                                    {this.props.location.state.menuList["401"]}
                                  </a>
                                </li>
                              ) : null}
                              {this.props.location.state.menuList["402"] ? (
                                <li>
                                  <a onClick={this.getroles.bind(this)}>
                                    {this.props.location.state.menuList["402"]}
                                  </a>
                                </li>
                              ) : null}
                              {this.props.location.state.menuList["403"] ? (
                                <li>
                                  <a
                                    onClick={this.CompletedTaskListbind.bind(
                                      this
                                    )}
                                  >
                                    {this.props.location.state.menuList["403"]}
                                  </a>
                                </li>
                              ) : null}
                              {this.props.location.state.menuList["404"] ? (
                                <li>
                                  <a onClick={this.templates.bind(this)}>
                                    {this.props.location.state.menuList["404"]}
                                  </a>
                                </li>
                              ) : null}
                              {this.props.location.state.menuList["405"] ? (
                                <li>
                                  <a onClick={this.ArchivedTasks.bind(this)}>
                                    {this.props.location.state.menuList["405"]}
                                  </a>
                                </li>
                              ) : null}
                              {this.props.location.state.menuList["408"] ? (
                                <li>
                                  <a onClick={this.StoreTasks.bind(this)}>
                                    {this.props.location.state.menuList["408"]}
                                  </a>
                                </li>
                              ) : null}
                              {this.props.location.state.menuList["411"] ? (
                                <li>
                                  <a onClick={this.toGenDashboard.bind(this)}>
                                    {this.props.location.state.menuList["411"]}
                                  </a>
                                </li>
                              ) : null}
                            </ul>
                          </div>
                        ) : null}
                      </li>
                    ) : null}
                    {this.props.location.state.menuList["406"] ? (
                      <div>
                        <li>
                          {" "}
                          <a onClick={this.Reportsbind.bind(this)}>
                            {" "}
                            <i className="fa fa-tasks"></i>{" "}
                            <span>
                              {this.props.location.state.menuList["406"]}
                            </span>{" "}
                          </a>{" "}
                        </li>
                      </div>
                    ) : null}

                    {this.props.location.state.menuList["414"] && this.props.location.state.format_id === "102" ? (
                      <div>
                        <li>
                          {" "}
                          <a onClick={this.Defectbind.bind(this)}>
                            {" "}
                            <i className="fa fa-tasks"></i>{" "}
                            <span>
                              {this.props.location.state.menuList["414"]}
                            </span>{" "}
                          </a>{" "}
                        </li>
                      </div>
                    ) : null}

                    {this.props.location.state.menuList["415"] ? (
                      <div>
                        <li>
                          {" "}
                          <a onClick={this.SmartPointBind.bind(this)}>
                            {" "}
                            <i className="fa fa-tasks"></i>{" "}
                            <span>
                              {this.props.location.state.menuList["415"]}
                            </span>{" "}
                          </a>{" "}
                        </li>
                      </div>
                    ) : null}

                    {this.props.location.state.menuList["409"] || this.props.location.state.menuList["410"] ? (
                      <div>
                        <li
                          className="sub-menu"
                          onClick={this.showIssueDropdwnHandler.bind(this)}
                        >
                          {" "}
                          <a href="javascript:;">
                            {" "}
                            <i className="fa fa-tasks"></i>{" "}
                            <span>Issue</span>{" "}
                          </a>
                          {this.state.IssueDropdwn ? (
                            <div>
                              <ul class="sub">
                                {this.props.location.state.menuList["410"] ? (
                                  <li>
                                    <a onClick={this.IssueRaising.bind(this)}>
                                      {this.props.location.state.menuList["410"]}
                                    </a>
                                  </li>
                                ) : null}
                                {this.props.location.state.menuList["409"] ? (
                                  <li>
                                    <a onClick={this.IssueDashboard.bind(this)}>
                                      {this.props.location.state.menuList["409"]}
                                    </a>
                                  </li>
                                ) : null}
                              </ul>
                            </div>
                          ) : null}
                        </li>
                      </div>
                    ) : null}

                    {this.props.location.state.menuList["407"] ? (
                      <div>
                        <li
                          className="sub-menu"
                          onClick={this.showTaskdwnHandler.bind(this)}
                        >
                          {" "}
                          <a href="javascript:;">
                            {" "}
                            <i className="fa fa-tasks"></i>{" "}
                            <span>
                              {this.props.location.state.menuList["407"]}
                            </span>{" "}
                          </a>
                          {this.state.Taskdwn ? (
                            <div>
                              <ul class="sub">
                                <li>
                                  {" "}
                                  <a onClick={this.tiker.bind(this)}>
                                    <span>Tiker Creation</span>{" "}
                                  </a>{" "}
                                </li>
                                <li>
                                  {" "}
                                  <a onClick={this.userlist.bind(this)}>
                                    <span>User List</span>{" "}
                                  </a>{" "}
                                </li>
                                {this.props.location.state.menuList["412"] ? (
                                  <li>
                                    {" "}
                                    <a onClick={this.Storecreation.bind(this)}>
                                      <span>{this.props.location.state.menuList["412"]}</span>{" "}
                                    </a>{" "}
                                  </li>
                                ) : null}
                                {this.props.location.state.menuList["413"] ? (
                                  <li>
                                    {" "}
                                    <a onClick={this.ToStoreList.bind(this)}>
                                      <span>{this.props.location.state.menuList["413"]}</span>{" "}
                                    </a>{" "}
                                  </li>
                                ) : null}
                              </ul>
                            </div>
                          ) : null}
                        </li>
                      </div>
                    ) : null}
                  </ul>
                ) : null}
              </div>
            </aside>
            <section id="main-content">
              <section className="wrapper">
                <div className="mt">
                  <h3>
                    <i className="fa fa-angle-right"></i> Store List
                    {" "}
                    <ExcelFile
                      filename="Store List"
                      element={
                        <a
                          className="pull-right btn btn-theme"
                        >
                          Download Store List
                        </a>
                      }>
                      <ExcelSheet
                        data={this.state.storeListForDownload}
                        name="Store Details"

                      >
                        <ExcelColumn label="STORE_NO" value="storeNo" />
                        <ExcelColumn label="STORE_NAME" value="storeName" />
                        <ExcelColumn label="CITY" value="city" />
                        <ExcelColumn label="STATE" value="state" />
                        <ExcelColumn label="ZONE" value="zone" />
                        <ExcelColumn label="STORE_MOBLE" value="storeMobile" />
                        <ExcelColumn label="STORE_EMAIL" value="storeEmail" />
                        <ExcelColumn label="ZONAL_ECN0" value="zonalEcno" />
                        <ExcelColumn label="REGIONAL_ECNO" value="regionalEcno" />
                        <ExcelColumn label="CLUSTER_ECNO" value="clusterEcno" />
                        <ExcelColumn label="VM_ECNO" value="vmEcno" />
                        <ExcelColumn label="COMMERCIAL_ECNO" value="commercialEcno" />
                        <ExcelColumn label="HR_ECNO" value="hrEcno" />
                        <ExcelColumn label="RM_ECNO" value="rmEcno" />
                        <ExcelColumn label="MM_ECNO" value="mmEcno" />
                        <ExcelColumn label="D&L_ECNO" value="dnlEcno" />
                        <ExcelColumn label="REGI_VM_EC_NO" value="REGI_VM_EC_NO" />
                        <ExcelColumn label="CLUS_VM_EC_NO" value="CLUS_VM_EC_NO" />
                        <ExcelColumn label="REGI_RM_EC_NO" value="REGI_RM_EC_NO" />
                        <ExcelColumn label="CLUS_RM_EC_NO" value="CLUS_RM_EC_NO" />
                        <ExcelColumn label="REGI_MM_EC_NO" value="REGI_MM_EC_NO" />
                        <ExcelColumn label="CLUS_MM_EC_NO" value="CLUS_MM_EC_NO" />
                        <ExcelColumn label="REGI_COMM_EC_NO" value="REGI_COMM_EC_NO" />
                        <ExcelColumn label="REGI_COMM_EC_NO" value="REGI_COMM_EC_NO" />
                        <ExcelColumn label="REGI_HR_EC_NO" value="REGI_HR_EC_NO" />
                        <ExcelColumn label="CLUS_HR_EC_NO" value="CLUS_HR_EC_NO" />
                        <ExcelColumn label="CLUS_DL_EC_NO" value="CLUS_DL_EC_NO" />
                        <ExcelColumn label="REGI_DL_EC_NO" value="REGI_DL_EC_NO" />
                        <ExcelColumn label="COMPLI_MANAG_EC_NO" value="COMPLI_MANAG_EC_NO" />
                        <ExcelColumn label="TRAINEE_EC_NO" value="TRAINEE_EC_NO" />
                      </ExcelSheet>
                    </ExcelFile>

                  </h3>
                  <hr />
                  <div className="row mt">
                    <div className="col-md-12">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
                          {" "}
                          <b>Store No.</b>
                          <br />
                          <input
                            type="text"
                            placeholder="Search Store No."
                            className="form-control"
                            id="taskId"
                            onChange={this.onChange}
                            autoComplete="off"
                            pattern="^[0-9]*$"
                          />
                        </div>

                        <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
                          {" "}
                          <b>Store Name</b>
                          <br />
                          <input
                            type="text"
                            placeholder="Search Store Name"
                            className="form-control"
                            id="taskname"
                            onChange={this.onChange1}
                            autoComplete="off"
                          />
                        </div>


                        <div className="col-xs-12 col-sm-1 col-md-1 col-lg-1 mt-xs-10">
                          <img
                            src={require("./img/searchicon.png")}
                            className="user-img mt-20"
                            style={{ width: "25px", height: "25px" }}
                          />
                        </div>
                        <div className="clearfix"></div>
                      </div>
                      <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12 tbwdth">
                        <div className="mt">
                          <div className="tbl-holder">
                            <div className={this.state.loading ? 'parentDisable' : ''} width="100%">
                              <div className='overlay-box'>
                                <Spinner
                                  visible={this.state.loading}
                                  spinnerColor={"rgba(0, 0, 0, 0.3)"}
                                />
                              </div>
                            </div>
                            <table className="table table-striped table-advance table-hover table-bordered tbl-task tbl-hhide">
                              <thead>
                                <tr>
                                  <th>Store No.</th>
                                  <th>Store Name</th>
                                  <th>City</th>
                                  <th>State</th>
                                </tr>
                              </thead>
                            </table>
                            <Scrollbars style={{ height: 296 }}>
                              <table className="table table-striped table-advance table-hover table-bordered tbl-task mob-tbl">
                                <tbody>
                                  {this.state.storeList.map(function (item, key) {
                                    if (
                                      (Search !== "" ||
                                        searchName !== "") &&
                                      (item.storeNo
                                        .toLowerCase()
                                        .indexOf(Search.toLowerCase()) ||
                                        item.storeName
                                          .toLowerCase()
                                          .indexOf(searchName.toLowerCase())) === -1
                                    ) {
                                      return null;
                                    }

                                    //if((searchSDate)!== "" && (item.startDate.indexOf(searchSDate))===-1 ){
                                    // return null
                                    //  }

                                    return (
                                      <tr key={key}>
                                        <td data-th="Store No.">
                                          <a
                                            href="javascript:void(0)"
                                            onClick={that.callStoreDetails.bind(
                                              that,
                                              item.storeNo,
                                              item.storeName,
                                              item.city,
                                              item.state,
                                              item.zonalEcno,
                                              item.regionalEcno,
                                              item.clusterEcno,
                                              item.storeMobile,
                                              item.storeEmail,
                                            )}
                                            className="black-text"
                                          >
                                            {" "}
                                            {item.storeNo}
                                          </a>
                                        </td>

                                        <td data-th="Store Name">
                                          {item.storeName}
                                        </td>
                                        <td data-th="City">
                                          {item.city}
                                        </td>
                                        <td data-th="State">
                                          {item.state}
                                        </td>


                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </Scrollbars>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </section>
          </section>
        </div>
      );
    }
  };

  ToStoreList = () => {
    this.props.history.push({
      pathname: "/StoreList",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        formerMasterList: this.state.formerMasterList,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  Storecreation = () => {
    this.props.history.push({
      pathname: "/Storecreation",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        formerMasterList: this.state.formerMasterList,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  showIssueDropdwnHandler = () => {
    this.setState({
      IssueDropdwn: !this.state.IssueDropdwn
    });
  };

  callStoreDetails = (storeNo,
    storeName,
    city,
    state,
    zonalEcno,
    regionalEcno,
    clusterEcno,
    storeMobile,
    storeEmail) => {
    this.props.history.push({
      pathname: "/StoreDetails",
      state: {
        store_No: storeNo,
        store_Name: storeName,
        city: city,
        state: state,
        zonalEcno: zonalEcno,
        regionalEcno: regionalEcno,
        clusterEcno: clusterEcno,
        storeMobile: storeMobile,
        storeEmail: storeEmail,
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        data: this.props.location.state.data,
        tskId: this.props.location.state.tskId,
        tskName: this.props.location.state.tskName,
        storeNo: this.props.location.state.storeNo,
        Isroffice: this.props.location.state.Isroffice,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  }

  toGenDashboard = () => {
    this.props.history.push({
      pathname: "/DashboardGeneral",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        data: this.props.location.state.data,
        tskId: this.props.location.state.tskId,
        tskName: this.props.location.state.tskName,
        storeNo: this.props.location.state.storeNo,
        Isroffice: this.props.location.state.Isroffice,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  IssueRaising = () => {
    this.props.history.push({
      pathname: "/IssueRaising",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        data: this.props.location.state.data,
        tskId: this.props.location.state.tskId,
        tskName: this.props.location.state.tskName,
        storeNo: this.props.location.state.storeNo,
        Isroffice: this.props.location.state.Isroffice,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  IssueDashboard = () => {
    this.props.history.push({
      pathname: "/IssueDashboard",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        data: this.props.location.state.data,
        tskId: this.props.location.state.tskId,
        tskName: this.props.location.state.tskName,
        storeNo: this.props.location.state.storeNo,
        Isroffice: this.props.location.state.Isroffice,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };



  templates = () => {
    this.props.history.push({
      pathname: "/Templates",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  callupdatetaskpage(tskId, tskName, tskDescr, strtDate, edDate, taskRoleId) {
    this.props.history.push({
      pathname: "/Updatetasks",
      state: {
        tskId: tskId,
        tskName: tskName,
        tskDescr: tskDescr,
        strtDate: new Date(strtDate),
        edDate: new Date(edDate),
        userid: this.props.location.state.userid,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        usrnm: this.props.location.state.usrnm,
        taskRoleId: taskRoleId,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  }
  calladdquestionpage(
    tskId,
    tskName,
    tskDescr,
    strtDate,
    edDate,
    publishStatus
  ) {
    this.props.history.push({
      pathname: "/Addquestion",
      state: {
        tskId: tskId,
        tskName: tskName,
        tskDescr: tskDescr,
        strtDate: strtDate,
        edDate: edDate,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        storeNo: this.props.location.state.storeNo,
        publishStatus: publishStatus,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  }

  getroles(e) {
    this.props.history.push({
      pathname: "/CreatetaskUI",
      state: {
        format_id: this.props.location.state.format_id,
        role_id: this.props.location.state.role_id,
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  }
  tiker = () => {
    this.props.history.push({
      pathname: "/Tiker",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  usercreation = () => {
    this.props.history.push({
      pathname: "/UserCreation",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };
  TaskactiveBind = () => {
    this.props.history.push({
      pathname: "/Taskactive",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  userlist = () => {
    this.props.history.push({
      pathname: "/UserList",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  Changepasswordbind = () => {
    this.props.history.push({
      pathname: "/ChangepasswordUI",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  SmartPointBind = () => {
    this.props.history.push({
      pathname: "/SmartPoint",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        data: this.props.location.state.data,
        tskId: this.props.location.state.tskId,
        tskName: this.props.location.state.tskName,
        storeNo: this.props.location.state.storeNo,
        Isroffice: this.props.location.state.Isroffice,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  Defectbind = () => {
    this.props.history.push({
      pathname: "/DefectRaising",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        data: this.props.location.state.data,
        tskId: this.props.location.state.tskId,
        tskName: this.props.location.state.tskName,
        storeNo: this.props.location.state.storeNo,
        Isroffice: this.props.location.state.Isroffice,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  Reportsbind = () => {
    this.props.history.push({
      pathname: "/Reports",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        data: this.state.data,
        tskId: this.state.tskId,
        tskName: this.state.tskName,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  ArchivedTasks = () => {
    this.props.history.push({
      pathname: "/ArchivedTasks",
      state: {
        data: this.state.data,
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
        Isroffice: this.props.location.state.Isroffice,
      }
    });
  };

  CompletedTaskListbind = () => {
    this.props.history.push({
      pathname: "/CompletedTaskList",
      state: {
        data: this.state.data,
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  StoreTasks = () => {
    this.props.history.push({
      pathname: "/StoreTasks",
      state: {
        data: this.state.data,
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };
}
export default withRouter(StoreList);
