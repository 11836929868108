import React, { Component } from "react";
import "jquery/dist/jquery";
import { withRouter } from "react-router-dom";
import { BrowserRouter as Router } from "react-router-dom";
import DatePicker from "react-datepicker";
import { confirmAlert } from "react-confirm-alert"; // Import
import "./css/react-confirm-alert.css"; // Import css
import Spinner from "react-spinner-material"; //Import Spinner
import "react-datepicker/dist/react-datepicker.css";
import { Scrollbars } from "react-custom-scrollbars";
import Resizer from "react-image-file-resizer";
import config from "react-global-configuration";
import IdleTimer from "react-idle-timer"; // For Idle
import "./css/style.css";
import "./css/style-responsive.css";
import { Encrypt, decryptData } from "./Encryption-Decrypytion";
import ReactExport from "react-data-export";
import disableBrowserBackButton from "disable-browser-back-navigation";
import { basicAuth } from "./BasicAuth";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const display = {
    display: "block"
};
const hide = {
    display: "none"
};

class SmartPoint extends Component {
    state = {
        TaskDropdwn: false,
        hasError: false
    };

    showTaskDropdwnHandler = () => {
        this.setState({
            TaskDropdwn: !this.state.TaskDropdwn
        });
    };
    componentWillMount() {
        if (
            this.props.location.state === undefined ||
            this.props.location.state === ""
        ) {
            window.location.href = "/";
        } else /* if (this.props.location.state.role_id === "900") */ {
            this.GetSalesOnload();
        }
    }

    constructor(props) {
        super(props);
        this.EditClick = this.EditClick.bind(this);
        this.state = {
            val1: [],
            displayMenu: false,
            hasError: false,
            toggle: false,
            disappear: false,
            appear: false,
            rlistid: [],
            rlistopt: [],
            valueid: [],
            role_id: "",
            resptaskname: [],
            respstartdate: [],
            respenddate: [],
            resptaskdesc: [],
            data: [],
            approvedList: [],
            usrnm: "",
            userid: "",
            Search: "",
            searchSDate: "",
            searchedDate: "",
            searcheddesc: "",
            tskId: "",
            tskName: "",
            loading: false,

            FileName: "",
            previewData: "",
            instructionUpload: "",
            instructiondata: [],
            StoreList: [],

            templetId: "",
            hammenu: true,
            scheduleTemplate: false,
            isMonthly: false,
            isDaily: false,
            scheduleDate: "",
            scheduleend: new Date(),
            responsetempId: "",
            responsetemptaskName: "",
            responsetaskDesc: "",
            responserecurrence: "",
            responsestart: new Date(),
            responsend: new Date(),
            responsetaskStores: [],
            showrecurrance: false,

            funcRole: [],
            funcRoleId: [],
            funcRoleDesc: [],
            finalFunctional: [],
            filterFunctional: [],
            optionSelectedFunctional: null,

            articleId: '',
            articleDesc: '',
            brand: '',
            cSloc: '',
            sSloc: '',
            quant: '',
            trNo: '',
            srImeiNo: '',
            doaNo: '',
            doaCertDate: '',
            cnNo: '',
            remark: '--Select--',
            actualProb: '',
            storeSelected: '',
            stateOfStore: '',
            toDate: '',
            fromDate: '',
            reportArr: [],
            valueTask: "--Select--",
            downloadListFlag: false,

        };
        this.state = {
            val1: [],
            role_id: "",
            startDate: new Date(),
            endDate: new Date(),
            resptaskname: [],
            respstartdate: [],
            respenddate: [],
            resptaskdesc: [],
            data: [],
            approvedList: [],
            usrnm: "",
            userid: "",
            Search: "",
            searchSDate: "",
            searchedDate: "",
            searcheddesc: "",
            tskId: "",
            tskName: "",
            loading: false,
            hasError: false,
            toggle: false,
            disappear: false,
            appear: false,
            rlistid: [],
            rlistopt: [],
            FileName: "",
            previewData: "",
            instructionUpload: "",
            instructiondata: [],
            StoreList: [],
            valueid: [],
            templetId: "",
            hammenu: true,
            scheduleTemplate: false,
            isMonthly: false,
            isDaily: false,
            scheduleDate: "",
            scheduleend: new Date(),
            responsetempId: "",
            responsetemptaskName: "",
            responsetaskDesc: "",
            responserecurrence: "",
            responsestart: new Date(),
            responsend: new Date(),
            responsetaskStores: [],
            showrecurrance: false,
            displayMenu: false,
            TaskDropdwn: false,
            taskTypeList: [],
            taskTypeDesc: [],
            taskTypeid: [],
            valueTask: "--Select--",
            funcRole: [],
            funcRoleId: [],
            funcRoleDesc: [],
            finalFunctional: [],
            filterFunctional: [],
            optionSelectedFunctional: null,
            articleId: '',
            articleDesc: '',
            brand: '',
            cSloc: '',
            sSloc: '',
            quant: '',
            trNo: '',
            srImeiNo: '',
            doaNo: '',
            doaCertDate: '',
            cnNo: '',
            remark: '--Select--',
            actualProb: '',
            storeSelected: '',
            stateOfStore: '',
            toDate: '',
            fromDate: '',
            reportArr: [],
            downloadListFlag: false,
            hasError: false
        };
        this.idleTimer = null;
        this.onAction = this._onAction.bind(this);
        this.onActive = this._onActive.bind(this);
        this.onIdle = this._onIdle.bind(this);
        this.handleChangeStart = this.handleChangeStart.bind(this);
        this.handlescheduledate = this.handlescheduledate.bind(this);
        this.handleChangeEnd = this.handleChangeEnd.bind(this);
        this.handleScheduleEnd = this.handleScheduleEnd.bind(this);
        this.showDropdownMenu = this.showDropdownMenu.bind(this);
        this.hideDropdownMenu = this.hideDropdownMenu.bind(this);
        this.handleChangeStartdatefor = this.handleChangeStartdatefor.bind(this);
        this.handleChangeEnddatefor = this.handleChangeEnddatefor.bind(this);
    }

    changeTaskType = e => {
        this.state.valueTask = e.target.value
        this.setState({ valueTask: e.target.value })

    };

    changeRemarkType = e => {
        this.state.remark = e.target.value
        this.setState({ remark: e.target.value })
    }

    logoutnormal = () => {
        localStorage.clear();
        this.props.history.replace("/");
        disableBrowserBackButton();
    };

    handleChangeStart(date) {
        // var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
        //   "July", "Aug", "Sep", "Oct", "Nov", "Dec"
        // ];
        this.setState({
            //   searchSDate: (date.getDate() + '-' + monthNames[date.getMonth()] + "-" + date.getFullYear()),
            startDate: date
        });
    }
    handleChangeEnd(date) {
        var monthNames = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec"
        ];
        this.setState({
            searchedDate:
                date.getDate() +
                "-" +
                monthNames[date.getMonth()] +
                "-" +
                date.getFullYear(),
            endDate: date
        });
    }
    logout = () => {
        if (this.props.location.state.Isroffice == true || this.props.location.state.role_id == '900') {
            this.props.history.replace("/");
            disableBrowserBackButton();

            // document.getElementById("rofficelogin").click();
        } else {
            this.setState({ loading: true });
            var Request1 = {
                userId: this.props.location.state.userid,
                guId: this.props.location.state.guId
            };
            var EncryptedRequest1 = Encrypt(Request1);
            fetch("/LogOut ", {
                method: "POST",
                headers: {
                    guId: this.props.location.state.guId,
                    Authorization: "Basic " + basicAuth(this.props.location.state.userid)
                },
                body: EncryptedRequest1
            })
                .then(response => response.text())
                .then(response => {
                    this.setState({ loading: false });
                    var DecryptedResponse = decryptData(response);
                    if (DecryptedResponse.errorCode === "00") {
                        this.props.history.replace("/");
                        disableBrowserBackButton();
                    } else {
                        confirmAlert({
                            message: DecryptedResponse.errorMsg,
                            buttons: [
                                {
                                    label: "Ok"
                                }
                            ]
                        });
                    }
                })
                .catch(error => {
                    confirmAlert({
                        title: "Alert !",
                        message: "Session expired",
                        buttons: [
                            {
                                label: "Ok",
                                onClick: () => {
                                    this.logoutnormal();
                                }
                            }
                        ]
                    });
                });
        }
    };

    showDropdownMenu(event) {
        event.preventDefault();
        this.setState({ displayMenu: true }, () => {
            document.addEventListener("click", this.hideDropdownMenu);
        });
    }

    hideDropdownMenu() {
        this.setState({ displayMenu: false }, () => {
            document.removeEventListener("click", this.hideDropdownMenu);
        });
    }

    onChange = e => {
        this.setState({
            Search: e.target.value
        });
    };

    onChange1 = e => {
        this.setState({ searcheddesc: e.target.value });
    };

    _onAction(e) {
    }

    _onActive(e) {
    }

    _onIdle(e) {
        localStorage.clear();
        window.location.href = "/";
        disableBrowserBackButton();
    }

    componentDidCatch() {
        this.setState({ hasError: true });
    }

    componentDidMount() {
        if (window.innerWidth <= 768) {
            this.setState({ hammenu: false });
        }
    }

    modalclose = () => {
        this.setState({
            toggle: false
        });
    };

    EditClick(
        orderId,
        saleValue,
        saleQuantity
    ) {
        this.setState({ toggle: !this.state.toggle });
        this.setState({
            articleId: orderId,
            cSloc: saleValue,
            quant: saleQuantity,
            scheduleDate: '',
        });
    }

    createtclose = () => {
        this.setState({
            disappear: false
        });
    };

    createmodal(taskName, taskDesc) {
        this.setState(prevState => ({
            disappear: !prevState.disappear
        }));
        this.setState({
            taskName: taskName,
            taskDesc: taskDesc
        });
    }



    boxclose = () => {
        this.setState({
            appear: false
        });
    };



    changerole = e => {
        this.setState({ val1: e.target.value });
        let flag = [];
        for (var i = 0; i < this.state.rlistopt.length; i++) {

            this.state.rlistopt[i] === e.target.value
                ? flag.push(this.state.rlistid[i])
                : this.setState({ valueid: "" });
        }
        this.setState({ valueid: flag }, () => {
        });
    };

    handleDateChangeRaw = e => {
        e.preventDefault();
    };

    tiker = () => {
        this.props.history.push({
            pathname: "/Tiker",
            state: {
                userid: this.props.location.state.userid,
                usrnm: this.props.location.state.usrnm,
                role_id: this.props.location.state.role_id,
                format_id: this.props.location.state.format_id,
                storeNo: this.props.location.state.storeNo,
                guId: this.props.location.state.guId,
                menuList: this.props.location.state.menuList,
                roleName: this.props.location.state.roleName,
            }
        });
    };
    InstructionHandler = e => {
        let files = e.target.files;

        if (
            files[0].type == "image/gif" ||
            files[0].type == "image/jpg" ||
            files[0].type == "image/jpeg" ||
            files[0].type == "image/png" ||
            files[0].type == "application/msword" ||
            files[0].type ==
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
            files[0].type ==
            "application/vnd.openxmlformats-officedocument.presentationml.presentation" ||
            files[0].type ==
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
            files[0].type == "application/vnd.ms-excel" ||
            files[0].type == "application/pdf" ||
            files[0].type == "application/vnd.ms-powerpoint" ||
            files[0].type == "text/plain"
        ) {
            if (files[0].type == "image/jpeg" || files[0].type == "image/png") {
                Resizer.imageFileResizer(
                    files[0],
                    500,
                    500,
                    "JPEG",
                    100,
                    0,
                    uri => {
                        this.setState({ previewData: uri });
                        this.setState({ instructionUpload: "File Uploaded Successfully" });
                        this.setState({ instructiondata: files });
                    },
                    "base64"
                );
            } else {
                if (files[0].size < 1572864) {
                    console.warn("Data File", files[0].size < 1572864);

                    let reader = new FileReader();

                    reader.readAsDataURL(files[0]);
                    reader.onload = e => {
                        console.warn("Data", e.target.result);
                        this.setState({ previewData: e.target.result });
                        this.setState({ instructionUpload: "File Uploaded Successfully" });
                        this.setState({ instructiondata: files });
                        console.warn("ByteArray", this.state.previewData);
                    };
                } else {
                    confirmAlert({
                        title: "Alert !",
                        message: "File size cannot be greater than 1.5 MB",
                        buttons: [
                            {
                                label: "Ok",
                                onClick: () => {
                                    this.setState({ instructionUpload: "" });
                                }
                            }
                        ]
                    });
                }
            }
        } else {
            this.setState({ instructionUpload: "" });
            confirmAlert({
                title: "Alert !",
                message: "Unsupported File Format.",
                buttons: [
                    {
                        label: "Ok",
                        onClick: () => {
                            this.setState({ instructionUpload: "" });
                        }
                    }
                ]
            });
        }
    };
    checkInterval = () => {
        if (document.getElementById("recurrence").value == "Monthly") {
            this.setState({
                isMonthly: true,
                isDaily: true
            });
        } else if (document.getElementById("recurrence").value == "Daily") {
            this.setState({
                isDaily: true,
                isMonthly: false
            });
        } else {
            this.setState({ isDaily: false, isMonthly: false });
        }
    };

    handlescheduledate = date => {
        this.setState({
            scheduleDate: date
        });
    };

    handleFromDate = date => {
        this.setState({
            fromDate: date
        });
    }

    handleToDate = date => {
        this.setState({
            toDate: date
        });
    }

    handleScheduleEnd = date => {
        this.setState({
            scheduleend: date
        });
    };

    handleschedulechange = e => {
        e.preventDefault();
    };

    handlefromchange = e => {
        e.preventDefault();
    };

    handletochange = e => {
        e.preventDefault();
    };

    handleDateraw = e => {
        e.preventDefault();
    };

    showTaskdwnHandler = () => {
        this.setState({
            Taskdwn: !this.state.Taskdwn
        });
    };

    usercreation = () => {
        this.props.history.push({
            pathname: "/UserCreation",
            state: {
                userid: this.props.location.state.userid,
                usrnm: this.props.location.state.usrnm,
                role_id: this.props.location.state.role_id,
                format_id: this.props.location.state.format_id,
                storeNo: this.props.location.state.storeNo,
                guId: this.props.location.state.guId,
                menuList: this.props.location.state.menuList,
                roleName: this.props.location.state.roleName,
            }
        });
    };

    handleChangeStartdatefor(date) {
        this.setState({
            responsestart: date
        });
    }

    handleChangeEnddatefor(date) {
        this.setState({
            responsend: date
        });
    }

    userlist = () => {
        this.props.history.push({
            pathname: "/UserList",
            state: {
                userid: this.props.location.state.userid,
                usrnm: this.props.location.state.usrnm,
                role_id: this.props.location.state.role_id,
                format_id: this.props.location.state.format_id,
                storeNo: this.props.location.state.storeNo,
                guId: this.props.location.state.guId,
                menuList: this.props.location.state.menuList,
                roleName: this.props.location.state.roleName,
            }
        });
    };

    handleArticleId = e => {
        this.setState({ articleId: e.target.value });
    };

    handleCsloc = e => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === "" || re.test(e.target.value)) {
            this.setState({ cSloc: e.target.value });
        }
    };

    handleSsloc = e => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === "" || re.test(e.target.value)) {
            this.setState({ sSloc: e.target.value });
        }
    };

    handleQty = e => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === "" || re.test(e.target.value)) {
            this.setState({ quant: e.target.value });
        }
    };

    handleApprovalChk = (key) => {

        if (this.state.data[key].clCheck === "0") {

            if (this.state.data[key].newCheck === "0") {
                this.state.data[key].newCheck = "1"
                this.state.approvedList.push(this.state.data[key].orderId)
            } else {
                this.state.data[key].newCheck = "0"
                for (var k = 0; k < this.state.approvedList.length; k++) {
                    if (this.state.approvedList[k] === this.state.data[key].orderId) {
                        this.state.approvedList.splice(k, 1);
                    }
                }
            }
            this.setState({ data: this.state.data })
        } else {
            confirmAlert({
                message: "Cannot change status of approved & submitted sales",
                buttons: [
                    {
                        label: "Ok"
                    }
                ]
            });
        }
    };

    render = () => {
        var that = this;

        var NoDays = [];
        var NoDays = [];
        for (let i = 1; i <= 30; i++) {
            NoDays.push(<option>{i}</option>);
        }

        var modal = [];
        modal.push(
            <div
                className="modal"
                role="dialog"
                style={this.state.toggle ? display : hide}
            >
                <div className="modal-dialog animate">
                    <div className="modal-content">
                        <div className="modal-header">
                            <a
                                className="close"
                                style={{ color: "#fff" }}
                                onClick={this.modalclose}
                            >
                                X
                            </a>
                            <h4 className="modal-title">Update Sale</h4>
                        </div>

                        <div className="modal-body box-scroll">
                            <div className="form-horizontal">

                                <div className="form-group">
                                    <h4 className="control-label col-sm-4">
                                        Order ID
                                    </h4>
                                    <div className="col-sm-8">
                                        <input
                                            type="text"
                                            class="form-control"
                                            id="articleId"
                                            autoComplete="off"
                                            value={this.state.articleId}
                                            maxLength="25"
                                            onChange={this.handleArticleId}
                                        ></input>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <h4 className="control-label col-sm-4">
                                        Sales Quantity
                                    </h4>
                                    <div className="col-sm-8">
                                        <input
                                            type="text"
                                            class="form-control"
                                            id="quantity"
                                            autoComplete="off"
                                            maxLength="20"
                                            value={this.state.quant}
                                            onChange={(e) => this.handleQty(e)}
                                        ></input>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <h4 className="control-label col-sm-4">
                                        Sales Value
                                    </h4>
                                    <div className="col-sm-8">
                                        <input
                                            type="text"
                                            class="form-control"
                                            id="cSloc"
                                            autoComplete="off"
                                            value={this.state.cSloc}
                                            onChange={(e) => this.handleCsloc(e)}
                                        ></input>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <h4 className="control-label col-sm-4">
                                        Sale Date
                                    </h4>
                                    <div className="col-sm-8">
                                        <DatePicker
                                            className="form-control form-control-inline input-medium default-date-picker"
                                            size="16"
                                            type="text"
                                            placeholderText="Select Date"
                                            dateFormat="dd-MM-yyyy"
                                            id="doaCertDate"
                                            selected={this.state.scheduleDate}
                                            maxDate={new Date()}
                                            onChange={this.handlescheduledate}
                                            onChangeRaw={this.handleschedulechange}
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="control-label col-sm-4">&nbsp;</div>
                                    <div className="col-sm-8">
                                        <button
                                            class="btn btn-primary min-wid-90 mt-17"
                                            onClick={this.UpdateSale.bind(this)}
                                        >
                                            Update Sale
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
        var createt = [];
        createt.push(
            <div
                className="modal"
                role="dialog"
                style={this.state.disappear ? display : hide}
            >
                <div className="modal-dialog animate">
                    <div className="modal-content">
                        <div className="modal-header">
                            <a
                                className="close"
                                style={{ color: "#fff" }}
                                onClick={this.createtclose}
                            >
                                X
                            </a>
                            <h4 className="modal-title">Sale Details</h4>
                        </div>
                        <div className="modal-body box-scroll">
                            <div className="form-horizontal">

                                <div className="form-group">
                                    <h4 className="control-label col-sm-4">
                                        Order ID
                                    </h4>
                                    <div className="col-sm-8">
                                        <input
                                            type="text"
                                            class="form-control"
                                            id="articleId"
                                            autoComplete="off"
                                            value={this.state.articleId}
                                            maxLength="25"
                                            onChange={this.handleArticleId}
                                        ></input>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <h4 className="control-label col-sm-4">
                                        Sales Quantity
                                    </h4>
                                    <div className="col-sm-8">
                                        <input
                                            type="text"
                                            class="form-control"
                                            id="quantity"
                                            autoComplete="off"
                                            maxLength="20"
                                            value={this.state.quant}
                                            onChange={this.handleQty}
                                        ></input>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <h4 className="control-label col-sm-4">
                                        Sales Value
                                    </h4>
                                    <div className="col-sm-8">
                                        <input
                                            type="text"
                                            class="form-control"
                                            id="cSloc"
                                            autoComplete="off"
                                            value={this.state.cSloc}
                                            onChange={this.handleCsloc}
                                        ></input>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <h4 className="control-label col-sm-4">
                                        Sale Date
                                    </h4>
                                    <div className="col-sm-8">
                                        <DatePicker
                                            className="form-control form-control-inline input-medium default-date-picker"
                                            size="16"
                                            type="text"
                                            placeholderText="Select Date"
                                            dateFormat="dd-MM-yyyy"
                                            id="doaCertDate"
                                            selected={this.state.scheduleDate}
                                            maxDate={new Date()}
                                            onChange={this.handlescheduledate}
                                            onChangeRaw={this.handleschedulechange}
                                        />
                                    </div>
                                </div>

                                <div className="form-group">
                                    <div className="control-label col-sm-4">&nbsp;</div>
                                    <div className="col-sm-8">
                                        <button
                                            class="btn btn-primary min-wid-90 mt-17"
                                            onClick={this.CreateSale.bind(this)}
                                        >
                                            Raise Sales
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );



        var that = this;

        const { Search } = this.state;
        const { searcheddesc } = this.state;

        if (this.state.hasError) {
            return (
                <div>
                    <h2>Error occurred!! please contact administrator</h2>
                </div>
            );
        } else {
            return (
                <Router>
                    <div>
                        <a
                            href={config.get("RofficeLoginURL")}
                            id="rofficelogin"
                            hidden
                        ></a>
                        {/* //<div className="spinner"> */}
                        {/* <Spinner  visible={this.state.loading} /> */}
                        {modal}
                        {createt}
                        {/* </div> */}
                        <section id="container">
                            <header className="header black-bg">
                                {/* <div className="sidebar-toggle-box">
      <div className="fa fa-bars tooltips" data-placement="right" data-original-title="Toggle Navigation"></div>
    </div> */}

                                <div>
                                    <IdleTimer
                                        ref={ref => {
                                            this.idleTimer = ref;
                                        }}
                                        element={document}
                                        onActive={this.onActive}
                                        onIdle={this.onIdle}
                                        onAction={this.onAction}
                                        debounce={250}
                                        timeout={1000 * 60 * 10}
                                    />
                                </div>
                                <a
                                    className="mob-show"
                                    onClick={() =>
                                        this.setState({ hammenu: !this.state.hammenu })
                                    }
                                >
                                    <i
                                        className="fa fa-tasks hammenu"
                                        style={{ "margin-top": "25px", 'padding': '0 15px' }}
                                    ></i>
                                </a>

                                <a onClick={this.TaskactiveBind.bind(this)} className="logo" style={{ 'padding': '0 10px' }}>
                                    <img src={require("./img/retail-logo.png")} alt="logo" />
                                </a>
                                <div className="markcenter">
                                    <p className="marquee">{this.state.tickerList}</p>
                                </div>
                                <ul className="nav pull-right pos-rel">
                                    <li className="dropdown">
                                        <a
                                            className="dropdown-toggle"
                                            data-toggle="dropdown"
                                            onClick={this.showDropdownMenu}
                                        >
                                            {" "}
                                            <img
                                                src={require("./img/user.png")}
                                                className="user-img"
                                            />{" "}
                                            <b className="name-show">{this.props.location.state.usrnm}</b>
                                            ({this.props.location.state.storeNo})

                                            <b className="caret" />
                                        </a>
                                    </li>

                                    {this.state.displayMenu ? (
                                        <ul className="dropdown-menuuser-dd">
                                            <li>
                                                Role : {this.props.location.state.roleName}
                                            </li>
                                            <li className="divider"></li>
                                            <li>
                                                <a onClick={this.Changepasswordbind}>Change Password</a>
                                            </li>
                                            <li className="divider"></li>
                                            <li>
                                                <a onClick={this.logout}>Log Out</a>
                                            </li>
                                        </ul>
                                    ) : null}
                                </ul>
                            </header>
                            <aside>
                                <div id="sidebar" className="nav-collapse">
                                    {this.state.hammenu ? (
                                        <ul className="sidebar-menu" id="nav-accordion">
                                            {this.props.location.state.menuList["401"] || this.props.location.state.menuList["402"] || this.props.location.state.menuList["403"] || this.props.location.state.menuList["404"] || this.props.location.state.menuList["405"] || this.props.location.state.menuList["408"] || this.props.location.state.menuList["411"] ? (
                                                <li
                                                    className="sub-menu mt"
                                                    onClick={this.showTaskDropdwnHandler.bind(this)}
                                                >
                                                    {" "}
                                                    <a href="javascript:;">
                                                        {" "}
                                                        <i className="fa fa-tasks"></i> <span>Task</span>{" "}
                                                    </a>
                                                    {this.state.TaskDropdwn ? (
                                                        <div>
                                                            <ul class="sub">
                                                                {this.props.location.state.menuList["401"] ? (
                                                                    <li>
                                                                        <a onClick={this.TaskactiveBind.bind(this)}>
                                                                            {this.props.location.state.menuList["401"]}
                                                                        </a>
                                                                    </li>
                                                                ) : null}
                                                                {this.props.location.state.menuList["402"] ? (
                                                                    <li>
                                                                        <a onClick={this.getroles.bind(this)}>
                                                                            {this.props.location.state.menuList["402"]}
                                                                        </a>
                                                                    </li>
                                                                ) : null}
                                                                {this.props.location.state.menuList["403"] ? (
                                                                    <li>
                                                                        <a
                                                                            onClick={this.CompletedTaskListbind.bind(
                                                                                this
                                                                            )}
                                                                        >
                                                                            {this.props.location.state.menuList["403"]}
                                                                        </a>
                                                                    </li>
                                                                ) : null}
                                                                {this.props.location.state.menuList["404"] ? (
                                                                    <li>
                                                                        <a onClick={this.templates.bind(this)}>
                                                                            {this.props.location.state.menuList["404"]}
                                                                        </a>
                                                                    </li>
                                                                ) : null}
                                                                {this.props.location.state.menuList["405"] ? (
                                                                    <li>
                                                                        <a onClick={this.ArchivedTasks.bind(this)}>
                                                                            {this.props.location.state.menuList["405"]}
                                                                        </a>
                                                                    </li>
                                                                ) : null}
                                                                {this.props.location.state.menuList["408"] ? (
                                                                    <li>
                                                                        <a onClick={this.StoreTasks.bind(this)}>
                                                                            {this.props.location.state.menuList["408"]}
                                                                        </a>
                                                                    </li>
                                                                ) : null}
                                                                {this.props.location.state.menuList["411"] ? (
                                                                    <li>
                                                                        <a onClick={this.toGenDashboard.bind(this)}>
                                                                            {this.props.location.state.menuList["411"]}
                                                                        </a>
                                                                    </li>
                                                                ) : null}
                                                            </ul>
                                                        </div>
                                                    ) : null}
                                                </li>
                                            ) : null}
                                            {this.props.location.state.menuList["406"] ? (
                                                <div>
                                                    <li>
                                                        {" "}
                                                        <a onClick={this.Reportsbind.bind(this)}>
                                                            {" "}
                                                            <i className="fa fa-tasks"></i>{" "}
                                                            <span>
                                                                {this.props.location.state.menuList["406"]}
                                                            </span>{" "}
                                                        </a>{" "}
                                                    </li>
                                                </div>
                                            ) : null}

                                            {this.props.location.state.menuList["414"] && this.props.location.state.format_id === "102" ? (
                                                <div>
                                                    <li>
                                                        {" "}
                                                        <a onClick={this.Defectbind.bind(this)}>
                                                            {" "}
                                                            <i className="fa fa-tasks"></i>{" "}
                                                            <span>
                                                                {this.props.location.state.menuList["414"]}
                                                            </span>{" "}
                                                        </a>{" "}
                                                    </li>
                                                </div>
                                            ) : null}

                                            {this.props.location.state.menuList["415"] ? (
                                                <div>
                                                    <li>
                                                        {" "}
                                                        <a onClick={this.SmartPointBind.bind(this)}>
                                                            {" "}
                                                            <i className="fa fa-tasks"></i>{" "}
                                                            <span>
                                                                {this.props.location.state.menuList["415"]}
                                                            </span>{" "}
                                                        </a>{" "}
                                                    </li>
                                                </div>
                                            ) : null}

                                            {this.props.location.state.menuList["409"] || this.props.location.state.menuList["410"] ? (
                                                <div>
                                                    <li
                                                        className="sub-menu"
                                                        onClick={this.showIssueDropdwnHandler.bind(this)}
                                                    >
                                                        {" "}
                                                        <a href="javascript:;">
                                                            {" "}
                                                            <i className="fa fa-tasks"></i>{" "}
                                                            <span>Issue</span>{" "}
                                                        </a>
                                                        {this.state.IssueDropdwn ? (
                                                            <div>
                                                                <ul class="sub">
                                                                    {this.props.location.state.menuList["410"] ? (
                                                                        <li>
                                                                            <a onClick={this.IssueRaising.bind(this)}>
                                                                                {this.props.location.state.menuList["410"]}
                                                                            </a>
                                                                        </li>
                                                                    ) : null}
                                                                    {this.props.location.state.menuList["409"] ? (
                                                                        <li>
                                                                            <a onClick={this.IssueDashboard.bind(this)}>
                                                                                {this.props.location.state.menuList["409"]}
                                                                            </a>
                                                                        </li>
                                                                    ) : null}
                                                                </ul>
                                                            </div>
                                                        ) : null}
                                                    </li>
                                                </div>
                                            ) : null}

                                            {this.props.location.state.menuList["407"] ? (
                                                <div>
                                                    <li
                                                        className="sub-menu"
                                                        onClick={this.showTaskdwnHandler.bind(this)}
                                                    >
                                                        {" "}
                                                        <a href="javascript:;">
                                                            {" "}
                                                            <i className="fa fa-tasks"></i>{" "}
                                                            <span>
                                                                {this.props.location.state.menuList["407"]}
                                                            </span>{" "}
                                                        </a>
                                                        {this.state.Taskdwn ? (
                                                            <div>
                                                                <ul class="sub">
                                                                    <li>
                                                                        {" "}
                                                                        <a onClick={this.tiker.bind(this)}>
                                                                            <span>Tiker Creation</span>{" "}
                                                                        </a>{" "}
                                                                    </li>
                                                                    <li>
                                                                        {" "}
                                                                        <a onClick={this.userlist.bind(this)}>
                                                                            <span>User List</span>{" "}
                                                                        </a>{" "}
                                                                    </li>
                                                                    {this.props.location.state.menuList["412"] ? (
                                                                        <li>
                                                                            {" "}
                                                                            <a onClick={this.Storecreation.bind(this)}>
                                                                                <span>{this.props.location.state.menuList["412"]}</span>{" "}
                                                                            </a>{" "}
                                                                        </li>
                                                                    ) : null}
                                                                    {this.props.location.state.menuList["413"] ? (
                                                                        <li>
                                                                            {" "}
                                                                            <a onClick={this.ToStoreList.bind(this)}>
                                                                                <span>{this.props.location.state.menuList["413"]}</span>{" "}
                                                                            </a>{" "}
                                                                        </li>
                                                                    ) : null}
                                                                </ul>
                                                            </div>
                                                        ) : null}
                                                    </li>
                                                </div>
                                            ) : null}
                                        </ul>
                                    ) : null}
                                </div>
                            </aside>

                            {this.props.location.state.role_id === "300" ?

                                <section id="main-content">
                                    <section class="wrapper">
                                        <div class="mt">
                                            <h3>
                                                <i class="fa fa-angle-right"></i> Sales List Download
                                            </h3>
                                            <hr />

                                            <div class="row mt">
                                                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                    <label>Select From Date</label>
                                                    <div class="upload-holder">
                                                        <DatePicker
                                                            className="form-control form-control-inline input-medium default-date-picker"
                                                            size="16"
                                                            type="text"
                                                            placeholderText="Select Date"
                                                            dateFormat="dd-MMM-yyyy"
                                                            id="fromDate"
                                                            selected={this.state.fromDate}
                                                            maxDate={new Date()}
                                                            onChange={this.handleFromDate}
                                                            onChangeRaw={this.handlefromchange}
                                                        />
                                                    </div>
                                                </div>


                                                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                    <label>Select To Date</label>
                                                    <div class="upload-holder">
                                                        <DatePicker
                                                            className="form-control form-control-inline input-medium default-date-picker"
                                                            size="16"
                                                            type="text"
                                                            placeholderText="Select Date"
                                                            dateFormat="dd-MMM-yyyy"
                                                            id="toDate"
                                                            selected={this.state.toDate}
                                                            maxDate={new Date()}
                                                            onChange={this.handleToDate}
                                                            onChangeRaw={this.handletochange}
                                                        />
                                                    </div>
                                                </div>

                                                {this.state.fromDate !== "" && this.state.toDate !== "" ? (
                                                    <div class="col-lg-2 col-md-2 col-sm-2 col-xs-12">

                                                        <button
                                                            class="btn btn-primary min-wid-90 mt-25"
                                                            onClick={this.GenerateList.bind(this)}
                                                        >
                                                            Generate List
                                                        </button>

                                                    </div>
                                                ) : null}



                                                <div class="col-lg-2 col-md-2 col-sm-2 col-xs-12">

                                                    <ExcelFile
                                                        filename="Smart Point List"
                                                        element={
                                                            <button disabled={!this.state.downloadListFlag} class="btn btn-primary min-wid-90 mt-25">
                                                                Download List
                                                            </button>
                                                        }>
                                                        <ExcelSheet
                                                            data={this.state.reportArr}
                                                            name="Sale Details"
                                                        >
                                                            <ExcelColumn label="Created Date" value="createdDate" />
                                                            <ExcelColumn label="Store" value="store" />
                                                            <ExcelColumn label="State" value="state" />
                                                            <ExcelColumn label="Order ID" value="orderId" />
                                                            <ExcelColumn label="Sale Date" value="saleDate" />
                                                            <ExcelColumn label="Sale Value" value="saleValue" />
                                                            <ExcelColumn label="Sale Quantity" value="saleQuantity" />
                                                            <ExcelColumn label="Approval Status" value="clCheck" />
                                                        </ExcelSheet>
                                                    </ExcelFile>

                                                </div>


                                            </div>
                                        </div>
                                    </section>
                                </section>

                                : null}
                            <section id="main-content">
                                <section style={{
                                    "margin-top": this.props.location.state.role_id === "300" ? "0px" : "60px",
                                    "display": "inline-block",
                                    "padding-left": "15px",
                                    "padding-right": "15px",
                                    "padding-bottom": "15px",
                                    "padding-top": "0px",
                                    width: "100%"
                                }}>
                                    <div className="mt">
                                        {this.props.location.state.role_id !== "900" ?
                                            <h3>
                                                <i className="fa fa-angle-right"></i> Sale List

                                            </h3>

                                            :

                                            <h3>
                                                <i className="fa fa-angle-right"></i> Smart Point
                                                <a
                                                    className="pull-right btn btn-theme"
                                                    onClick={this.createmodal.bind(this)}
                                                >
                                                    + Raise Sales
                                                </a>{" "}
                                            </h3>
                                        }
                                        <hr />
                                        <div className="row mt">
                                            <div className="col-md-12">
                                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                    <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
                                                        {" "}
                                                        <b>Order ID</b>
                                                        <br />
                                                        <input
                                                            type="text"
                                                            placeholder="Search Order ID"
                                                            className="form-control"
                                                            id="taskname"
                                                            onChange={this.onChange}
                                                            autoComplete="off"
                                                        />
                                                    </div>

                                                    <div className="col-xs-12 col-sm-1 col-md-1 col-lg-1 mt-xs-10">
                                                        <img
                                                            src={require("./img/searchicon.png")}
                                                            className="user-img mt-20"
                                                            style={{ width: "25px", height: "25px" }}
                                                        />
                                                    </div>
                                                    <div className="clearfix"></div>
                                                </div>
                                                <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12 tbwdth">
                                                    <div className="mt">
                                                        <div className="tbl-holder">
                                                            <div className={this.state.loading ? 'parentDisable' : ''} width="100%">
                                                                <div className='overlay-box'>
                                                                    <Spinner
                                                                        visible={this.state.loading}
                                                                        spinnerColor={"rgba(0, 0, 0, 0.3)"}
                                                                    />
                                                                </div>
                                                            </div>


                                                            <table className="table table-striped table-advance table-hover table-bordered tbl-task tbl-hhide">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Order ID</th>
                                                                        <th>Sale Date</th>
                                                                        <th>Quantity</th>
                                                                        <th>Sale Value</th>
                                                                        {that.props.location.state.role_id === "900" ? <th>Action</th> : <th>Store</th>}
                                                                        {that.props.location.state.role_id !== "900" ? <th>Approval</th> : null}
                                                                    </tr>
                                                                </thead>
                                                            </table>

                                                            <Scrollbars style={{ height: 296 }}>
                                                                <table className="table table-striped table-advance table-hover table-bordered tbl-task mob-tbl">
                                                                    <tbody>
                                                                        {this.state.data.map(function (item, key) {
                                                                            if (
                                                                                (Search !== "") &&
                                                                                (item.orderId
                                                                                    .toLowerCase()
                                                                                    .indexOf(Search.toLowerCase())) === -1
                                                                            ) {
                                                                                return null;
                                                                            }

                                                                            return (
                                                                                <tr key={key}>
                                                                                    <td data-th="Order ID">
                                                                                        {item.orderId}
                                                                                    </td>
                                                                                    <td data-th="Date">
                                                                                        {item.saleDate}
                                                                                    </td>
                                                                                    <td data-th="Quantity">
                                                                                        {item.saleQuantity}
                                                                                    </td>
                                                                                    <td data-th="Value">
                                                                                        {item.saleValue}
                                                                                    </td>

                                                                                    {that.props.location.state.role_id === "900" ?
                                                                                        <td data-th="Action">

                                                                                            <>
                                                                                                <a
                                                                                                    className="edit-icon"
                                                                                                    onClick={that.EditClick.bind(
                                                                                                        that,
                                                                                                        item.store,
                                                                                                        item.orderId,
                                                                                                        item.saleValue,
                                                                                                        item.saleQuantity,
                                                                                                        item.saleDate
                                                                                                    )}
                                                                                                >
                                                                                                    {" "}
                                                                                                    <i className="fa fa-pencil "></i>{" "}
                                                                                                </a>
                                                                                                <a
                                                                                                    className="delete-icon"
                                                                                                    onClick={that.deleteSale.bind(
                                                                                                        that,
                                                                                                        item.store,
                                                                                                        item.orderId,
                                                                                                        item.saleValue,
                                                                                                        item.saleQuantity,
                                                                                                        item.saleDate
                                                                                                    )}
                                                                                                >
                                                                                                    {" "}
                                                                                                    <i className="fa fa-trash-o "></i>{" "}
                                                                                                </a>
                                                                                            </>


                                                                                        </td>
                                                                                        :
                                                                                        <td data-th="Store">
                                                                                            {item.store}
                                                                                        </td>
                                                                                    }

                                                                                    {that.props.location.state.role_id === "503" ?
                                                                                        <td data-th="Approval">
                                                                                            <input
                                                                                                type="checkbox"
                                                                                                checked={item.newCheck === "1" ? true : false}
                                                                                                onClick={that.handleApprovalChk.bind(that, key)}
                                                                                            />
                                                                                        </td>
                                                                                        :
                                                                                        null}

                                                                                    {that.props.location.state.role_id !== "503" && that.props.location.state.role_id !== "900" ?
                                                                                        <td data-th="Approval">
                                                                                            {item.clCheck === "1" ? "Approved" : "Not Approved"}
                                                                                        </td>
                                                                                        :
                                                                                        null}

                                                                                </tr>
                                                                            );
                                                                        })}
                                                                    </tbody>
                                                                </table>
                                                            </Scrollbars>
                                                        </div>
                                                    </div>
                                                </div>

                                                {this.state.approvedList.length !== 0 ?
                                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                                                        <div class="text-center mt ">
                                                            <text>Please click Submit to Approve Selected Sales {""}</text>
                                                            <button
                                                                class="btn btn-primary min-wid-90 "
                                                                onClick={this.submitApproval.bind(this)}
                                                            >
                                                                Submit
                                                            </button>

                                                        </div>
                                                    </div>
                                                    : null}

                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </section>
                        </section>
                    </div>
                </Router>
            );
        }
    };

    showIssueDropdwnHandler = () => {
        this.setState({
            IssueDropdwn: !this.state.IssueDropdwn
        });
    };

    IssueRaising = () => {
        this.props.history.push({
            pathname: "/IssueRaising",
            state: {
                userid: this.props.location.state.userid,
                usrnm: this.props.location.state.usrnm,
                role_id: this.props.location.state.role_id,
                format_id: this.props.location.state.format_id,
                data: this.props.location.state.data,
                tskId: this.props.location.state.tskId,
                tskName: this.props.location.state.tskName,
                storeNo: this.props.location.state.storeNo,
                Isroffice: this.props.location.state.Isroffice,
                guId: this.props.location.state.guId,
                menuList: this.props.location.state.menuList,
                roleName: this.props.location.state.roleName,
            }
        });
    };

    IssueDashboard = () => {
        this.props.history.push({
            pathname: "/IssueDashboard",
            state: {
                userid: this.props.location.state.userid,
                usrnm: this.props.location.state.usrnm,
                role_id: this.props.location.state.role_id,
                format_id: this.props.location.state.format_id,
                data: this.props.location.state.data,
                tskId: this.props.location.state.tskId,
                tskName: this.props.location.state.tskName,
                storeNo: this.props.location.state.storeNo,
                Isroffice: this.props.location.state.Isroffice,
                guId: this.props.location.state.guId,
                menuList: this.props.location.state.menuList,
                roleName: this.props.location.state.roleName,
            }
        });
    };

    submitApproval(e) {
        confirmAlert({
            message: "Once Approved it cannot be Disapproved. Are you sure to Submit ?",
            buttons: [
                {
                    label: "Cancel"
                },
                {
                    label: "Ok",
                    onClick: () => {
                        var Request = {
                            userId: this.props.location.state.userid,
                            orderIds: this.state.approvedList,
                            guId: this.props.location.state.guId
                        };
                        var EncryptedRequest = Encrypt(Request);

                        fetch("/InsertSaleCLCheck", {
                            method: "POST",
                            headers: {
                                guId: this.props.location.state.guId,
                                Authorization: "Basic " + basicAuth(this.props.location.state.userid)
                            },
                            body: EncryptedRequest
                        })
                            .then(response => response.text())
                            .then(response => {
                                var DecryptedResponse = decryptData(response);
                                if (DecryptedResponse.errorCode === "00") {
                                    this.setState({ loading: false });
                                    confirmAlert({
                                        message: "Sale(s) Approved Successfully",
                                        buttons: [
                                            {
                                                label: "Ok",
                                                onClick: () => window.location.reload(true)
                                            }
                                        ]
                                    });
                                } else {
                                    this.setState({ loading: false });
                                    confirmAlert({
                                        message: DecryptedResponse.errorMsg,
                                        buttons: [
                                            {
                                                label: "Ok"
                                                // onClick: () =>
                                            }
                                        ]
                                    });
                                }
                            })
                            .catch(error => {
                                confirmAlert({
                                    title: "Alert !",
                                    message: "Session expired",
                                    buttons: [
                                        {
                                            label: "Ok",
                                            onClick: () => {
                                                this.logoutnormal();
                                            }
                                        }
                                    ]
                                });
                            });

                    }
                }
            ]
        });

    }


    GetSalesOnload(e) {
        this.setState({ loading: true });
        this.setState({ userid: this.props.location.state.userid });

        if (
            this.props.location.state !== undefined &&
            this.props.location.state.usrnm !== ""
        ) {
            this.setState({
                usrnm: this.props.location.state.usrnm
            });

            var Request1 = {
                storeNo: this.props.location.state.role_id === "900" ? this.props.location.state.storeNo : "",
                userId: this.props.location.state.role_id !== "900" ? this.props.location.state.userid : "",
                guId: this.props.location.state.guId
            };
            var EncryptedRequest1 = Encrypt(Request1);

            fetch("/SalePointList", {
                method: "POST",
                headers: {
                    guId: this.props.location.state.guId,
                    Authorization: "Basic " + basicAuth(this.props.location.state.userid)
                },
                body: EncryptedRequest1
            })
                .then(response => response.text())
                .then(response => {
                    var DecryptedResponse1 = decryptData(response);
                    if (DecryptedResponse1.errorCode === "00") {
                        this.setState({ data: DecryptedResponse1.salePointList });
                        for (var k = 0; k < this.state.data.length; k++) {
                            var abc = {
                                newCheck: this.state.data[k].clCheck,
                                ...this.state.data[k]
                            };
                            this.state.data[k] = abc

                        }
                        this.setState({ data: DecryptedResponse1.salePointList });
                        this.setState({
                            loading: false
                        });
                    } else {
                        this.setState({ loading: false });
                        confirmAlert({
                            message: DecryptedResponse1.errorMsg,
                            buttons: [
                                {
                                    label: "Ok"
                                }
                            ]
                        });
                    }
                })
                .catch(error => {
                    confirmAlert({
                        title: "Alert !",
                        message: "Session expired",
                        buttons: [
                            {
                                label: "Ok",
                                onClick: () => {
                                    this.logoutnormal();
                                }
                            }
                        ]
                    });
                });

        }
    }

    CreateSale = () => {

        if (this.state.articleId === '') {
            confirmAlert({
                title: "Alert !",
                message: "Please Enter Order ID",
                buttons: [
                    {
                        label: "Ok"
                    }
                ]
            });
        } else if (this.state.quant.length === 0) {
            confirmAlert({
                title: "Alert !",
                message: "Please Enter Quantity",
                buttons: [
                    {
                        label: "Ok"
                    }
                ]
            });
        } else if (this.state.cSloc.length === 0) {
            confirmAlert({
                title: "Alert !",
                message: "Please Enter Sales Value",
                buttons: [
                    {
                        label: "Ok"
                    }
                ]
            });
        } else if (this.state.scheduleDate === "") {
            confirmAlert({
                title: "Alert !",
                message: "Please Select Sale Date",
                buttons: [
                    {
                        label: "Ok"
                    }
                ]
            });
        } else {
            var Request1 = {
                "userId": this.props.location.state.storeNo,
                "guId": this.props.location.state.guId,
                "salePoint": {
                    "store": this.props.location.state.storeNo,
                    "orderId": this.state.articleId,
                    "saleValue": this.state.cSloc,
                    "saleQuantity": this.state.quant,
                    "saleDate": document.getElementById("doaCertDate").value,
                }
            };

            var EncryptedRequest1 = Encrypt(Request1);

            fetch("/InsertSalePoint", {
                method: "POST",
                headers: {
                    guId: this.props.location.state.guId,
                    Authorization: "Basic " + basicAuth(this.props.location.state.userid)
                },
                body: EncryptedRequest1
            })
                .then(response => response.text())
                .then(response => {
                    var DecryptedResponse = decryptData(response);
                    if (DecryptedResponse.errorCode === "00") {
                        confirmAlert({
                            title: "Success",
                            message: "Sale Raised successfully.  ",
                            buttons: [
                                {
                                    label: "Ok",
                                    onClick: () => {
                                        this.createtclose();
                                        window.location.reload(true);
                                    }
                                }
                            ]
                        });
                    } else {
                        confirmAlert({
                            title: "Alert !",
                            message: DecryptedResponse.errorMsg,
                            buttons: [
                                {
                                    label: "Ok"
                                }
                            ]
                        });
                    }
                })
                .catch(error => {
                    confirmAlert({
                        title: "Alert !",
                        message: "Session expired",
                        buttons: [
                            {
                                label: "Ok",
                                onClick: () => {
                                    this.logoutnormal();
                                }
                            }
                        ]
                    });
                });
        }
    }

    deleteSale = (store, orderId, saleValue, saleQuantity, saleDate) => {
        confirmAlert({
            message: "Are you sure, you want to delete this sale?",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => {
                        var Request2 = {
                            "userId": this.props.location.state.storeNo,
                            "guId": this.props.location.state.guId,
                            "salePoint": {
                                "store": store,
                                "orderId": orderId,
                                "saleValue": saleValue,
                                "saleQuantity": saleQuantity,
                                "saleDate": saleDate,
                            }
                        };
                        var EncryptedRequest2 = Encrypt(Request2);
                        fetch("/DeleteSalePoint", {
                            method: "POST",
                            headers: {
                                guId: this.props.location.state.guId,
                                Authorization:
                                    "Basic " + basicAuth(this.props.location.state.userid)
                            },
                            body: EncryptedRequest2
                        })
                            .then(response => response.text())
                            .then(response => {
                                var DecryptedResponse2 = decryptData(response);
                                if (DecryptedResponse2.errorCode === "00") {
                                    confirmAlert({
                                        message: "Sale Deleted Successfully.",
                                        buttons: [
                                            {
                                                label: "Ok",
                                                onClick: () => {
                                                    window.location.reload(true);
                                                }
                                            }
                                        ]
                                    });
                                    this.forceUpdate();
                                } else {
                                    confirmAlert({
                                        title: "Alert !",
                                        message: DecryptedResponse2.errorMsg,
                                        buttons: [
                                            {
                                                label: "Ok"
                                            }
                                        ]
                                    });
                                }
                            })

                            .catch(error => {
                                confirmAlert({
                                    title: "Alert !",
                                    message: "Session expired",
                                    buttons: [
                                        {
                                            label: "Ok",
                                            onClick: () => {
                                                this.logoutnormal();
                                            }
                                        }
                                    ]
                                });
                            });
                    }
                },
                {
                    label: "No",
                    onClick: () => {
                        return false;
                    }
                }
            ]
        });
    };

    GenerateList = async () => {
        var Request2 = {
            "userId": this.props.location.state.userid,
            "guId": this.props.location.state.guId,
            "formatId": this.props.location.state.format_id,
            "startDate": document.getElementById("fromDate").value,
            "endDate": document.getElementById("toDate").value
        };
        var EncryptedRequest2 = Encrypt(Request2);
        await fetch("/SalePointReport", {
            method: "POST",
            headers: {
                guId: this.props.location.state.guId,
                Authorization:
                    "Basic " +
                    basicAuth(this.props.location.state.userid)
            },
            body: EncryptedRequest2
        })
            .then(response => response.text())
            .then(response => {
                var DecryptedResponse2 = decryptData(response);
                if (DecryptedResponse2.errorCode === "00") {

                    this.setState({ reportArr: DecryptedResponse2.salePointList, downloadListFlag: true });

                } else {
                    confirmAlert({
                        title: "Alert !",
                        message: DecryptedResponse2.errorMsg,
                        buttons: [
                            {
                                label: "Ok"
                            }
                        ]
                    });
                }
            })

            .catch(error => {
                confirmAlert({
                    title: "Alert !",
                    message: "Session expired",
                    buttons: [
                        {
                            label: "Ok",
                            onClick: () => {
                                this.logoutnormal();
                            }
                        }
                    ]
                });
            });
    }

    UpdateSale = () => {

        if (this.state.articleId === '') {
            confirmAlert({
                title: "Alert !",
                message: "Please Enter Order ID",
                buttons: [
                    {
                        label: "Ok"
                    }
                ]
            });
        } else if (this.state.quant.length === 0) {
            confirmAlert({
                title: "Alert !",
                message: "Please Enter Quantity",
                buttons: [
                    {
                        label: "Ok"
                    }
                ]
            });
        } else if (this.state.cSloc.length === 0) {
            confirmAlert({
                title: "Alert !",
                message: "Please Enter Sales Value",
                buttons: [
                    {
                        label: "Ok"
                    }
                ]
            });
        } else if (this.state.scheduleDate === "") {
            confirmAlert({
                title: "Alert !",
                message: "Please Select Sale Date",
                buttons: [
                    {
                        label: "Ok"
                    }
                ]
            });
        } else {
            confirmAlert({
                message: "Are you sure, you want to update this Sale?",
                buttons: [
                    {
                        label: "Yes",
                        onClick: () => {
                            var Request2 = {
                                "userId": this.props.location.state.storeNo,
                                "guId": this.props.location.state.guId,
                                "salePoint": {
                                    "store": this.props.location.state.storeNo,
                                    "orderId": this.state.articleId,
                                    "saleValue": this.state.cSloc,
                                    "saleQuantity": this.state.quant,
                                    "saleDate": document.getElementById("doaCertDate").value,
                                }
                            };
                            var EncryptedRequest2 = Encrypt(Request2);
                            fetch("/UpdateSalePoint", {
                                method: "POST",
                                headers: {
                                    guId: this.props.location.state.guId,
                                    Authorization:
                                        "Basic " +
                                        basicAuth(this.props.location.state.userid)
                                },
                                body: EncryptedRequest2
                            })
                                .then(response => response.text())
                                .then(response => {
                                    var DecryptedResponse2 = decryptData(response);
                                    if (DecryptedResponse2.errorCode === "00") {
                                        confirmAlert({
                                            message: "Sale updated successfully.",
                                            buttons: [
                                                {
                                                    label: "Ok",
                                                    onClick: () => {
                                                        this.modalclose();
                                                        window.location.reload(true);
                                                    }
                                                }
                                            ]
                                        });

                                    } else {
                                        confirmAlert({
                                            title: "Alert !",
                                            message: DecryptedResponse2.errorMsg,
                                            buttons: [
                                                {
                                                    label: "Ok"
                                                }
                                            ]
                                        });
                                    }
                                })

                                .catch(error => {
                                    confirmAlert({
                                        title: "Alert !",
                                        message: "Session expired",
                                        buttons: [
                                            {
                                                label: "Ok",
                                                onClick: () => {
                                                    this.logoutnormal();
                                                }
                                            }
                                        ]
                                    });
                                });
                        }
                    },
                    {
                        label: "No",
                        onClick: () => {
                            return false;
                        }
                    }
                ]
            });

        }

    };


    ToStoreList = () => {
        this.props.history.push({
            pathname: "/StoreList",
            state: {
                userid: this.props.location.state.userid,
                usrnm: this.props.location.state.usrnm,
                role_id: this.props.location.state.role_id,
                format_id: this.props.location.state.format_id,
                formerMasterList: this.state.formerMasterList,
                storeNo: this.props.location.state.storeNo,
                guId: this.props.location.state.guId,
                menuList: this.props.location.state.menuList,
                roleName: this.props.location.state.roleName,
            }
        });
    };

    Storecreation = () => {
        this.props.history.push({
            pathname: "/Storecreation",
            state: {
                userid: this.props.location.state.userid,
                usrnm: this.props.location.state.usrnm,
                role_id: this.props.location.state.role_id,
                format_id: this.props.location.state.format_id,
                formerMasterList: this.state.formerMasterList,
                storeNo: this.props.location.state.storeNo,
                guId: this.props.location.state.guId,
                menuList: this.props.location.state.menuList,
                roleName: this.props.location.state.roleName,
            }
        });
    };

    toGenDashboard = () => {
        this.props.history.push({
            pathname: "/DashboardGeneral",
            state: {
                userid: this.props.location.state.userid,
                usrnm: this.props.location.state.usrnm,
                role_id: this.props.location.state.role_id,
                format_id: this.props.location.state.format_id,
                data: this.props.location.state.data,
                tskId: this.props.location.state.tskId,
                tskName: this.props.location.state.tskName,
                storeNo: this.props.location.state.storeNo,
                Isroffice: this.props.location.state.Isroffice,
                guId: this.props.location.state.guId,
                menuList: this.props.location.state.menuList,
                roleName: this.props.location.state.roleName,
            }
        });
    };

    callupdatetaskpage(tskId, tskName, tskDescr, strtDate, edDate) {
        this.props.history.push({
            pathname: "/Updatetasks",
            state: {
                tskId: tskId,
                tskName: tskName,
                tskDescr: tskDescr,
                strtDate: new Date(strtDate),
                edDate: new Date(edDate),
                userid: this.props.location.state.userid,
                role_id: this.props.location.state.role_id,
                format_id: this.props.location.state.format_id,
                usrnm: this.props.location.state.usrnm,
                storeNo: this.props.location.state.storeNo,
                guId: this.props.location.state.guId,
                menuList: this.props.location.state.menuList,
                roleName: this.props.location.state.roleName,
            }
        });
    }
    calladdquestionpage(
        tskId,
        tskName,
        tskDescr,
        strtDate,
        edDate,
        publishStatus
    ) {
        this.props.history.push({
            pathname: "/Addquestion",
            state: {
                tskId: tskId,
                tskName: tskName,
                tskDescr: tskDescr,
                strtDate: strtDate,
                edDate: edDate,
                role_id: this.props.location.state.role_id,
                format_id: this.props.location.state.format_id,
                userid: this.props.location.state.userid,
                usrnm: this.props.location.state.usrnm,
                storeNo: this.props.location.state.storeNo,
                publishStatus: publishStatus,
                guId: this.props.location.state.guId,
                menuList: this.props.location.state.menuList,
                roleName: this.props.location.state.roleName,
            }
        });
    }

    StoreTasks = () => {
        this.props.history.push({
            pathname: "/StoreTasks",
            state: {
                data: this.props.location.state.data,
                userid: this.props.location.state.userid,
                usrnm: this.props.location.state.usrnm,
                role_id: this.props.location.state.role_id,
                format_id: this.props.location.state.format_id,
                storeNo: this.props.location.state.storeNo,
                guId: this.props.location.state.guId,
                menuList: this.props.location.state.menuList,
                roleName: this.props.location.state.roleName,
            }
        });
    };

    getroles(e) {
        this.props.history.push({
            pathname: "/CreatetaskUI",
            state: {
                format_id: this.props.location.state.format_id,
                role_id: this.props.location.state.role_id,
                userid: this.props.location.state.userid,
                usrnm: this.props.location.state.usrnm,
                storeNo: this.props.location.state.storeNo,
                guId: this.props.location.state.guId,
                menuList: this.props.location.state.menuList,
                roleName: this.props.location.state.roleName,
            }
        });
    }

    TaskactiveBind = () => {
        this.props.history.push({
            pathname: "/Taskactive",
            state: {
                userid: this.props.location.state.userid,
                usrnm: this.props.location.state.usrnm,
                role_id: this.props.location.state.role_id,
                format_id: this.props.location.state.format_id,
                storeNo: this.props.location.state.storeNo,
                guId: this.props.location.state.guId,
                menuList: this.props.location.state.menuList,
                roleName: this.props.location.state.roleName,
            }
        });
    };

    ArchivedTasks = () => {
        this.props.history.push({
            pathname: "/ArchivedTasks",
            state: {
                data: this.props.location.state.data,
                userid: this.props.location.state.userid,
                usrnm: this.props.location.state.usrnm,
                role_id: this.props.location.state.role_id,
                format_id: this.props.location.state.format_id,
                storeNo: this.props.location.state.storeNo,
                guId: this.props.location.state.guId,
                menuList: this.props.location.state.menuList,
                roleName: this.props.location.state.roleName,
                Isroffice: this.props.location.state.Isroffice,
            }
        });
    };

    templates = () => {
        this.props.history.push({
            pathname: "/Templates",
            state: {
                userid: this.props.location.state.userid,
                usrnm: this.props.location.state.usrnm,
                role_id: this.props.location.state.role_id,
                format_id: this.props.location.state.format_id,
                storeNo: this.props.location.state.storeNo,
                guId: this.props.location.state.guId,
                menuList: this.props.location.state.menuList,
                roleName: this.props.location.state.roleName,
            }
        });
    };

    Changepasswordbind = () => {
        this.props.history.push({
            pathname: "/ChangepasswordUI",
            state: {
                userid: this.props.location.state.userid,
                usrnm: this.props.location.state.usrnm,
                role_id: this.props.location.state.role_id,
                format_id: this.props.location.state.format_id,
                storeNo: this.props.location.state.storeNo,
                guId: this.props.location.state.guId,
                menuList: this.props.location.state.menuList,
                roleName: this.props.location.state.roleName,
            }
        });
    };

    SmartPointBind = () => {
        this.props.history.push({
            pathname: "/SmartPoint",
            state: {
                userid: this.props.location.state.userid,
                usrnm: this.props.location.state.usrnm,
                role_id: this.props.location.state.role_id,
                format_id: this.props.location.state.format_id,
                data: this.props.location.state.data,
                tskId: this.props.location.state.tskId,
                tskName: this.props.location.state.tskName,
                storeNo: this.props.location.state.storeNo,
                Isroffice: this.props.location.state.Isroffice,
                guId: this.props.location.state.guId,
                menuList: this.props.location.state.menuList,
                roleName: this.props.location.state.roleName,
            }
        });
    };

    Defectbind = () => {
        this.props.history.push({
            pathname: "/DefectRaising",
            state: {
                userid: this.props.location.state.userid,
                usrnm: this.props.location.state.usrnm,
                role_id: this.props.location.state.role_id,
                format_id: this.props.location.state.format_id,
                data: this.props.location.state.data,
                tskId: this.props.location.state.tskId,
                tskName: this.props.location.state.tskName,
                storeNo: this.props.location.state.storeNo,
                Isroffice: this.props.location.state.Isroffice,
                guId: this.props.location.state.guId,
                menuList: this.props.location.state.menuList,
                roleName: this.props.location.state.roleName,
            }
        });
    };

    Reportsbind = () => {
        this.props.history.push({
            pathname: "/Reports",
            state: {
                userid: this.props.location.state.userid,
                usrnm: this.props.location.state.usrnm,
                role_id: this.props.location.state.role_id,
                format_id: this.props.location.state.format_id,
                data: this.props.location.state.data,
                tskId: this.state.tskId,
                tskName: this.state.tskName,
                storeNo: this.props.location.state.storeNo,
                guId: this.props.location.state.guId,
                menuList: this.props.location.state.menuList,
                roleName: this.props.location.state.roleName,
            }
        });
    };

    CompletedTaskListbind = () => {
        this.props.history.push({
            pathname: "/CompletedTaskList",
            state: {
                data: this.props.location.state.data,
                userid: this.props.location.state.userid,
                usrnm: this.props.location.state.usrnm,
                role_id: this.props.location.state.role_id,
                format_id: this.props.location.state.format_id,
                storeNo: this.props.location.state.storeNo,
                guId: this.props.location.state.guId,
                menuList: this.props.location.state.menuList,
                roleName: this.props.location.state.roleName,
            }
        });
    };

}
export default withRouter(SmartPoint);
