/**
 * eslint-disable eqeqeq
 *
 * @format
 */

/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import "jquery/dist/jquery";
import { withRouter } from "react-router-dom";
import disableBrowserBackButton from "disable-browser-back-navigation";
import DatePicker from "react-datepicker";
import { confirmAlert } from "react-confirm-alert"; // Import
import "./css/react-confirm-alert.css"; // Import css
import Spinner from "react-spinner-material"; //Import Spinner
import "react-datepicker/dist/react-datepicker.css";
import { Scrollbars } from "react-custom-scrollbars";
import config from "react-global-configuration";
import IdleTimer from "react-idle-timer"; // For Idle
import ProgressBar from "react-bootstrap/ProgressBar";
import "./css/style.css";
import "./css/style-responsive.css";
import { Encrypt, decryptData } from "./Encryption-Decrypytion";
import { basicAuth } from "./BasicAuth";
import { BrowserRouter as Router } from "react-router-dom";
import XLSX from "sheetjs-style";
import * as FileSaver from "file-saver";

const display = {
  display: "block",
};
const hide = {
  display: "none",
};

class taskactive extends Component {
  state = {
    TaskDropdwn: false,
    hasError: false,
  };
  showTaskDropdwnHandler = () => {
    this.setState({
      TaskDropdwn: !this.state.TaskDropdwn,
    });
  };

  showTaskdwnHandler = () => {
    this.setState({
      Taskdwn: !this.state.Taskdwn,
    });
  };

  showIssueDropdwnHandler = () => {
    this.setState({
      IssueDropdwn: !this.state.IssueDropdwn,
    });
  };

  componentDidMount() {
    if (window.innerWidth <= 768) {
      this.setState({ hammenu: false });
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      displayMenu: false,
      hasError: false,
    };
    this.state = {
      startDate: "",
      endDate: "",
      role_id: "",
      format_id: "",
      searchEDate: "",
      resptaskname: [],
      respstartdate: [],
      respenddate: [],
      resptaskdesc: [],
      data: [],
      usrnm: "",
      userid: "",
      tickerList: [],
      Search: "",
      searchSDate: "",
      searchName: "",
      tskId: "",
      tskName: "",
      loading: false,
      hasError: false,
      hammenu: true,
      menu: this.props.location.state.menuList,
      guId: "",
      menuList: "",
      displayMenu: false,
      IssueDropdwn: false,
      viewChat: false,
      storesTasks: [],
    };
    this.idleTimer = null;
    this.onAction = this._onAction.bind(this);
    this.onActive = this._onActive.bind(this);
    this.onIdle = this._onIdle.bind(this);
    this.handleChangeStart = this.handleChangeStart.bind(this);
    this.handleChangeEnd = this.handleChangeEnd.bind(this);

    this.showDropdownMenu = this.showDropdownMenu.bind(this);
    this.hideDropdownMenu = this.hideDropdownMenu.bind(this);
  }

  logoutnormal = () => {
    localStorage.clear();
    this.props.history.replace("/");
    disableBrowserBackButton();
  };

  handleChangeStart(date) {
    var monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    this.setState({
      searchSDate:
        date.getDate() +
        "-" +
        monthNames[date.getMonth()] +
        "-" +
        date.getFullYear(),
      startDate: date,
    });
  }
  handleChangeEnd(date) {
    var monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    this.setState({
      searchEDate:
        date.getDate() +
        "-" +
        monthNames[date.getMonth()] +
        "-" +
        date.getFullYear(),
      endDate: date,
    });
  }

  logout = () => {
    if (
      this.props.location.state.Isroffice == true ||
      this.props.location.state.role_id == "900"
    ) {
      this.props.history.replace("/");
      disableBrowserBackButton();

      // document.getElementById("rofficelogin").click();
    } else {
      this.setState({ loading: true });
      var Request1 = {
        userId: this.props.location.state.userid,
        guId: this.props.location.state.guId,
      };
      var EncryptedRequest1 = Encrypt(Request1);
      fetch("/LogOut ", {
        method: "POST",
        headers: {
          guId: this.props.location.state.guId,
          Authorization: "Basic " + basicAuth(this.props.location.state.userid),
        },
        body: EncryptedRequest1,
      })
        .then((response) => response.text())
        .then((response) => {
          this.setState({ loading: false });
          var DecryptedResponse = decryptData(response);
          if (DecryptedResponse.errorCode === "00") {
            this.props.history.replace("/");
            disableBrowserBackButton();
          } else {
            confirmAlert({
              message: DecryptedResponse.errorMsg,
              buttons: [
                {
                  label: "Ok",
                },
              ],
            });
          }
        })
        .catch((error) => {
          confirmAlert({
            title: "Alert !",
            message: "Session expired",
            buttons: [
              {
                label: "Ok",
                onClick: () => {
                  this.logoutnormal();
                },
              },
            ],
          });
        });
    }
  };

  showDropdownMenu(event) {
    event.preventDefault();
    this.setState({ displayMenu: true }, () => {
      document.addEventListener("click", this.hideDropdownMenu);
    });
  }

  hideDropdownMenu() {
    this.setState({ displayMenu: false }, () => {
      document.removeEventListener("click", this.hideDropdownMenu);
    });
  }

  onChange = (e) => {
    this.setState({
      Search: e.target.value,
    });
  };

  onChange1 = (e) => {
    this.setState({ searchName: e.target.value });
  };

  handleDateChangeRaws = (e) => {
    e.preventDefault();
  };
  handleDateChangeRawe = (e) => {
    e.preventDefault();
  };

  _onAction(e) {}

  _onActive(e) {}

  _onIdle(e) {
    localStorage.clear();
    window.location.href = "/";
    disableBrowserBackButton();
  }

  componentWillMount() {
    if (
      this.props.location.state === undefined ||
      this.props.location.state === ""
    ) {
      window.location.href = "/";
    } else {
      this.setState({ guId: this.props.location.state.guId });
      if (this.props.location.state.role_id === "300") {
        this.GetTasksOnload();
      } else if (this.props.location.state.role_id === "511") {
        this.GetTasksTraining();
      } else {
        this.GetTasksClusterStore();
        this.GetStoresOfCluster();
      }
    }
  }

  callpreviewquestionpage(
    tskId,
    tskName,
    tskDescr,
    answerStatus,
    strtDate,
    edDate,
    taskReadFlag,
    instReadFlag,
    instReadFlag2,
    instReadFlag3,
    taskType,
    functionalList
  ) {
    if (taskReadFlag == "0") {
      var Request1 = {
        taskId: tskId,
        storeId: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
      };
      var EncryptedRequest1 = Encrypt(Request1);
      fetch("/TaskReadFlag ", {
        method: "POST",
        headers: {
          guId: this.props.location.state.guId,
          Authorization: "Basic " + basicAuth(this.props.location.state.userid),
        },
        body: EncryptedRequest1,
      })
        .then((response) => response.text())
        .then((response) => {
          var DecryptedResponse = decryptData(response);
          if (DecryptedResponse.errorCode === "00") {
            this.props.history.push({
              pathname: "/AnsweringPage",
              state: {
                tskId: tskId,
                tskName: tskName,
                tskDescr: tskDescr,
                answerStatus: answerStatus,
                strtDate: strtDate,
                edDate: edDate,
                taskType: taskType,
                functionalList: functionalList,
                role_id: this.props.location.state.role_id,
                format_id: this.props.location.state.format_id,
                userid: this.props.location.state.userid,
                usrnm: this.props.location.state.usrnm,
                data: this.props.location.state.data,
                storeNo: this.props.location.state.storeNo,
                instReadFlag: instReadFlag,
                instReadFlag2: instReadFlag2,
                instReadFlag3: instReadFlag3,
                Isroffice: this.props.location.state.Isroffice,
                guId: this.props.location.state.guId,
                menuList: this.props.location.state.menuList,
                roleName: this.props.location.state.roleName,
              },
            });
          } else {
            this.setState({ loading: false });
            confirmAlert({
              message: DecryptedResponse.errorMsg,
              buttons: [
                {
                  label: "Ok",
                },
              ],
            });
          }
        })
        .catch((error) => {
          confirmAlert({
            title: "Alert !",
            message: "Session expired",
            buttons: [
              {
                label: "Ok",
                onClick: () => {
                  this.logoutnormal();
                },
              },
            ],
          });
        });
    } else if (this.props.location.state.role_id === "511") {
      this.props.history.push({
        pathname: "/AnsweringPageForTraining",
        state: {
          tskId: tskId,
          tskName: tskName,
          tskDescr: tskDescr,
          answerStatus: answerStatus,
          strtDate: strtDate,
          edDate: edDate,
          taskType: taskType,
          functionalList: functionalList,
          role_id: this.props.location.state.role_id,
          format_id: this.props.location.state.format_id,
          userid: this.props.location.state.userid,
          usrnm: this.props.location.state.usrnm,
          data: this.props.location.state.data,
          storeNo: this.props.location.state.storeNo,
          instReadFlag: instReadFlag,
          instReadFlag2: instReadFlag2,
          instReadFlag3: instReadFlag3,
          Isroffice: this.props.location.state.Isroffice,
          guId: this.props.location.state.guId,
          menuList: this.props.location.state.menuList,
          roleName: this.props.location.state.roleName,
        },
      });
    } else {
      this.props.history.push({
        pathname: "/AnsweringPage",
        state: {
          tskId: tskId,
          tskName: tskName,
          tskDescr: tskDescr,
          answerStatus: answerStatus,
          strtDate: strtDate,
          edDate: edDate,
          taskType: taskType,
          functionalList: functionalList,
          role_id: this.props.location.state.role_id,
          format_id: this.props.location.state.format_id,
          userid: this.props.location.state.userid,
          usrnm: this.props.location.state.usrnm,
          data: this.props.location.state.data,
          storeNo: this.props.location.state.storeNo,
          instReadFlag: instReadFlag,
          instReadFlag2: instReadFlag2,
          instReadFlag3: instReadFlag3,
          Isroffice: this.props.location.state.Isroffice,
          guId: this.props.location.state.guId,
          menuList: this.props.location.state.menuList,
          roleName: this.props.location.state.roleName,
        },
      });
    }
  }

  componentDidCatch() {
    this.setState({ hasError: true });
  }

  chatBoxclose = () => {
    this.setState({
      viewChat: false,
    });
  };

  createChatmodal() {
    console.log("inside.........");
    this.setState((prevState) => ({
      viewChat: !prevState.viewChat,
    }));
  }

  async handleExcelExport(tableData) {
    if (tableData.length === 0) {
      confirmAlert({
        message: "No Active Tasks",
        buttons: [
          {
            label: "Ok",
          },
        ],
      });
      return;
    }
    const excelData = tableData.map((row) => {
      return {
        "Task Id": row.taskId,
        "Task Name": row.taskName,
        "Task Type": row.taskType,
        "Start Date": row.startDate,
        "End Date": row.endDate,
        Percentage: row.percentage,
        Status: row.publishStatus,
      };
    });

    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(excelData, {
      header: [
        "Task Id",
        "Task Name",
        "Task Type",
        "Start Date",
        "End Date",
        "Percentage",
        "Status",
      ],
    });
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, "Tasks" + fileExtension);
  }

  render = () => {
    console.log("PROPS", this.props.location.state);

    var that = this;

    var chatBox = [];
    chatBox.push(
      <div
        className="modal"
        role="dialog"
        style={this.state.viewChat ? display : hide}
      >
        <div className="modal-dialog animate">
          <div className="modal-content">
            <div className="modal-header">
              <a
                className="close"
                style={{ color: "#fff" }}
                onClick={this.chatBoxclose}
              >
                X
              </a>
              <h4 className="modal-title">Query Chat</h4>
            </div>
            <div className="modal-body" style={{ padding: "25px" }}>
              <div className="form-horizontal">
                <div>
                  <div className="tbl-holder mt-20">
                    <table className="table table-striped table-advance table-hover table-bordered tbl-task tbl-hhide ">
                      <thead>
                        <tr>
                          <th>Task ID</th>
                          <th>Created By</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                    </table>

                    <Scrollbars style={{ height: 296 }}>
                      <table className="table table-striped table-advance table-hover table-bordered tbl-task mob-tbl ">
                        <tbody>
                          {this.state.data.map(function (item, key) {
                            if (item.taskId !== "52981") {
                              return null;
                            }

                            return (
                              <tr key={key}>
                                <td data-th="ID">
                                  {" "}
                                  <a
                                    href="javascript:void(0)"
                                    onClick={that.calladdquestionpage.bind(
                                      that,
                                      item.taskId,
                                      item.taskName,
                                      item.taskDescr,
                                      item.startDate,
                                      item.endDate,
                                      item.publishStatus,
                                      item.taskType,
                                      item.functionalList
                                    )}
                                    className="black-text"
                                  >
                                    {" "}
                                    {item.taskId}
                                  </a>
                                </td>
                                <td data-th="Created By">
                                  Service Manager(6494)
                                </td>
                                <td data-th="Status">Open</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </Scrollbars>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    const { Search } = this.state;
    const { searchSDate } = this.state;
    const { searchEDate } = this.state;
    const { searchName } = this.state;

    if (this.state.hasError) {
      return (
        <div>
          <h2>Error occurred !!! please contact administrator</h2>
        </div>
      );
    } else {
      return (
        <Router>
          <div>
            {chatBox}
            <a
              href={config.get("RofficeLoginURL")}
              id="rofficelogin"
              hidden
            ></a>

            <section id="container">
              <div>
                <IdleTimer
                  ref={(ref) => {
                    this.idleTimer = ref;
                  }}
                  element={document}
                  onActive={this.onActive}
                  onIdle={this.onIdle}
                  onAction={this.onAction}
                  debounce={250}
                  timeout={1000 * 60 * 10}
                />
              </div>
              <header className="header black-bg">
                <a
                  className="mob-show"
                  onClick={() =>
                    this.setState({ hammenu: !this.state.hammenu })
                  }
                >
                  <i
                    className="fa fa-tasks hammenu"
                    style={{ "margin-top": "25px", padding: "0 15px" }}
                  ></i>
                </a>

                <a
                  onClick={this.TaskactiveBind.bind(this)}
                  className="logo"
                  style={{ padding: "0 10px" }}
                >
                  <img src={require("./img/retail-logo.png")} alt="logo" />
                </a>
                <div className="markcenter">
                  <p className="marquee">{this.state.tickerList}</p>
                </div>
                <ul className="nav pull-right pos-rel">
                  <li className="dropdown">
                    <a
                      className="dropdown-toggle"
                      data-toggle="dropdown"
                      onClick={this.showDropdownMenu}
                    >
                      {" "}
                      <img
                        src={require("./img/user.png")}
                        className="user-img"
                      />{" "}
                      <b className="name-show">
                        {this.props.location.state.usrnm}
                      </b>
                      ({this.props.location.state.storeNo})
                      <b className="caret" />
                    </a>
                  </li>

                  {this.state.displayMenu ? (
                    <ul className="dropdown-menuuser-dd">
                      <li>Role : {this.props.location.state.roleName}</li>
                      <li className="divider"></li>
                      <li>
                        <a onClick={this.Changepasswordbind}>Change Password</a>
                      </li>
                      <li className="divider"></li>
                      <li>
                        <a onClick={this.logout}>Log Out</a>
                      </li>
                    </ul>
                  ) : null}
                </ul>
              </header>

              <aside>
                <div id="sidebar" className="nav-collapse">
                  {this.state.hammenu ? (
                    <ul className="sidebar-menu" id="nav-accordion">
                      {this.props.location.state.menuList["401"] ||
                      this.props.location.state.menuList["402"] ||
                      this.props.location.state.menuList["403"] ||
                      this.props.location.state.menuList["404"] ||
                      this.props.location.state.menuList["405"] ||
                      this.props.location.state.menuList["408"] ||
                      this.props.location.state.menuList["411"] ? (
                        <li
                          className="sub-menu mt"
                          onClick={this.showTaskDropdwnHandler.bind(this)}
                        >
                          {" "}
                          <a href="javascript:;">
                            {" "}
                            <i className="fa fa-tasks"></i> <span>Task</span>{" "}
                          </a>
                          {this.state.TaskDropdwn ? (
                            <div>
                              <ul class="sub">
                                {this.props.location.state.menuList["401"] ? (
                                  <li>
                                    <a onClick={this.TaskactiveBind.bind(this)}>
                                      {
                                        this.props.location.state.menuList[
                                          "401"
                                        ]
                                      }
                                    </a>
                                  </li>
                                ) : null}
                                {this.props.location.state.menuList["402"] ? (
                                  <li>
                                    <a onClick={this.getroles.bind(this)}>
                                      {
                                        this.props.location.state.menuList[
                                          "402"
                                        ]
                                      }
                                    </a>
                                  </li>
                                ) : null}
                                {this.props.location.state.menuList["403"] ? (
                                  <li>
                                    <a
                                      onClick={this.CompletedTaskListbind.bind(
                                        this
                                      )}
                                    >
                                      {
                                        this.props.location.state.menuList[
                                          "403"
                                        ]
                                      }
                                    </a>
                                  </li>
                                ) : null}
                                {this.props.location.state.menuList["404"] ? (
                                  <li>
                                    <a onClick={this.templates.bind(this)}>
                                      {
                                        this.props.location.state.menuList[
                                          "404"
                                        ]
                                      }
                                    </a>
                                  </li>
                                ) : null}
                                {this.props.location.state.menuList["405"] ? (
                                  <li>
                                    <a onClick={this.ArchivedTasks.bind(this)}>
                                      {
                                        this.props.location.state.menuList[
                                          "405"
                                        ]
                                      }
                                    </a>
                                  </li>
                                ) : null}
                                {this.props.location.state.menuList["408"] ? (
                                  <li>
                                    <a onClick={this.StoreTasks.bind(this)}>
                                      {
                                        this.props.location.state.menuList[
                                          "408"
                                        ]
                                      }
                                    </a>
                                  </li>
                                ) : null}
                                {this.props.location.state.menuList["411"] ? (
                                  <li>
                                    <a onClick={this.toGenDashboard.bind(this)}>
                                      {
                                        this.props.location.state.menuList[
                                          "411"
                                        ]
                                      }
                                    </a>
                                  </li>
                                ) : null}
                              </ul>
                            </div>
                          ) : null}
                        </li>
                      ) : null}
                      {this.props.location.state.menuList["406"] ? (
                        <div>
                          <li>
                            {" "}
                            <a onClick={this.Reportsbind.bind(this)}>
                              {" "}
                              <i className="fa fa-tasks"></i>{" "}
                              <span>
                                {this.props.location.state.menuList["406"]}
                              </span>{" "}
                            </a>{" "}
                          </li>
                        </div>
                      ) : null}

                      {this.props.location.state.menuList["414"] &&
                      this.props.location.state.format_id === "102" ? (
                        <div>
                          <li>
                            {" "}
                            <a onClick={this.Defectbind.bind(this)}>
                              {" "}
                              <i className="fa fa-tasks"></i>{" "}
                              <span>
                                {this.props.location.state.menuList["414"]}
                              </span>{" "}
                            </a>{" "}
                          </li>
                        </div>
                      ) : null}

                      {this.props.location.state.menuList["415"] ? (
                        <div>
                          <li>
                            {" "}
                            <a onClick={this.SmartPointBind.bind(this)}>
                              {" "}
                              <i className="fa fa-tasks"></i>{" "}
                              <span>
                                {this.props.location.state.menuList["415"]}
                              </span>{" "}
                            </a>{" "}
                          </li>
                        </div>
                      ) : null}

                      {this.props.location.state.menuList["409"] ||
                      this.props.location.state.menuList["410"] ? (
                        <div>
                          <li
                            className="sub-menu"
                            onClick={this.showIssueDropdwnHandler.bind(this)}
                          >
                            {" "}
                            <a href="javascript:;">
                              {" "}
                              <i className="fa fa-tasks"></i> <span>Issue</span>{" "}
                            </a>
                            {this.state.IssueDropdwn ? (
                              <div>
                                <ul class="sub">
                                  {this.props.location.state.menuList["410"] ? (
                                    <li>
                                      <a onClick={this.IssueRaising.bind(this)}>
                                        {
                                          this.props.location.state.menuList[
                                            "410"
                                          ]
                                        }
                                      </a>
                                    </li>
                                  ) : null}
                                  {this.props.location.state.menuList["409"] ? (
                                    <li>
                                      <a
                                        onClick={this.IssueDashboard.bind(this)}
                                      >
                                        {
                                          this.props.location.state.menuList[
                                            "409"
                                          ]
                                        }
                                      </a>
                                    </li>
                                  ) : null}
                                </ul>
                              </div>
                            ) : null}
                          </li>
                        </div>
                      ) : null}

                      {this.props.location.state.menuList["407"] ? (
                        <div>
                          <li
                            className="sub-menu"
                            onClick={this.showTaskdwnHandler.bind(this)}
                          >
                            {" "}
                            <a href="javascript:;">
                              {" "}
                              <i className="fa fa-tasks"></i>{" "}
                              <span>
                                {this.props.location.state.menuList["407"]}
                              </span>{" "}
                            </a>
                            {this.state.Taskdwn ? (
                              <div>
                                <ul class="sub">
                                  <li>
                                    {" "}
                                    <a onClick={this.tiker.bind(this)}>
                                      <span>Tiker Creation</span>{" "}
                                    </a>{" "}
                                  </li>
                                  <li>
                                    {" "}
                                    <a onClick={this.userlist.bind(this)}>
                                      <span>User List</span>{" "}
                                    </a>{" "}
                                  </li>
                                  {this.props.location.state.menuList["412"] ? (
                                    <li>
                                      {" "}
                                      <a
                                        onClick={this.Storecreation.bind(this)}
                                      >
                                        <span>
                                          {
                                            this.props.location.state.menuList[
                                              "412"
                                            ]
                                          }
                                        </span>{" "}
                                      </a>{" "}
                                    </li>
                                  ) : null}
                                  {this.props.location.state.menuList["413"] ? (
                                    <li>
                                      {" "}
                                      <a onClick={this.ToStoreList.bind(this)}>
                                        <span>
                                          {
                                            this.props.location.state.menuList[
                                              "413"
                                            ]
                                          }
                                        </span>{" "}
                                      </a>{" "}
                                    </li>
                                  ) : null}
                                </ul>
                              </div>
                            ) : null}
                          </li>
                        </div>
                      ) : null}
                    </ul>
                  ) : null}
                </div>
              </aside>

              <section id="main-content">
                <section className="wrapper">
                  <div className="mt">
                    <h3>
                      <i className="fa fa-angle-right"></i> Active Task(s)
                      {/* <a
                        style={{ margin: '5px' }}
                        className="pull-right btn btn-theme"
                        onClick={this.createChatmodal.bind(this)}
                      >
                        Check Queries
                      </a> */}
                    </h3>
                    <hr />
                    <div className="row mt">
                      <div className="col-md-12">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
                            {" "}
                            <b>Task ID</b>
                            <br />
                            <input
                              type="text"
                              placeholder="Search Task ID"
                              className="form-control"
                              id="taskId"
                              onChange={this.onChange}
                              autoComplete="off"
                            />
                          </div>

                          <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
                            {" "}
                            <b>Task Name</b>
                            <br />
                            <input
                              type="text"
                              placeholder="Search Task Name"
                              className="form-control"
                              id="taskname"
                              onChange={this.onChange1}
                              autoComplete="off"
                            />
                          </div>

                          <div className="col-xs-12 col-sm-3 col-md-2 col-lg-2 mt-xs-10 w-sm-110">
                            {" "}
                            <b>Start Date</b>
                            <br />
                            <DatePicker
                              autoComplete="off"
                              className="form-control form-control-inline input-medium default-date-picker"
                              size="16"
                              type="text"
                              placeholderText="Select Start Date "
                              dateFormat="dd-MMM-yyyy"
                              id="startDate"
                              selected={this.state.startDate}
                              onChange={this.handleChangeStart}
                              onChangeRaw={this.handleDateChangeRaws}
                            />
                          </div>
                          <div className="col-xs-12 col-sm-3 col-md-2 col-lg-2 mt-xs-10 w-sm-110">
                            <b> End Date</b>
                            <br />
                            <DatePicker
                              autoComplete="off"
                              className="form-control form-control-inline input-medium default-date-picker"
                              size="16"
                              type="text"
                              placeholderText="Select End Date"
                              dateFormat="dd-MMM-yyyy"
                              id="endDate"
                              selected={this.state.endDate}
                              //  minDate={this.state.startDate }
                              // maxDate={addDays(new Date(),365)}
                              onChange={this.handleChangeEnd}
                              onChangeRaw={this.handleDateChangeRawe}
                            />
                          </div>
                          <div
                            className="col-xs-12 col-sm-1 col-md-1 col-lg-1 mt-xs-10"
                            style={{ width: "4%" }}
                          >
                            <img
                              src={require("./img/searchicon.png")}
                              className="user-img mt-20"
                              style={{ width: "25px", height: "25px" }}
                            />
                          </div>
                          <div className="col-xs-12 col-sm-1 col-md-1 col-lg-1 mt-xs-10 mt-20">
                            {this.props.location.state.role_id === "300" ? (
                              <button
                                class="btn btn-theme"
                                onClick={() =>
                                  this.handleExcelExport(this.state.data)
                                }
                              >
                                Download
                              </button>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="clearfix"></div>
                        </div>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12 tbwdth">
                          <div className="mt">
                            <div className="tbl-holder">
                              <div
                                className={
                                  this.state.loading ? "parentDisable" : ""
                                }
                                width="100%"
                              >
                                <div className="overlay-box">
                                  <Spinner
                                    visible={this.state.loading}
                                    spinnerColor={"rgba(0, 0, 0, 0.3)"}
                                  />
                                </div>
                              </div>
                              {this.props.location.state.role_id === "300" ? (
                                <div>
                                  <table className="table table-striped table-advance table-hover table-bordered tbl-task tbl-hhide">
                                    <thead>
                                      <tr>
                                        <th>Task ID</th>
                                        <th>Task Name</th>
                                        <th>Task Type</th>
                                        <th>Start Date</th>
                                        <th>End Date</th>
                                        <th>% Completed</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                      </tr>
                                    </thead>
                                  </table>

                                  <Scrollbars style={{ height: 296 }}>
                                    <table className="table table-striped table-advance table-hover table-bordered tbl-task mob-tbl">
                                      <tbody>
                                        {this.state.data.map(function (
                                          item,
                                          key
                                        ) {
                                          if (
                                            (Search !== "" ||
                                              searchSDate !== "" ||
                                              searchEDate !== "" ||
                                              searchName !== "") &&
                                            (item.taskId
                                              .toLowerCase()
                                              .indexOf(Search.toLowerCase()) ||
                                              item.taskName
                                                .toLowerCase()
                                                .indexOf(
                                                  searchName.toLowerCase()
                                                ) ||
                                              item.startDate
                                                .toLowerCase()
                                                .indexOf(
                                                  searchSDate.toLowerCase()
                                                ) ||
                                              item.endDate
                                                .toLowerCase()
                                                .indexOf(
                                                  searchEDate.toLowerCase()
                                                )) === -1
                                          ) {
                                            return null;
                                          }

                                          //if((searchSDate)!== "" && (item.startDate.indexOf(searchSDate))===-1 ){
                                          // return null
                                          //  }

                                          return (
                                            <tr key={key}>
                                              <td data-th="Task ID">
                                                <a
                                                  href="javascript:void(0)"
                                                  onClick={that.calladdquestionpage.bind(
                                                    that,
                                                    item.taskId,
                                                    item.taskName,
                                                    item.taskDescr,
                                                    item.startDate,
                                                    item.endDate,
                                                    item.publishStatus,
                                                    item.taskType,
                                                    item.functionalList
                                                  )}
                                                  className="black-text"
                                                >
                                                  {" "}
                                                  {item.taskId}
                                                </a>
                                              </td>

                                              <td data-th="Task Name">
                                                {item.taskName}
                                              </td>
                                              <td data-th="Task Name">
                                                {item.taskType}
                                              </td>
                                              <td data-th="Start Date">
                                                {item.startDate}
                                              </td>
                                              <td data-th="End Date">
                                                {item.endDate}
                                              </td>
                                              <td data-th="Percentage">
                                                {" "}
                                                <ProgressBar
                                                  now={item.percentage}
                                                  label={`${item.percentage}%`}
                                                />
                                              </td>
                                              <td data-th="Status">
                                                {item.publishStatus}
                                              </td>
                                              <td data-th="Action">
                                                <a
                                                  className="edit-icon"
                                                  onClick={that.callupdatetaskpage.bind(
                                                    that,
                                                    item.taskId,
                                                    item.taskName,
                                                    item.taskDescr,
                                                    item.startDate,
                                                    item.endDate,
                                                    item.taskRoleId,
                                                    item.taskType,
                                                    item.functionalList,
                                                    item.taskFile,
                                                    item.taskFile1,
                                                    item.taskFile2
                                                  )}
                                                >
                                                  {" "}
                                                  <i className="fa fa-pencil "></i>{" "}
                                                </a>
                                                <a
                                                  className="delete-icon"
                                                  onClick={that.deletetask.bind(
                                                    that,
                                                    item.taskId
                                                  )}
                                                >
                                                  {" "}
                                                  <i className="fa fa-trash-o "></i>{" "}
                                                </a>

                                                <span className="d-none">
                                                  {item.taskId}
                                                </span>
                                              </td>
                                            </tr>
                                          );
                                        })}
                                      </tbody>
                                    </table>
                                  </Scrollbars>
                                </div>
                              ) : (
                                <div className="tbl-holder">
                                  <div
                                    className={
                                      this.state.loading ? "parentDisable" : ""
                                    }
                                    width="100%"
                                  >
                                    <div className="overlay-box">
                                      <Spinner
                                        visible={this.state.loading}
                                        spinnerColor={"rgba(0, 0, 0, 0.3)"}
                                      />
                                    </div>
                                  </div>

                                  <table className="table table-striped table-advance table-hover table-bordered tbl-task tbl-hhide">
                                    <thead>
                                      <tr>
                                        <th>Task ID</th>
                                        <th>Task Name</th>
                                        <th>Task Type</th>
                                        <th>Start Date</th>
                                        <th>End Date</th>
                                        <th>Created By</th>
                                        <th>Status</th>
                                      </tr>
                                    </thead>
                                  </table>
                                  <Scrollbars style={{ height: 296 }}>
                                    <table className="table table-striped table-advance table-hover table-bordered tbl-task">
                                      <tbody>
                                        {this.state.data.map(function (
                                          item,
                                          key
                                        ) {
                                          if (
                                            (Search !== "" ||
                                              searchSDate !== "" ||
                                              searchEDate !== "" ||
                                              searchName !== "All") &&
                                            (item.taskId
                                              .toLowerCase()
                                              .indexOf(Search.toLowerCase()) ||
                                              item.taskName
                                                .toLowerCase()
                                                .indexOf(
                                                  searchName.toLowerCase()
                                                ) ||
                                              item.startDate
                                                .toLowerCase()
                                                .indexOf(
                                                  searchSDate.toLowerCase()
                                                ) ||
                                              item.endDate
                                                .toLowerCase()
                                                .indexOf(
                                                  searchEDate.toLowerCase()
                                                )) === -1
                                          ) {
                                            return null;
                                          }
                                          return (
                                            <tr key={key}>
                                              <td data-th="Task ID">
                                                <a
                                                  onClick={that.callpreviewquestionpage.bind(
                                                    that,
                                                    item.taskId,
                                                    item.taskName,
                                                    item.taskDescr,
                                                    item.answerStatus,
                                                    item.startDate,
                                                    item.endDate,
                                                    item.taskReadFlag,
                                                    item.instReadFlag,
                                                    item.instReadFlag2,
                                                    item.instReadFlag3,
                                                    item.taskType,
                                                    item.functionalList
                                                  )}
                                                  className="black-text"
                                                  style={{
                                                    cursor: "pointer",
                                                  }}
                                                >
                                                  {" "}
                                                  {item.taskId}
                                                </a>
                                              </td>

                                              <td data-th="Task Name">
                                                {item.taskName}
                                              </td>
                                              <td data-th="Task Name">
                                                {item.taskType}
                                              </td>
                                              <td data-th="Start Date">
                                                {" "}
                                                {item.startDate}
                                              </td>
                                              <td data-th="End Date">
                                                {item.endDate}
                                              </td>
                                              <td data-th="Created By">
                                                {" "}
                                                {item.createdByName}
                                              </td>
                                              <td data-th="Status">
                                                {item.answerStatus}
                                              </td>
                                              <span className="d-none">
                                                {item.taskId}
                                              </span>
                                            </tr>
                                          );
                                        })}
                                      </tbody>
                                    </table>
                                  </Scrollbars>
                                </div>
                              )}
                            </div>

                            {(this.props.location.state.format_id === "101" ||
                              this.props.location.state.format_id === "102") &&
                            this.props.location.state.role_id === "503" ? (
                              <div style={{ marginTop: "50px" }}>
                                <h3 style={{ marginLeft: "-15px" }}>
                                  <i className="fa fa-angle-right"></i> My Store
                                  Task(s)
                                </h3>
                                <hr />
                                <div className="tbl-holder">
                                  <table className="table table-striped table-advance table-hover table-bordered tbl-task tbl-hhide">
                                    <thead>
                                      <tr>
                                        <th>Task ID</th>
                                        <th className="store-length">Store</th>
                                        <th className="task-name-length">
                                          Task Name
                                        </th>
                                        <th className="store-length">
                                          Task Type
                                        </th>
                                        <th className="date-length">
                                          Start Date
                                        </th>
                                        <th className="date-length">
                                          End Date
                                        </th>
                                        <th className="date-length">
                                          Created By
                                        </th>
                                        <th>Status</th>
                                      </tr>
                                    </thead>
                                  </table>
                                  <Scrollbars style={{ height: 296 }}>
                                    <table className="table table-striped table-advance table-hover table-bordered tbl-task">
                                      <tbody>
                                        {this.state.storesTasks.map(function (
                                          item,
                                          key
                                        ) {
                                          return (
                                            <tr key={key}>
                                              <td>{item.taskId}</td>
                                              <td className="store-length">
                                                {item.storeNo}
                                              </td>
                                              <td className="task-name-length">
                                                {item.taskName}
                                              </td>
                                              <td className="store-length">
                                                {item.taskType}
                                              </td>
                                              <td className="date-length">
                                                {item.startDate}
                                              </td>
                                              <td className="date-length">
                                                {item.endDate}
                                              </td>
                                              <td className="date-length">
                                                {item.createdByName}
                                              </td>
                                              <td>{item.answerStatus}</td>
                                            </tr>
                                          );
                                        })}
                                      </tbody>
                                    </table>
                                  </Scrollbars>
                                </div>
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </section>
            </section>
          </div>
        </Router>
      );
    }
  };

  Storecreation = () => {
    this.props.history.push({
      pathname: "/Storecreation",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        formerMasterList: this.state.formerMasterList,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      },
    });
  };

  ToStoreList = () => {
    this.props.history.push({
      pathname: "/StoreList",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        formerMasterList: this.state.formerMasterList,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      },
    });
  };

  GetTasksOnload(e) {
    this.setState({ loading: true });
    if (
      this.props.location.state !== undefined &&
      this.props.location.state.usrnm !== ""
    ) {
      this.setState({ usrnm: this.props.location.state.usrnm });
      this.setState({ userid: this.props.location.state.userid });
      this.setState({ tskId: this.props.location.state.tskId });
      this.setState({ tskName: this.props.location.state.tskName });
      this.setState({ guId: this.props.location.state.guId });
      var Request1 = {
        createdBy: this.props.location.state.userid,
        guId: this.props.location.state.guId,
      };
      var EncryptedRequest1 = Encrypt(Request1);

      fetch("/SearchTask", {
        method: "POST",
        headers: {
          guId: this.props.location.state.guId,
          Authorization: "Basic " + basicAuth(this.props.location.state.userid),
        },
        body: EncryptedRequest1,
      })
        .then((response) => response.text())
        .then((response) => {
          var DecryptedResponse1 = decryptData(response);

          if (DecryptedResponse1.errorCode === "00") {
            this.setState({ data: DecryptedResponse1.taskList });
            this.setState({
              loading: false,
            });
          } else {
            this.setState({ loading: false });
            confirmAlert({
              message: DecryptedResponse1.errorMsg,
              buttons: [
                {
                  label: "Ok",
                },
              ],
            });
          }
        })
        .catch((error) => {
          confirmAlert({
            title: "Alert !",
            message: "Session expired",
            buttons: [
              {
                label: "Ok",
                onClick: () => {
                  this.logoutnormal();
                },
              },
            ],
          });
        });
    } else {
      window.location.href = "/";
    }
  }

  GetTasksTraining(e) {
    this.setState({ guId: this.props.location.state.guId });
    if (this.props.location.state.tickerList !== null) {
      var tickerdata = [];
      if (this.props.location.state.tickerList !== undefined) {
        for (let t = 0; t < this.props.location.state.tickerList.length; t++) {
          tickerdata.push(
            this.props.location.state.tickerList[t].tickerText + " || "
          );
        }
      }
    }
    if (this.props.location.state.usrnm !== "") {
      var Request = {
        userId: this.props.location.state.userid,
        roleId: this.props.location.state.role_id,
        guId: this.props.location.state.guId,
      };

      var EncryptedRequest = Encrypt(Request);
      fetch("/GetTrainingTaskList", {
        method: "POST",
        headers: {
          guId: this.props.location.state.guId,
          Authorization: "Basic " + basicAuth(this.props.location.state.userid),
        },
        body: EncryptedRequest,
      })
        .then((response) => response.text())
        .then((response) => {
          var DecryptedResponse = decryptData(response);
          if (DecryptedResponse.errorCode === "00") {
            this.setState({ loading: true });
            this.setState({ data: DecryptedResponse.taskList });
            this.setState({ usrnm: this.props.location.state.usrnm });
            this.setState({ loading: false });
            this.setState({ storeNo: this.props.location.state.storeNo });
            this.setState({ userid: this.props.location.state.userid });
            this.setState({ tickerList: tickerdata });
          }
        })
        .catch((error) => {
          confirmAlert({
            title: "Alert !",
            message: "Session expired",
            buttons: [
              {
                label: "Ok",
                onClick: () => {
                  this.logoutnormal();
                },
              },
            ],
          });
        });
    } else {
      window.location.href = "/";
    }
  }

  GetTasksClusterStore(e) {
    this.setState({ guId: this.props.location.state.guId });
    if (this.props.location.state.tickerList !== null) {
      var tickerdata = [];
      if (this.props.location.state.tickerList !== undefined) {
        for (let t = 0; t < this.props.location.state.tickerList.length; t++) {
          tickerdata.push(
            this.props.location.state.tickerList[t].tickerText + " || "
          );
        }
      }
    }
    if (this.props.location.state.usrnm !== "") {
      if (
        this.props.location.state.role_id === "900" ||
        this.props.location.state.role_id === "520"
      ) {
        var Request = {
          storeNo: this.props.location.state.storeNo,
          roleId: this.props.location.state.role_id,
          guId: this.props.location.state.guId,
        };
      } else {
        var Request = {
          userId: this.props.location.state.userid,
          roleId: this.props.location.state.role_id,
          guId: this.props.location.state.guId,
        };
      }

      var EncryptedRequest = Encrypt(Request);
      fetch("/StoreUserTaskList", {
        method: "POST",
        headers: {
          guId: this.props.location.state.guId,
          Authorization: "Basic " + basicAuth(this.props.location.state.userid),
        },
        body: EncryptedRequest,
      })
        .then((response) => response.text())
        .then((response) => {
          var DecryptedResponse = decryptData(response);
          if (DecryptedResponse.errorCode === "00") {
            this.setState({ loading: true });
            this.setState({ data: DecryptedResponse.taskList });
            this.setState({ usrnm: this.props.location.state.usrnm });
            this.setState({ loading: false });
            this.setState({ storeNo: this.props.location.state.storeNo });
            this.setState({ userid: this.props.location.state.userid });
            this.setState({ tickerList: tickerdata });
          }
        })
        .catch((error) => {
          confirmAlert({
            title: "Alert !",
            message: "Session expired",
            buttons: [
              {
                label: "Ok",
                onClick: () => {
                  this.logoutnormal();
                },
              },
            ],
          });
        });
    } else {
      window.location.href = "/";
    }
  }

  async GetStoresOfCluster(e) {
    if (
      (this.props.location.state.format_id === "101" ||
        this.props.location.state.format_id === "102") &&
      this.props.location.state.role_id === "503"
    ) {
      var request = {
        userId: this.props.location.state.userid,
        guId: this.props.location.state.guId,
        roleId: "900",
      };
      var EncryptedRequest = Encrypt(request);

      await fetch("/ManageStoreTask", {
        method: "POST",
        headers: {
          guId: this.props.location.state.guId,
          Authorization: "Basic " + basicAuth(this.props.location.state.userid),
        },
        body: EncryptedRequest,
      })
        .then((response) => response.text())
        .then((response) => {
          var DecryptedResponse = decryptData(response);
          if (DecryptedResponse.errorCode === "00") {
            this.setState({ loading: false });
            this.setState({ storesTasks: DecryptedResponse.taskLists });
            console.log("Stores of Cluster", this.state.storesTasks);
          }
        })
        .catch((error) => {
          confirmAlert({
            title: "Alert !",
            message: "Session expired",
            buttons: [
              {
                label: "Ok",
                onClick: () => {
                  this.logoutnormal();
                },
              },
            ],
          });
        });
    }
  }

  deletetask(tskid) {
    confirmAlert({
      message: "Are you sure, you want to delete this task?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            var Request2 = {
              taskId: tskid,
              createdBy: this.props.location.state.userid,
              action: "Delete",
              guId: this.props.location.state.guId,
            };
            var EncryptedRequest2 = Encrypt(Request2);
            fetch("/DeleteTask", {
              method: "POST",
              headers: {
                guId: this.props.location.state.guId,
                Authorization:
                  "Basic " + basicAuth(this.props.location.state.userid),
              },
              body: EncryptedRequest2,
            })
              .then((response) => response.text())
              .then((response) => {
                var DecryptedResponse2 = decryptData(response);
                if (DecryptedResponse2.errorCode === "00") {
                  confirmAlert({
                    message: "Task  Deleted Successfully.",
                    buttons: [
                      {
                        label: "Ok",
                        onClick: () => {
                          window.location.reload(true);
                        },
                      },
                    ],
                  });
                  this.forceUpdate();
                } else {
                  confirmAlert({
                    title: "Alert !",
                    message: DecryptedResponse2.errorMsg,
                    buttons: [
                      {
                        label: "Ok",
                      },
                    ],
                  });
                }
              })
              .catch((error) => {
                confirmAlert({
                  title: "Alert !",
                  message: "Session expired",
                  buttons: [
                    {
                      label: "Ok",
                      onClick: () => {
                        this.logoutnormal();
                      },
                    },
                  ],
                });
              });
          },
        },
        {
          label: "No",
          onClick: () => {
            return false;
          },
        },
      ],
    });
  }

  templates = () => {
    this.props.history.push({
      pathname: "/Templates",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      },
    });
  };

  callupdatetaskpage(
    tskId,
    tskName,
    tskDescr,
    strtDate,
    edDate,
    taskRoleId,
    taskType,
    functionalList,
    taskFile,
    taskFile1,
    taskFile2
  ) {
    this.props.history.push({
      pathname: "/Updatetasks",
      state: {
        tskId: tskId,
        tskName: tskName,
        tskDescr: tskDescr,
        functionalList: functionalList,
        strtDate: new Date(strtDate),
        edDate: new Date(edDate),
        userid: this.props.location.state.userid,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        usrnm: this.props.location.state.usrnm,
        taskRoleId: taskRoleId,
        taskType: taskType,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
        taskFile: taskFile,
        taskFile1: taskFile1,
        taskFile2: taskFile2,
      },
    });
  }
  calladdquestionpage(
    tskId,
    tskName,
    tskDescr,
    strtDate,
    edDate,
    publishStatus,
    taskType,
    functionalList
  ) {
    this.props.history.push({
      pathname: "/Addquestion",
      state: {
        tskId: tskId,
        tskName: tskName,
        tskDescr: tskDescr,
        strtDate: strtDate,
        edDate: edDate,
        functionalList: functionalList,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        storeNo: this.props.location.state.storeNo,
        publishStatus: publishStatus,
        taskType: taskType,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      },
    });
  }

  getroles(e) {
    this.props.history.push({
      pathname: "/CreatetaskUI",
      state: {
        format_id: this.props.location.state.format_id,
        role_id: this.props.location.state.role_id,
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      },
    });
  }
  tiker = () => {
    this.props.history.push({
      pathname: "/Tiker",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      },
    });
  };

  usercreation = () => {
    this.props.history.push({
      pathname: "/UserCreation",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      },
    });
  };
  TaskactiveBind = () => {
    this.props.history.push({
      pathname: "/Taskactive",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      },
    });
  };

  userlist = () => {
    this.props.history.push({
      pathname: "/UserList",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      },
    });
  };

  Changepasswordbind = () => {
    this.props.history.push({
      pathname: "/ChangepasswordUI",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      },
    });
  };

  Reportsbind = () => {
    this.props.history.push({
      pathname: "/Reports",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        data: this.props.location.state.data,
        tskId: this.props.location.state.tskId,
        tskName: this.props.location.state.tskName,
        storeNo: this.props.location.state.storeNo,
        Isroffice: this.props.location.state.Isroffice,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      },
    });
  };

  Defectbind = () => {
    this.props.history.push({
      pathname: "/DefectRaising",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        data: this.props.location.state.data,
        tskId: this.props.location.state.tskId,
        tskName: this.props.location.state.tskName,
        storeNo: this.props.location.state.storeNo,
        Isroffice: this.props.location.state.Isroffice,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      },
    });
  };

  SmartPointBind = () => {
    this.props.history.push({
      pathname: "/SmartPoint",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        data: this.props.location.state.data,
        tskId: this.props.location.state.tskId,
        tskName: this.props.location.state.tskName,
        storeNo: this.props.location.state.storeNo,
        Isroffice: this.props.location.state.Isroffice,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      },
    });
  };

  IssueRaising = () => {
    this.props.history.push({
      pathname: "/IssueRaising",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        data: this.props.location.state.data,
        tskId: this.props.location.state.tskId,
        tskName: this.props.location.state.tskName,
        storeNo: this.props.location.state.storeNo,
        Isroffice: this.props.location.state.Isroffice,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      },
    });
  };

  toGenDashboard = () => {
    this.props.history.push({
      pathname: "/DashboardGeneral",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        data: this.props.location.state.data,
        tskId: this.props.location.state.tskId,
        tskName: this.props.location.state.tskName,
        storeNo: this.props.location.state.storeNo,
        Isroffice: this.props.location.state.Isroffice,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      },
    });
  };

  IssueDashboard = () => {
    this.props.history.push({
      pathname: "/IssueDashboard",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        data: this.props.location.state.data,
        tskId: this.props.location.state.tskId,
        tskName: this.props.location.state.tskName,
        storeNo: this.props.location.state.storeNo,
        Isroffice: this.props.location.state.Isroffice,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      },
    });
  };

  CompletedTaskListbind = () => {
    this.props.history.push({
      pathname: "/CompletedTaskList",
      state: {
        data: this.props.location.state.data,
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      },
    });
  };

  ArchivedTasks = () => {
    this.props.history.push({
      pathname: "/ArchivedTasks",
      state: {
        data: this.props.location.state.data,
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
        Isroffice: this.props.location.state.Isroffice,
      },
    });
  };

  StoreTasks = () => {
    this.props.history.push({
      pathname: "/StoreTasks",
      state: {
        data: this.props.location.state.data,
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      },
    });
  };
}
export default withRouter(taskactive);
