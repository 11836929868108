/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import "jquery/dist/jquery";
import { withRouter } from "react-router-dom";
import addDays from "date-fns/addDays";
import DatePicker from "react-datepicker";
import setMinutes from "date-fns/setMinutes";
import setHours from "date-fns/setHours";
import MySelect from "./MySelect.jsx";
import { confirmAlert } from "react-confirm-alert"; // Import
import "./css/react-confirm-alert.css"; // Import css
import Spinner from "react-spinner-material"; //Import Spinner
import "react-datepicker/dist/react-datepicker.css";
import { Scrollbars } from "react-custom-scrollbars";
import readXlsxFile from "read-excel-file";
import IdleTimer from "react-idle-timer"; // For Idle
import "./css/style.css";
import "./css/style-responsive.css";
import { Encrypt, decryptData } from "./Encryption-Decrypytion";
import ReactExport from "react-data-export";
import disableBrowserBackButton from "disable-browser-back-navigation";
import FormDataService from "./FormDataService";
import { basicAuth } from "./BasicAuth";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const display = {
  display: "block"
};
const hide = {
  display: "none"
};

class Templates extends Component {
  state = {
    TaskDropdwn: false,
    hasError: false
  };

  showTaskDropdwnHandler = () => {
    this.setState({
      TaskDropdwn: !this.state.TaskDropdwn
    });
  };
  componentWillMount() {
    if (
      this.props.location.state === undefined ||
      this.props.location.state === ""
    ) {
      window.location.href = "/";
    } else {
      this.GetTasksOnload();
    }
  }

  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      val1: [],
      displayMenu: false,
      hasError: false,
      toggle: false,
      disappear: false,
      appear: false,
      rlistid: [],
      rlistopt: [],
      valueid: [],
      role_id: "",
      resptaskname: [],
      respstartdate: [],
      respenddate: [],
      resptaskdesc: [],
      data: [],
      usrnm: "",
      userid: "",
      Search: "",
      searchSDate: "",
      searchedDate: "",
      searcheddesc: "",
      tskId: "",
      tskName: "",
      loading: false,

      FileName: "",
      previewData: "",
      instructionUpload: "",
      instructiondata: [],
      StoreList: [],

      templetId: "",
      hammenu: true,
      scheduleTemplate: false,
      isMonthly: false,
      isWeekly: false,
      isDaily: false,
      scheduleDate: new Date(),
      scheduleend: new Date(),
      responsetempId: "",
      responsetemptaskName: "",
      responsetaskDesc: "",
      responserecurrence: "",
      responsestart: new Date(),
      responsend: new Date(),
      responsetaskStores: [],
      showrecurrance: false,

      funcRole: [],
      funcRoleId: [],
      funcRoleDesc: [],
      finalFunctional: [],
      filterFunctional: [],
      optionSelectedFunctional: null,
      instructionUpload1: "",
      instructionUpload2: "",
      instructionUpload3: "",

    };
    this.state = {
      val1: [],
      role_id: "",
      startDate: new Date(),
      endDate: new Date(),
      resptaskname: [],
      respstartdate: [],
      respenddate: [],
      resptaskdesc: [],
      data: [],
      usrnm: "",
      userid: "",
      Search: "",
      searchSDate: "",
      searchedDate: "",
      searcheddesc: "",
      tskId: "",
      tskName: "",
      loading: false,
      hasError: false,
      toggle: false,
      disappear: false,
      appear: false,
      rlistid: [],
      rlistopt: [],
      FileName: "",
      previewData: "",
      instructionUpload: "",
      instructiondata: [],
      StoreList: [],
      valueid: [],
      templetId: "",
      hammenu: true,
      scheduleTemplate: false,
      isMonthly: false,
      isWeekly: false,
      isDaily: false,
      scheduleDate: new Date(),
      scheduleend: new Date(),
      responsetempId: "",
      responsetemptaskName: "",
      responsetaskDesc: "",
      responserecurrence: "",
      responsestart: new Date(),
      responsend: new Date(),
      responsetaskStores: [],
      showrecurrance: false,
      displayMenu: false,
      TaskDropdwn: false,
      taskTypeList: [],
      taskTypeDesc: [],
      taskTypeid: [],
      valueTaskid: "",
      funcRole: [],
      funcRoleId: [],
      funcRoleDesc: [],
      finalFunctional: [],
      filterFunctional: [],
      optionSelectedFunctional: null,
      instructionUpload1: "",
      instructionUpload2: "",
      instructionUpload3: "",
      hasError: false
    };
    this.idleTimer = null;
    this.onAction = this._onAction.bind(this);
    this.onActive = this._onActive.bind(this);
    this.onIdle = this._onIdle.bind(this);
    this.handleChangeStart = this.handleChangeStart.bind(this);
    this.handlescheduledate = this.handlescheduledate.bind(this);
    this.handleChangeEnd = this.handleChangeEnd.bind(this);
    this.handleScheduleEnd = this.handleScheduleEnd.bind(this);
    this.showDropdownMenu = this.showDropdownMenu.bind(this);
    this.hideDropdownMenu = this.hideDropdownMenu.bind(this);
    this.handleChangeStartdatefor = this.handleChangeStartdatefor.bind(this);
    this.handleChangeEnddatefor = this.handleChangeEnddatefor.bind(this);
  }

  changeTaskType = e => {
    this.setState({ valueTask: e.target.value });

    let flag = [];

    for (var i = 0; i < this.state.taskTypeDesc.length; i++) {

      this.state.taskTypeDesc[i] === e.target.value
        ? flag.push(this.state.taskTypeid[i])
        : this.setState({ valueTaskid: "" });
    }
    this.setState({ valueTaskid: flag });
    this.state.valueTaskid = flag;
  };

  logoutnormal = () => {
    localStorage.clear();
    this.props.history.replace("/");
    disableBrowserBackButton();
  };

  handleChangeStart(date) {
    this.setState({
      startDate: date
    });
  }
  handleChangeEnd(date) {
    var monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ];
    this.setState({
      searchedDate:
        date.getDate() +
        "-" +
        monthNames[date.getMonth()] +
        "-" +
        date.getFullYear(),
      endDate: date
    });
  }
  logout = () => {
    this.setState({ loading: true });
    var Request1 = {
      userId: this.props.location.state.userid,
      guId: this.props.location.state.guId
    };
    var EncryptedRequest1 = Encrypt(Request1);
    fetch("/LogOut ", {
      method: "POST",
      headers: {
        guId: this.props.location.state.guId,
        Authorization: "Basic " + basicAuth(this.props.location.state.userid)
      },
      body: EncryptedRequest1
    })
      .then(response => response.text())
      .then(response => {
        this.setState({ loading: false });
        var DecryptedResponse = decryptData(response);
        if (DecryptedResponse.errorCode === "00") {
          this.props.history.replace("/");
          disableBrowserBackButton();
        } else {
          confirmAlert({
            message: DecryptedResponse.errorMsg,
            buttons: [
              {
                label: "Ok"
              }
            ]
          });
        }
      })
      .catch(error => {
        confirmAlert({
          title: "Alert !",
          message: "Session expired",
          buttons: [
            {
              label: "Ok",
              onClick: () => {
                this.logoutnormal();
              }
            }
          ]
        });
      });
  };

  showDropdownMenu(event) {
    event.preventDefault();
    this.setState({ displayMenu: true }, () => {
      document.addEventListener("click", this.hideDropdownMenu);
    });
  }

  hideDropdownMenu() {
    this.setState({ displayMenu: false }, () => {
      document.removeEventListener("click", this.hideDropdownMenu);
    });
  }

  onChange = e => {
    this.setState({
      Search: e.target.value
    });
  };

  onChange1 = e => {
    this.setState({ searcheddesc: e.target.value });
  };

  _onAction(e) {
  }

  _onActive(e) {
  }

  _onIdle(e) {
    localStorage.clear();
    window.location.href = "/";
    disableBrowserBackButton();
  }

  componentDidCatch() {
    this.setState({ hasError: true });
  }

  componentDidMount() {
    if (window.innerWidth <= 768) {
      this.setState({ hammenu: false });
    }
  }

  modalclose = () => {
    this.setState({
      toggle: false
    });

    window.location.reload(true);
  };

  handleChangeFunctional = selected => {
    this.setState({
      optionSelectedFunctional: selected
    })
    this.state.optionSelectedFunctional = selected;
    this.state.filterFunctional = [];
    this.state.finalFunctional = [];
    if (this.state.optionSelectedFunctional !== null) {
      if (selected.length > 0) {
        for (var i = 0; i < selected.length; i++) {
          var abc;
          abc = selected[i].value;

          this.state.filterFunctional.push(abc);
        }
        var item = [...new Set(this.state.filterFunctional)];

        this.state.finalFunctional = item;
      }
    } else {
      this.state.finalFunctional = [];
    }
  }


  toggle(taskId, taskName, taskDesc, recurrence, taskType, taskFile, taskFile1, taskFile2) {
    var that = this;
    this.setState({ responsetaskStores: [] });

    if (recurrence != null) {
      this.state.showrecurrance = true;
      var Request1 = { taskId: taskId, guId: this.props.location.state.guId };
      var EncryptedRequest1 = Encrypt(Request1);
      fetch("/GetTemplateDetails", {
        method: "POST",
        headers: {
          guId: this.props.location.state.guId,
          Authorization: "Basic " + basicAuth(this.props.location.state.userid)
        },
        body: EncryptedRequest1
      })
        .then(response => response.text())
        .then(response => {
          var DecryptedResponse1 = decryptData(response);

          if (DecryptedResponse1.errorCode === "00") {
            this.setState({
              taskId: taskId,
              taskName: taskName,
              taskDesc: taskDesc,
              taskType: taskType,
              responserecurrence: recurrence,
              responsestart: new Date(
                DecryptedResponse1.templteList[0].startDate
              ),
              responsend: new Date(DecryptedResponse1.templteList[0].endDate),
              toggle: !this.state.toggle
            });

            let flag = [];

            for (var i = 0; i < this.state.taskTypeDesc.length; i++) {

              this.state.taskTypeDesc[i] === this.state.taskType
                ? flag.push(this.state.taskTypeid[i])
                : this.setState({ valueTaskid: "" });
            }
            this.setState({ valueTaskid: flag });
            this.state.valueTaskid = flag;

            console.log('valueTaskid', this.state.valueTaskid);

            this.state.StoreList = [];
            for (
              var i = 0;
              i < DecryptedResponse1.templteList[0].storeId.length;
              i++
            ) {
              var storedata = { storeId: "" };
              storedata.storeId = DecryptedResponse1.templteList[0].storeId[i];
              this.state.StoreList.push(
                DecryptedResponse1.templteList[0].storeId[i]
              );
              that.state.responsetaskStores.push(storedata);
            }
          } else {
            this.setState({ loading: false });
            confirmAlert({
              message: DecryptedResponse1.errorMsg,
              buttons: [
                {
                  label: "Ok"
                }
              ]
            });
          }
        })
        .catch(error => {
          confirmAlert({
            title: "Alert !",
            message: "Session expired",
            buttons: [
              {
                label: "Ok",
                onClick: () => {
                  this.logoutnormal();
                }
              }
            ]
          });
        });
    } else {
      this.setState({ toggle: !this.state.toggle });
      this.state.showrecurrance = false;
      this.setState({
        taskId: taskId,
        taskName: taskName,
        taskDesc: taskDesc,
        taskType: taskType
      });
    }
  }

  fileHandler = event => {
    const input = document.getElementById("upload-storedata");
    var stores = [];
    readXlsxFile(input.files[0]).then((rows, files) => {
      for (let index = 0; index < rows.length; index++) {
        const element = rows[index];
        stores.push(element[0]);
      }
      this.setState({
        StoreList: stores,
        FileName: "File Uploaded Successfully"
      });
    });
  };

  createtclose = () => {
    this.setState({
      disappear: false
    });
  };

  createmodal(taskName, taskDesc) {
    this.setState(prevState => ({
      disappear: !prevState.disappear
    }));
    this.setState({
      taskName: taskName,
      taskDesc: taskDesc
    });
  }

  duplicate(taskName, taskDesc, taskId, taskType, taskFile, taskFile1, taskFile2) {
    this.setState(prevState => ({
      appear: !prevState.appear
    }));
    this.setState({
      taskName: taskName,
      taskDesc: taskDesc,
      templetId: taskId,
      taskType: taskType,
      taskFile: taskFile,
      taskFile1: taskFile1,
      taskFile2: taskFile2
    });
  }

  boxclose = () => {
    this.setState({
      appear: false
    });
  };



  changerole = e => {
    this.setState({ val1: e.target.value });
    let flag = [];
    for (var i = 0; i < this.state.rlistopt.length; i++) {

      this.state.rlistopt[i] === e.target.value
        ? flag.push(this.state.rlistid[i])
        : this.setState({ valueid: "" });
    }
    this.setState({ valueid: flag }, () => {
    });
  };

  handleDateChangeRaw = e => {
    e.preventDefault();
  };

  tiker = () => {
    this.props.history.push({
      pathname: "/Tiker",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };
  InstructionHandler = async (e, params) => {
    try {
      let files = e.target.files;

      var Request1 = {
        taskId: "",
        templateId: this.state.taskId,
        instructionNo: params.toString(),
        guId: this.props.location.state.guId
      };

      var EncryptedRequest1 = Encrypt(Request1);
      var guIdS = this.state.guId;
      this.setState({ loading: true });
      const response = await FormDataService.uploadFormData(
        files[0],
        EncryptedRequest1,
        this.props.location.state.guId,
        "Basic " + basicAuth(this.props.location.state.userid)
      );

      var DecryptedResponse = decryptData(response.data);
      if (params === 1) {
        this.setState({
          instructionUpload1: "File Uploaded Successfully",
          loading: false
        });
        this.state.instructionUpload1 = "File Uploaded Successfully";
      } else if (params === 2) {
        this.setState({
          instructionUpload2: "File Uploaded Successfully",
          loading: false
        });
      } else if (params === 3) {
        this.setState({
          instructionUpload3: "File Uploaded Successfully",
          loading: false
        });
      }

      confirmAlert({
        message: DecryptedResponse.errorMsg,
        buttons: [
          {
            label: "Ok"
          }
        ]
      });

    } catch (error) {
      this.setState({ instructionUpload1: "", instructionUpload2: "", instructionUpload3: "", loading: false });

      confirmAlert({
        message: "File not saved.",
        buttons: [
          {
            label: "Ok"
          }
        ]
      });
    }
  };


  checkInterval = () => {
    if (document.getElementById("recurrence").value == "Monthly") {
      this.setState({
        isMonthly: true,
        isWeekly: false,
        isDaily: true
      });
    } else if (document.getElementById("recurrence").value == "Daily") {
      this.setState({
        isDaily: true,
        isWeekly: false,
        isMonthly: false
      });
    } else if (document.getElementById("recurrence").value == "Weekly") {
      this.setState({
        isDaily: true,
        isWeekly: true,
        isMonthly: false
      });
    } else {
      this.setState({ isDaily: false, isMonthly: false, isWeekly: false });
    }
  };

  handlescheduledate = date => {
    this.setState({
      scheduleDate: date
    });
  };

  handleScheduleEnd = date => {
    this.setState({
      scheduleend: date
    });
  };

  handleschedulechange = e => {
    e.preventDefault();
  };

  handleDateraw = e => {
    e.preventDefault();
  };

  showTaskdwnHandler = () => {
    this.setState({
      Taskdwn: !this.state.Taskdwn
    });
  };

  usercreation = () => {
    this.props.history.push({
      pathname: "/UserCreation",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  handleChangeStartdatefor(date) {
    this.setState({
      responsestart: date
    });
  }

  handleChangeEnddatefor(date) {
    this.setState({
      responsend: date
    });
  }

  userlist = () => {
    this.props.history.push({
      pathname: "/UserList",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  render = () => {
    var that = this;
    var renderMonths = [];

    // for (let i = 1; i <= 31; i++) {
    //   renderMonths.push(<option>{i}</option>)
    // }

    var NoDays = [];
    var NoDays = [];
    for (let i = 1; i <= 30; i++) {
      NoDays.push(<option>{i}</option>);
    }

    var NoDaysWeekly = [];
    var NoDaysWeekly = [];
    for (let i = 1; i <= 7; i++) {
      NoDaysWeekly.push(<option>{i}</option>);
    }

    var modal = [];
    modal.push(
      <div
        className="modal"
        role="dialog"
        style={this.state.toggle ? display : hide}
      >
        <div className="modal-dialog animate">
          <div className="modal-content">
            <div className="modal-header">
              <a
                className="close"
                style={{ color: "#fff" }}
                onClick={this.modalclose}
              >
                X
              </a>
              <h4 className="modal-title">Update Template</h4>
            </div>

            <div className="modal-body box-scroll">
              <div className="form-horizontal">
                <div className="form-group">
                  <h4 className="control-label col-sm-4">Template ID</h4>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      class="form-control"
                      value={this.state.taskId}
                      onChange={f => this.setState({ taskId: f.target.value })}
                      id="taskupID"
                      disabled
                    ></input>
                    <br />
                  </div>

                  <h4 className="control-label col-sm-4">Template Type</h4>
                  <div className="col-sm-8">

                    <select
                      class="form-control"
                      onChange={this.changeTaskType.bind(this)}
                      id="taskTypeManual"
                    >
                      <option>{this.state.taskType}</option>
                      {this.state.taskTypeDesc.map(element => (
                        this.state.taskType == element ? null : <option>{element}</option>
                      ))}
                      {/* this.props.location.state.menuList.map((element) => (<option>{element}</option>)) */}
                    </select>
                    <br />
                  </div>

                  <h4 className="control-label col-sm-4">Template Name</h4>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      class="form-control"
                      value={this.state.taskName}
                      onChange={f =>
                        this.setState({ taskName: f.target.value })
                      }
                      id="taskupName"
                      autoComplete="off"
                      maxLength="250"
                    ></input>
                    <br />
                  </div>

                  <h4 className="control-label col-sm-4"> Description</h4>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      class="form-control"
                      value={this.state.taskDesc}
                      onChange={f =>
                        this.setState({ taskDesc: f.target.value })
                      }
                      id="taskupDesc"
                      autoComplete="off"
                      maxLength="250"
                    ></input>
                  </div>

                  <div className="clearfix"></div>
                  {this.state.showrecurrance ? (
                    <div>
                      <h4 className="control-label col-sm-4 mt-20">
                        Template Interval
                      </h4>

                      <div className="col-sm-8 mt-20">
                        <input
                          type="text"
                          class="form-control"
                          value={this.state.responserecurrence}
                          id="interval"
                          autoComplete="off"
                          maxLength="250"
                          disabled
                        ></input>
                      </div>

                      <div className="clearfix"></div>
                      <h4 className="control-label col-sm-4 mt-20">
                        Start Date
                      </h4>

                      <div className="col-sm-8 mt-20">
                        <DatePicker
                          className="form-control form-control-inline input-medium default-date-picker"
                          dateFormat="dd-MMM-yyyy HH:mm"
                          id="startdatefortemp"
                          size="16"
                          type="text"
                          showTimeSelect
                          timeIntervals={30}
                          value={this.state.responsestart}
                          selected={this.state.responsestart}
                          minDate={new Date()}
                          autoComplete="off"
                          onChange={this.handleChangeStartdatefor}
                          maxLength="250"
                        />
                      </div>

                      <div className="clearfix"></div>
                      <h4 className="control-label col-sm-4 mt-20">End Date</h4>

                      <div className="col-sm-8 mt-20">
                        <DatePicker
                          className="form-control form-control-inline input-medium default-date-picker"
                          dateFormat="dd-MMM-yyyy HH:mm"
                          showTimeSelect
                          size="16"
                          type="text"
                          timeIntervals={30}
                          value={this.state.responsend}
                          selected={this.state.responsend}
                          minDate={this.state.responsestart}
                          maxDate={addDays(new Date(), 365)}
                          onChange={this.handleChangeEnddatefor}
                          id="enddatefortemp"
                          autoComplete="off"
                          maxLength="250"
                        />
                      </div>


                      {this.state.responserecurrence == "Monthly" ? (
                        <>
                          <div className="clearfix"></div>
                          <h4 class="control-label col-sm-4 ">&nbsp;</h4>
                          <div className="col-sm-8 mt-20">
                            <select
                              class="form-control "
                              style={{
                                width: "150px"
                              }}
                              id="updateday"
                            >
                              <option>No of Days</option>
                              {NoDays}
                            </select>
                          </div>
                        </>
                      ) : null}

                      {this.props.location.state.taskType !== "Issue" ?
                        <>
                          <div className="clearfix"></div>
                          <h4 className="control-label col-sm-4">Task Instruction (Optional)</h4>
                          <div className="col-sm-8 mt-20">
                            <input
                              id="instructions"
                              type="text"
                              class="form-control input-upload"
                              placeholder="Upload Instructions"
                              value={this.state.instructionUpload1}
                            />
                            <span class="btn-upload">Upload</span>
                            <input
                              id="upload-instructions"
                              type="file"
                              name="Instruction-data"
                              class="input-hidden"
                              onChange={e => this.InstructionHandler(e, 1)}
                              value=""
                              accept=".gif,.jpg,.jpeg,.png,.doc,.docx,.pptx,.ppt,.xlsx,.xls,.txt,.pdf,.zip"
                            />
                          </div>
                        </>
                        : null}
                      {this.props.location.state.taskType !== "Issue" ?
                        <>
                          <div className="clearfix"></div>
                          <h4 className="control-label col-sm-4">Reference File 1 (Optional)</h4>
                          <div className="col-sm-8 mt-20">
                            <input
                              id="instructions"
                              type="text"
                              class="form-control input-upload"
                              placeholder="Upload Instructions"
                              value={this.state.instructionUpload2}
                            />
                            <span class="btn-upload">Upload</span>
                            <input
                              id="upload-instructions"
                              type="file"
                              name="Instruction-data"
                              class="input-hidden"
                              onChange={e => this.InstructionHandler(e, 2)}
                              value=""
                              accept=".gif,.jpg,.jpeg,.png,.doc,.docx,.pptx,.ppt,.xlsx,.xls,.txt,.pdf,.zip"
                            />
                          </div>
                        </>
                        : null}
                      <div class="clearfix"></div>
                      {this.props.location.state.taskType !== "Issue" ?
                        <>
                          <div className="clearfix"></div>
                          <h4 className="control-label col-sm-4">Reference File 2 (Optional)</h4>
                          <div className="col-sm-8 mt-20">
                            <input
                              id="instructions"
                              type="text"
                              class="form-control input-upload"
                              placeholder="Upload Instructions"
                              value={this.state.instructionUpload3}
                            />
                            <span class="btn-upload">Upload</span>
                            <input
                              id="upload-instructions"
                              type="file"
                              name="Instruction-data"
                              class="input-hidden"
                              onChange={e => this.InstructionHandler(e, 3)}
                              value=""
                              accept=".gif,.jpg,.jpeg,.png,.doc,.docx,.pptx,.ppt,.xlsx,.xls,.txt,.pdf,.zip"
                            />
                          </div>
                        </>
                        : null}

                      <div className="clearfix"></div>
                      <h4 className="control-label col-sm-4 mt-20">Role</h4>

                      <div className="col-sm-8 mt-20">
                        <select
                          id="multi-select"
                          className="form-control"
                          multiple="multiple"
                          onChange={this.changerole.bind(this)}
                          autoComplete="off"
                        >
                          {this.state.rlistopt.map(element => (
                            <option>{element}</option>
                          ))}
                        </select>
                      </div>

                      <div className="clearfix"></div>
                      <h4 className="control-label col-sm-4 mt-20">
                        EC Codes/Store Codes (Optional)
                      </h4>
                      <br />
                      <div className="col-sm-8 ">
                        <div class="upload-holder">
                          <input
                            id="store-list"
                            type="text"
                            class="form-control input-upload"
                            value={this.state.FileName}
                          />
                          <span class="btn-upload">Upload</span>
                          <input
                            id="upload-storedata"
                            type="file"
                            name="Store-list-data"
                            class="input-hidden"
                            onChange={this.fileHandler.bind(this)}
                            accept=".xlsx,.xls"
                          />
                        </div>

                        <span class="help-block">
                          Sample File{" "}
                          <a href={require("./doc/sample.xlsx")}>Sample.xls</a>
                        </span>
                        <span>Existing store data </span>
                        <ExcelFile element={<a> Download</a>}>
                          <ExcelSheet
                            data={this.state.responsetaskStores}
                            name="Store Details"
                          >
                            <ExcelColumn label="Store No" value="storeId" />
                          </ExcelSheet>
                        </ExcelFile>
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="col-sm-8" style={{ "margin-left": "180px" }}>
                  <button
                    class="btn btn-primary min-wid-90 mt-17"
                    onClick={this.UpdateTemplate.bind(this)}
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
    var createt = [];
    createt.push(
      <div
        className="modal"
        role="dialog"
        style={this.state.disappear ? display : hide}
      >
        <div className="modal-dialog animate">
          <div className="modal-content">
            <div className="modal-header">
              <a
                className="close"
                style={{ color: "#fff" }}
                onClick={this.createtclose}
              >
                X
              </a>
              <h4 className="modal-title">Create Template</h4>
            </div>
            <div className="modal-body box-scroll">
              <div className="form-horizontal">
                <div className="form-group">
                  <h4 className="control-label col-sm-4">Template Name</h4>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      class="form-control"
                      id="tempName"
                      autoComplete="off"
                      maxLength="250"
                    ></input>
                    <br />
                  </div>
                </div>
                <div className="form-group">
                  <h4 className="control-label col-sm-4">
                    Template Description
                  </h4>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      class="form-control"
                      id="tempDesc"
                      autoComplete="off"
                      maxLength="250"
                    ></input>
                  </div>
                </div>
                <div className="form-group">
                  <h4 className="control-label col-sm-4">
                    Template Type
                  </h4>
                  <div className="col-sm-8">
                    <select
                      class="form-control"
                      onChange={this.changeTaskType.bind(this)}
                      id="taskTypeManual"
                    >
                      <option>--Select--</option>
                      {this.state.taskTypeDesc.map(element => (
                        <option>{element}</option>
                      ))}
                      {/* this.props.location.state.menuList.map((element) => (<option>{element}</option>)) */}
                    </select>
                  </div>
                </div>

                <div className="form-group">
                  <h4 className="control-label col-sm-4">
                    {" "}
                    Scheduled Template
                  </h4>
                  <div className="col-sm-8">
                    <input
                      type="checkbox"
                      style={{ "margin-top": "18px" }}
                      class="custom-control-input"
                      id="schedulecheck"
                      onChange={() => {
                        this.setState({
                          scheduleTemplate: !this.state.scheduleTemplate,
                          isDaily: false,
                          isWeekly: false,
                          isMonthly: false,
                        });
                      }}
                    />
                    <br />
                    {this.state.scheduleTemplate ? (
                      <div>
                        <select
                          class="form-control col-lg-3 col-md-4 col-sm-4 col-xs-12 mb"
                          id="recurrence"
                          onChange={this.checkInterval}
                        >
                          <option>Select Interval</option>
                          <option>Daily</option>
                          <option>Weekly</option>
                          <option>Monthly</option>
                        </select>

                        <div className="Clearfix"></div>

                        {this.state.isDaily == true ? (
                          <div>
                            <label>
                              <b>Start Date</b>
                            </label>
                            <br />
                            <DatePicker
                              className="form-control form-control-inline input-medium default-date-picker"
                              size="16"
                              type="text"
                              placeholderText="Select Start Date "
                              dateFormat="dd-MMM-yyyy HH:mm"
                              id="Dailydate"
                              selected={this.state.scheduleDate}
                              showTimeSelect
                              timeIntervals={30}
                              minDate={new Date()}
                              onChange={this.handlescheduledate}
                              onChangeRaw={this.handleschedulechange}
                            />
                          </div>
                        ) : null}

                        {this.state.isDaily == true ? (
                          <div className="mt-20">
                            <label>
                              <b>End Date</b>
                            </label>
                            <br />
                            {
                              <DatePicker
                                className="form-control form-control-inline input-medium default-date-picker"
                                size="16"
                                type="text"
                                placeholderText="Select End Date"
                                dateFormat="dd-MMM-yyyy HH:mm"
                                id="Dailyedate"
                                selected={this.state.scheduleend}
                                showTimeSelect
                                timeIntervals={30}
                                minTime={setHours(
                                  setMinutes(
                                    new Date(),
                                    this.state.scheduleDate.getMinutes()
                                  ),
                                  this.state.scheduleDate.getHours()
                                )}
                                maxTime={setHours(
                                  setMinutes(new Date(), 59),
                                  23
                                )}
                                onChange={this.handleScheduleEnd}
                                minDate={this.state.scheduleDate}
                                maxDate={addDays(new Date(), 365)}
                                onChangeRaw={this.handleDateraw}
                              />
                            }
                          </div>
                        ) : null}
                        {this.state.isMonthly == true ? (
                          <div className="mt-20">
                            <label>
                              <b>Task Active Period</b>
                            </label>
                            <select
                              class="form-control"
                              style={{ width: "150px" }}
                              id="recurrenceday"
                            >
                              <option>No of Days</option>
                              {NoDays}
                            </select>
                          </div>
                        ) : null}
                        {this.state.isWeekly == true ? (
                          <div className="mt-20">
                            <label>
                              <b>Task Active Period</b>
                            </label>
                            <select
                              class="form-control"
                              style={{ width: "150px" }}
                              id="recurrencedayweekly"
                            >
                              <option>No of Days</option>
                              {NoDaysWeekly}
                            </select>
                          </div>
                        ) : null}
                        <div className="clearfix"></div>

                        <div className="form-group mt-20">
                          <label className="control-label col-sm-8 ">
                            <b>Functional View Authority (Optional)</b>
                          </label>
                          <div className="col-sm-12 ">
                            <MySelect
                              options={this.state.funcRole}
                              isMulti
                              closeMenuOnSelect={true}
                              hideSelectedOptions={false}
                              /*  components={animatedComponents} */
                              onChange={this.handleChangeFunctional.bind(this)}
                              allowSelectAll={true}
                              value={this.state.optionSelectedFunctional}
                              id="uom"
                            />
                          </div>
                        </div>

                        <div className="form-group mt-20">
                          <label className="control-label col-sm-4 ">
                            <b>Role</b>
                          </label>
                          <div className="col-sm-12 ">
                            <select
                              id="multi-select"
                              className="form-control"
                              multiple="multiple"
                              onChange={this.changerole.bind(this)}
                              autoComplete="off"
                            >
                              {this.state.rlistopt.map(element => (
                                <option>{element}</option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <div class="form-group">
                            <label>
                              <b>EC Codes/Store Codes</b>
                            </label>
                            <br />
                            <div class="upload-holder">
                              <input
                                id="store-list"
                                type="text"
                                class="form-control input-upload"
                                value={this.state.FileName}
                              />
                              <span class="btn-upload">Upload</span>
                              <input
                                id="upload-storedata"
                                type="file"
                                name="Store-list-data"
                                class="input-hidden"
                                onChange={this.fileHandler.bind(this)}
                                accept=".xlsx,.xls"
                              />
                            </div>
                            <span class="help-block">
                              Sample File{" "}
                              <a href={require("./doc/sample.xlsx")}>
                                Sample.xls
                              </a>
                            </span>{" "}
                          </div>
                          <div>
                            <label hidden>Selected Stores</label>
                            <br />
                            <input
                              type="hidden"
                              className="form-control"
                              value={this.state.StoreList}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="form-group">
                  <div className="control-label col-sm-4">&nbsp;</div>
                  <div className="col-sm-8">
                    <button
                      class="btn btn-primary min-wid-90 mt-17"
                      onClick={this.CreateTemplate.bind(this)}
                    >
                      Create Template
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    var box = [];

    box.push(
      <div
        className="modal"
        role="dialog"
        style={this.state.appear ? display : hide}
      >
        <div className="modal-dialog animate">
          <div className="modal-content">
            <div className="modal-header">
              <a
                className="close"
                style={{ color: "#fff" }}
                onClick={this.boxclose}
              >
                X
              </a>
              <h4 className="modal-title">Create Task (using this template)</h4>
            </div>
            <div className="modal-body box-scroll">
              <div className="form-horizontal">
                <div className="form-group">
                  <h4 className="control-label col-sm-4">Task Name</h4>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Task Name"
                      value={this.state.taskName}
                      onChange={f =>
                        this.setState({ taskName: f.target.value })
                      }
                      id="taskNameCT"
                      maxlength="150"
                      autoComplete="off"
                    />
                    <br />
                  </div>
                  <h4 className="control-label col-sm-4">Task Description</h4>
                  <div className="col-sm-8">
                    <textarea
                      type="text"
                      class="form-control"
                      placeholder="Enter Task Description"
                      value={this.state.taskDesc}
                      onChange={f =>
                        this.setState({ taskDesc: f.target.value })
                      }
                      id="taskDescCT"
                      maxlength="250"
                      autoComplete="off"
                    />
                    <br />
                  </div>


                  <h4 className="control-label col-sm-4">Start Date</h4>
                  <div className="col-sm-8">
                    <DatePicker
                      ref="startdate"
                      className="form-control"
                      placeholderText="Select Start Date"
                      size="16"
                      type="text"
                      dateFormat="dd-MMM-yyyy HH:mm"
                      id="startDateCT"
                      selected={this.state.startDate}
                      minDate={new Date()}
                      showTimeSelect
                      timeIntervals={30}
                      onChange={this.handleChangeStart}
                      autoComplete="off"
                      onChangeRaw={this.handleDateChangeRaw}
                    />
                    <br />
                    <br />
                  </div>

                  <h4 className="control-label col-sm-4">End Date</h4>
                  <div className="col-sm-8">
                    {
                      <DatePicker
                        className="form-control"
                        size="16"
                        type="text"
                        placeholderText="Select End Date"
                        dateFormat="dd-MMM-yyyy HH:mm"
                        id="endDateCT"
                        selected={this.state.endDate}
                        onChange={this.handleChangeEnd}
                        minDate={this.state.startDate}
                        showTimeSelect
                        timeIntervals={30}
                        maxDate={addDays(new Date(), 365)}
                        onChangeRaw={this.handleDateChangeRaw}
                        autoComplete="off"
                      />
                    }
                    <br />
                    <br />
                  </div>

                  <h4 className="control-label col-sm-4">Functional View Authority (Optional)</h4>
                  <div className="col-sm-8">
                    <MySelect
                      options={this.state.funcRole}
                      isMulti
                      closeMenuOnSelect={true}
                      hideSelectedOptions={false}
                      /*  components={animatedComponents} */
                      onChange={this.handleChangeFunctional.bind(this)}
                      allowSelectAll={true}
                      value={this.state.optionSelectedFunctional}
                      id="uom"
                    />
                    <br />
                    <br />
                  </div>

                  <h4 className="control-label col-sm-4">Role</h4>
                  <div className="col-sm-8">
                    <select
                      id="multi-select"
                      className="form-control"
                      multiple="multiple"
                      onChange={this.changerole.bind(this)}
                      autoComplete="off"
                    >
                      {this.state.rlistopt.map(element => (
                        <option>{element}</option>
                      ))}
                    </select>
                    <br />
                  </div>

                  <h4 className="control-label col-sm-4">EC Codes/Store Codes</h4>
                  <div className="col-sm-8">
                    <input
                      id="store-list"
                      type="text"
                      class="form-control input-upload"
                      value={this.state.FileName}
                    />
                    <span class="btn-upload">Upload</span>
                    <input
                      id="upload-storedata"
                      type="file"
                      name="Store-list-data"
                      class="input-hidden"
                      onChange={this.fileHandler.bind(this)}
                      accept=".xlsx,.xls"
                    />
                  </div>
                  <div className="col-sm-8">
                    <p class="text-right samp-mg">
                      Sample File{" "}
                      <a href={require("./doc/sample.xlsx")}>Sample.xls</a>
                    </p>
                  </div>

                  {/* <h4 className="control-label col-sm-4">&nbsp;</h4> */}
                  <div className="col-sm-8">
                    <button
                      class="btn btn-primary min-wid-90 mt-20"
                      onClick={this.CreateTask.bind(this)}
                    >
                      Create
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    var that = this;

    const { Search } = this.state;
    const { searchSDate } = this.state;
    const { searchedDate } = this.state;
    const { searcheddesc } = this.state;

    if (this.state.hasError) {
      return (
        <div>
          <h2>Error occurred!! please contact administrator</h2>
        </div>
      );
    } else {
      return (
        <div>
          {/* //<div className="spinner"> */}
          {/* <Spinner  visible={this.state.loading} /> */}
          {modal}
          {createt}
          {box}
          {/* </div> */}
          <section id="container">
            <header className="header black-bg">
              {/* <div className="sidebar-toggle-box">
      <div className="fa fa-bars tooltips" data-placement="right" data-original-title="Toggle Navigation"></div>
    </div> */}

              <div>
                <IdleTimer
                  ref={ref => {
                    this.idleTimer = ref;
                  }}
                  element={document}
                  onActive={this.onActive}
                  onIdle={this.onIdle}
                  onAction={this.onAction}
                  debounce={250}
                  timeout={1000 * 60 * 10}
                />
              </div>
              <a
                className="mob-show"
                onClick={() =>
                  this.setState({ hammenu: !this.state.hammenu })
                }
              >
                <i
                  className="fa fa-tasks hammenu"
                  style={{ "margin-top": "25px", 'padding': '0 15px' }}
                ></i>
              </a>

              <a onClick={this.TaskactiveBind.bind(this)} className="logo" style={{ 'padding': '0 10px' }}>
                <img src={require("./img/retail-logo.png")} alt="logo" />
              </a>
              <div className="markcenter">
                <p className="marquee">{this.state.tickerList}</p>
              </div>
              <ul className="nav pull-right pos-rel">
                <li className="dropdown">
                  <a
                    className="dropdown-toggle"
                    data-toggle="dropdown"
                    onClick={this.showDropdownMenu}
                  >
                    {" "}
                    <img
                      src={require("./img/user.png")}
                      className="user-img"
                    />{" "}
                    <b className="name-show">{this.props.location.state.usrnm}</b>
                    ({this.props.location.state.storeNo})

                    <b className="caret" />
                  </a>
                </li>

                {this.state.displayMenu ? (
                  <ul className="dropdown-menuuser-dd">
                    <li>
                      Role : {this.props.location.state.roleName}
                    </li>
                    <li className="divider"></li>
                    <li>
                      <a onClick={this.Changepasswordbind}>Change Password</a>
                    </li>
                    <li className="divider"></li>
                    <li>
                      <a onClick={this.logout}>Log Out</a>
                    </li>
                  </ul>
                ) : null}
              </ul>
            </header>
            <aside>
              <div id="sidebar" className="nav-collapse">
                {this.state.hammenu ? (
                  <ul className="sidebar-menu" id="nav-accordion">
                    {this.props.location.state.menuList["401"] || this.props.location.state.menuList["402"] || this.props.location.state.menuList["403"] || this.props.location.state.menuList["404"] || this.props.location.state.menuList["405"] || this.props.location.state.menuList["408"] || this.props.location.state.menuList["411"] ? (
                      <li
                        className="sub-menu mt"
                        onClick={this.showTaskDropdwnHandler.bind(this)}
                      >
                        {" "}
                        <a href="javascript:;">
                          {" "}
                          <i className="fa fa-tasks"></i> <span>Task</span>{" "}
                        </a>
                        {this.state.TaskDropdwn ? (
                          <div>
                            <ul class="sub">
                              {this.props.location.state.menuList["401"] ? (
                                <li>
                                  <a onClick={this.TaskactiveBind.bind(this)}>
                                    {this.props.location.state.menuList["401"]}
                                  </a>
                                </li>
                              ) : null}
                              {this.props.location.state.menuList["402"] ? (
                                <li>
                                  <a onClick={this.getroles.bind(this)}>
                                    {this.props.location.state.menuList["402"]}
                                  </a>
                                </li>
                              ) : null}
                              {this.props.location.state.menuList["403"] ? (
                                <li>
                                  <a
                                    onClick={this.CompletedTaskListbind.bind(
                                      this
                                    )}
                                  >
                                    {this.props.location.state.menuList["403"]}
                                  </a>
                                </li>
                              ) : null}
                              {this.props.location.state.menuList["404"] ? (
                                <li>
                                  <a onClick={this.templates.bind(this)}>
                                    {this.props.location.state.menuList["404"]}
                                  </a>
                                </li>
                              ) : null}
                              {this.props.location.state.menuList["405"] ? (
                                <li>
                                  <a onClick={this.ArchivedTasks.bind(this)}>
                                    {this.props.location.state.menuList["405"]}
                                  </a>
                                </li>
                              ) : null}
                              {this.props.location.state.menuList["408"] ? (
                                <li>
                                  <a onClick={this.StoreTasks.bind(this)}>
                                    {this.props.location.state.menuList["408"]}
                                  </a>
                                </li>
                              ) : null}
                              {this.props.location.state.menuList["411"] ? (
                                <li>
                                  <a onClick={this.toGenDashboard.bind(this)}>
                                    {this.props.location.state.menuList["411"]}
                                  </a>
                                </li>
                              ) : null}
                            </ul>
                          </div>
                        ) : null}
                      </li>
                    ) : null}
                    {this.props.location.state.menuList["406"] ? (
                      <div>
                        <li>
                          {" "}
                          <a onClick={this.Reportsbind.bind(this)}>
                            {" "}
                            <i className="fa fa-tasks"></i>{" "}
                            <span>
                              {this.props.location.state.menuList["406"]}
                            </span>{" "}
                          </a>{" "}
                        </li>
                      </div>
                    ) : null}

                    {this.props.location.state.menuList["414"] && this.props.location.state.format_id === "102" ? (
                      <div>
                        <li>
                          {" "}
                          <a onClick={this.Defectbind.bind(this)}>
                            {" "}
                            <i className="fa fa-tasks"></i>{" "}
                            <span>
                              {this.props.location.state.menuList["414"]}
                            </span>{" "}
                          </a>{" "}
                        </li>
                      </div>
                    ) : null}

                    {this.props.location.state.menuList["415"] ? (
                      <div>
                        <li>
                          {" "}
                          <a onClick={this.SmartPointBind.bind(this)}>
                            {" "}
                            <i className="fa fa-tasks"></i>{" "}
                            <span>
                              {this.props.location.state.menuList["415"]}
                            </span>{" "}
                          </a>{" "}
                        </li>
                      </div>
                    ) : null}

                    {this.props.location.state.menuList["409"] || this.props.location.state.menuList["410"] ? (
                      <div>
                        <li
                          className="sub-menu"
                          onClick={this.showIssueDropdwnHandler.bind(this)}
                        >
                          {" "}
                          <a href="javascript:;">
                            {" "}
                            <i className="fa fa-tasks"></i>{" "}
                            <span>Issue</span>{" "}
                          </a>
                          {this.state.IssueDropdwn ? (
                            <div>
                              <ul class="sub">
                                {this.props.location.state.menuList["410"] ? (
                                  <li>
                                    <a onClick={this.IssueRaising.bind(this)}>
                                      {this.props.location.state.menuList["410"]}
                                    </a>
                                  </li>
                                ) : null}
                                {this.props.location.state.menuList["409"] ? (
                                  <li>
                                    <a onClick={this.IssueDashboard.bind(this)}>
                                      {this.props.location.state.menuList["409"]}
                                    </a>
                                  </li>
                                ) : null}
                              </ul>
                            </div>
                          ) : null}
                        </li>
                      </div>
                    ) : null}

                    {this.props.location.state.menuList["407"] ? (
                      <div>
                        <li
                          className="sub-menu"
                          onClick={this.showTaskdwnHandler.bind(this)}
                        >
                          {" "}
                          <a href="javascript:;">
                            {" "}
                            <i className="fa fa-tasks"></i>{" "}
                            <span>
                              {this.props.location.state.menuList["407"]}
                            </span>{" "}
                          </a>
                          {this.state.Taskdwn ? (
                            <div>
                              <ul class="sub">
                                <li>
                                  {" "}
                                  <a onClick={this.tiker.bind(this)}>
                                    <span>Tiker Creation</span>{" "}
                                  </a>{" "}
                                </li>
                                <li>
                                  {" "}
                                  <a onClick={this.userlist.bind(this)}>
                                    <span>User List</span>{" "}
                                  </a>{" "}
                                </li>
                                {this.props.location.state.menuList["412"] ? (
                                  <li>
                                    {" "}
                                    <a onClick={this.Storecreation.bind(this)}>
                                      <span>{this.props.location.state.menuList["412"]}</span>{" "}
                                    </a>{" "}
                                  </li>
                                ) : null}
                                {this.props.location.state.menuList["413"] ? (
                                  <li>
                                    {" "}
                                    <a onClick={this.ToStoreList.bind(this)}>
                                      <span>{this.props.location.state.menuList["413"]}</span>{" "}
                                    </a>{" "}
                                  </li>
                                ) : null}
                              </ul>
                            </div>
                          ) : null}
                        </li>
                      </div>
                    ) : null}
                  </ul>
                ) : null}
              </div>
            </aside>
            <section id="main-content">
              <section className="wrapper">
                <div className="mt">
                  <h3>
                    <i className="fa fa-angle-right"></i> Template(s)
                    <a
                      className="pull-right btn btn-theme"
                      onClick={this.createmodal.bind(this)}
                    >
                      + Create Template
                    </a>{" "}
                  </h3>
                  <hr />
                  <div className="row mt">
                    <div className="col-md-12">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
                          {" "}
                          <b>Template Name</b>
                          <br />
                          <input
                            type="text"
                            placeholder="Search Template Name"
                            className="form-control"
                            id="taskname"
                            onChange={this.onChange}
                            autoComplete="off"
                          />
                        </div>

                        <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                          {" "}
                          <b>Template Description</b>
                          <br />
                          <input
                            type="text"
                            placeholder="Search Template Description"
                            className="form-control"
                            id="taskdesc"
                            onChange={this.onChange1}
                            autoComplete="off"
                          />
                        </div>
                        <div className="col-xs-12 col-sm-1 col-md-1 col-lg-1 mt-xs-10">
                          <img
                            src={require("./img/searchicon.png")}
                            className="user-img mt-20"
                            style={{ width: "25px", height: "25px" }}
                          />
                        </div>
                        <div className="clearfix"></div>
                      </div>
                      <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12 tbwdth">
                        <div className="mt">
                          <div className="tbl-holder">
                            <div className={this.state.loading ? 'parentDisable' : ''} width="100%">
                              <div className='overlay-box'>
                                <Spinner
                                  visible={this.state.loading}
                                  spinnerColor={"rgba(0, 0, 0, 0.3)"}
                                />
                              </div>
                            </div>

                            <table className="table table-striped table-advance table-hover table-bordered tbl-task tbl-hhide">
                              <thead>
                                <tr>
                                  <th>Template ID</th>
                                  <th>Template Name</th>
                                  <th>Recurrence</th>
                                  <th>Type</th>
                                  <th>Description</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                            </table>

                            <Scrollbars style={{ height: 296 }}>
                              <table className="table table-striped table-advance table-hover table-bordered tbl-task mob-tbl">
                                <tbody>
                                  {this.state.data.map(function (item, key) {
                                    if (
                                      (Search !== "" || searcheddesc !== "") &&
                                      (item.taskName
                                        .toLowerCase()
                                        .indexOf(Search.toLowerCase()) ||
                                        item.taskDesc
                                          .toLowerCase()
                                          .indexOf(
                                            searcheddesc.toLowerCase()
                                          )) === -1
                                    ) {
                                      return null;
                                    }

                                    return (
                                      <tr key={key}>
                                        <td data-th="Template ID">
                                          <a
                                            href="javascript:void(0)"
                                            onClick={that.calladdtemplatepage.bind(
                                              that,
                                              item.taskId,
                                              item.taskName,
                                              item.taskDesc,
                                              item.taskType
                                            )}
                                            className="black-text"
                                          >
                                            {" "}
                                            {item.taskId}
                                          </a>
                                        </td>

                                        <td data-th="Template Name">
                                          {item.taskName}
                                        </td>
                                        <td data-th="Recurrence">
                                          {item.recurrence}
                                        </td>
                                        <td data-th="Type">
                                          {item.taskType}
                                        </td>
                                        <td data-th="Description">
                                          {item.taskDesc}
                                        </td>
                                        <td data-th="Action">
                                          <a
                                            className="edit-icon"
                                            onClick={that.toggle.bind(
                                              that,
                                              item.taskId,
                                              item.taskName,
                                              item.taskDesc,
                                              item.recurrence,
                                              item.taskType,
                                              item.taskFile,
                                              item.taskFile1,
                                              item.taskFile2
                                            )}
                                          >
                                            {" "}
                                            <i className="fa fa-pencil "></i>{" "}
                                          </a>
                                          <a
                                            className="delete-icon"
                                            onClick={that.deletetask.bind(
                                              that,
                                              item.taskId
                                            )}
                                          >
                                            {" "}
                                            <i className="fa fa-trash-o "></i>{" "}
                                          </a>
                                          <a
                                            onClick={that.duplicate.bind(
                                              that,
                                              item.taskName,
                                              item.taskDesc,
                                              item.taskId,
                                              item.taskType,
                                              item.taskFile,
                                              item.taskFile1,
                                              item.taskFile2
                                            )}
                                          >
                                            <img
                                              src={require("./img/add-template.png")}
                                              className="ml-20"
                                            />
                                          </a>
                                          <span className="d-none">
                                            {item.taskId}
                                          </span>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </Scrollbars>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </section>
          </section>
        </div>
      );
    }
  };

  showIssueDropdwnHandler = () => {
    this.setState({
      IssueDropdwn: !this.state.IssueDropdwn
    });
  };

  IssueRaising = () => {
    this.props.history.push({
      pathname: "/IssueRaising",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        data: this.props.location.state.data,
        tskId: this.props.location.state.tskId,
        tskName: this.props.location.state.tskName,
        storeNo: this.props.location.state.storeNo,
        Isroffice: this.props.location.state.Isroffice,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  IssueDashboard = () => {
    this.props.history.push({
      pathname: "/IssueDashboard",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        data: this.props.location.state.data,
        tskId: this.props.location.state.tskId,
        tskName: this.props.location.state.tskName,
        storeNo: this.props.location.state.storeNo,
        Isroffice: this.props.location.state.Isroffice,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  GetTasksOnload(e) {
    this.fetchrole();
    this.setState({ loading: true });
    this.setState({ userid: this.props.location.state.userid });

    if (
      this.props.location.state !== undefined &&
      this.props.location.state.usrnm !== ""
    ) {
      this.setState({
        usrnm: this.props.location.state.usrnm
      });

      var Request1 = {
        formatId: this.props.location.state.format_id,
        guId: this.props.location.state.guId
      };
      var EncryptedRequest1 = Encrypt(Request1);

      fetch("/GetAllTemplate", {
        method: "POST",
        headers: {
          guId: this.props.location.state.guId,
          Authorization: "Basic " + basicAuth(this.props.location.state.userid)
        },
        body: EncryptedRequest1
      })
        .then(response => response.text())
        .then(response => {
          var DecryptedResponse1 = decryptData(response);
          if (DecryptedResponse1.errorCode === "00") {
            this.setState({ data: DecryptedResponse1.templteList });
            this.setState({ usrnm: this.props.location.state.usrnm });
            this.setState({ userid: this.props.location.state.userid });
            this.setState({ tskId: this.props.location.state.tskId });
            this.setState({ tskName: this.props.location.state.tskName });
            this.setState({
              loading: false
            });
          } else {
            this.setState({ loading: false });
            confirmAlert({
              message: DecryptedResponse1.errorMsg,
              buttons: [
                {
                  label: "Ok"
                }
              ]
            });
          }
        })
        .catch(error => {
          confirmAlert({
            title: "Alert !",
            message: "Session expired",
            buttons: [
              {
                label: "Ok",
                onClick: () => {
                  this.logoutnormal();
                }
              }
            ]
          });
        });


      var Request3 = {
        formatId: this.props.location.state.format_id,
        guId: this.props.location.state.guId
      };
      var EncryptedRequest3 = Encrypt(Request3);

      fetch("/GetFunctionalRoles", {
        method: "POST",
        headers: {
          guId: this.props.location.state.guId,
          Authorization:
            "Basic " + basicAuth(this.props.location.state.userid)
        },
        body: EncryptedRequest3
      })
        .then(response => response.text())
        .then(response => {
          var DecryptedResponse = decryptData(response);
          if (DecryptedResponse.errorCode === "00") {

            for (var i = 0; i < DecryptedResponse.funcrole.length; i++) {
              var abc = { value: "", label: "" };
              abc.value = DecryptedResponse.funcrole[i].roleId;
              abc.label = DecryptedResponse.funcrole[i].roleDesc;
              this.state.funcRole.push(abc);
            }
            this.setState({ funcRole: this.state.funcRole });
          } else {
            confirmAlert({
              message: DecryptedResponse.errormsg,
              buttons: [
                {
                  label: "Ok",
                  onClick: () => {
                    return false;
                  }
                }
              ]
            });
          }
        })
        .catch(error => {
          confirmAlert({
            title: "Alert !",
            message: "Session expired",
            buttons: [
              {
                label: "Ok",
                onClick: () => {
                  this.logoutnormal();
                }
              }
            ]
          });
        });


    } else {
      window.location.href = "/";
    }

    fetch("/GetTaskType", {
      method: "GET",
      headers: {
        guId: this.props.location.state.guId,
        Authorization:
          "Basic " + basicAuth(this.props.location.state.userid)
      },

    })
      .then(response => response.text())
      .then(response => {
        var DecryptedResponse = decryptData(response);
        if (DecryptedResponse.errorCode === "00") {

          this.setState({ taskTypeList: DecryptedResponse.getTaskTypeModel });

          let temp = [];
          let temp1 = [];


          for (var i = 0; i < DecryptedResponse.getTaskTypeModel.length; i++) {

            temp.push(DecryptedResponse.getTaskTypeModel[i].taskType);
            temp1.push(DecryptedResponse.getTaskTypeModel[i].taskTypeId);
          }
          this.setState({ taskTypeDesc: temp });
          this.setState({ taskTypeid: temp1 });

          console.log('valueTaskid', this.state.taskTypeDesc);

        } else {
          confirmAlert({
            message: DecryptedResponse.errorMsg,
            buttons: [
              {
                label: "Ok",
                onClick: () => {
                  return false;
                }
              }
            ]
          });
        }
      })
      .catch(error => {
        confirmAlert({
          title: "Alert !",
          message: "Session expired",
          buttons: [
            {
              label: "Ok",
              onClick: () => {
                this.logoutnormal();
              }
            }
          ]
        });
      });
  }

  CreateTemplate = () => {
    var monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ];

    var date =
      new Date().getDate() +
      "-" +
      monthNames[new Date().getMonth()] +
      "-" +
      new Date().getFullYear();

    var systemtime =
      date + " " + new Date().getHours() + ":" + new Date().getMinutes();

    if (this.state.isDaily === true) {
      var windowstarttime = document.getElementById("Dailydate").value;
      var windowendtime = document.getElementById("Dailyedate").value;
    }

    if (
      !document.getElementById("tempDesc").value ||
      !document.getElementById("tempName").value
    ) {
      confirmAlert({
        message: "Please enter the required fields.",
        buttons: [
          {
            label: "Ok",
            onClick: () => {
              return false;
            }
          }
        ]
      });
    } else if (
      this.state.scheduleTemplate == true &&
      document.getElementById("recurrence").value == "Select Interval"
    ) {
      confirmAlert({
        message: "Please select an interval.",
        buttons: [
          {
            label: "Ok",
            onClick: () => {
              return false;
            }
          }
        ]
      });
    } else if (new Date(windowendtime) <= new Date(windowstarttime)) {
      confirmAlert({
        message: "End date/time cannot be earlier than start date/time.",
        buttons: [
          {
            label: "Ok",
            onClick: () => {
              return false;
            }
          }
        ]
      });
    } else if (
      new Date(windowendtime).getHours() <= new Date(windowstarttime).getHours()
    ) {
      confirmAlert({
        message: "End time cannot be earlier than start time.",
        buttons: [
          {
            label: "Ok",
            onClick: () => {
              return false;
            }
          }
        ]
      });
    } else if (new Date(windowendtime) <= new Date(systemtime)) {
      confirmAlert({
        message:
          "End or Start date/time cannot be same or earlier than system date/time.",
        buttons: [
          {
            label: "Ok",
            onClick: () => {
              return false;
            }
          }
        ]
      });
    } else if (new Date(windowstarttime) < new Date(systemtime)) {
      confirmAlert({
        message: "Start date/time cannot be earlier than system date/time.",
        buttons: [
          {
            label: "Ok",
            onClick: () => {
              return false;
            }
          }
        ]
      });
    } else if (
      this.state.scheduleTemplate == true &&
      this.state.isMonthly == true &&
      document.getElementById("recurrenceday").value == "No of Days"
    ) {
      confirmAlert({
        // title: 'Alert !',
        message: "Please select no of days .",
        buttons: [
          {
            label: "Ok",
            onClick: () => {
              return false;
            }
          }
        ]
      });
    } else if (
      this.state.scheduleTemplate == true &&
      this.state.isWeekly == true &&
      document.getElementById("recurrencedayweekly").value == "No of Days"
    ) {
      confirmAlert({
        // title: 'Alert !',
        message: "Please select no of days .",
        buttons: [
          {
            label: "Ok",
            onClick: () => {
              return false;
            }
          }
        ]
      });
    } else if (this.state.isDaily == true && this.state.valueid.length == 0) {
      confirmAlert({
        // title: 'Alert !',
        message: "Please select role.",
        buttons: [
          {
            label: "Ok",
            onClick: () => {
              return false;
            }
          }
        ]
      });
    } else if (
      this.state.scheduleTemplate == true &&
      this.state.StoreList.length == 0
    ) {
      confirmAlert({
        // title: 'Alert !',
        message: "Please upload stores.",
        buttons: [
          {
            label: "Ok",
            onClick: () => {
              return false;
            }
          }
        ]
      });
    } else {
      var Request1 = {};
      if (this.state.scheduleTemplate != true) {
        Request1 = {
          taskType: this.state.valueTaskid.toString(),
          createdBy: this.state.userid,
          taskDesc: document.getElementById("tempDesc").value,
          taskName: document.getElementById("tempName").value,
          guId: this.props.location.state.guId,
          functionalList: this.state.finalFunctional

        };
      } else if (this.state.isMonthly == true || this.state.isWeekly == true) {
        Request1 = {
          taskType: this.state.valueTaskid.toString(),
          createdBy: this.state.userid,
          taskDesc: document.getElementById("tempDesc").value,
          taskName: document.getElementById("tempName").value,
          recurrence: document.getElementById("recurrence").value,
          startDate: document.getElementById("Dailydate").value,
          endDate: document.getElementById("Dailyedate").value,
          noOfDay: document.getElementById("recurrence").value === "Monthly" ? document.getElementById("recurrenceday").value : document.getElementById("recurrencedayweekly").value,
          roleId: this.state.valueid,
          formatId: this.state.format_id,
          storeId: this.state.StoreList,
          taskFile: this.state.previewData,
          guId: this.props.location.state.guId,
          functionalList: this.state.finalFunctional
        };
      } else {
        Request1 = {
          taskType: this.state.valueTaskid.toString(),
          createdBy: this.state.userid,
          taskDesc: document.getElementById("tempDesc").value,
          taskName: document.getElementById("tempName").value,
          recurrence: document.getElementById("recurrence").value,
          startDate: document.getElementById("Dailydate").value,
          endDate: document.getElementById("Dailyedate").value,
          roleId: this.state.valueid,
          formatId: this.state.format_id,
          storeId: this.state.StoreList,
          taskFile: this.state.previewData,
          guId: this.props.location.state.guId,
          functionalList: this.state.finalFunctional
        };
      }
      var EncryptedRequest1 = Encrypt(Request1);

      fetch("/CreateTemplate", {
        method: "POST",
        headers: {
          guId: this.props.location.state.guId,
          Authorization: "Basic " + basicAuth(this.props.location.state.userid)
        },
        body: EncryptedRequest1
      })
        .then(response => response.text())
        .then(response => {
          var DecryptedResponse = decryptData(response);
          if (DecryptedResponse.errorCode === "00") {
            if (DecryptedResponse.unmatchedStore) {
              confirmAlert({
                title: "Success",
                message: "Template created successfully.  " +
                  DecryptedResponse.unmatchedStore +
                  " " +
                  "does not belongs to your format.",
                buttons: [
                  {
                    label: "Ok",
                    onClick: () => {
                      this.createtclose();
                      window.location.reload(true);
                    }
                  }
                ]
              });
            } else {
              confirmAlert({
                title: "Success",
                message: "Template created successfully.  ",
                buttons: [
                  {
                    label: "Ok",
                    onClick: () => {
                      this.createtclose();
                      window.location.reload(true);
                    }
                  }
                ]
              });
            }
          } else {
            confirmAlert({
              title: "Alert !",
              message: DecryptedResponse.errorMsg,
              buttons: [
                {
                  label: "Ok"
                }
              ]
            });
          }
        })
        .catch(error => {
          confirmAlert({
            title: "Alert !",
            message: "Session expired",
            buttons: [
              {
                label: "Ok",
                onClick: () => {
                  this.logoutnormal();
                }
              }
            ]
          });
        });
    }
  };

  deletetask = tskid => {
    confirmAlert({
      message: "Are you sure, you want to delete this template?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            var Request2 = {
              taskId: tskid,
              deletedBy: this.state.userid,
              guId: this.props.location.state.guId
            };
            var EncryptedRequest2 = Encrypt(Request2);
            fetch("/DeleteTemplate", {
              method: "POST",
              headers: {
                guId: this.props.location.state.guId,
                Authorization:
                  "Basic " + basicAuth(this.props.location.state.userid)
              },
              body: EncryptedRequest2
            })
              .then(response => response.text())
              .then(response => {
                var DecryptedResponse2 = decryptData(response);
                if (DecryptedResponse2.errorCode === "00") {
                  confirmAlert({
                    message: "Template  Deleted Successfully.",
                    buttons: [
                      {
                        label: "Ok",
                        onClick: () => {
                          window.location.reload(true);
                        }
                      }
                    ]
                  });
                  this.forceUpdate();
                } else {
                  confirmAlert({
                    title: "Alert !",
                    message: DecryptedResponse2.errorMsg,
                    buttons: [
                      {
                        label: "Ok"
                      }
                    ]
                  });
                }
              })

              .catch(error => {
                confirmAlert({
                  title: "Alert !",
                  message: "Session expired",
                  buttons: [
                    {
                      label: "Ok",
                      onClick: () => {
                        this.logoutnormal();
                      }
                    }
                  ]
                });
              });
          }
        },
        {
          label: "No",
          onClick: () => {
            return false;
          }
        }
      ]
    });
  };

  UpdateTemplate = () => {
    if (
      !document.getElementById("taskupDesc").value ||
      !document.getElementById("taskupName").value ||
      this.state.valueTaskid === "--Select--"
    ) {
      confirmAlert({
        message: "Please enter the required fields",
        buttons: [
          {
            label: "Ok",
            onClick: () => {
              return false;
            }
          }
        ]
      });
    } else if (this.state.showrecurrance == true) {
      var vstartdate = new Date(
        document.getElementById("startdatefortemp").value
      );
      var venddate = new Date(document.getElementById("enddatefortemp").value);
      if (venddate < vstartdate) {
        confirmAlert({
          message: "End date cannot be earlier than start date",
          buttons: [
            {
              label: "Ok",
              onClick: () => {
                return false;
              }
            }
          ]
        });
      } else {
        if (this.state.valueid.length == 0) {
          confirmAlert({
            message: "Please select role.",
            buttons: [
              {
                label: "Ok",
                onClick: () => {
                  return false;
                }
              }
            ]
          });
        } else {
          if (this.state.responserecurrence == "Monthly") {
            if (document.getElementById("updateday").value == "No of Days") {
              confirmAlert({
                message: "Select no of days",
                buttons: [
                  {
                    label: "Ok",
                    onClick: () => {
                      return false;
                    }
                  }
                ]
              });
            } else {
              confirmAlert({
                message: "Are you sure, you want to update this template?",
                buttons: [
                  {
                    label: "Yes",
                    onClick: () => {
                      var Request2 = {
                        taskId: document.getElementById("taskupID").value,
                        taskDesc: document.getElementById("taskupDesc").value,
                        taskType: this.state.valueTaskid.toString(),
                        taskName: document.getElementById("taskupName").value,
                        updatedBy: this.state.userid,
                        startDate: document.getElementById("startdatefortemp")
                          .value,
                        endDate: document.getElementById("enddatefortemp")
                          .value,
                        storeId: this.state.StoreList,
                        recurrence: document.getElementById("interval").value,
                        roleId: this.state.valueid,
                        formatId: this.state.format_id,
                        noOfDay: document.getElementById("updateday").value,
                        guId: this.props.location.state.guId
                      };
                      var EncryptedRequest2 = Encrypt(Request2);
                      fetch("/UpdateTemplate", {
                        method: "POST",
                        headers: {
                          guId: this.props.location.state.guId,
                          Authorization:
                            "Basic " +
                            basicAuth(this.props.location.state.userid)
                        },
                        body: EncryptedRequest2
                      })
                        .then(response => response.text())
                        .then(response => {
                          var DecryptedResponse2 = decryptData(response);

                          if (DecryptedResponse2.errorCode === "00") {
                            if (DecryptedResponse2.unmatchedStore) {
                              confirmAlert({
                                message: "Template updated successfully. " +
                                  DecryptedResponse2.unmatchedStore +
                                  " " +
                                  "does not belong to your format.",
                                buttons: [
                                  {
                                    label: "Ok",
                                    onClick: () => {
                                      this.modalclose();
                                      window.location.reload(true);
                                    }
                                  }
                                ]
                              });
                              this.forceUpdate();
                            } else {
                              confirmAlert({
                                message: "Template updated successfully.",
                                buttons: [
                                  {
                                    label: "Ok",
                                    onClick: () => {
                                      this.modalclose();
                                      window.location.reload(true);
                                    }
                                  }
                                ]
                              });
                            }
                          } else {
                            confirmAlert({
                              title: "Alert !",
                              message: DecryptedResponse2.errorMsg,
                              buttons: [
                                {
                                  label: "Ok"
                                }
                              ]
                            });
                          }
                        })

                        .catch(error => {
                          confirmAlert({
                            title: "Alert !",
                            message: "Session expired",
                            buttons: [
                              {
                                label: "Ok",
                                onClick: () => {
                                  this.logoutnormal();
                                }
                              }
                            ]
                          });
                        });
                    }
                  },
                  {
                    label: "No",
                    onClick: () => {
                      return false;
                    }
                  }
                ]
              });
            }
          } else {
            confirmAlert({
              message: "Are you sure, you want to update this template?",
              buttons: [
                {
                  label: "Yes",
                  onClick: () => {
                    var Request2 = {
                      taskId: document.getElementById("taskupID").value,
                      taskDesc: document.getElementById("taskupDesc").value,
                      taskType: this.state.valueTaskid.toString(),
                      taskName: document.getElementById("taskupName").value,
                      updatedBy: this.state.userid,
                      startDate: document.getElementById("startdatefortemp")
                        .value,
                      endDate: document.getElementById("enddatefortemp").value,
                      storeId: this.state.StoreList,
                      recurrence: document.getElementById("interval").value,
                      roleId: this.state.valueid,
                      formatId: this.state.format_id,
                      guId: this.props.location.state.guId
                    };
                    var EncryptedRequest2 = Encrypt(Request2);
                    fetch("/UpdateTemplate", {
                      method: "POST",
                      headers: {
                        guId: this.props.location.state.guId,
                        Authorization:
                          "Basic " + basicAuth(this.props.location.state.userid)
                      },
                      body: EncryptedRequest2
                    })
                      .then(response => response.text())
                      .then(response => {
                        var DecryptedResponse2 = decryptData(response);
                        if (DecryptedResponse2.errorCode === "00") {
                          if (DecryptedResponse2.unmatchedStore) {
                            confirmAlert({
                              message: "Template updated successfully. " +
                                DecryptedResponse2.unmatchedStore +
                                " " +
                                "does not belongs to your format.",
                              buttons: [
                                {
                                  label: "Ok",
                                  onClick: () => {
                                    this.modalclose();
                                    window.location.reload(true);
                                  }
                                }
                              ]
                            });
                            this.forceUpdate();
                          } else {
                            confirmAlert({
                              message: "Template updated successfully.",
                              buttons: [
                                {
                                  label: "Ok",
                                  onClick: () => {
                                    this.modalclose();
                                    window.location.reload(true);
                                  }
                                }
                              ]
                            });
                          }
                        } else {
                          confirmAlert({
                            title: "Alert !",
                            message: DecryptedResponse2.errorMsg,
                            buttons: [
                              {
                                label: "Ok"
                              }
                            ]
                          });
                        }
                      })

                      .catch(error => {
                        confirmAlert({
                          title: "Alert !",
                          message: "Session expired",
                          buttons: [
                            {
                              label: "Ok",
                              onClick: () => {
                                this.logoutnormal();
                              }
                            }
                          ]
                        });
                      });
                  }
                },
                {
                  label: "No",
                  onClick: () => {
                    return false;
                  }
                }
              ]
            });
          }
        }
      }
    } else {
      confirmAlert({
        message: "Are you sure, you want to update this template?",
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              var Request2 = {
                taskId: document.getElementById("taskupID").value,
                taskDesc: document.getElementById("taskupDesc").value,
                taskName: document.getElementById("taskupName").value,
                taskType: this.state.valueTaskid.toString(),
                updatedBy: this.state.userid,
                guId: this.props.location.state.guId
              };
              var EncryptedRequest2 = Encrypt(Request2);
              fetch("/UpdateTemplate", {
                method: "POST",
                headers: {
                  guId: this.props.location.state.guId,
                  Authorization:
                    "Basic " + basicAuth(this.props.location.state.userid)
                },
                body: EncryptedRequest2
              })
                .then(response => response.text())
                .then(response => {
                  var DecryptedResponse2 = decryptData(response);
                  if (DecryptedResponse2.errorCode === "00") {
                    if (DecryptedResponse2.unmatchedStore) {
                      confirmAlert({
                        message: "Template updated successfully. " +
                          DecryptedResponse2.unmatchedStore +
                          " " +
                          "does not belong to your format.",
                        buttons: [
                          {
                            label: "Ok",
                            onClick: () => {
                              this.modalclose();
                              window.location.reload(true);
                            }
                          }
                        ]
                      });
                      this.forceUpdate();
                    } else {
                      confirmAlert({
                        message: "Template updated successfully.",
                        buttons: [
                          {
                            label: "Ok",
                            onClick: () => {
                              this.modalclose();
                              window.location.reload(true);
                            }
                          }
                        ]
                      });
                    }
                  } else {
                    confirmAlert({
                      title: "Alert !",
                      message: DecryptedResponse2.errorMsg,
                      buttons: [
                        {
                          label: "Ok"
                        }
                      ]
                    });
                  }
                })

                .catch(error => {
                  confirmAlert({
                    title: "Alert !",
                    message: "Session expired",
                    buttons: [
                      {
                        label: "Ok",
                        onClick: () => {
                          this.logoutnormal();
                        }
                      }
                    ]
                  });
                });
            }
          },
          {
            label: "No",
            onClick: () => {
              return false;
            }
          }
        ]
      });
    }
  };
  CreateTask(e) {
    e.preventDefault();

    this.state.valueTask = this.state.taskType;
    let flag = [];

    for (var i = 0; i < this.state.taskTypeDesc.length; i++) {

      this.state.taskTypeDesc[i] === this.state.taskType
        ? flag.push(this.state.taskTypeid[i])
        : this.state.valueTaskid = "";
    }

    this.state.valueTaskid = flag;

    var monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ];

    var date =
      new Date().getDate() +
      "-" +
      monthNames[new Date().getMonth()] +
      "-" +
      new Date().getFullYear();

    var systemtime =
      date + " " + new Date().getHours() + ":" + new Date().getMinutes();
    var windowstarttime = document.getElementById("startDateCT").value;
    var windowendtime = document.getElementById("endDateCT").value;

    if (
      !document.getElementById("taskNameCT").value ||
      !document.getElementById("taskDescCT").value
    ) {
      confirmAlert({
        title: "Alert !",
        message: "Please enter required fields",
        buttons: [
          {
            label: "Ok",
            onClick: () => {
              return false;
            }
          }
        ]
      });
    }

    else {
      var vstartdate = new Date(document.getElementById("startDateCT").value);
      var venddate = new Date(document.getElementById("endDateCT").value);
      if (venddate < vstartdate) {
        confirmAlert({
          message: "End date/time cannot be earlier than start date/time.",
          buttons: [
            {
              label: "Ok",
              onClick: () => {
                return false;
              }
            }
          ]
        });
      } else if (new Date(windowendtime) <= new Date(systemtime)) {
        confirmAlert({
          message:
            "End or Start date/time cannot be same or earlier than system date/time.",
          buttons: [
            {
              label: "Ok",
              onClick: () => {
                return false;
              }
            }
          ]
        });
      } else if (new Date(windowstarttime) < new Date(systemtime)) {
        confirmAlert({
          message: "Start date/time cannot be earlier than system date/time.",
          buttons: [
            {
              label: "Ok",
              onClick: () => {
                return false;
              }
            }
          ]
        });
      } else {
        var Request1 = {
          taskType: this.state.valueTaskid.toString(),
          startDate: document.getElementById("startDateCT").value,
          endDate: document.getElementById("endDateCT").value,
          createdBy: this.state.userid,
          taskDesc: document.getElementById("taskDescCT").value,
          taskName: document.getElementById("taskNameCT").value,
          storeId: this.state.StoreList,
          action: "Create",
          roleId: this.state.valueid,
          formatId: this.state.format_id,
          taskFile: this.state.previewData,
          templateId: this.state.templetId,
          guId: this.props.location.state.guId,
          uploadType: "1",
          functionalList: this.state.finalFunctional,
          taskFile: this.state.taskFile,
          taskFile1: this.state.taskFile1,
          taskFile2: this.state.taskFile2
        };
        var EncryptedRequest1 = Encrypt(Request1);

        fetch("/CreateTask", {
          method: "POST",
          headers: {
            guId: this.props.location.state.guId,
            Authorization:
              "Basic " + basicAuth(this.props.location.state.userid)
          },
          body: EncryptedRequest1
        })
          .then(response => response.text())
          .then(response => {
            var DecryptedResponse = decryptData(response);

            if (DecryptedResponse.errorCode === "00") {
              if (DecryptedResponse.unmatchedStore) {
                confirmAlert({
                  title: "Success",
                  message:
                    "Task created successfully." +
                    DecryptedResponse.unmatchedStore +
                    " " +
                    "does not belongs to your format.",
                  buttons: [
                    {
                      label: "Ok",
                      onClick: () => {
                        this.props.history.push({
                          pathname: "/Taskactive",
                          state: {
                            userid: this.props.location.state.userid,
                            usrnm: this.props.location.state.usrnm,
                            role_id: this.props.location.state.role_id,
                            format_id: this.props.location.state.format_id,
                            storeNo: this.props.location.state.storeNo,
                            guId: this.props.location.state.guId,
                            menuList: this.props.location.state.menuList,
                            roleName: this.props.location.state.roleName,
                          }
                        });
                      }
                    }
                  ]
                });
              } else {
                confirmAlert({
                  title: "Success",
                  message: "Task created successfully.  ",
                  buttons: [
                    {
                      label: "Ok",
                      onClick: () => {
                        this.props.history.push({
                          pathname: "/Taskactive",
                          state: {
                            userid: this.props.location.state.userid,
                            usrnm: this.props.location.state.usrnm,
                            role_id: this.props.location.state.role_id,
                            format_id: this.props.location.state.format_id,
                            storeNo: this.props.location.state.storeNo,
                            guId: this.props.location.state.guId,
                            menuList: this.props.location.state.menuList,
                            roleName: this.props.location.state.roleName,
                          }
                        });
                      }
                    }
                  ]
                });
              }
            } else {
              confirmAlert({
                title: "Alert !",
                message: DecryptedResponse.errorMsg,
                buttons: [
                  {
                    label: "Ok"
                  }
                ]
              });
            }
          })

          .catch(error => {
            confirmAlert({
              title: "Alert !",
              message: "Session expired",
              buttons: [
                {
                  label: "Ok",
                  onClick: () => {
                    this.logoutnormal();
                  }
                }
              ]
            });
          });
      }
    }
  }

  ToStoreList = () => {
    this.props.history.push({
      pathname: "/StoreList",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        formerMasterList: this.state.formerMasterList,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  Storecreation = () => {
    this.props.history.push({
      pathname: "/Storecreation",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        formerMasterList: this.state.formerMasterList,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  toGenDashboard = () => {
    this.props.history.push({
      pathname: "/DashboardGeneral",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        data: this.props.location.state.data,
        tskId: this.props.location.state.tskId,
        tskName: this.props.location.state.tskName,
        storeNo: this.props.location.state.storeNo,
        Isroffice: this.props.location.state.Isroffice,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  callupdatetaskpage(tskId, tskName, tskDescr, strtDate, edDate) {
    this.props.history.push({
      pathname: "/Updatetasks",
      state: {
        tskId: tskId,
        tskName: tskName,
        tskDescr: tskDescr,
        strtDate: new Date(strtDate),
        edDate: new Date(edDate),
        userid: this.props.location.state.userid,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        usrnm: this.props.location.state.usrnm,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  }
  calladdquestionpage(
    tskId,
    tskName,
    tskDescr,
    strtDate,
    edDate,
    publishStatus
  ) {
    this.props.history.push({
      pathname: "/Addquestion",
      state: {
        tskId: tskId,
        tskName: tskName,
        tskDescr: tskDescr,
        strtDate: strtDate,
        edDate: edDate,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        storeNo: this.props.location.state.storeNo,
        publishStatus: publishStatus,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  }

  calladdtemplatepage(tskId, tskName, tskDescr, taskType) {
    this.props.history.push({
      pathname: "/Addtemplate",
      state: {
        tskId: tskId,
        tskName: tskName,
        tskDescr: tskDescr,
        taskType: taskType,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  }

  StoreTasks = () => {
    this.props.history.push({
      pathname: "/StoreTasks",
      state: {
        data: this.state.data,
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  getroles(e) {
    this.props.history.push({
      pathname: "/CreatetaskUI",
      state: {
        format_id: this.props.location.state.format_id,
        role_id: this.props.location.state.role_id,
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  }

  TaskactiveBind = () => {
    this.props.history.push({
      pathname: "/Taskactive",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  ArchivedTasks = () => {
    this.props.history.push({
      pathname: "/ArchivedTasks",
      state: {
        data: this.state.data,
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
        Isroffice: this.props.location.state.Isroffice,
      }
    });
  };

  templates = () => {
    this.props.history.push({
      pathname: "/Templates",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  Changepasswordbind = () => {
    this.props.history.push({
      pathname: "/ChangepasswordUI",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  SmartPointBind = () => {
    this.props.history.push({
      pathname: "/SmartPoint",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        data: this.props.location.state.data,
        tskId: this.props.location.state.tskId,
        tskName: this.props.location.state.tskName,
        storeNo: this.props.location.state.storeNo,
        Isroffice: this.props.location.state.Isroffice,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  Defectbind = () => {
    this.props.history.push({
      pathname: "/DefectRaising",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        data: this.props.location.state.data,
        tskId: this.props.location.state.tskId,
        tskName: this.props.location.state.tskName,
        storeNo: this.props.location.state.storeNo,
        Isroffice: this.props.location.state.Isroffice,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  Reportsbind = () => {
    this.props.history.push({
      pathname: "/Reports",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        data: this.state.data,
        tskId: this.state.tskId,
        tskName: this.state.tskName,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  CompletedTaskListbind = () => {
    this.props.history.push({
      pathname: "/CompletedTaskList",
      state: {
        data: this.state.data,
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };
  fetchrole(e) {
    if (this.props.location.state) {
      //alert("getroles called "+ this.props.role_id);
      this.setState({ roleId: this.props.location.state.role_id });
      this.setState({ formatId: this.props.location.state.format_id });
      this.setState({ userid: this.props.location.state.userid });
      this.setState({ usrnm: this.props.location.state.usrnm });

      var Request = {
        formatId: this.props.location.state.format_id,
        userId: this.props.location.state.userid,
        guId: this.props.location.state.guId
      };
      var EncryptedRequest = Encrypt(Request);

      fetch("/GetRolesByFormat", {
        method: "POST",
        headers: {
          guId: this.props.location.state.guId,
          Authorization: "Basic " + basicAuth(this.props.location.state.userid)
        },
        body: EncryptedRequest
      })
        .then(response => response.text())
        .then(response => {
          var DecryptedResponse = decryptData(response);

          if (DecryptedResponse.errorCode === "00") {
            this.setState({ roleList: DecryptedResponse.roleList });
            let temp = [];
            let temp1 = [];
            let temp2 = [];
            for (var i = 0; i < DecryptedResponse.roleList.length; i++) {
              temp.push(DecryptedResponse.roleList[i].roleDescription);
              temp1.push(DecryptedResponse.roleList[i].role_id);
              temp2.push(DecryptedResponse.roleList[i].format_id);
            }
            this.setState({ rlistopt: temp });
            this.setState({ rlistid: temp1 });
          } else {
            confirmAlert({
              message: DecryptedResponse.errorMsg,
              buttons: [
                {
                  label: "Ok",
                  onClick: () => {
                    return false;
                  }
                }
              ]
            });
          }
        })
        .catch(error => {
          confirmAlert({
            title: "Alert !",
            message: "Session expired",
            buttons: [
              {
                label: "Ok",
                onClick: () => {
                  this.logoutnormal();
                }
              }
            ]
          });
        });
    } else {
      this.props.history.push({
        pathname: "/"
      });
    }
  }
}
export default withRouter(Templates);
