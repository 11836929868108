/* eslint-disable no-lone-blocks */
import React, { Component } from "react";
import DatePicker from "react-datepicker";
import { confirmAlert } from "react-confirm-alert"; // Import
import "./css/react-confirm-alert.css"; // Import css
import addDays from "date-fns/addDays";
import IdleTimer from "react-idle-timer"; // For Idle
import "./css/style.css";
import "./css/style-responsive.css";
import { Encrypt, decryptData } from "./Encryption-Decrypytion";
import disableBrowserBackButton from "disable-browser-back-navigation";
import "react-datepicker/dist/react-datepicker.css";
import { withRouter } from "react-router-dom";
import { BrowserRouter as Router } from "react-router-dom";
import readXlsxFile from "read-excel-file";
import ReactExport from "react-data-export";
import FormDataService from "./FormDataService";
import { basicAuth } from "./BasicAuth";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class Updatetasks extends Component {
  state = {
    TaskDropdwn: false,
    displayMenu: false,
    hasError: false
  };
  showTaskDropdwnHandler = () => {
    this.setState({
      TaskDropdwn: !this.state.TaskDropdwn
    });
  };

  constructor(props) {
    super(props);
    this.state = {
      tskDescr: "",
      FileName: "",
      tskName: "",
      taskRoleId: [],
      strtDate: new Date(),
      edDate: new Date(),
      StoreList: "StoreList",
      showAction: false,
      usrnm: "",
      userid: "",
      roleId: "",
      loaded: this.props.location.state,
      taskStores: [],
      hasError: false,
      hammenu: true,
      taskRoleIdes: [],
      guId: this.props.location.state.guId,
      menuList: this.props.location.state.menuList,
      tskId: this.props.location.state.tskId,
      TaskDropdwn: false,
      displayMenu: false,
      taskTypeList: [],
      taskTypeDesc: [],
      taskTypeid: [],
      valueTaskid: "",
      instructionNo: "",
      instructionUpload1: "",
      instructionUpload2: "",
      instructionUpload3: "",
    };
    this.handleChangeStart = this.handleChangeStart.bind(this);
    this.handleChangeEnd = this.handleChangeEnd.bind(this);
    this.showDropdownMenu = this.showDropdownMenu.bind(this);
    this.hideDropdownMenu = this.hideDropdownMenu.bind(this);
    this.CheckProps.bind(this);
    this.idleTimer = null;
    this.onAction = this._onAction.bind(this);
    this.onActive = this._onActive.bind(this);
    this.onIdle = this._onIdle.bind(this);
  }

  logoutnormal = () => {
    localStorage.clear();
    this.props.history.replace("/");
    disableBrowserBackButton();
  };

  changeTaskType = e => {
    this.setState({ valueTask: e.target.value });

    let flag = [];

    for (var i = 0; i < this.state.taskTypeDesc.length; i++) {

      this.state.taskTypeDesc[i] === e.target.value
        ? flag.push(this.state.taskTypeid[i])
        : this.setState({ valueTaskid: "" });
    }
    this.setState({ valueTaskid: flag });
    this.state.valueTaskid = flag;
  };



  _onAction(e) {
  }

  _onActive(e) {
  }

  _onIdle(e) {
    localStorage.clear();
    window.location.href = "/";
    disableBrowserBackButton();
  }

  componentWillMount() {
    if (
      this.props.location.state === undefined ||
      this.props.location.state === "" ||
      this.props.location.state.menuList === undefined
    ) {
      window.location.href = "/";
    } else {
      this.state.valueTask = this.props.location.state.taskType

      this.SetUpdateTask();
    }
  }

  showDropdownMenu(event) {
    event.preventDefault();
    this.setState({ displayMenu: true }, () => {
      document.addEventListener("click", this.hideDropdownMenu);
    });
  }

  hideDropdownMenu() {
    this.setState({ displayMenu: false }, () => {
      document.removeEventListener("click", this.hideDropdownMenu);
    });
  }

  componentDidMount() {
    if (window.innerWidth <= 768) {
      this.setState({ hammenu: false });
    }
    console.log('tasktype', this.props.location.state.taskType);
  }

  logout = () => {
    this.setState({ loading: true });
    var Request1 = {
      userId: this.props.location.state.userid,
      guId: this.props.location.state.guId
    };
    var EncryptedRequest1 = Encrypt(Request1);
    fetch("/LogOut ", {
      method: "POST",
      headers: {
        guId: this.props.location.state.guId,
        Authorization: "Basic " + basicAuth(this.props.location.state.userid)
      },
      body: EncryptedRequest1
    })
      .then(response => response.text())
      .then(response => {
        this.setState({ loading: false });
        var DecryptedResponse = decryptData(response);
        if (DecryptedResponse.errorCode === "00") {
          this.props.history.replace("/");
          disableBrowserBackButton();
        } else {
          confirmAlert({
            message: DecryptedResponse.errorMsg,
            buttons: [
              {
                label: "Ok"
              }
            ]
          });
        }
      })
      .catch(error => {
        confirmAlert({
          title: "Alert !",
          message: "Session expired",
          buttons: [
            {
              label: "Ok",
              onClick: () => {
                this.logoutnormal();
              }
            }
          ]
        });
      });
  };

  handleChangeStart(date) {
    this.setState({
      strtDate: date
    });
  }

  handleChangeEnd(date) {
    this.setState({
      edDate: date
    });
  }

  submit = () => {
    confirmAlert({
      message: "Are you sure, you want to update the task?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            this.UpdateTask.bind(this);
          }
        },
        {
          label: "No",
          onClick: () => {
            this.forceUpdate();
          }
        }
      ]
    });
  };

  fileHandler = event => {
    const input = document.getElementById("upload-storedata");
    var exits = [];
    readXlsxFile(input.files[0]).then((rows, files) => {
      for (let index = 0; index < rows.length; index++) {
        const element = rows[index];
        exits.push(element[0]);
      }
      this.setState({
        StoreList: exits,
        FileName: "File Uploaded Successfully",
        showAction: true
      });
    });
  };


  InstructionHandler = async (e, params) => {
    try {
      let files = e.target.files;
      var Request1 = {
        taskId: this.state.tskId,
        instructionNo: params.toString(),
        guId: this.props.location.state.guId
      };

      var EncryptedRequest1 = Encrypt(Request1);
      this.setState({ loading: true });
      const response = await FormDataService.uploadFormData(
        files[0],
        EncryptedRequest1,
        this.props.location.state.guId,
        "Basic " + basicAuth(this.props.location.state.userid)
      );
      var DecryptedResponse = decryptData(response.data);
      if (params === 1) {
        this.setState({
          instructionUpload1: "File Uploaded Successfully",
          loading: false
        });
        this.state.instructionUpload1 = "File Uploaded Successfully";
      } else if (params === 2) {
        this.setState({
          instructionUpload2: "File Uploaded Successfully",
          loading: false
        });
      } else if (params === 3) {
        this.setState({
          instructionUpload3: "File Uploaded Successfully",
          loading: false
        });
      }

      confirmAlert({
        message: DecryptedResponse.errorMsg,
        buttons: [
          {
            label: "Ok"
          }
        ]
      });

    } catch (error) {
      this.setState({ instructionUpload1: "", instructionUpload2: "", instructionUpload3: "", loading: false });

      confirmAlert({
        message: "File not saved.",
        buttons: [
          {
            label: "Ok"
          }
        ]
      });
    }
  };

  componentDidCatch() {
    this.setState({ hasError: true });
  }

  userlist = () => {
    this.props.history.push({
      pathname: "/UserList",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };
  tiker = () => {
    this.props.history.push({
      pathname: "/Tiker",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  showTaskdwnHandler = () => {
    this.setState({
      Taskdwn: !this.state.Taskdwn
    });
  };

  usercreation = () => {
    this.props.history.push({
      pathname: "/UserCreation",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  render = () => {
    if (this.state.hasError) {
      return (
        <div>
          <h2>Error occurred!! please contact administrator</h2>
        </div>
      );
    } else {
      return (
        <Router>
          {this.state.loaded ? (
            <div>
              <section id="container">
                <div>
                  <IdleTimer
                    ref={ref => {
                      this.idleTimer = ref;
                    }}
                    element={document}
                    onActive={this.onActive}
                    onIdle={this.onIdle}
                    onAction={this.onAction}
                    debounce={250}
                    timeout={1000 * 60 * 10}
                  />
                </div>
                <header class="header black-bg">
                  <a
                    className="mob-show"
                    onClick={() =>
                      this.setState({ hammenu: !this.state.hammenu })
                    }
                  >
                    <i
                      className="fa fa-tasks hammenu"
                      style={{ "margin-top": "25px", 'padding': '0 15px' }}
                    ></i>
                  </a>

                  <a onClick={this.TaskactiveBind.bind(this)} className="logo" style={{ 'padding': '0 10px' }}>
                    <img src={require("./img/retail-logo.png")} alt="logo" />
                  </a>
                  <div className="markcenter">
                    <p className="marquee">{this.state.tickerList}</p>
                  </div>
                  <ul className="nav pull-right pos-rel">
                    <li className="dropdown">
                      <a
                        className="dropdown-toggle"
                        data-toggle="dropdown"
                        onClick={this.showDropdownMenu}
                      >
                        {" "}
                        <img
                          src={require("./img/user.png")}
                          className="user-img"
                        />{" "}
                        <b className="name-show">{this.props.location.state.usrnm}</b>
                        ({this.props.location.state.storeNo})

                        <b className="caret" />
                      </a>
                    </li>

                    {this.state.displayMenu ? (
                      <ul className="dropdown-menuuser-dd">
                        <li>
                          Role : {this.props.location.state.roleName}
                        </li>
                        <li className="divider"></li>
                        <li>
                          <a onClick={this.Changepasswordbind}>Change Password</a>
                        </li>
                        <li className="divider"></li>
                        <li>
                          <a onClick={this.logout}>Log Out</a>
                        </li>
                      </ul>
                    ) : null}
                  </ul>
                </header>
                <aside>
                  <div id="sidebar" className="nav-collapse">
                    {this.state.hammenu ? (
                      <ul className="sidebar-menu" id="nav-accordion">
                        {this.props.location.state.menuList["401"] || this.props.location.state.menuList["402"] || this.props.location.state.menuList["403"] || this.props.location.state.menuList["404"] || this.props.location.state.menuList["405"] || this.props.location.state.menuList["408"] || this.props.location.state.menuList["411"] ? (
                          <li
                            className="sub-menu mt"
                            onClick={this.showTaskDropdwnHandler.bind(this)}
                          >
                            {" "}
                            <a href="javascript:;">
                              {" "}
                              <i className="fa fa-tasks"></i> <span>Task</span>{" "}
                            </a>
                            {this.state.TaskDropdwn ? (
                              <div>
                                <ul class="sub">
                                  {this.props.location.state.menuList["401"] ? (
                                    <li>
                                      <a onClick={this.TaskactiveBind.bind(this)}>
                                        {this.props.location.state.menuList["401"]}
                                      </a>
                                    </li>
                                  ) : null}
                                  {this.props.location.state.menuList["402"] ? (
                                    <li>
                                      <a onClick={this.getroles.bind(this)}>
                                        {this.props.location.state.menuList["402"]}
                                      </a>
                                    </li>
                                  ) : null}
                                  {this.props.location.state.menuList["403"] ? (
                                    <li>
                                      <a
                                        onClick={this.CompletedTaskListbind.bind(
                                          this
                                        )}
                                      >
                                        {this.props.location.state.menuList["403"]}
                                      </a>
                                    </li>
                                  ) : null}
                                  {this.props.location.state.menuList["404"] ? (
                                    <li>
                                      <a onClick={this.templates.bind(this)}>
                                        {this.props.location.state.menuList["404"]}
                                      </a>
                                    </li>
                                  ) : null}
                                  {this.props.location.state.menuList["405"] ? (
                                    <li>
                                      <a onClick={this.ArchivedTasks.bind(this)}>
                                        {this.props.location.state.menuList["405"]}
                                      </a>
                                    </li>
                                  ) : null}
                                  {this.props.location.state.menuList["408"] ? (
                                    <li>
                                      <a onClick={this.StoreTasks.bind(this)}>
                                        {this.props.location.state.menuList["408"]}
                                      </a>
                                    </li>
                                  ) : null}
                                  {this.props.location.state.menuList["411"] ? (
                                    <li>
                                      <a onClick={this.toGenDashboard.bind(this)}>
                                        {this.props.location.state.menuList["411"]}
                                      </a>
                                    </li>
                                  ) : null}
                                </ul>
                              </div>
                            ) : null}
                          </li>
                        ) : null}
                        {this.props.location.state.menuList["406"] ? (
                          <div>
                            <li>
                              {" "}
                              <a onClick={this.Reportsbind.bind(this)}>
                                {" "}
                                <i className="fa fa-tasks"></i>{" "}
                                <span>
                                  {this.props.location.state.menuList["406"]}
                                </span>{" "}
                              </a>{" "}
                            </li>
                          </div>
                        ) : null}

                        {this.props.location.state.menuList["414"] && this.props.location.state.format_id === "102" ? (
                          <div>
                            <li>
                              {" "}
                              <a onClick={this.Defectbind.bind(this)}>
                                {" "}
                                <i className="fa fa-tasks"></i>{" "}
                                <span>
                                  {this.props.location.state.menuList["414"]}
                                </span>{" "}
                              </a>{" "}
                            </li>
                          </div>
                        ) : null}

                        {this.props.location.state.menuList["415"] ? (
                          <div>
                            <li>
                              {" "}
                              <a onClick={this.SmartPointBind.bind(this)}>
                                {" "}
                                <i className="fa fa-tasks"></i>{" "}
                                <span>
                                  {this.props.location.state.menuList["415"]}
                                </span>{" "}
                              </a>{" "}
                            </li>
                          </div>
                        ) : null}

                        {this.props.location.state.menuList["409"] || this.props.location.state.menuList["410"] ? (
                          <div>
                            <li
                              className="sub-menu"
                              onClick={this.showIssueDropdwnHandler.bind(this)}
                            >
                              {" "}
                              <a href="javascript:;">
                                {" "}
                                <i className="fa fa-tasks"></i>{" "}
                                <span>Issue</span>{" "}
                              </a>
                              {this.state.IssueDropdwn ? (
                                <div>
                                  <ul class="sub">
                                    {this.props.location.state.menuList["410"] ? (
                                      <li>
                                        <a onClick={this.IssueRaising.bind(this)}>
                                          {this.props.location.state.menuList["410"]}
                                        </a>
                                      </li>
                                    ) : null}
                                    {this.props.location.state.menuList["409"] ? (
                                      <li>
                                        <a onClick={this.IssueDashboard.bind(this)}>
                                          {this.props.location.state.menuList["409"]}
                                        </a>
                                      </li>
                                    ) : null}
                                  </ul>
                                </div>
                              ) : null}
                            </li>
                          </div>
                        ) : null}

                        {this.props.location.state.menuList["407"] ? (
                          <div>
                            <li
                              className="sub-menu"
                              onClick={this.showTaskdwnHandler.bind(this)}
                            >
                              {" "}
                              <a href="javascript:;">
                                {" "}
                                <i className="fa fa-tasks"></i>{" "}
                                <span>
                                  {this.props.location.state.menuList["407"]}
                                </span>{" "}
                              </a>
                              {this.state.Taskdwn ? (
                                <div>
                                  <ul class="sub">
                                    <li>
                                      {" "}
                                      <a onClick={this.tiker.bind(this)}>
                                        <span>Tiker Creation</span>{" "}
                                      </a>{" "}
                                    </li>
                                    <li>
                                      {" "}
                                      <a onClick={this.userlist.bind(this)}>
                                        <span>User List</span>{" "}
                                      </a>{" "}
                                    </li>
                                    {this.props.location.state.menuList["412"] ? (
                                      <li>
                                        {" "}
                                        <a onClick={this.Storecreation.bind(this)}>
                                          <span>{this.props.location.state.menuList["412"]}</span>{" "}
                                        </a>{" "}
                                      </li>
                                    ) : null}
                                    {this.props.location.state.menuList["413"] ? (
                                      <li>
                                        {" "}
                                        <a onClick={this.ToStoreList.bind(this)}>
                                          <span>{this.props.location.state.menuList["413"]}</span>{" "}
                                        </a>{" "}
                                      </li>
                                    ) : null}
                                  </ul>
                                </div>
                              ) : null}
                            </li>
                          </div>
                        ) : null}
                      </ul>
                    ) : null}
                  </div>
                </aside>
                <section id="main-content">
                  <section class="wrapper">
                    <div class="mt">
                      <h3>
                        <i class="fa fa-angle-right"></i> Update Task
                      </h3>
                      <hr />
                      <div class="row mt" onLoad={this.CheckProps.bind(this)}>
                        <form method="post">
                          <div class="col-lg-2 col-md-2 col-sm-2 col-xs-12">
                            <label>Task ID</label>
                            <br />
                            <input
                              ref="taskid"
                              type="text"
                              className="form-control"
                              size="16"
                              placeholder={this.state.tskId}
                              id="taskID"
                              disabled="disabled"
                            />
                          </div>
                          <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                            <label>Task Name</label>
                            <br />
                            <input
                              ref="taskname"
                              type="text"
                              class="form-control"
                              onChange={f =>
                                this.setState({ tskName: f.target.value })
                              }
                              value={this.state.tskName}
                              id="taskName"
                              autoComplete="off"
                              maxlength="250"
                            />
                          </div>
                          <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 ">
                            <label>Start Date</label>
                            <br />
                            <DatePicker
                              ref="startdate"
                              className="form-control form-control-inline input-medium default-date-picker "
                              size="16"
                              type="text"
                              value={this.state.strtDate}
                              dateFormat="dd-MMM-yyyy HH:mm"
                              id="startDate"
                              selected={this.state.strtDate}
                              showTimeSelect
                              timeIntervals={30}
                              minDate={new Date()}
                              onChange={this.handleChangeStart}
                            />
                          </div>
                          <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 ">
                            <label>End Date</label>
                            <br />
                            <DatePicker
                              ref="enddate"
                              className="form-control form-control-inline input-medium default-date-picker"
                              size="16"
                              type="text"
                              dateFormat="dd-MMM-yyyy HH:mm"
                              id="endDate"
                              onChange={this.handleChangeEnd}
                              minDate={this.state.strtDate}
                              showTimeSelect
                              timeIntervals={30}
                              maxDate={addDays(new Date(), 365)}
                              selected={this.state.edDate}
                            />
                          </div>

                          {/* <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12" hidden>
              <label>Role</label>
              <br/>
              <select id="multi-select" className="form-control" multiple="multiple" onChange={this.change.bind(this)}>
              {this.state.rlistopt.map((element)=>(<option>{element}</option>))}
              </select>
            </div> */}
                          <div class="clearfix"></div>
                          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-20">
                            <label>Task Description</label>
                            <br />
                            <textarea
                              ref="taskdescr"
                              onChange={e =>
                                this.setState({ tskDescr: e.target.value })
                              }
                              type="text"
                              class="form-control"
                              value={this.state.tskDescr}
                              id="taskDesc"
                              maxLength="250"
                            ></textarea>
                          </div>
                          <div class="clearfix"></div>
                          <div class="mt-20">
                            <div class="col-lg-2 col-md-2 col-sm-2 col-xs-12">
                              <label>Task Type</label>
                              <br />
                              <select
                                class="form-control"
                                onChange={this.changeTaskType.bind(this)}
                                id="taskTypeId"
                              >
                                <option>{this.props.location.state.taskType}</option>
                                {this.state.taskTypeDesc.map(element => (
                                  this.props.location.state.taskType == element ? null : <option>{element}</option>
                                ))}
                                {/* this.props.location.state.menuList.map((element) => (<option>{element}</option>)) */}
                              </select>
                            </div>
                            {this.props.location.state.role_id === "300" ?
                              <div class="col-lg-2 col-md-2 col-sm-2 col-xs-12">
                                <label>Functions Involved</label>
                                <br />
                                <input
                                  ref="taskid"
                                  type="text"
                                  className="form-control"
                                  placeholder={this.props.location.state.functionalList}
                                  id="taskID"
                                  disabled="disabled"
                                />
                              </div>
                              : null}
                            {this.props.location.state.taskType !== "Issue" ?
                              <div class="form-group col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                <label>Task Instruction (Optional)</label>
                                <br />
                                <div class="upload-holder">
                                  <input
                                    id="instructions"
                                    type="text"
                                    class="form-control input-upload"
                                    placeholder="Upload Instructions"
                                    value={this.state.instructionUpload1}
                                  />
                                  <span class="btn-upload">Upload</span>
                                  <input
                                    id="upload-instructions"
                                    type="file"
                                    name="Instruction-data"
                                    class="input-hidden"
                                    onChange={e => this.InstructionHandler(e, 1)}
                                    value=""
                                    accept=".gif,.jpg,.jpeg,.png,.doc,.docx,.pptx,.ppt,.xlsx,.xls,.txt,.pdf,.zip"
                                  />
                                </div>
                              </div>
                              : null}
                            {/* this.props.location.state.taskFile ? (
                          <div
                            class="col-md-6 col-lg-6 col-sm-6 col-xs-12"
                            style={{ marginTop: "10px" }}
                          >
                            <a onClick={this.openIns.bind(this, 1)}>
                              View Instruction
                            </a>
                            /
                            <a
                              download="instructions"
                              onClick={this.DownloadIns.bind(this, 1)}
                            >
                              Download Instruction
                            </a>
                            <a
                              download={this.props.location.state.taskFile}
                              href={"/IMAGES/" + this.props.location.state.taskFile}
                              id="FileDnld"
                              hidden
                            />
                          </div>
                        ) : null */}

                            {this.props.location.state.taskType !== "Issue" ?
                              <div class="form-group col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                <label>Reference File 1 (Optional)</label>
                                <br />
                                <div class="upload-holder">
                                  <input
                                    id="instructions"
                                    type="text"
                                    class="form-control input-upload"
                                    placeholder="Upload Instructions"
                                    value={this.state.instructionUpload2}
                                  />
                                  <span class="btn-upload">Upload</span>
                                  <input
                                    id="upload-instructions"
                                    type="file"
                                    name="Instruction-data"
                                    class="input-hidden"
                                    onChange={e => this.InstructionHandler(e, 2)}
                                    value=""
                                    accept=".gif,.jpg,.jpeg,.png,.doc,.docx,.pptx,.ppt,.xlsx,.xls,.txt,.pdf,.zip"
                                  />
                                </div>
                              </div>
                              : null}

                            {/* this.props.location.state.taskFile1 ? (
                        <div
                          class="col-md-6 col-lg-6 col-sm-6 col-xs-12"
                          style={{ marginTop: "10px" }}
                        >
                          <a onClick={this.openIns.bind(this, 1)}>
                            View Instruction
                          </a>
                          /
                          <a
                            download="instructions"
                            onClick={this.DownloadIns.bind(this, 1)}
                          >
                            Download Instruction
                          </a>
                          <a
                            download={this.props.location.state.taskFile1}
                            href={"/IMAGES/" + this.props.location.state.taskFile1}
                            id="FileDnld"
                            hidden
                          />
                        </div>
                      ) : null */}

                            <div class="clearfix"></div>
                            {this.props.location.state.taskType !== "Issue" ?
                              <div class="form-group col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                <label>Reference File 2 (Optional)</label>
                                <br />
                                <div class="upload-holder">
                                  <input
                                    id="instructions"
                                    type="text"
                                    class="form-control input-upload"
                                    placeholder="Upload Instructions"
                                    value={this.state.instructionUpload3}
                                  />
                                  <span class="btn-upload">Upload</span>
                                  <input
                                    id="upload-instructions"
                                    type="file"
                                    name="Instruction-data"
                                    class="input-hidden"
                                    onChange={e => this.InstructionHandler(e, 3)}
                                    value=""
                                    accept=".gif,.jpg,.jpeg,.png,.doc,.docx,.pptx,.ppt,.xlsx,.xls,.txt,.pdf,.zip"
                                  />
                                </div>
                              </div>
                              : null}
                            {this.props.location.state.taskType !== "Issue" ?
                              <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                <label>Store / Individual (Optional)</label>
                                <br />
                                <div class="upload-holder">
                                  <input
                                    id="store-list"
                                    type="text"
                                    class="form-control input-upload"
                                    value={this.state.FileName}
                                  />
                                  <span class="btn-upload">Upload</span>
                                  <input
                                    id="upload-storedata"
                                    type="file"
                                    name="Store-list-data"
                                    class="input-hidden"
                                    onChange={this.fileHandler.bind(this)}
                                    value=""
                                    accept=".xlsx,.xls"
                                  />
                                </div>
                                <span class="help-block">
                                  Sample File{" "}
                                  <a href={require("./doc/sample.xlsx")}>
                                    Sample.xls
                                  </a>
                                </span>
                                <span>Existing store / individual data </span>
                                <ExcelFile element={<a> Download</a>}>
                                  <ExcelSheet
                                    data={this.state.taskStores}
                                    name="Store Details"
                                  >
                                    <ExcelColumn
                                      label="Assigned To"
                                      value="storeNo"
                                    />
                                    <ExcelColumn label="Name" value="storeName" />
                                  </ExcelSheet>
                                </ExcelFile>
                              </div>
                              : null}
                            {this.state.showAction ? (
                              <div id="showaction" className="mh-100">
                                {/* <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
            <div>
              <label>Selected Stores</label>
              <br/>
              <input type="text" className="form-control" value={this.state.StoreList}/>
            </div>
          </div> */}
                                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                  <label>Action on selected stores</label>
                                  <select
                                    class="form-control"
                                    //onChange={this.changeqtype.bind(this)}
                                    id="SelActType"
                                  >
                                    <option>Add</option>
                                    <option>Delete</option>
                                  </select>
                                </div>
                              </div>
                            ) : null}

                          </div>

                          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                            <button
                              class="btn btn-primary min-wid-90 mt-17"
                              onClick={this.UpdateTask.bind(this)}
                            >
                              Update
                            </button>
                            <button
                              class="btn btn-primary min-wid-90 ml-10 mt-17"
                              onClick={this.BackPage.bind(this)}
                            >
                              Cancel
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </section>
                </section>
              </section>
            </div>
          ) : (
            this.props.history.push({
              pathname: "/"
            })
          )}
        </Router>
      );
    }
  };

  showIssueDropdwnHandler = () => {
    this.setState({
      IssueDropdwn: !this.state.IssueDropdwn
    });
  };

  IssueRaising = () => {
    this.props.history.push({
      pathname: "/IssueRaising",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        data: this.props.location.state.data,
        tskId: this.props.location.state.tskId,
        tskName: this.props.location.state.tskName,
        storeNo: this.props.location.state.storeNo,
        Isroffice: this.props.location.state.Isroffice,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  IssueDashboard = () => {
    this.props.history.push({
      pathname: "/IssueDashboard",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        data: this.props.location.state.data,
        tskId: this.props.location.state.tskId,
        tskName: this.props.location.state.tskName,
        storeNo: this.props.location.state.storeNo,
        Isroffice: this.props.location.state.Isroffice,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  toGenDashboard = () => {
    this.props.history.push({
      pathname: "/DashboardGeneral",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        data: this.props.location.state.data,
        tskId: this.props.location.state.tskId,
        tskName: this.props.location.state.tskName,
        storeNo: this.props.location.state.storeNo,
        Isroffice: this.props.location.state.Isroffice,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  SetUpdateTask = () => {
    if (this.props.location.state.usrnm !== "") {
      this.setState({
        tskDescr: this.props.location.state.tskDescr,
        tskName: this.props.location.state.tskName,
        strtDate: this.props.location.state.strtDate,
        edDate: this.props.location.state.edDate,
        roleId: this.props.location.state.role_id,
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        taskRoleIdes: this.props.location.state.taskRoleId
      });
      this.state.taskRoleId.push(this.props.location.state.taskRoleId);

      var Request = {
        taskId: this.state.tskId,
        guId: this.props.location.state.guId
      };
      var EncryptedRequest = Encrypt(Request);

      fetch("/GetTaskStores", {
        method: "POST",
        headers: {
          guId: this.props.location.state.guId,
          Authorization: "Basic " + basicAuth(this.props.location.state.userid)
        },
        body: EncryptedRequest
      })
        .then(response => response.text())
        .then(response => {
          var DecryptedResponse = decryptData(response);
          if (DecryptedResponse.errorCode === "00") {
            this.setState({ taskStores: DecryptedResponse.storeList });
          } else {
            confirmAlert({
              title: "Alert !",
              message: DecryptedResponse.errorMsg,
              buttons: [
                {
                  label: "Ok"
                }
              ]
            });
          }
        })
        .catch(error => {
          confirmAlert({
            title: "Alert !",
            message: "Session expired",
            buttons: [
              {
                label: "Ok",
                onClick: () => {
                  this.logoutnormal();
                }
              }
            ]
          });
        });
    } else {
      window.location.href = "/";
    }
    fetch("/GetTaskType", {
      method: "GET",
      headers: {
        guId: this.props.location.state.guId,
        Authorization:
          "Basic " + basicAuth(this.props.location.state.userid)
      },

    })
      .then(response => response.text())
      .then(response => {
        var DecryptedResponse = decryptData(response);
        if (DecryptedResponse.errorCode === "00") {

          this.setState({ taskTypeList: DecryptedResponse.getTaskTypeModel });

          let temp = [];
          let temp1 = [];


          for (var i = 0; i < DecryptedResponse.getTaskTypeModel.length; i++) {

            temp.push(DecryptedResponse.getTaskTypeModel[i].taskType);
            temp1.push(DecryptedResponse.getTaskTypeModel[i].taskTypeId);
          }
          this.setState({ taskTypeDesc: temp });
          this.setState({ taskTypeid: temp1 });

    let flag = [];

    for (var i = 0; i < this.state.taskTypeDesc.length; i++) {

      this.state.taskTypeDesc[i] === this.props.location.state.taskType
        ? flag.push(this.state.taskTypeid[i])
        : this.setState({ valueTaskid: "" });
    }
    this.setState({ valueTaskid: flag });
    this.state.valueTaskid = flag;

    console.log('valueTaskid', this.state.valueTaskid);

        } else {
          confirmAlert({
            message: DecryptedResponse.errorMsg,
            buttons: [
              {
                label: "Ok",
                onClick: () => {
                  return false;
                }
              }
            ]
          });
        }
      })
      .catch(error => {
        confirmAlert({
          title: "Alert !",
          message: "Session expired",
          buttons: [
            {
              label: "Ok",
              onClick: () => {
                this.logoutnormal();
              }
            }
          ]
        });
      });
  };

  BackPage = e => {
    e.preventDefault();
    {
      this.props.location.state.role_id === "300"
        ? confirmAlert({
          message: "Are you sure, you want to cancel?",
          buttons: [
            {
              label: "Yes",
              onClick: () => {
                this.props.history.push({
                  pathname: "/Taskactive",
                  state: {
                    userid: this.props.location.state.userid,
                    usrnm: this.props.location.state.usrnm,
                    role_id: this.state.roleId,
                    format_id: this.props.location.state.format_id,
                    storeNo: this.props.location.state.storeNo,
                    guId: this.props.location.state.guId,
                    menuList: this.props.location.state.menuList,
                    roleName: this.props.location.state.roleName,
                  }
                });
              }
            },
            {
              label: "No",
              onClick: () => {
                return false;
              }
            }
          ]
        })
        : confirmAlert({
          message: "Are you sure, you want to cancel?",
          buttons: [
            {
              label: "Yes",
              onClick: () => {
                this.props.history.push({
                  pathname: "/StoreTasks",
                  state: {
                    userid: this.props.location.state.userid,
                    usrnm: this.props.location.state.usrnm,
                    role_id: this.state.roleId,
                    format_id: this.props.location.state.format_id,
                    storeNo: this.props.location.state.storeNo,
                    guId: this.props.location.state.guId,
                    menuList: this.props.location.state.menuList,
                    roleName: this.props.location.state.roleName,
                  }
                });
              }
            },
            {
              label: "No",
              onClick: () => {
                return false;
              }
            }
          ]
        });
    }
  };

  UpdateTask(e) {
    e.preventDefault();
    if (
      document.getElementById("taskName").value == "" ||
      document.getElementById("taskDesc").value == "" ||
      this.state.valueTaskid === "--Select--"
    ) {
      confirmAlert({
        message: "Please enter required fields",
        buttons: [
          {
            label: "Ok",
            onClick: () => {
              return false;
            }
          }
        ]
      });
    } else if (document.getElementById("taskTypeId").value == "--Select--") {
      confirmAlert({
        message: "Please enter Task Type",
        buttons: [
          {
            label: "Ok",
            onClick: () => {
              return false;
            }
          }
        ]
      });
    } else {
      var vstartdate = new Date(document.getElementById("startDate").value);
      var venddate = new Date(document.getElementById("endDate").value);
      if (venddate < vstartdate) {
        confirmAlert({
          message: "End date cannot be earlier than start date",
          buttons: [
            {
              label: "Ok",
              onClick: () => {
                return false;
              }
            }
          ]
        });
      } else {
        if (this.state.showAction == false) {
          confirmAlert({
            message: "Are you sure, you want to update the task?",
            buttons: [
              {
                label: "Yes",
                onClick: () => {
                  var Request1 = {
                    taskId: this.state.tskId,
                    taskDesc: this.refs.taskdescr.value,
                    taskType: this.state.valueTaskid.toString(),
                    startDate: document.getElementById("startDate").value,
                    endDate: document.getElementById("endDate").value,
                    createdBy: this.props.location.state.userid,
                    taskName: document.getElementById("taskName").value,
                    action: "None",
                    storeId: [],
                    roleId: [this.state.taskRoleIdes],
                    guId: this.props.location.state.guId
                  };
                  var EncryptedRequest1 = Encrypt(Request1);

                  fetch("/UpdateTask", {
                    method: "POST",
                    headers: {
                      guId: this.props.location.state.guId,
                      Authorization:
                        "Basic " + basicAuth(this.props.location.state.userid)
                    },
                    body: EncryptedRequest1
                  })
                    .then(response => response.text())
                    .then(response => {
                      var DecryptedResponse = decryptData(response);

                      if (DecryptedResponse.errorCode === "00") {
                        confirmAlert({
                          message: "Task updated successfully. ",
                          buttons: [
                            {
                              label: "Ok",
                              onClick: () => {
                                {
                                  this.props.location.state.role_id === "300"
                                    ? this.props.history.push({
                                      pathname: "/Taskactive",
                                      state: {
                                        userid: this.props.location.state
                                          .userid,
                                        usrnm: this.props.location.state.usrnm,
                                        role_id: this.state.roleId,
                                        format_id: this.props.location.state
                                          .format_id,
                                        storeNo: this.props.location.state
                                          .storeNo,
                                        guId: this.props.location.state.guId,
                                        menuList: this.props.location.state
                                          .menuList,
                                        roleName: this.props.location.state.roleName,
                                      }
                                    })
                                    : this.props.history.push({
                                      pathname: "/StoreTasks",
                                      state: {
                                        userid: this.props.location.state
                                          .userid,
                                        usrnm: this.props.location.state.usrnm,
                                        role_id: this.state.roleId,
                                        format_id: this.props.location.state
                                          .format_id,
                                        storeNo: this.props.location.state
                                          .storeNo,
                                        guId: this.props.location.state.guId,
                                        menuList: this.props.location.state
                                          .menuList,
                                        roleName: this.props.location.state.roleName,
                                      }
                                    });
                                }
                              }
                            }
                          ]
                        });
                        this.forceUpdate();
                      } else {
                        confirmAlert({
                          title: "Alert !",
                          message: DecryptedResponse.errorMsg,
                          buttons: [
                            {
                              label: "Ok"
                            }
                          ]
                        });
                      }
                    })
                    .catch(error => {
                      confirmAlert({
                        title: "Alert !",
                        message: "Session expired",
                        buttons: [
                          {
                            label: "Ok",
                            onClick: () => {
                              this.logoutnormal();
                            }
                          }
                        ]
                      });
                    });
                }
              },
              {
                label: "No",
                onClick: () => {
                  return false;
                }
              }
            ]
          });
        }
        else {
          var Request2 = {
            taskId: this.state.tskId,
            taskDesc: this.refs.taskdescr.value,
            taskType: this.state.valueTaskid.toString(),
            startDate: document.getElementById("startDate").value,
            endDate: document.getElementById("endDate").value,
            createdBy: this.props.location.state.userid,
            taskName: document.getElementById("taskName").value,
            action: document.getElementById("SelActType").value,
            storeId: this.state.StoreList,
            roleId: this.state.taskRoleId,
            guId: this.props.location.state.guId
          };
          var EncryptedRequest2 = Encrypt(Request2);

          fetch("/UpdateTask", {
            method: "POST",
            headers: {
              guId: this.props.location.state.guId,
              Authorization:
                "Basic " + basicAuth(this.props.location.state.userid)
            },
            body: EncryptedRequest2
          })
            .then(response => response.text())
            .then(response => {
              var DecryptedResponse = decryptData(response);

              if (DecryptedResponse.errorCode === "00") {
                confirmAlert({
                  message: "Task updated successfully.",
                  buttons: [
                    {
                      label: "Ok",
                      onClick: () => {
                        {
                          this.props.location.state.role_id === "300"
                            ? this.props.history.push({
                              pathname: "/Taskactive",
                              state: {
                                userid: this.props.location.state.userid,
                                usrnm: this.props.location.state.usrnm,
                                role_id: this.state.roleId,
                                format_id: this.props.location.state
                                  .format_id,
                                storeNo: this.props.location.state.storeNo,
                                guId: this.props.location.state.guId,
                                menuList: this.props.location.state.menuList,
                                roleName: this.props.location.state.roleName,
                              }
                            })
                            : this.props.history.push({
                              pathname: "/StoreTasks",
                              state: {
                                userid: this.props.location.state.userid,
                                usrnm: this.props.location.state.usrnm,
                                role_id: this.state.roleId,
                                format_id: this.props.location.state
                                  .format_id,
                                storeNo: this.props.location.state.storeNo,
                                guId: this.props.location.state.guId,
                                menuList: this.props.location.state.menuList,
                                roleName: this.props.location.state.roleName,
                              }
                            });
                        }
                      }
                    }
                  ]
                });

                this.forceUpdate();
              } else {
                confirmAlert({
                  title: "Alert !",
                  message: DecryptedResponse.errorMsg,
                  buttons: [
                    {
                      label: "Ok"
                    }
                  ]
                });
              }
            })
            .catch(error => {
              confirmAlert({
                title: "Alert !",
                message: "Session expired",
                buttons: [
                  {
                    label: "Ok",
                    onClick: () => {
                      this.logoutnormal();
                    }
                  }
                ]
              });
            });
        }
      }
    }
  }

  ToStoreList = () => {
    this.props.history.push({
      pathname: "/StoreList",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        formerMasterList: this.state.formerMasterList,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  Storecreation = () => {
    this.props.history.push({
      pathname: "/Storecreation",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        formerMasterList: this.state.formerMasterList,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  CompletedTaskListbind = () => {
    this.props.history.push({
      pathname: "/CompletedTaskList",
      state: {
        data: this.state.data,
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };
  Changepasswordbind = () => {
    this.props.history.push({
      pathname: "/ChangepasswordUI",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  TaskactiveBind = () => {
    this.props.history.push({
      pathname: "/Taskactive",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.state.roleId,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  CheckProps = () => {
    if (this.props.location.state) {
    } else {
      this.props.history.push({
        pathname: "/"
      });
    }
  };

  ArchivedTasks = () => {
    this.props.history.push({
      pathname: "/ArchivedTasks",
      state: {
        data: this.state.data,
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
        Isroffice: this.props.location.state.Isroffice,
        Isroffice: this.props.location.state.Isroffice,
      }
    });
  };

  SmartPointBind = () => {
    this.props.history.push({
      pathname: "/SmartPoint",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        data: this.props.location.state.data,
        tskId: this.props.location.state.tskId,
        tskName: this.props.location.state.tskName,
        storeNo: this.props.location.state.storeNo,
        Isroffice: this.props.location.state.Isroffice,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  Defectbind = () => {
    this.props.history.push({
      pathname: "/DefectRaising",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        data: this.props.location.state.data,
        tskId: this.props.location.state.tskId,
        tskName: this.props.location.state.tskName,
        storeNo: this.props.location.state.storeNo,
        Isroffice: this.props.location.state.Isroffice,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  Reportsbind = () => {
    this.props.history.push({
      pathname: "/Reports",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  StoreTasks = () => {
    this.props.history.push({
      pathname: "/StoreTasks",
      state: {
        data: this.state.data,
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  templates = () => {
    this.props.history.push({
      pathname: "/Templates",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  getroles(e) {
    this.props.history.push({
      pathname: "/CreatetaskUI",
      state: {
        format_id: this.props.location.state.format_id,
        role_id: this.props.location.state.role_id,
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  }
}

export default withRouter(Updatetasks);


