import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert"; // Import
import "./css/react-confirm-alert.css"; // Import css
import Spinner from "react-spinner-material"; //Import Spinner
import IdleTimer from "react-idle-timer"; // For Idle
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import addDays from "date-fns/addDays";
import "./css/style.css";
import "./css/style-responsive.css";
import { Encrypt, decryptData } from "./Encryption-Decrypytion";
import disableBrowserBackButton from "disable-browser-back-navigation";
import { basicAuth } from "./BasicAuth";

const display = {
  display: "block"
};
const hide = {
  display: "none"
};

class QuestionPaper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      TaskDropdwn: false,
      taskId: "",
      taskName: "",
      taskDescription: "",
      startDate: "",
      endDate: "",
      storeNo: "",
      displayMenu: false,
      loaded: this.props.location.state,
      taskStores: [],
      fileval: "Upload file",
      fileuploaded: [],
      nooffileques: 0,
      file: "",
      filestr: "",
      fileupmsg: "",
      storenumber: "",
      hasError: false,
      viewdate: true,
      questionid: "",
      status: "",
      file: "",
      file2: "",
      file3: "",
    };

    this.state = {
      taskId: "",
      taskName: "",
      taskDescription: "",
      startDate: "",
      endDate: "",
      storeNo: "",
      TaskDropdwn: false,
      data: [],
      usrnm: "",
      role_id: "",
      quesid: [],
      ans: [],
      loading: false,
      taskStores: [],
      file: "",
      fileval: "Upload file",
      fileuploaded: [],
      nooffileques: 0,
      filestr: "",
      fileupmsg: "",
      storenumber: "",
      hasError: false,
      hammenu: true,
      datapresent: false,
      startdateupdateticker: "",
      UpdatesDate: "",
      storeval: "",
      file: "",
      file2: "",
      file3: "",
    };

    this.showDropdownMenu = this.showDropdownMenu.bind(this);
    this.hideDropdownMenu = this.hideDropdownMenu.bind(this);
    this.handletickersupdate = this.handletickersupdate.bind(this);
    this.idleTimer = null;
    this.onAction = this._onAction.bind(this);
    this.onActive = this._onActive.bind(this);
    this.onIdle = this._onIdle.bind(this);
  }

  logoutnormal = () => {
    localStorage.clear();
    this.props.history.replace("/");
    disableBrowserBackButton();
  };

  openproof = (param, evt) => {
    evt.preventDefault();
    var string;
    var qlen = param.length;
    if (this.state.fileuploaded.length > 0) {
      for (let index = 0; index < this.state.fileuploaded.length; index++) {
        const element = this.state.fileuploaded[index];
        if (element.includes(param, 0)) {
          var elelen = element.length;
          string = element.substring(qlen, elelen);
          if (string == "null") {
          } else {
            var iframe =
              "<iframe width='100%' height='100%' src='" +
              string +
              "'></iframe>";
            var x = window.open();
            x.document.open();
            x.document.write(iframe);
            x.document.close();
          }
        }
      }
    } else {
      confirmAlert({
        title: "Alert !",
        message: "No files uploaded yet",
        buttons: [
          {
            label: "OK"
          }
        ]
      });
    }
  };

  _onAction(e) {
  }

  _onActive(e) {
  }

  _onIdle(e) {
    localStorage.clear();
    window.location.href = "/";
    disableBrowserBackButton();
  }

  componentDidMount() {
    if (window.innerWidth <= 768) {
      this.setState({ hammenu: false });
    }
  }

  showTaskDropdwnHandler = () => {
    this.setState({
      TaskDropdwn: !this.state.TaskDropdwn
    });
  };

  showDropdownMenu(event) {
    event.preventDefault();
    this.setState({ displayMenu: true }, () => {
      document.addEventListener("click", this.hideDropdownMenu);
    });
  }

  hideDropdownMenu() {
    this.setState({ displayMenu: false }, () => {
      document.removeEventListener("click", this.hideDropdownMenu);
    });
  }
  logout = () => {
    if (this.props.location.state.Isroffice == true || this.props.location.state.role_id == '900') {
      this.props.history.replace("/");
      disableBrowserBackButton();

      // document.getElementById("rofficelogin").click();
    } else {
      this.setState({ loading: true });
      var Request1 = {
        userId: this.props.location.state.userid,
        guId: this.props.location.state.guId
      };
      var EncryptedRequest1 = Encrypt(Request1);
      fetch("/LogOut ", {
        method: "POST",
        headers: {
          guId: this.props.location.state.guId,
          Authorization: "Basic " + basicAuth(this.props.location.state.userid)
        },
        body: EncryptedRequest1
      })
        .then(response => response.text())
        .then(response => {
          this.setState({ loading: false });
          var DecryptedResponse = decryptData(response);
          if (DecryptedResponse.errorCode === "00") {
            this.props.history.replace("/");
            disableBrowserBackButton();
          } else {
            confirmAlert({
              message: DecryptedResponse.errorMsg,
              buttons: [
                {
                  label: "Ok"
                }
              ]
            });
          }
        })
        .catch(error => {
          confirmAlert({
            title: "Alert !",
            message: "Session expired",
            buttons: [
              {
                label: "Ok",
                onClick: () => {
                  this.logoutnormal();
                }
              }
            ]
          });
        });
    }
  };

  setquesid(param, event) {
    this.state.quesid.push(param);
    alert("quesid: " + param);
  }

  componentDidCatch() {
    this.setState({ hasError: true });
  }
  Changepasswordbind = () => {
    this.props.history.push({
      pathname: "/ChangepasswordUI",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  templates = () => {
    this.props.history.push({
      pathname: "/Templates",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  toggle = () => {
    this.setState(prevState => ({
      toggle: !prevState.toggle
    }));
  };

  modalclose = () => {
    this.setState({
      toggle: false
    });
  };

  tiker = () => {
    this.props.history.push({
      pathname: "/Tiker",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };
  dropdownselect = e => {
    this.setState({ datapresent: false, data: [], storeval: e.label });
  };

  componentWillMount() {

    if (
      this.props.location.state === undefined ||
      this.props.location.state === ""
    ) {
      window.location.href = "/";
    } else {
      this.setState({ UpdatesDate: this.props.location.state.endDate });
      this.fetchQuestionPaper();
    }
  }

  showTaskdwnHandler = () => {
    this.setState({
      Taskdwn: !this.state.Taskdwn
    });
  };

  usercreation = () => {
    this.props.history.push({
      pathname: "/UserCreation",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  userlist = () => {
    this.props.history.push({
      pathname: "/UserList",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  handletickersupdate(dates) {
    this.setState({ startdateupdateticker: dates });
    this.setState({ UpdatesDate: dates });
  }

  handleDateChangeRaw = e => {
    e.preventDefault();
  };
  render = () => {
    var that = this;
    var ReassignTask = [];
    var monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ];

    var date =
      new Date().getDate() +
      "-" +
      monthNames[new Date().getMonth()] +
      "-" +
      new Date().getFullYear();


    ReassignTask.push(

      <div style={this.state.datapresent ? display : hide} className="imgalign">
        {this.props.location.state.role_id !== "900" && this.props.location.state.role_id !== "504" && this.props.location.state.role_id !== "505" && this.props.location.state.role_id !== "506" && this.props.location.state.role_id !== "507" && this.props.location.state.role_id !== "508" && this.props.location.state.role_id !== "509" ?
          <a class="mr-50" onClick={this.toggle.bind(this)}>
            <img
              class="mr-10"
              style={{ height: "40px" }}
              src={require("./img/reassign.png")}
            ></img>
            <b>Reassign Task</b>
          </a>
          : null}
      </div>
    );


    var modal = [];

    modal.push(
      <div
        className="modal"
        role="dialog"
        style={this.state.toggle ? display : hide}
      >
        <div className="modal-dialog animate">
          <div className="modal-content">
            <div className="modal-header">
              <a
                className="close"
                style={{ color: "#fff" }}
                onClick={this.modalclose}
              >
                X
              </a>
              <h4 className="modal-title">Reassign</h4>
            </div>
            <div className="modal-body">
              <div className="form-horizontal">
                <div className="form-group">
                  {new Date(this.props.location.state.endDate) >=
                    new Date(date) ? (
                    <div>
                      <h4 className="control-label col-sm-4">End Date</h4>
                      <div className="col-sm-8">
                        <input
                          type="text"
                          class="form-control"
                          value={this.props.location.state.endDate}
                          id="extend"
                          disabled
                        ></input>
                        <br />
                      </div>
                    </div>
                  ) : (
                    <div className="clearfix">
                      <h4 className="control-label col-sm-4">Extended Date</h4>
                      <div className="col-sm-8">
                        <DatePicker
                          className="form-control"
                          showTimeSelect
                          timeIntervals={15}
                          value={this.state.UpdatesDate}
                          onChange={this.handletickersupdate}
                          id="extend"
                          onChangeRaw={this.handleDateChangeRaw}
                          minDate={new Date()}
                          dateFormat="dd-MMM-yyyy HH:mm"
                          selected={this.state.startdateupdateticker}
                          autoComplete="off"
                          maxDate={addDays(new Date(), 365)}
                        // minTime={setHours(setMinutes(new Date(), new Date().getMinutes()),  new Date().getHours())}
                        // maxTime={setHours(setMinutes(new Date(), 59), 23)}
                        />
                        <br />
                      </div>
                    </div>

                  )}

                  <br />
                  <h4 className="control-label col-sm-4">Comment</h4>
                  <div className="col-sm-8">
                    <textarea
                      type="text"
                      class="form-control"
                      id="comment"
                      autoComplete="off"
                      maxLength="200"
                    ></textarea>
                  </div>
                  <h4 className="control-label col-sm-4">&nbsp;</h4>
                  <div className="col-sm-8">
                    <button
                      class="btn btn-primary min-wid-90 mt-17"
                      onClick={this.ReopenTask}
                    >
                      Reassign
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    if (this.state.hasError) {
      return (
        <div>
          <h2>Error occurred!! please contact administrator</h2>
        </div>
      );
    } else {
      return (
        <div>
          {modal}
          <div className={this.state.loading ? 'parentDisable' : ''} width="100%">
            <div className='overlay-box'>
              <Spinner
                visible={this.state.loading}
                spinnerColor={"rgba(0, 0, 0, 0.3)"}
              />
            </div>
          </div>
          <section id="container">
            <div>
              <IdleTimer
                ref={ref => {
                  this.idleTimer = ref;
                }}
                element={document}
                onActive={this.onActive}
                onIdle={this.onIdle}
                onAction={this.onAction}
                debounce={250}
                timeout={1000 * 60 * 10}
              />
              {/* your app here */}
            </div>
            <header class="header black-bg">
              {/* <div class="sidebar-toggle-box">
      <div class="fa fa-bars tooltips" data-placement="right" data-original-title="Toggle Navigation"></div>
    </div> */}
              <a
                className="mob-show"
                onClick={() =>
                  this.setState({ hammenu: !this.state.hammenu })
                }
              >
                <i
                  className="fa fa-tasks hammenu"
                  style={{ "margin-top": "25px", 'padding': '0 15px' }}
                ></i>
              </a>

              <a onClick={this.TaskactiveBind.bind(this)} className="logo" style={{ 'padding': '0 10px' }}>
                <img src={require("./img/retail-logo.png")} alt="logo" />
              </a>
              <div className="markcenter">
                <p className="marquee">{this.state.tickerList}</p>
              </div>
              <ul className="nav pull-right pos-rel">
                <li className="dropdown">
                  <a
                    className="dropdown-toggle"
                    data-toggle="dropdown"
                    onClick={this.showDropdownMenu}
                  >
                    {" "}
                    <img
                      src={require("./img/user.png")}
                      className="user-img"
                    />{" "}
                    <b className="name-show">{this.props.location.state.usrnm}</b>
                    ({this.props.location.state.storeNo})

                    <b className="caret" />
                  </a>
                </li>

                {this.state.displayMenu ? (
                  <ul className="dropdown-menuuser-dd">
                    <li>
                      Role : {this.props.location.state.roleName}
                    </li>
                    <li className="divider"></li>
                    <li>
                      <a onClick={this.Changepasswordbind}>Change Password</a>
                    </li>
                    <li className="divider"></li>
                    <li>
                      <a onClick={this.logout}>Log Out</a>
                    </li>
                  </ul>
                ) : null}
              </ul>
            </header>
            <aside>
              <div id="sidebar" className="nav-collapse">
                {this.state.hammenu ? (
                  <ul className="sidebar-menu" id="nav-accordion">
                    {this.props.location.state.menuList["401"] || this.props.location.state.menuList["402"] || this.props.location.state.menuList["403"] || this.props.location.state.menuList["404"] || this.props.location.state.menuList["405"] || this.props.location.state.menuList["408"] || this.props.location.state.menuList["411"] ? (
                      <li
                        className="sub-menu mt"
                        onClick={this.showTaskDropdwnHandler.bind(this)}
                      >
                        {" "}
                        <a href="javascript:;">
                          {" "}
                          <i className="fa fa-tasks"></i> <span>Task</span>{" "}
                        </a>
                        {this.state.TaskDropdwn ? (
                          <div>
                            <ul class="sub">
                              {this.props.location.state.menuList["401"] ? (
                                <li>
                                  <a onClick={this.TaskactiveBind.bind(this)}>
                                    {this.props.location.state.menuList["401"]}
                                  </a>
                                </li>
                              ) : null}
                              {this.props.location.state.menuList["402"] ? (
                                <li>
                                  <a onClick={this.getroles.bind(this)}>
                                    {this.props.location.state.menuList["402"]}
                                  </a>
                                </li>
                              ) : null}
                              {this.props.location.state.menuList["403"] ? (
                                <li>
                                  <a
                                    onClick={this.CompletedTaskListbind.bind(
                                      this
                                    )}
                                  >
                                    {this.props.location.state.menuList["403"]}
                                  </a>
                                </li>
                              ) : null}
                              {this.props.location.state.menuList["404"] ? (
                                <li>
                                  <a onClick={this.templates.bind(this)}>
                                    {this.props.location.state.menuList["404"]}
                                  </a>
                                </li>
                              ) : null}
                              {this.props.location.state.menuList["405"] ? (
                                <li>
                                  <a onClick={this.ArchivedTasks.bind(this)}>
                                    {this.props.location.state.menuList["405"]}
                                  </a>
                                </li>
                              ) : null}
                              {this.props.location.state.menuList["408"] ? (
                                <li>
                                  <a onClick={this.StoreTasks.bind(this)}>
                                    {this.props.location.state.menuList["408"]}
                                  </a>
                                </li>
                              ) : null}
                              {this.props.location.state.menuList["411"] ? (
                                <li>
                                  <a onClick={this.toGenDashboard.bind(this)}>
                                    {this.props.location.state.menuList["411"]}
                                  </a>
                                </li>
                              ) : null}
                            </ul>
                          </div>
                        ) : null}
                      </li>
                    ) : null}
                    {this.props.location.state.menuList["406"] ? (
                      <div>
                        <li>
                          {" "}
                          <a onClick={this.Reportsbind.bind(this)}>
                            {" "}
                            <i className="fa fa-tasks"></i>{" "}
                            <span>
                              {this.props.location.state.menuList["406"]}
                            </span>{" "}
                          </a>{" "}
                        </li>
                      </div>
                    ) : null}

                    {this.props.location.state.menuList["414"] && this.props.location.state.format_id === "102" ? (
                      <div>
                        <li>
                          {" "}
                          <a onClick={this.Defectbind.bind(this)}>
                            {" "}
                            <i className="fa fa-tasks"></i>{" "}
                            <span>
                              {this.props.location.state.menuList["414"]}
                            </span>{" "}
                          </a>{" "}
                        </li>
                      </div>
                    ) : null}

                    {this.props.location.state.menuList["415"] ? (
                      <div>
                        <li>
                          {" "}
                          <a onClick={this.SmartPointBind.bind(this)}>
                            {" "}
                            <i className="fa fa-tasks"></i>{" "}
                            <span>
                              {this.props.location.state.menuList["415"]}
                            </span>{" "}
                          </a>{" "}
                        </li>
                      </div>
                    ) : null}

                    {this.props.location.state.menuList["409"] || this.props.location.state.menuList["410"] ? (
                      <div>
                        <li
                          className="sub-menu"
                          onClick={this.showIssueDropdwnHandler.bind(this)}
                        >
                          {" "}
                          <a href="javascript:;">
                            {" "}
                            <i className="fa fa-tasks"></i>{" "}
                            <span>Issue</span>{" "}
                          </a>
                          {this.state.IssueDropdwn ? (
                            <div>
                              <ul class="sub">
                                {this.props.location.state.menuList["410"] ? (
                                  <li>
                                    <a onClick={this.IssueRaising.bind(this)}>
                                      {this.props.location.state.menuList["410"]}
                                    </a>
                                  </li>
                                ) : null}
                                {this.props.location.state.menuList["409"] ? (
                                  <li>
                                    <a onClick={this.IssueDashboard.bind(this)}>
                                      {this.props.location.state.menuList["409"]}
                                    </a>
                                  </li>
                                ) : null}
                              </ul>
                            </div>
                          ) : null}
                        </li>
                      </div>
                    ) : null}

                    {this.props.location.state.menuList["407"] ? (
                      <div>
                        <li
                          className="sub-menu"
                          onClick={this.showTaskdwnHandler.bind(this)}
                        >
                          {" "}
                          <a href="javascript:;">
                            {" "}
                            <i className="fa fa-tasks"></i>{" "}
                            <span>
                              {this.props.location.state.menuList["407"]}
                            </span>{" "}
                          </a>
                          {this.state.Taskdwn ? (
                            <div>
                              <ul class="sub">
                                <li>
                                  {" "}
                                  <a onClick={this.tiker.bind(this)}>
                                    <span>Tiker Creation</span>{" "}
                                  </a>{" "}
                                </li>
                                <li>
                                  {" "}
                                  <a onClick={this.userlist.bind(this)}>
                                    <span>User List</span>{" "}
                                  </a>{" "}
                                </li>
                                {this.props.location.state.menuList["412"] ? (
                                  <li>
                                    {" "}
                                    <a onClick={this.Storecreation.bind(this)}>
                                      <span>{this.props.location.state.menuList["412"]}</span>{" "}
                                    </a>{" "}
                                  </li>
                                ) : null}
                                {this.props.location.state.menuList["413"] ? (
                                  <li>
                                    {" "}
                                    <a onClick={this.ToStoreList.bind(this)}>
                                      <span>{this.props.location.state.menuList["413"]}</span>{" "}
                                    </a>{" "}
                                  </li>
                                ) : null}
                              </ul>
                            </div>
                          ) : null}
                        </li>
                      </div>
                    ) : null}
                  </ul>
                ) : null}
              </div>
            </aside>
            <section id="main-content">
              <section class="wrapper">
                <div class="row">
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-20">
                      <h3>
                        <i className="fa fa-angle-right"></i>
                        {" "}{this.props.location.state.taskName}
                      </h3>
                      <p>
                        Description :{" "}
                        {this.props.location.state.taskDescription}
                      </p>
                      <p>Store No : {this.props.location.state.storeNo}</p>
                    </div>
                    <div class="col-md-6 col-lg-6 col-sm-6 col-xs-6">
                      Start Date : {this.props.location.state.startDate}{" "}
                    </div>
                    <div class="col-md-6 col-lg-6 col-sm-6 col-xs-6">
                      End Date : {this.props.location.state.endDate}
                    </div>
                    <div class="clearfix">
                      {this.state.file ? (
                        <div
                          class="col-md-6 col-lg-6 col-sm-6 col-xs-12"
                          style={{ marginTop: "10px" }}
                        >
                          <a onClick={this.openIns.bind(this, 1)}>
                            View Instruction
                          </a>
                          /
                          <a
                            download="instructions"
                            onClick={this.DownloadIns.bind(this, 1)}
                          >
                            Download Instruction
                          </a>
                          <a
                            download={this.state.file}
                            href={"/IMAGES/" + this.state.file}
                            id="FileDnld"
                            hidden
                          />
                        </div>
                      ) : null}
                      {this.state.file2 ? (
                        <div
                          class="col-md-6 col-lg-6 col-sm-6 col-xs-12"
                          style={{ marginTop: "10px" }}
                        >
                          <a onClick={this.openIns.bind(this, 2)}>
                            View Instruction 2
                          </a>
                          /
                          <a
                            download="instructions"
                            onClick={this.DownloadIns.bind(this, 2)}
                          >
                            Download Instruction 2
                          </a>
                          <a
                            download={this.state.file2}
                            href={"/IMAGES/" + this.state.file2}
                            id="FileDnld2"
                            hidden
                          />
                        </div>
                      ) : null}
                      {this.state.file3 ? (
                        <div
                          class="col-md-6 col-lg-6 col-sm-6 col-xs-12"
                          style={{ marginTop: "10px" }}
                        >
                          <a onClick={this.openIns.bind(this, 3)}>
                            View Instruction 3
                          </a>
                          /
                          <a
                            download="instructions"
                            onClick={this.DownloadIns.bind(this, 3)}
                          >
                            Download Instruction 3
                          </a>
                          <a
                            download={this.state.file3}
                            href={"/IMAGES/" + this.state.file3}
                            id="FileDnld3"
                            hidden
                          />
                        </div>
                      ) : null}

                    </div>
                    <hr />

                    <br />


                    {ReassignTask}
                    {(this.state.loading = false)}

                    <ul class="ques">
                      {this.state.data
                        ? this.state.data.map(function (item, key) {

                          switch (item.questiontype) {
                            case "1":
                              return (
                                <li key={key}>
                                  <div class="custom-selection ml-0 pl-0">
                                    <p>
                                      {item.questionText}
                                      <that.Astrik
                                        isMandatory={item.isMandatory}
                                      />
                                    </p>
                                    {item.options.map((optele, index) => (
                                      <span class="nameloc">
                                        <input
                                          type="radio"
                                          class="custom-control-input"
                                          id={item.questionId + index}
                                          name={item.questionId}
                                          value={optele}
                                          {...(item.answer === optele
                                            ? (that.state.checked = true)
                                            : (that.state.checked = false))}
                                          checked={that.state.checked}
                                          defaultChecked={that.state.checked}
                                          disabled="disabled"
                                        />
                                        <label
                                          class="custom-control-label"
                                          for={item.questionId + index}
                                        >
                                          {optele}
                                        </label>
                                      </span>
                                    ))}
                                  </div>
                                </li>
                              );
                              break;
                            case "2":
                              return (
                                <li key={key}>
                                  <div class="custom-selection ml-0 pl-0">
                                    <p>
                                      {item.questionText}
                                      <that.Astrik
                                        isMandatory={item.isMandatory}
                                      />
                                    </p>
                                    {item.options.map(optele => (
                                      <span class="nameloc">
                                        <input
                                          type="checkbox"
                                          class="custom-control-input"
                                          id="customCheck-1"
                                          name={item.questionId}
                                          disabled="disabled"
                                          {...(item.answer.includes(optele)
                                            ? (that.state.checked = true)
                                            : (that.state.checked = false))}
                                          checked={that.state.checked}
                                          defaultChecked={that.state.checked}
                                        />
                                        <label
                                          class="custom-control-label"
                                          for="customCheck-1"
                                        >
                                          {optele}
                                        </label>
                                      </span>
                                    ))}
                                  </div>
                                </li>
                              );
                              break;
                            case "3":
                              return (
                                <li key={key}>
                                  <div class="custom-selection ml-0 pl-0">
                                    <p>
                                      {item.questionText}
                                      <that.Astrik
                                        isMandatory={item.isMandatory}
                                      />
                                    </p>
                                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 pl-0">
                                      <input
                                        type="text"
                                        disabled="disabled"
                                        class="form-control"
                                        placeholder="Some Text"
                                        name={item.questionId}
                                        defaultValue={item.answer}
                                      />
                                    </div>
                                  </div>
                                  <div class="clearfix"></div>
                                </li>
                              );
                              break;
                            case "4":
                              return (
                                <li key={key}>
                                  <div class="custom-selection ml-0 pl-0">
                                    <p>
                                      {item.questionText}
                                      <that.Astrik
                                        isMandatory={item.isMandatory}
                                      />
                                    </p>
                                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 pl-0">
                                      <input
                                        type="text"
                                        disabled="disabled"
                                        class="form-control"
                                        placeholder="Number Only"
                                        name={item.questionId}
                                        defaultValue={item.answer}
                                      />
                                    </div>
                                  </div>
                                  <div class="clearfix"></div>
                                </li>
                              );
                              break;
                            case "5":
                              return (
                                <li key={key}>
                                  <div class="custom-selection ml-0 pl-0">
                                    <p>
                                      {item.questionText}
                                      <that.Astrik
                                        isMandatory={item.isMandatory}
                                      />
                                    </p>
                                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 pl-0">
                                      <input
                                        type="email"
                                        disabled="disabled"
                                        class="form-control"
                                        placeholder="Email-ID"
                                        name={item.questionId}
                                        defaultValue={item.answer}
                                      />
                                    </div>
                                  </div>
                                  <div class="clearfix"></div>
                                </li>
                              );
                              break;
                            case "6":
                              var vchky = "";
                              var vchkn = "";
                              if (item.answer === "Yes") {
                                vchky = true;
                                vchkn = false;
                              } else if (item.answer === "No") {
                                vchky = false;
                                vchkn = true;
                              }
                              return (
                                <li key={key}>
                                  <div class="custom-selection  ml-0 pl-0">
                                    <p>
                                      {item.questionText}
                                      <that.Astrik
                                        isMandatory={item.isMandatory}
                                      />
                                    </p>
                                    <span class="ml-10">
                                      <input
                                        type="radio"
                                        class="custom-control-input"
                                        id="customRadio1-11"
                                        name={item.questionId}
                                        disabled="disabled"
                                        checked={vchky}
                                        value="Yes"
                                      />
                                      <label
                                        class="custom-control-label"
                                        for="customRadio1-11"
                                      >
                                        Yes
                                      </label>
                                    </span>{" "}
                                    <span>
                                      <input
                                        type="radio"
                                        class="custom-control-input"
                                        id="customRadio2-22"
                                        name={item.questionId}
                                        checked={vchkn}
                                        disabled="disabled"
                                        value="No"
                                      />
                                      <label
                                        class="custom-control-label"
                                        for="customRadio2-22"
                                      >
                                        No
                                      </label>
                                    </span>{" "}
                                  </div>
                                </li>
                              );
                              break;
                            case "7":
                              return (
                                <li key={key}>
                                  <div class="custom-selection ml-0 pl-0">
                                    <p>
                                      {item.questionText}
                                      <that.Astrik
                                        isMandatory={item.isMandatory}
                                      />
                                    </p>
                                    <span class="ml-10">
                                      <input
                                        type="radio"
                                        class="custom-control-input"
                                        id="customRadio1-1"
                                        name={item.questionId}
                                        disabled="disabled"
                                        {...(item.answer === "Yes"
                                          ? (that.state.checked = true)
                                          : (that.state.checked = false))}
                                        value="Yes"
                                        checked={that.state.checked}
                                      />
                                      <label
                                        class="custom-control-label"
                                        for="customRadio1-1"
                                      >
                                        Yes
                                      </label>
                                    </span>{" "}
                                    <span>
                                      <input
                                        type="radio"
                                        class="custom-control-input"
                                        id="customRadio2-2"
                                        name={item.questionId}
                                        disabled="disabled"
                                        {...(item.answer === "No"
                                          ? (that.state.checked = true)
                                          : (that.state.checked = false))}
                                        checked={that.state.checked}
                                      />
                                      <label
                                        class="custom-control-label"
                                        for="customRadio2-2"
                                      >
                                        No
                                      </label>
                                    </span>{" "}
                                    <span>
                                      <input
                                        type="radio"
                                        class="custom-control-input"
                                        id="customRadio3-3"
                                        name={item.questionId}
                                        disabled="disabled"
                                        {...(item.answer === "NA"
                                          ? (that.state.checked = true)
                                          : (that.state.checked = false))}
                                        checked={that.state.checked}
                                      />
                                      <label
                                        class="custom-control-label"
                                        for="customRadio3-3"
                                      >
                                        NA
                                      </label>
                                    </span>{" "}
                                  </div>
                                </li>
                              );
                              break;
                            case "8":
                              var filestr = "";

                              var array = [];
                              var hrf = "";
                              var disble = "";
                              if (item.imageArray.length > 0) {
                                that.state.fileupmsg = "File Uploaded";
                              } else {
                                that.state.fileupmsg = "Upload file";
                              }
                              if (that.state.fileuploaded.length > 0) {
                                for (
                                  let index = 0;
                                  index < that.state.fileuploaded.length;
                                  index++
                                ) {
                                  const element =
                                    that.state.fileuploaded[index];

                                  if (element.includes(item.questionId, 0)) {
                                    var elelen = element.length;
                                    filestr = element.substring(
                                      item.questionId.length,
                                      elelen
                                    );
                                    if (filestr != "null") {
                                      array.push(filestr);
                                    }

                                    that.state.fileval = "File uploaded";
                                  }
                                }
                              }
                              return (
                                <li key={key}>
                                  <div class="custom-selection ml-0 pl-0">
                                    <p>
                                      {item.questionText}
                                      <that.Astrik
                                        isMandatory={item.isMandatory}
                                      />
                                    </p>
                                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 pl-0">
                                      <input
                                        id="store-list"
                                        type="text"
                                        class="form-control input-upload"
                                        value={that.state.fileupmsg}
                                        disabled="disabled"
                                      />
                                      <span
                                        class="btn-upload"
                                        style={{ padding: "7px 15px" }}
                                      >
                                        Upload
                                      </span>
                                      <input
                                        id="upload-storedata"
                                        type="file"
                                        name="Store-list-data"
                                        class="input-hidden"
                                        disabled="disabled"
                                      />
                                    </div>
                                    <div class="clearfix"></div>
                                    <a
                                      href={that.state.fileuploaded}
                                      onClick={that.openproof.bind(
                                        that,
                                        item.questionId
                                      )}
                                    >
                                      View Answer
                                    </a>
                                    /
                                    {filestr.length > 0 ? (
                                      <div>
                                        {array.map((image, index) => (
                                          <div>
                                            <a
                                              download={
                                                item.questionId +
                                                "_" +
                                                that.state.storenumber
                                              }
                                              href={image}
                                            >
                                              Download Answer({index + 1}){" "}
                                            </a>
                                            <br />
                                          </div>
                                        ))}{" "}
                                      </div>
                                    ) : (
                                      <a
                                        download={
                                          item.questionId +
                                          "_" +
                                          that.state.storenumber
                                        }
                                      >
                                        Download Answer
                                      </a>
                                    )}
                                  </div>
                                  <div class="clearfix"></div>
                                </li>
                              );

                              break;

                            case "9":
                              var ansarr;
                              if (
                                item.answer === undefined ||
                                item.answer === null ||
                                item.answer === ""
                              ) {
                                ansarr = "";
                              } else {
                                ansarr = item.answer.split(",");
                              }
                              return (
                                <li key={key}>
                                  <div class="custom-selection ml-0 pl-0">
                                    <p>
                                      {item.questionText}
                                      <that.Astrik
                                        isMandatory={item.isMandatory}
                                      />{" "}
                                    </p>
                                    {item.options.map((optele, index) => (
                                      <span class="ml-0 pl-0">
                                        <p for={item.questionId + index}>
                                          <b>{optele}</b>
                                        </p>
                                        <input
                                          type="text"
                                          disabled="disabled"
                                          class="form-control col-lg-1 col-md-1 col-sm-1 col-xs-4 "
                                          {...(ansarr.length > 0
                                            ? (that.state.defval =
                                              ansarr[index])
                                            : (that.state.defval = ""))}
                                          defaultValue={that.state.defval}
                                          name={item.questionId}
                                        />
                                      </span>
                                    ))}
                                  </div>
                                </li>
                              );
                              break;

                            case "10":
                              return (
                                <li key={key}>
                                  <div class="custom-selection ml-0 pl-0">
                                    <p>
                                      {item.questionText}
                                      <that.Astrik
                                        isMandatory={item.isMandatory}
                                      />
                                    </p>
                                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 pl-0">
                                      <input
                                        type="date"
                                        disabled="disabled"
                                        class="form-control"
                                        defaultValue={item.answer}
                                        name={item.questionId}
                                      />
                                    </div>
                                  </div>
                                  <div class="clearfix"></div>
                                </li>
                              );

                              break;
                            case "11":
                              var filestr = "";

                              var array = [];
                              var hrf = "";
                              var disble = "";
                              if (item.imageArray.length > 0) {
                                that.state.fileupmsg = "File Uploaded";
                              } else {
                                that.state.fileupmsg = "Upload file";
                              }
                              if (that.state.fileuploaded.length > 0) {
                                for (
                                  let index = 0;
                                  index < that.state.fileuploaded.length;
                                  index++
                                ) {
                                  const element =
                                    that.state.fileuploaded[index];

                                  if (element.includes(item.questionId, 0)) {
                                    var elelen = element.length;
                                    filestr = element.substring(
                                      item.questionId.length,
                                      elelen
                                    );
                                    if (filestr != "null") {
                                      array.push(filestr);
                                    }

                                    that.state.fileval = "File uploaded";
                                  }
                                }
                              }
                              return (
                                <li key={key}>
                                  <div class="custom-selection ml-0 pl-0">
                                    <p>
                                      {item.questionText}
                                      <that.Astrik
                                        isMandatory={item.isMandatory}
                                      />
                                    </p>
                                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 pl-0">
                                      <input
                                        id="store-list"
                                        type="text"
                                        class="form-control input-upload"
                                        value={that.state.fileupmsg}
                                        disabled="disabled"
                                      />
                                      <span
                                        class="btn-upload"
                                        style={{ padding: "7px 15px" }}
                                      >
                                        Upload
                                      </span>
                                      <input
                                        id="upload-storedata"
                                        type="file"
                                        name="Store-list-data"
                                        class="input-hidden"
                                        disabled="disabled"
                                      />
                                    </div>
                                    <div class="clearfix"></div>
                                    <a
                                      href={that.state.fileuploaded}
                                      onClick={that.openproof.bind(
                                        that,
                                        item.questionId
                                      )}
                                    >
                                      View Answer
                                    </a>
                                    /
                                    {filestr.length > 0 ? (
                                      <div>
                                        {array.map((image, index) => (
                                          <div>
                                            <a
                                              download={
                                                item.questionId +
                                                "_" +
                                                that.state.storenumber
                                              }
                                              href={image}
                                            >
                                              Download Answer({index + 1}){" "}
                                            </a>
                                            <br />
                                          </div>
                                        ))}{" "}
                                      </div>
                                    ) : (
                                      <a
                                        download={
                                          item.questionId +
                                          "_" +
                                          that.state.storenumber
                                        }
                                      >
                                        Download Answer
                                      </a>
                                    )}
                                  </div>
                                  <div class="clearfix"></div>
                                </li>
                              );

                              break;
                              case "12":
                              return (
                                <li key={key}>
                                  <div class="custom-selection ml-0 pl-0">
                                    <p>
                                      {item.questionText}
                                      <that.Astrik
                                        isMandatory={item.isMandatory}
                                      />
                                    </p>
                                    {item.options.map(optele => (
                                      <span class="nameloc">
                                        <input
                                          type="checkbox"
                                          class="custom-control-input"
                                          id="customCheck-1"
                                          name={item.questionId}
                                          disabled="disabled"
                                          {...(item.answer.includes(optele)
                                            ? (that.state.checked = true)
                                            : (that.state.checked = false))}
                                          checked={that.state.checked}
                                          defaultChecked={that.state.checked}
                                        />
                                        <label
                                          class="custom-control-label"
                                          for="customCheck-1"
                                        >
                                          {optele}
                                        </label>
                                      </span>
                                    ))}
                                  </div>
                                </li>
                              );
                              break;
                            
                            default:
                              confirmAlert({
                                title: "Alert !",
                                message: "Error displaying preview.",
                                buttons: [
                                  {
                                    label: "Ok"
                                  }
                                ]
                              });
                              break;
                          }
                        })
                        : null}
                    </ul>
                    <br />

                    <div class="clearfix"></div>
                  </div>
                </div>
              </section>
            </section>
          </section>
        </div>
      );
    }
  };

  ToStoreList = () => {
    this.props.history.push({
      pathname: "/StoreList",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        formerMasterList: this.state.formerMasterList,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  Storecreation = () => {
    this.props.history.push({
      pathname: "/Storecreation",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        formerMasterList: this.state.formerMasterList,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  showIssueDropdwnHandler = () => {
    this.setState({
      IssueDropdwn: !this.state.IssueDropdwn
    });
  };

  IssueRaising = () => {
    this.props.history.push({
      pathname: "/IssueRaising",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        data: this.props.location.state.data,
        tskId: this.props.location.state.tskId,
        tskName: this.props.location.state.tskName,
        storeNo: this.props.location.state.storeNo,
        Isroffice: this.props.location.state.Isroffice,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  toGenDashboard = () => {
    this.props.history.push({
      pathname: "/DashboardGeneral",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        data: this.props.location.state.data,
        tskId: this.props.location.state.tskId,
        tskName: this.props.location.state.tskName,
        storeNo: this.props.location.state.storeNo,
        Isroffice: this.props.location.state.Isroffice,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  IssueDashboard = () => {
    this.props.history.push({
      pathname: "/IssueDashboard",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        data: this.props.location.state.data,
        tskId: this.props.location.state.tskId,
        tskName: this.props.location.state.tskName,
        storeNo: this.props.location.state.storeNo,
        Isroffice: this.props.location.state.Isroffice,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  fetchQuestionPaper = e => {

    if (
      this.props.location.state !== undefined &&
      this.props.location.state.usrnm !== ""
    ) {
      this.setState({ loading: true });
      this.state.fileuploaded = [];
      this.setState({ fileval: "Upload file" });
      this.setState({ data: [] });

      if (this.props.location.state) {
        this.setState({
          role_id: this.props.location.state.role_id,
          usrnm: this.props.location.state.usrnm
        });

        this.setState({ taskId: this.props.location.state.taskId });
        this.setState({ storeNo: this.props.location.state.storeNo });

        var Request1 = {
          taskId: this.props.location.state.taskId,
          storeId: this.props.location.state.storeNo,
          guId: this.props.location.state.guId
        };
        var EncryptedRequest1 = Encrypt(Request1);

        fetch("/TaskAnswer", {
          method: "POST",
          headers: {
            guId: this.props.location.state.guId,
            Authorization:
              "Basic " + basicAuth(this.props.location.state.userid)
          },
          body: EncryptedRequest1
        })
          .then(response => response.text())
          .then(response => {
            var DecryptedResponse = decryptData(response);
            if (
              DecryptedResponse.errorCode === "00" &&
              DecryptedResponse.listQuestions != undefined
            ) {
              this.setState({ file: DecryptedResponse.task.taskFile });
              this.setState({ file2: DecryptedResponse.task.taskFile2 });
              this.setState({ file3: DecryptedResponse.task.taskFile3 });
              this.setState({ data: DecryptedResponse.listQuestions });
              this.state.datapresent = true;
              var isimgq = "false";
              if (DecryptedResponse.listQuestions.length > 0) {
                for (
                  let index = 0;
                  index < DecryptedResponse.listQuestions.length;
                  index++
                ) {
                  const one = DecryptedResponse.listQuestions[index];
                  if (one.questiontype === "8" || one.questiontype === "11") {
                    if (one.imageArray === "") {
                      this.state.fileuploaded = [];
                      this.setState({ fileval: "Upload file" });
                    } else {
                      for (let k = 0; k < one.imageArray.length; k++) {
                        this.state.fileuploaded.push(
                          one.questionId + one.imageArray[k]
                        );
                      }
                      this.setState({ file: one.imageArray });
                      this.setState({ fileval: "One file attached" });
                      isimgq = "true";
                    }
                  }
                  if (isimgq === "false") {
                    this.state.fileuploaded = [];
                    this.setState({ fileval: "Upload file" });
                  }
                }
              }
              this.setState({ loading: false });
            } else {
              this.setState({ loading: false });
              confirmAlert({
                title: "Alert !",
                message: "No answers to display",
                buttons: [
                  {
                    label: "Ok"
                  }
                ]
              });
              this.setState({ datapresent: false });
            }
          })
          .catch(error => {
            confirmAlert({
              title: "Alert !",
              message: "Session expired",
              buttons: [
                {
                  label: "Ok",
                  onClick: () => {
                    this.logoutnormal();
                  }
                }
              ]
            });
          });
      }
    } else {
      window.location.href = "/";
    }
  };

  getroles(e) {
    this.props.history.push({
      pathname: "/CreatetaskUI",
      state: {
        format_id: this.props.location.state.format_id,
        role_id: this.props.location.state.role_id,
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  }
  Astrik = props => {
    const isMandatory = props.isMandatory;
    if (isMandatory == 1) {
      return <font color="red"> *</font>;
    }
    return null;
  };

  openIns(params) {
    if (params === 1) {
      var string = this.state.file;
      var iframe =
        "<iframe width='100%' height='100%' src='" +
        string +
        "'></iframe>";
      var iframe =
        "<iframe width='100%' height='100%' src='" +
        "/IMAGES/" +
        this.state.file +
        "'></iframe>";
      var x = window.open();
      x.document.open();
      x.document.write(iframe);
      x.document.close();
    } else if (params === 2) {
      var string = this.state.file2;
      var iframe =
        "<iframe width='100%' height='100%' src='" +
        string +
        "'></iframe>";
      var iframe =
        "<iframe width='100%' height='100%' src='" +
        "/IMAGES/" +
        this.state.file2 +
        "'></iframe>";
      var x = window.open();
      x.document.open();
      x.document.write(iframe);
      x.document.close();
    } else if (params === 3) {
      var string = this.state.file3;
      var iframe =
        "<iframe width='100%' height='100%' src='" +
        string +
        "'></iframe>";
      var iframe =
        "<iframe width='100%' height='100%' src='" +
        "/IMAGES/" +
        this.state.file3 +
        "'></iframe>";
      var x = window.open();
      x.document.open();
      x.document.write(iframe);
      x.document.close();
    }
  }


  DownloadIns = (params) => {
    if (params === 1) {
      document.getElementById("FileDnld").click();
    } else if (params === 2) {
      document.getElementById("FileDnld2").click();
    } else if (params === 3) {
      document.getElementById("FileDnld3").click();
    }
  };

  SmartPointBind = () => {
    this.props.history.push({
      pathname: "/SmartPoint",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        data: this.props.location.state.data,
        tskId: this.props.location.state.tskId,
        tskName: this.props.location.state.tskName,
        storeNo: this.props.location.state.storeNo,
        Isroffice: this.props.location.state.Isroffice,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  Defectbind = () => {
    this.props.history.push({
      pathname: "/DefectRaising",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        data: this.props.location.state.data,
        tskId: this.props.location.state.tskId,
        tskName: this.props.location.state.tskName,
        storeNo: this.props.location.state.storeNo,
        Isroffice: this.props.location.state.Isroffice,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  Reportsbind = () => {
    this.props.history.push({
      pathname: "/Reports",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        data: this.state.data,
        tskId: this.state.taskId,
        tskName: this.state.taskName,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };
  StoreTasks = () => {
    this.props.history.push({
      pathname: "/StoreTasks",
      state: {
        data: this.state.data,
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  ReopenTask = () => {
    //if(this.props.location.state.edDate )
    var monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ];

    var date =
      new Date().getDate() +
      "-" +
      monthNames[new Date().getMonth()] +
      "-" +
      new Date().getFullYear();

    if (!document.getElementById("comment").value) {
      confirmAlert({
        // title: 'Confirm to submit',
        message: "Please enter comments.",
        buttons: [
          {
            label: "Ok"
            //onClick: () => alert('Click Yes')
          }
        ]
      });
    } else {
      if (new Date(document.getElementById("extend").value) >= new Date(date)) {
        confirmAlert({
          // title: 'Confirm to submit',
          message: "Are you sure, you want to reassign task?",
          buttons: [
            {
              label: "Yes",
              onClick: () => {
                var Request1 = {
                  taskId: this.props.location.state.taskId,
                  guId: this.props.location.state.guId,
                  storeNo: this.state.storeNo,
                  comment: document.getElementById("comment").value,
                  extendedDate: document.getElementById("extend").value
                };
                var EncryptedRequest1 = Encrypt(Request1);
                fetch("/ReopenTask ", {
                  method: "POST",
                  headers: {
                    guId: this.props.location.state.guId,
                    Authorization:
                      "Basic " + basicAuth(this.props.location.state.userid)
                  },
                  body: EncryptedRequest1
                })
                  .then(response => response.text())
                  .then(response => {
                    var DecryptedResponse = decryptData(response);
                    if (DecryptedResponse.errorCode === "00") {
                      confirmAlert({
                        message: "Task reassigned successfully.",
                        buttons: [
                          {
                            label: "Ok",
                            onClick: () => {
                              this.modalclose();
                            }
                          }
                        ]
                      });
                    } else {
                      this.setState({ loading: false });
                      confirmAlert({
                        message: DecryptedResponse.errorMsg,
                        buttons: [
                          {
                            label: "Ok"
                          }
                        ]
                      });
                    }
                  })
                  .catch(error => {
                    confirmAlert({
                      title: "Alert !",
                      message: "Session expired",
                      buttons: [
                        {
                          label: "Ok",
                          onClick: () => {
                            this.logoutnormal();
                          }
                        }
                      ]
                    });
                  });
              }
            },
            {
              label: "No",
              onClick: () => {
                return false;
              }
            }
          ]
        });
      } else {
        confirmAlert({
          message:
            "Extended date should be greater than or equal to today's date.",
          buttons: [
            {
              label: "Ok",
              onClick: () => {
                return false;
              }
            }
          ]
        });
      }
    }
  };

  ArchivedTasks = () => {
    this.props.history.push({
      pathname: "/ArchivedTasks",
      state: {
        data: this.state.data,
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
        Isroffice: this.props.location.state.Isroffice,
      }
    });
  };

  CompletedTaskListbind = () => {
    this.props.history.push({
      pathname: "/CompletedTaskList",
      state: {
        data: this.state.data,
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };

  TaskactiveBind = () => {
    this.props.history.push({
      pathname: "/Taskactive",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      }
    });
  };
}
export default QuestionPaper;
