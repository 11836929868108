/** @format */

import React, { Component } from "react";
import "jquery/dist/jquery";
import { withRouter } from "react-router-dom";
import addDays from "date-fns/addDays";
import DatePicker from "react-datepicker";
import { confirmAlert } from "react-confirm-alert"; // Import
import "./css/react-confirm-alert.css"; // Import css
import Spinner from "react-spinner-material"; //Import Spinner
import "react-datepicker/dist/react-datepicker.css";
import { Scrollbars } from "react-custom-scrollbars";
import IdleTimer from "react-idle-timer"; // For Idle
import "./css/style.css";
import "./css/style-responsive.css";
import { Encrypt, decryptData } from "./Encryption-Decrypytion";
import disableBrowserBackButton from "disable-browser-back-navigation";
import { basicAuth } from "./BasicAuth";
import MySelect from "./MySelect.jsx";
import readXlsxFile from "read-excel-file";

const display = {
  display: "block",
};
const hide = {
  display: "none",
};
class Ticker extends Component {
  state = {
    TaskDropdwn: false,
    hasError: false,
  };
  showTaskDropdwnHandler = () => {
    this.setState({
      TaskDropdwn: !this.state.TaskDropdwn,
    });
  };

  constructor(props) {
    super(props);
    this.state = {
      format_id: "",
      displayMenu: false,
      hasError: false,
      toggle: false,
    };
    this.state = {
      format_id: "",
      startDate: "",
      UpdatesDate: "",
      UpdateeDate: "",
      startdateticker: "",
      startdateupdateticker: "",
      enddateticker: "",
      enddateupdateticker: "",
      startdate: "",
      endDate: "",
      enddate: "",
      resptaskname: [],
      respstartdate: [],
      respenddate: [],
      resptaskdesc: [],
      data: [],
      usrnm: "",
      userid: "",
      Search: "",
      searchSDate: "",
      searchedDate: "",
      searcheddesc: "",
      tskId: "",
      tickerId: "",
      tskName: "",
      loading: false,
      hasError: false,
      toggle: false,
      strtDate: "",
      edDate: "",
      hammenu: true,
      displayMenu: false,
      TaskDropdwn: false,
      zoneSelected: [],
      zoneList: [],
      optionSelectedZ: null,
      assignSelect: "",
      StoreList: [],
      FileName: "",
      finalZone: [],
    };
    this.idleTimer = null;
    this.onAction = this._onAction.bind(this);
    this.onActive = this._onActive.bind(this);
    this.onIdle = this._onIdle.bind(this);
    this.handleChangeStart = this.handleChangeStart.bind(this);
    this.handletickersupdate = this.handletickersupdate.bind(this);
    this.handletickereupdate = this.handletickereupdate.bind(this);
    this.handleChangesticker = this.handleChangesticker.bind(this);
    this.handleChangeeticker = this.handleChangeeticker.bind(this);
    this.handleChangeStar = this.handleChangeStar.bind(this);
    this.handleChangeEnd = this.handleChangeEnd.bind(this);
    this.handleChangend = this.handleChangend.bind(this);

    this.showDropdownMenu = this.showDropdownMenu.bind(this);
    this.hideDropdownMenu = this.hideDropdownMenu.bind(this);
  }

  logoutnormal = () => {
    localStorage.clear();
    this.props.history.replace("/");
    disableBrowserBackButton();
  };

  handleChangeStart(date) {
    var monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    this.setState({
      searchSDate:
        date.getDate() +
        "-" +
        monthNames[date.getMonth()] +
        "-" +
        date.getFullYear(),
      startDate: date,
    });
  }

  componentDidMount() {
    if (window.innerWidth <= 768) {
      this.setState({ hammenu: false });
    }
  }

  handletickersupdate(dates) {
    this.setState({ startdateupdateticker: dates });
    this.setState({ UpdatesDate: dates });
  }

  handletickereupdate(dates) {
    this.setState({
      enddateupdateticker: dates,
      UpdateeDate: dates,
    });
  }

  handleChangesticker(dates) {
    this.setState({
      startdateticker: dates,
    });
  }

  handleDateChangeRaw = (e) => {
    e.preventDefault();
  };

  handleDateChangeRawe = (e) => {
    e.preventDefault();
  };

  handleChangeEnd(date) {
    var monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    this.setState({
      searchedDate:
        date.getDate() +
        "-" +
        monthNames[date.getMonth()] +
        "-" +
        date.getFullYear(),
      endDate: date,
    });
  }

  handleChangeeticker(dated) {
    this.setState({
      enddateticker: dated,
    });
  }

  logout = () => {
    this.setState({ loading: true });
    var Request1 = {
      userId: this.props.location.state.userid,
      guId: this.props.location.state.guId,
    };
    var EncryptedRequest1 = Encrypt(Request1);
    fetch("/LogOut ", {
      method: "POST",
      headers: {
        guId: this.props.location.state.guId,
        Authorization: "Basic " + basicAuth(this.props.location.state.userid),
      },
      body: EncryptedRequest1,
    })
      .then((response) => response.text())
      .then((response) => {
        this.setState({ loading: false });
        var DecryptedResponse = decryptData(response);
        if (DecryptedResponse.errorCode === "00") {
          this.props.history.replace("/");
          disableBrowserBackButton();
        } else {
          confirmAlert({
            message: DecryptedResponse.errorMsg,
            buttons: [
              {
                label: "Ok",
              },
            ],
          });
        }
      })
      .catch((error) => {
        confirmAlert({
          title: "Alert !",
          message: "Session expired",
          buttons: [
            {
              label: "Ok",
              onClick: () => {
                this.logoutnormal();
              },
            },
          ],
        });
      });
  };

  showDropdownMenu(event) {
    event.preventDefault();
    this.setState({ displayMenu: true }, () => {
      document.addEventListener("click", this.hideDropdownMenu);
    });
  }

  hideDropdownMenu() {
    this.setState({ displayMenu: false }, () => {
      document.removeEventListener("click", this.hideDropdownMenu);
    });
  }

  handleAssignTo() {
    this.state.assignSelect = document.getElementById("assignTo").value;
  }

  fileHandler = (event) => {
    const input = document.getElementById("upload-storedata");
    var stores = [];
    readXlsxFile(input.files[0]).then((rows, files) => {
      for (let index = 0; index < rows.length; index++) {
        const element = rows[index];
        stores.push(element[0]);
      }
      this.setState({
        StoreList: stores,
        FileName: "File Uploaded Successfully",
        finalZone: [],
      });
    });
  };

  onChange = (e) => {
    this.setState({
      Search: e.target.value,
    });
  };

  componentWillMount() {
    if (
      this.props.location.state === undefined ||
      this.props.location.state === ""
    ) {
      window.location.href = "/";
    } else {
      this.GetTasksOnload();
    }
  }

  handleChangeZ = (selected) => {
    this.setState({
      optionSelectedZ: selected,
      StoreList: [],
    });
    if (selected != null) {
      this.state.zoneSelected = selected;
      var abc = [];
      for (var i = 0; i < this.state.zoneSelected.length; i++) {
        abc.push(this.state.zoneSelected[i].label);
      }
      this.state.finalZone = abc;
    }
  };

  handleDateChangeRaws = (e) => {
    e.preventDefault();
  };

  _onAction(e) {}

  _onActive(e) {}

  _onIdle(e) {
    localStorage.clear();
    window.location.href = "/";
    disableBrowserBackButton();
  }

  componentDidCatch() {
    this.setState({ hasError: true });
  }

  userlist = () => {
    this.props.history.push({
      pathname: "/UserList",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      },
    });
  };

  createtclose = () => {
    document.getElementById("taskName").value = null;
    this.setState({
      disappear: false,
      startdateticker: "",
      enddateticker: "",
    });
  };

  createmodal(taskName, taskDescr) {
    this.setState((prevState) => ({
      disappear: !prevState.disappear,
    }));
    this.setState({
      taskName: taskName,
      taskDescr: taskDescr,
    });
  }

  modalclose = (e) => {
    this.setState({
      toggle: false,
    });
  };

  UpdateTicker(tickerId, tickerText, startDate, endDate) {
    this.setState({
      tickerId: tickerId,
      tickerText: tickerText,
      UpdatesDate: startDate,
      UpdateeDate: endDate,
      toggle: !this.state.toggle,
    });
  }

  templates = () => {
    this.props.history.push({
      pathname: "/Templates",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      },
    });
  };
  showTaskdwnHandler = () => {
    this.setState({
      Taskdwn: !this.state.Taskdwn,
    });
  };

  usercreation = () => {
    this.props.history.push({
      pathname: "/UserCreation",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      },
    });
  };

  handleChangeStar(date) {
    this.setState({
      strtDate: date,
    });
  }

  handleChangend(date) {
    this.setState({
      edDate: date,
    });
  }
  render = () => {
    var modal = [];

    modal.push(
      <div
        className="modal"
        role="dialog"
        style={this.state.toggle ? display : hide}
      >
        <div className="modal-dialog animate">
          <div className="modal-content">
            <div className="modal-header">
              <a
                className="close"
                style={{ color: "#fff" }}
                onClick={this.modalclose}
              >
                X
              </a>
              <h4 className="modal-title">Update Ticker</h4>
            </div>
            <div className="modal-body">
              <div className="form-horizontal">
                <div className="form-group">
                  <h4 className="control-label col-sm-4">Ticker Id :</h4>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      class="form-control"
                      value={this.state.tickerId}
                      onChange={(f) =>
                        this.setState({ tickerId: f.target.value })
                      }
                      id="tickerid"
                      disabled
                    ></input>
                  </div>
                </div>
                <div className="form-group">
                  <h4 className="control-label col-sm-4">
                    Ticker Description :
                  </h4>
                  <div className="col-sm-8">
                    <textarea
                      className="form-control"
                      value={this.state.tickerText}
                      onChange={(f) =>
                        this.setState({ tickerText: f.target.value })
                      }
                      id="tickerdesc"
                      maxlength="200"
                    ></textarea>
                  </div>
                </div>
                <div className="form-group">
                  <h4 className="control-label col-sm-4">Start Date :</h4>
                  <div className="col-sm-8">
                    <DatePicker
                      ref="startdate"
                      className="form-control"
                      size="16"
                      type="text"
                      dateFormat="dd-MMM-yyyy"
                      id="updatestartDate"
                      selected={this.state.startdateupdateticker}
                      minDate={new Date()}
                      onChange={this.handletickersupdate}
                      autoComplete="off"
                      value={this.state.UpdatesDate}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <h4 className="control-label col-sm-4">End Date :</h4>
                  <div className="col-sm-8">
                    <DatePicker
                      ref="enddate"
                      className="form-control"
                      size="16"
                      type="text"
                      dateFormat="dd-MMM-yyyy"
                      id="updateendDate"
                      onChange={this.handletickereupdate}
                      maxDate={addDays(new Date(), 365)}
                      selected={this.state.enddateupdateticker}
                      minDate={this.state.startdateupdateticker}
                      autoComplete="off"
                      value={this.state.UpdateeDate}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="col-sm-4">&nbsp;</div>
                  <div className="col-sm-8">
                    <button
                      class="btn btn-primary min-wid-90 mt-17"
                      onClick={this.UpdateTask.bind(this)}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    var createt = [];
    createt.push(
      <div
        className="modal"
        role="dialog"
        style={this.state.disappear ? display : hide}
      >
        <div className="modal-dialog animate">
          <div className="modal-content">
            <div className="modal-header">
              <a
                className="close"
                style={{ color: "#fff" }}
                onClick={this.createtclose}
              >
                X
              </a>
              <h4 className="modal-title">Create Ticker</h4>
            </div>
            <div className="modal-body">
              <div className="form-horizontal">
                <div className="form-group">
                  <h4 className="control-label col-sm-4">Ticker Text :</h4>
                  <div className="col-sm-8">
                    <textarea
                      class="form-control"
                      id="taskName"
                      autoComplete="off"
                      maxlength="200"
                    ></textarea>
                  </div>
                </div>

                <div className="form-group">
                  <h4 className="control-label col-sm-4">
                    Ticker Start Date :
                  </h4>
                  <div className="col-sm-8">
                    <DatePicker
                      className="form-control"
                      size="16"
                      type="text"
                      placeholderText="Select Start Date "
                      dateFormat="dd-MMM-yyyy"
                      id="startDat"
                      selected={this.state.startdateticker}
                      minDate={new Date()}
                      onChange={this.handleChangesticker}
                      onChangeRaw={this.handleDateChangeRaw}
                      autoComplete="off"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <h4 className="control-label col-sm-4">Ticker End Date :</h4>
                  <div className="col-sm-8">
                    <DatePicker
                      className=" form-control"
                      size="16"
                      type="text"
                      placeholderText="Select End Date"
                      dateFormat="dd-MMM-yyyy"
                      id="endDat"
                      selected={this.state.enddateticker}
                      onChange={this.handleChangeeticker}
                      minDate={this.state.startdateticker}
                      maxDate={addDays(new Date(), 365)}
                      onChangeRaw={this.handleDateChangeRawe}
                      autoComplete="off"
                    />
                  </div>
                </div>

                <div className="form-group">
                  <h4 className="control-label col-sm-4">Assign To :</h4>
                  <div className="col-sm-8">
                    <select
                      class="form-control"
                      onChange={(e) => {
                        this.setState({
                          assignSelect: e.target.value,
                        });
                      }}
                      id="assignTo"
                    >
                      <option>--Select--</option>
                      <option>Zone Wise</option>
                      <option>Store Wise</option>
                      <option>Service Center Wise</option>
                      {/* this.props.location.state.menuList.map((element) => (<option>{element}</option>)) */}
                    </select>
                  </div>
                </div>

                {this.state.assignSelect === "Zone Wise" ? (
                  <div className="form-group">
                    <h4 className="control-label col-sm-4">Select Zone :</h4>
                    <div className="col-sm-8">
                      <MySelect
                        options={this.state.zoneList}
                        isMulti
                        closeMenuOnSelect={true}
                        hideSelectedOptions={false}
                        /*  components={animatedComponents} */
                        onChange={this.handleChangeZ.bind(this)}
                        allowSelectAll={true}
                        value={this.state.optionSelectedZ}
                        id="uom"
                      />
                    </div>
                  </div>
                ) : null}

                {this.state.assignSelect === "Store Wise" ||
                this.state.assignSelect === "Service Center Wise" ? (
                  <div className="form-group">
                    <h4 className="control-label col-sm-4">Stores :</h4>

                    <div class="col-sm-8">
                      <input
                        id="store-list"
                        type="text"
                        class="form-control input-upload"
                        value={this.state.FileName}
                      />
                      <span class="btn-upload">Upload</span>
                      <input
                        id="upload-storedata"
                        type="file"
                        name="Store-list-data"
                        class="input-hidden"
                        onChange={this.fileHandler.bind(this)}
                        accept=".xlsx,.xls"
                        style={{ height: "50%" }}
                      />
                      <span class="help-block">
                        Sample File{" "}
                        <a href={require("./doc/sample.xlsx")}>Sample.xls</a>
                      </span>{" "}
                    </div>
                  </div>
                ) : null}

                <div className="form-group">
                  <div className="col-sm-4">&nbsp;</div>
                  <div className="col-sm-8">
                    <button
                      class="btn btn-primary min-wid-90 mt-17"
                      onClick={this.CreateTicker.bind(this)}
                    >
                      Create Ticker
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    var that = this;

    const { Search } = this.state;
    const { searchSDate } = this.state;
    const { searchedDate } = this.state;
    // const { searcheddesc }  = this.state;

    if (this.state.hasError) {
      return (
        <div>
          <h2>Error occurred!! please contact administrator</h2>
        </div>
      );
    } else {
      return (
        <div>
          {/* //<div className="spinner"> */}
          {/* <Spinner  visible={this.state.loading} /> */}

          {/* </div> */}
          <section id="container">
            <header className="header black-bg">
              <div>
                <IdleTimer
                  ref={(ref) => {
                    this.idleTimer = ref;
                  }}
                  element={document}
                  onActive={this.onActive}
                  onIdle={this.onIdle}
                  onAction={this.onAction}
                  debounce={250}
                  timeout={1000 * 60 * 10}
                />
                {modal}
                {createt}
              </div>
              {/* <marquee scrollamount="3">
       <li>Reliance Trends </li>
         </marquee> */}
              <a
                className="mob-show"
                onClick={() => this.setState({ hammenu: !this.state.hammenu })}
              >
                <i
                  className="fa fa-tasks hammenu"
                  style={{ "margin-top": "25px", padding: "0 15px" }}
                ></i>
              </a>

              <a
                onClick={this.TaskactiveBind.bind(this)}
                className="logo"
                style={{ padding: "0 10px" }}
              >
                <img src={require("./img/retail-logo.png")} alt="logo" />
              </a>
              <div className="markcenter">
                <p className="marquee">{this.state.tickerList}</p>
              </div>
              <ul className="nav pull-right pos-rel">
                <li className="dropdown">
                  <a
                    className="dropdown-toggle"
                    data-toggle="dropdown"
                    onClick={this.showDropdownMenu}
                  >
                    {" "}
                    <img
                      src={require("./img/user.png")}
                      className="user-img"
                    />{" "}
                    <b className="name-show">
                      {this.props.location.state.usrnm}
                    </b>
                    ({this.props.location.state.storeNo})
                    <b className="caret" />
                  </a>
                </li>

                {this.state.displayMenu ? (
                  <ul className="dropdown-menuuser-dd">
                    <li>Role : {this.props.location.state.roleName}</li>
                    <li className="divider"></li>
                    <li>
                      <a onClick={this.Changepasswordbind}>Change Password</a>
                    </li>
                    <li className="divider"></li>
                    <li>
                      <a onClick={this.logout}>Log Out</a>
                    </li>
                  </ul>
                ) : null}
              </ul>
            </header>
            <aside>
              <div id="sidebar" className="nav-collapse">
                {this.state.hammenu ? (
                  <ul className="sidebar-menu" id="nav-accordion">
                    {this.props.location.state.menuList["401"] ||
                    this.props.location.state.menuList["402"] ||
                    this.props.location.state.menuList["403"] ||
                    this.props.location.state.menuList["404"] ||
                    this.props.location.state.menuList["405"] ||
                    this.props.location.state.menuList["408"] ||
                    this.props.location.state.menuList["411"] ? (
                      <li
                        className="sub-menu mt"
                        onClick={this.showTaskDropdwnHandler.bind(this)}
                      >
                        {" "}
                        <a href="javascript:;">
                          {" "}
                          <i className="fa fa-tasks"></i> <span>Task</span>{" "}
                        </a>
                        {this.state.TaskDropdwn ? (
                          <div>
                            <ul class="sub">
                              {this.props.location.state.menuList["401"] ? (
                                <li>
                                  <a onClick={this.TaskactiveBind.bind(this)}>
                                    {this.props.location.state.menuList["401"]}
                                  </a>
                                </li>
                              ) : null}
                              {this.props.location.state.menuList["402"] ? (
                                <li>
                                  <a onClick={this.getroles.bind(this)}>
                                    {this.props.location.state.menuList["402"]}
                                  </a>
                                </li>
                              ) : null}
                              {this.props.location.state.menuList["403"] ? (
                                <li>
                                  <a
                                    onClick={this.CompletedTaskListbind.bind(
                                      this
                                    )}
                                  >
                                    {this.props.location.state.menuList["403"]}
                                  </a>
                                </li>
                              ) : null}
                              {this.props.location.state.menuList["404"] ? (
                                <li>
                                  <a onClick={this.templates.bind(this)}>
                                    {this.props.location.state.menuList["404"]}
                                  </a>
                                </li>
                              ) : null}
                              {this.props.location.state.menuList["405"] ? (
                                <li>
                                  <a onClick={this.ArchivedTasks.bind(this)}>
                                    {this.props.location.state.menuList["405"]}
                                  </a>
                                </li>
                              ) : null}
                              {this.props.location.state.menuList["408"] ? (
                                <li>
                                  <a onClick={this.StoreTasks.bind(this)}>
                                    {this.props.location.state.menuList["408"]}
                                  </a>
                                </li>
                              ) : null}
                              {this.props.location.state.menuList["411"] ? (
                                <li>
                                  <a onClick={this.toGenDashboard.bind(this)}>
                                    {this.props.location.state.menuList["411"]}
                                  </a>
                                </li>
                              ) : null}
                            </ul>
                          </div>
                        ) : null}
                      </li>
                    ) : null}
                    {this.props.location.state.menuList["406"] ? (
                      <div>
                        <li>
                          {" "}
                          <a onClick={this.Reportsbind.bind(this)}>
                            {" "}
                            <i className="fa fa-tasks"></i>{" "}
                            <span>
                              {this.props.location.state.menuList["406"]}
                            </span>{" "}
                          </a>{" "}
                        </li>
                      </div>
                    ) : null}

                    {this.props.location.state.menuList["414"] &&
                    this.props.location.state.format_id === "102" ? (
                      <div>
                        <li>
                          {" "}
                          <a onClick={this.Defectbind.bind(this)}>
                            {" "}
                            <i className="fa fa-tasks"></i>{" "}
                            <span>
                              {this.props.location.state.menuList["414"]}
                            </span>{" "}
                          </a>{" "}
                        </li>
                      </div>
                    ) : null}

                    {this.props.location.state.menuList["415"] ? (
                      <div>
                        <li>
                          {" "}
                          <a onClick={this.SmartPointBind.bind(this)}>
                            {" "}
                            <i className="fa fa-tasks"></i>{" "}
                            <span>
                              {this.props.location.state.menuList["415"]}
                            </span>{" "}
                          </a>{" "}
                        </li>
                      </div>
                    ) : null}

                    {this.props.location.state.menuList["409"] ||
                    this.props.location.state.menuList["410"] ? (
                      <div>
                        <li
                          className="sub-menu"
                          onClick={this.showIssueDropdwnHandler.bind(this)}
                        >
                          {" "}
                          <a href="javascript:;">
                            {" "}
                            <i className="fa fa-tasks"></i> <span>Issue</span>{" "}
                          </a>
                          {this.state.IssueDropdwn ? (
                            <div>
                              <ul class="sub">
                                {this.props.location.state.menuList["410"] ? (
                                  <li>
                                    <a onClick={this.IssueRaising.bind(this)}>
                                      {
                                        this.props.location.state.menuList[
                                          "410"
                                        ]
                                      }
                                    </a>
                                  </li>
                                ) : null}
                                {this.props.location.state.menuList["409"] ? (
                                  <li>
                                    <a onClick={this.IssueDashboard.bind(this)}>
                                      {
                                        this.props.location.state.menuList[
                                          "409"
                                        ]
                                      }
                                    </a>
                                  </li>
                                ) : null}
                              </ul>
                            </div>
                          ) : null}
                        </li>
                      </div>
                    ) : null}

                    {this.props.location.state.menuList["407"] ? (
                      <div>
                        <li
                          className="sub-menu"
                          onClick={this.showTaskdwnHandler.bind(this)}
                        >
                          {" "}
                          <a href="javascript:;">
                            {" "}
                            <i className="fa fa-tasks"></i>{" "}
                            <span>
                              {this.props.location.state.menuList["407"]}
                            </span>{" "}
                          </a>
                          {this.state.Taskdwn ? (
                            <div>
                              <ul class="sub">
                                <li>
                                  {" "}
                                  <a onClick={this.tiker.bind(this)}>
                                    <span>Tiker Creation</span>{" "}
                                  </a>{" "}
                                </li>
                                <li>
                                  {" "}
                                  <a onClick={this.userlist.bind(this)}>
                                    <span>User List</span>{" "}
                                  </a>{" "}
                                </li>
                                {this.props.location.state.menuList["412"] ? (
                                  <li>
                                    {" "}
                                    <a onClick={this.Storecreation.bind(this)}>
                                      <span>
                                        {
                                          this.props.location.state.menuList[
                                            "412"
                                          ]
                                        }
                                      </span>{" "}
                                    </a>{" "}
                                  </li>
                                ) : null}
                                {this.props.location.state.menuList["413"] ? (
                                  <li>
                                    {" "}
                                    <a onClick={this.ToStoreList.bind(this)}>
                                      <span>
                                        {
                                          this.props.location.state.menuList[
                                            "413"
                                          ]
                                        }
                                      </span>{" "}
                                    </a>{" "}
                                  </li>
                                ) : null}
                              </ul>
                            </div>
                          ) : null}
                        </li>
                      </div>
                    ) : null}
                  </ul>
                ) : null}
              </div>
            </aside>
            <section id="main-content">
              <section className="wrapper">
                <div className="mt">
                  <h3>
                    <i className="fa fa-angle-right"></i> Ticker(s)
                    <a
                      className="pull-right btn btn-theme"
                      onClick={this.createmodal.bind(this)}
                    >
                      + Create Ticker
                    </a>{" "}
                  </h3>
                  <hr />
                  <div className="row mt">
                    <div className="col-md-12">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="col-xs-12 col-sm-5 col-md-5 col-lg-5">
                          {" "}
                          <b>Ticker Text</b>
                          <br />
                          <input
                            type="text"
                            placeholder="Search Ticker Text"
                            className="form-control"
                            id="tasknames"
                            onChange={this.onChange}
                            autoComplete="off"
                          />
                        </div>
                        {/*                      
                      <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3"> <b>Task Description</b><br />
                        <input type="text" placeholder="Search Task Description" className="form-control" id="taskdesc" onChange={this.onChange1} autoComplete="off" />
                      </div> */}

                        <div className="col-xs-12 col-sm-3 col-md-2 col-lg-2 mt-xs-10 w-sm-110">
                          {" "}
                          <b>Start Date</b>
                          <br />
                          <DatePicker
                            autoComplete="off"
                            className="form-control form-control-inline input-medium default-date-picker"
                            size="16"
                            type="text"
                            placeholderText="Select Start Date "
                            dateFormat="dd-MMM-yyyy"
                            id="startDates"
                            selected={this.state.startDate}
                            // minDate={this.state.startDate }
                            onChange={this.handleChangeStart}
                            onChangeRaw={this.handleDateChangeRaws}
                          />
                        </div>
                        <div className="col-xs-12 col-sm-3 col-md-2 col-lg-2 mt-xs-10 w-sm-110">
                          <b> End Date</b>
                          <br />
                          <DatePicker
                            autoComplete="off"
                            className="form-control form-control-inline input-medium default-date-picker"
                            size="16"
                            type="text"
                            placeholderText="Select End Date"
                            dateFormat="dd-MMM-yyyy"
                            id="endDates"
                            selected={this.state.endDate}
                            //  minDate={this.state.startDate }
                            // maxDate={addDays(new Date(),365)}
                            onChange={this.handleChangeEnd}
                            onChangeRaw={this.handleDateChangeRawe}
                          />
                        </div>
                        <div className="col-xs-12 col-sm-1 col-md-1 col-lg-1 mt-xs-10">
                          <img
                            src={require("./img/searchicon.png")}
                            className="user-img mt-20"
                            style={{ width: "25px", height: "25px" }}
                          />
                        </div>
                        <div className="clearfix"></div>
                      </div>
                      <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12 tbwdth">
                        <div className="mt">
                          <div className="tbl-holder">
                            <div
                              className={
                                this.state.loading ? "parentDisable" : ""
                              }
                              width="100%"
                            >
                              <div className="overlay-box">
                                <Spinner
                                  visible={this.state.loading}
                                  spinnerColor={"rgba(0, 0, 0, 0.3)"}
                                />
                              </div>
                            </div>

                            <table className="table table-striped table-advance table-hover table-bordered tbl-ticker tbl-hhide">
                              <thead>
                                <tr>
                                  <th>Ticker Text</th>
                                  {/* <th>Description</th> */}
                                  <th>Start Date</th>
                                  <th>End Date</th>
                                  {/* <th>% Completed</th>
                                <th>Status</th> */}
                                  <th>Action</th>
                                </tr>
                              </thead>
                            </table>

                            <Scrollbars style={{ height: 296 }}>
                              <table className="table table-striped table-advance table-hover table-bordered tbl-ticker mob-tbl">
                                <tbody>
                                  {this.state.data.map(function (item, key) {
                                    if (
                                      (Search !== "" ||
                                        searchSDate !== "" ||
                                        searchedDate !== "") &&
                                      (item.tickerText
                                        .toLowerCase()
                                        .indexOf(Search.toLowerCase()) ||
                                        // item.taskDescr.toLowerCase().indexOf(searcheddesc.toLowerCase())||
                                        item.startDate
                                          .toLowerCase()
                                          .indexOf(searchSDate.toLowerCase()) ||
                                        item.endDate
                                          .toLowerCase()
                                          .indexOf(
                                            searchedDate.toLowerCase()
                                          )) === -1
                                    ) {
                                      return null;
                                    }

                                    //if((searchSDate)!== "" && (item.startDate.indexOf(searchSDate))===-1 ){
                                    // return null
                                    //  }

                                    return (
                                      <tr key={key}>
                                        <td
                                          data-th="Ticker Text"
                                          className="black-text"
                                        >
                                          {" "}
                                          {item.tickerText}
                                        </td>
                                        <td data-th="Start Date">
                                          {item.startDate}
                                        </td>
                                        <td data-th="End Date">
                                          {item.endDate}
                                        </td>

                                        <td data-th="Action">
                                          <a
                                            className="edit-icon"
                                            onClick={that.UpdateTicker.bind(
                                              that,
                                              item.tickerId,
                                              item.tickerText,
                                              item.startDate,
                                              item.endDate
                                            )}
                                          >
                                            {" "}
                                            <i className="fa fa-pencil "></i>{" "}
                                          </a>
                                          <a
                                            className="delete-icon"
                                            onClick={that.deletetask.bind(
                                              that,
                                              item.tickerId
                                            )}
                                          >
                                            {" "}
                                            <i className="fa fa-trash-o "></i>{" "}
                                          </a>

                                          <span className="d-none">
                                            {item.tickerId}
                                          </span>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </Scrollbars>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </section>
          </section>
        </div>
      );
    }
  };

  ToStoreList = () => {
    this.props.history.push({
      pathname: "/StoreList",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        formerMasterList: this.state.formerMasterList,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      },
    });
  };

  Storecreation = () => {
    this.props.history.push({
      pathname: "/Storecreation",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        formerMasterList: this.state.formerMasterList,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      },
    });
  };

  showIssueDropdwnHandler = () => {
    this.setState({
      IssueDropdwn: !this.state.IssueDropdwn,
    });
  };

  IssueRaising = () => {
    this.props.history.push({
      pathname: "/IssueRaising",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        data: this.props.location.state.data,
        tskId: this.props.location.state.tskId,
        tskName: this.props.location.state.tskName,
        storeNo: this.props.location.state.storeNo,
        Isroffice: this.props.location.state.Isroffice,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      },
    });
  };

  toGenDashboard = () => {
    this.props.history.push({
      pathname: "/DashboardGeneral",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        data: this.props.location.state.data,
        tskId: this.props.location.state.tskId,
        tskName: this.props.location.state.tskName,
        storeNo: this.props.location.state.storeNo,
        Isroffice: this.props.location.state.Isroffice,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      },
    });
  };

  IssueDashboard = () => {
    this.props.history.push({
      pathname: "/IssueDashboard",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        data: this.props.location.state.data,
        tskId: this.props.location.state.tskId,
        tskName: this.props.location.state.tskName,
        storeNo: this.props.location.state.storeNo,
        Isroffice: this.props.location.state.Isroffice,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      },
    });
  };

  GetTasksOnload(e) {
    this.setState({ loading: true });
    this.setState({ userid: this.props.location.state.userid });
    //  this.setState({})

    if (
      this.props.location.state !== undefined &&
      this.props.location.state.usrnm !== ""
    ) {
      this.setState({
        usrnm: this.props.location.state.usrnm,
      });

      var Request1 = {
        formatId: this.props.location.state.format_id,
        guId: this.props.location.state.guId,
      };
      var EncryptedRequest1 = Encrypt(Request1);

      fetch("/GetAllTicker", {
        method: "POST",
        headers: {
          guId: this.props.location.state.guId,
          Authorization: "Basic " + basicAuth(this.props.location.state.userid),
        },
        body: EncryptedRequest1,
      })
        .then((response) => response.text())
        .then((response) => {
          var DecryptedResponse1 = decryptData(response);

          if (DecryptedResponse1.errorCode === "00") {
            this.setState({ data: DecryptedResponse1.tikerList });
            this.setState({ usrnm: this.props.location.state.usrnm });
            this.setState({ userid: this.props.location.state.userid });
            this.setState({ tickerId: this.props.location.state.tickerId });
            this.setState({ tickerText: this.props.location.state.tickerText });
            this.setState({
              loading: false,
            });
          } else {
            this.setState({ loading: false });
            confirmAlert({
              message: DecryptedResponse1.errorMsg,
              buttons: [
                {
                  label: "Ok",
                },
              ],
            });
          }
        })
        .catch((error) => {
          confirmAlert({
            title: "Alert !",
            message: "Session expired",
            buttons: [
              {
                label: "Ok",
              },
            ],
          });
        });

      var requestZone = {
        formatId: this.props.location.state.format_id,
        guId: this.props.location.state.guId,
        requestType: "Zone",
      };
      var EncryptedRequestZone = Encrypt(requestZone);
      fetch("/GetZoneData", {
        method: "POST",
        headers: {
          guId: this.props.location.state.guId,
          Authorization: "Basic " + basicAuth(this.props.location.state.userid),
        },
        body: EncryptedRequestZone,
      })
        .then((response) => response.text())
        .then((response) => {
          var DecryptedResponse = decryptData(response);
          if (DecryptedResponse.errorCode === "00") {
            for (let d = 0; d < DecryptedResponse.zoneList.length; d++) {
              var zone = {
                label: "",
                value: "",
              };
              zone.label = DecryptedResponse.zoneList[d];
              zone.value = DecryptedResponse.zoneList[d];
              this.state.zoneList.push(zone);
            }
          } else {
            confirmAlert({
              message: DecryptedResponse.errorMessage,
              buttons: [
                {
                  label: "Ok",
                  onClick: () => {
                    return false;
                  },
                },
              ],
            });
          }
        })
        .catch((error) => {
          confirmAlert({
            title: "Alert !",
            message: "Session expired",
            buttons: [
              {
                label: "Ok",
                onClick: () => {
                  this.logoutnormal();
                },
              },
            ],
          });
        });
    } else {
      window.location.href = "/";
    }
  }

  CreateTicker = (e) => {
    e.preventDefault();
    if (!document.getElementById("taskName").value) {
      confirmAlert({
        title: "Alert !",
        message: "Please enter required fields",
        buttons: [
          {
            label: "Ok",
            onClick: () => {
              return false;
            },
          },
        ],
      });
    } else {
      var vstartdate = new Date(document.getElementById("startDat").value);
      var venddate = new Date(document.getElementById("endDat").value);

      if (
        !document.getElementById("startDat").value ||
        !document.getElementById("endDat").value
      ) {
        confirmAlert({
          message: "Please enter start/end date.",
          buttons: [
            {
              label: "Ok",
              onClick: () => {
                return false;
              },
            },
          ],
        });
      } else {
        if (venddate < vstartdate) {
          confirmAlert({
            message: "End date cannot be earlier than start date",
            buttons: [
              {
                label: "Ok",
                onClick: () => {
                  return false;
                },
              },
            ],
          });
        } else {
          var Request1 = {
            startDate: document.getElementById("startDat").value,
            endDate: document.getElementById("endDat").value,
            tickerText: document.getElementById("taskName").value,
            formatId: this.props.location.state.format_id,
            zoneList: this.state.finalZone,
            storeList: this.state.StoreList,
            guId: this.props.location.state.guId,
          };

          var EncryptedRequest1 = Encrypt(Request1);
          fetch("/CreateTicker", {
            method: "POST",
            headers: {
              guId: this.props.location.state.guId,
              Authorization:
                "Basic " + basicAuth(this.props.location.state.userid),
            },
            body: EncryptedRequest1,
          })
            .then((response) => response.text())
            .then((response) => {
              var DecryptedResponse = decryptData(response);
              if (DecryptedResponse.errorCode === "00") {
                {
                  this.createtclose();
                }
                confirmAlert({
                  title: "Success",
                  message: "Ticker created successfully.  ",
                  buttons: [
                    {
                      label: "Ok",
                      onClick: () => {
                        window.location.reload(true);
                      },
                    },
                  ],
                });
              } else {
                confirmAlert({
                  title: "Alert !",
                  message: DecryptedResponse.errorMsg,
                  buttons: [
                    {
                      label: "Ok",
                    },
                  ],
                });
              }
            })
            .catch((error) => {
              confirmAlert({
                title: "Alert !",
                message: "Session expired",
                buttons: [
                  {
                    label: "Ok",
                    onClick: () => {
                      this.logoutnormal();
                    },
                  },
                ],
              });
            });
        }
      }
    }
  };

  deletetask(tskid) {
    confirmAlert({
      message: "Are you sure, you want to delete this ticker?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            var Request2 = {
              tickerId: tskid,
              guId: this.props.location.state.guId,
            };
            var EncryptedRequest2 = Encrypt(Request2);
            fetch("/DeleteTicker", {
              method: "POST",
              headers: {
                guId: this.props.location.state.guId,
                Authorization:
                  "Basic " + basicAuth(this.props.location.state.userid),
              },
              body: EncryptedRequest2,
            })
              .then((response) => response.text())
              .then((response) => {
                var DecryptedResponse2 = decryptData(response);
                if (DecryptedResponse2.errorCode === "00") {
                  confirmAlert({
                    message: "Ticker  Deleted Successfully.",
                    buttons: [
                      {
                        label: "Ok",
                        onClick: () => {
                          window.location.reload(true);
                        },
                      },
                    ],
                  });
                  this.forceUpdate();
                } else {
                  confirmAlert({
                    title: "Alert !",
                    message: DecryptedResponse2.errorMsg,
                    buttons: [
                      {
                        label: "Ok",
                      },
                    ],
                  });
                }
              })
              .catch((error) => {
                confirmAlert({
                  title: "Alert !",
                  message: "Session expired",
                  buttons: [
                    {
                      label: "Ok",
                      onClick: () => {
                        this.logoutnormal();
                      },
                    },
                  ],
                });
              });
          },
        },
        {
          label: "No",
          onClick: () => {
            return false;
          },
        },
      ],
    });
  }

  callupdatetaskpage(tickerId, tskName, strtDate, edDate) {
    this.props.history.push({
      pathname: "/Updatetasks",
      state: {
        userid: this.props.location.state.userid,
        tickerId: tickerId,
        tskName: tskName,
        strtDate: new Date(strtDate),
        edDate: new Date(edDate),
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      },
    });
  }
  calladdquestionpage(
    tickerId,
    tskName,
    tskDescr,
    strtDate,
    edDate,
    publishStatus
  ) {
    this.props.history.push({
      pathname: "/Addquestion",
      state: {
        tickerId: tickerId,
        tskName: tskName,
        tskDescr: tskDescr,
        strtDate: strtDate,
        edDate: edDate,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        storeNo: this.props.location.state.storeNo,
        publishStatus: publishStatus,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      },
    });
  }
  UpdateTask(e) {
    e.preventDefault();
    if (document.getElementById("tickerdesc").value === "") {
      confirmAlert({
        message: "Please enter required fields",
        buttons: [
          {
            label: "Ok",
            onClick: () => {
              return false;
            },
          },
        ],
      });
    } else {
      var vstartdate = new Date(
        document.getElementById("updatestartDate").value
      );
      var venddate = new Date(document.getElementById("updateendDate").value);
      if (venddate < vstartdate) {
        confirmAlert({
          message: "End date cannot be earlier than start date",
          buttons: [
            {
              label: "Ok",
              onClick: () => {
                return false;
              },
            },
          ],
        });
      } else {
        confirmAlert({
          message: "Are you sure, you want to update the ticker?",
          buttons: [
            {
              label: "Yes",
              onClick: () => {
                var Request1 = {
                  guId: this.props.location.state.guId,
                  tickerId: document.getElementById("tickerid").value,
                  tickerText: document.getElementById("tickerdesc").value,
                  startDate: document.getElementById("updatestartDate").value,
                  endDate: document.getElementById("updateendDate").value,
                };
                var EncryptedRequest1 = Encrypt(Request1);

                fetch("/UpdateTicker", {
                  method: "POST",
                  headers: {
                    guId: this.props.location.state.guId,
                    Authorization:
                      "Basic " + basicAuth(this.props.location.state.userid),
                  },
                  body: EncryptedRequest1,
                })
                  .then((response) => response.text())
                  .then((response) => {
                    var DecryptedResponse = decryptData(response);

                    if (DecryptedResponse.errorCode === "00") {
                      confirmAlert({
                        message: "Ticker updated successfully. ",
                        buttons: [
                          {
                            label: "Ok",
                            onClick: () => {
                              this.modalclose();
                              window.location.reload(true);
                            },
                          },
                        ],
                      });

                      this.forceUpdate();
                    } else {
                      confirmAlert({
                        title: "Alert !",
                        message: DecryptedResponse.errorMsg,
                        buttons: [
                          {
                            label: "Ok",
                          },
                        ],
                      });
                    }
                  })
                  .catch((error) => {
                    confirmAlert({
                      title: "Alert !",
                      message: "Session expired",
                      buttons: [
                        {
                          label: "Ok",
                          onClick: () => {
                            this.logoutnormal();
                          },
                        },
                      ],
                    });
                  });
              },
            },
            {
              label: "No",
              onClick: () => {
                return false;
              },
            },
          ],
        });
      }
    }
  }

  getroles(e) {
    this.props.history.push({
      pathname: "/CreatetaskUI",
      state: {
        format_id: this.props.location.state.format_id,
        role_id: this.props.location.state.role_id,
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      },
    });
  }

  ArchivedTasks = () => {
    this.props.history.push({
      pathname: "/ArchivedTasks",
      state: {
        data: this.state.data,
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
        Isroffice: this.props.location.state.Isroffice,
      },
    });
  };

  StoreTasks = () => {
    this.props.history.push({
      pathname: "/StoreTasks",
      state: {
        data: this.state.data,
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      },
    });
  };
  TaskactiveBind = () => {
    this.props.history.push({
      pathname: "/Taskactive",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      },
    });
  };

  Changepasswordbind = () => {
    this.props.history.push({
      pathname: "/ChangepasswordUI",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      },
    });
  };

  SmartPointBind = () => {
    this.props.history.push({
      pathname: "/SmartPoint",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        data: this.props.location.state.data,
        tskId: this.props.location.state.tskId,
        tskName: this.props.location.state.tskName,
        storeNo: this.props.location.state.storeNo,
        Isroffice: this.props.location.state.Isroffice,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      },
    });
  };

  Defectbind = () => {
    this.props.history.push({
      pathname: "/DefectRaising",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        data: this.props.location.state.data,
        tskId: this.props.location.state.tskId,
        tskName: this.props.location.state.tskName,
        storeNo: this.props.location.state.storeNo,
        Isroffice: this.props.location.state.Isroffice,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      },
    });
  };

  Reportsbind = () => {
    this.props.history.push({
      pathname: "/Reports",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        data: this.state.data,
        tskId: this.state.tskId,
        tskName: this.state.tskName,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      },
    });
  };

  tiker = () => {
    this.props.history.push({
      pathname: "/Tiker",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      },
    });
  };

  CompletedTaskListbind = () => {
    this.props.history.push({
      pathname: "/CompletedTaskList",
      state: {
        data: this.state.data,
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      },
    });
  };
}
export default withRouter(Ticker);
