import axios from "axios";
class Service {
  constructor() {
    console.log("Service is constructed");
  }

  getRestClient(guId, basic) {
    if (!this.serviceInstance) {
      this.serviceInstance = axios.create({
        baseURL: "/UploadInstructions",
        timeout: 50000,
        headers: {
          "Content-Type": "text/plain",
          guId: guId,
          Authorization: basic
        }
      });
    }
    return this.serviceInstance;
  }
}

export default new Service();
