import React, { Component } from "react";
import "./css/style.css";
import "./css/style-responsive.css";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import Spinner from "react-spinner-material"; //Import Spinner
import { Encrypt, decryptData } from "./Encryption-Decrypytion";
import disableBrowserBackButton from "disable-browser-back-navigation";
import IdleTimer from "react-idle-timer"; // For Idle
import { withRouter } from "react-router-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { basicAuth } from "./BasicAuth";
import readXlsxFile from "read-excel-file";

class UserCreation extends Component {
  state = {
    value: "",
    userId: "",
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    formatId: "",
    formerMasterList: [],
    roleMasterList: [],
    rformatlist: [],
    rformatlisted: [],
    rformatId: [],
    rrole: [],
    lstformat: [],
    formatDesc: [],
    formarMasterList: [],
    roleMasterList1: [],
    storeList: [],
    values: "",
    mobile: "",
    hasError: false,
    userid: "",
    uploadFlag: false,
    FileName: "",
    userList: [],
    loading: false,
    loadingStatement: "",
    userCreationFailStatus: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      value: "",
      username: "",
      valueid: [],
      role_id: [],
      firstName: "",
      FileName: "",
      middleName: "",
      lastName: "",
      StoreList: [],
      email: "",
      formatId: null,
      userId: "",
      formerMasterList: [],
      roleMasterList: [],
      rformatlist: [],
      rformatlisted: [],
      rformatId: [],
      rrole: [],
      rlistid: [],
      lstformat: [],
      storeList: [],
      displayMenu: false,
      values: "",
      mobile: "",
      hasError: false,
      userid: "",
      hammenu: true,
      mobNo: "",
      formatDesc: [],
      formarMasterList: [],
      roleMasterList1: [],
      uploadFlag: false,
      FileName: "",
      userList: [],
      loading: false,
      loadingStatement: "",
      userCreationFailStatus: false,
      showAction: false,
      usersToDelete: [],
    };

    this.showDropdownMenu = this.showDropdownMenu.bind(this);
    this.hideDropdownMenu = this.hideDropdownMenu.bind(this);
    this.idleTimer = null;
    this.onAction = this._onAction.bind(this);
    this.onActive = this._onActive.bind(this);
    this.onIdle = this._onIdle.bind(this);
  }

  logoutnormal = () => {
    localStorage.clear();
    this.props.history.replace("/");
    disableBrowserBackButton();
  };

  logout = () => {
    this.setState({ loading: true });
    var Request1 = {
      userId: this.props.location.state.userid,
      guId: this.props.location.state.guId,
    };
    var EncryptedRequest1 = Encrypt(Request1);
    fetch("/LogOut ", {
      method: "POST",
      headers: {
        guId: this.props.location.state.guId,
        Authorization: "Basic " + basicAuth(this.props.location.state.userid),
      },
      body: EncryptedRequest1,
    })
      .then((response) => response.text())
      .then((response) => {
        this.setState({ loading: false });
        var DecryptedResponse = decryptData(response);
        if (DecryptedResponse.errorCode === "00") {
          this.props.history.replace("/");
          disableBrowserBackButton();
        } else {
          confirmAlert({
            message: DecryptedResponse.errorMsg,
            buttons: [
              {
                label: "Ok",
              },
            ],
          });
        }
      })
      .catch((error) => {
        confirmAlert({
          title: "Alert !",
          message: "Session expired",
          buttons: [
            {
              label: "Ok",
              onClick: () => {
                this.logoutnormal();
              },
            },
          ],
        });
      });
  };

  _onAction(e) {}

  _onActive(e) {}

  _onIdle(e) {
    localStorage.clear();
    window.location.href = "/";
    disableBrowserBackButton();
  }

  handleSetList([]) {
    this.setState({
      formarMasterList: [],
    });
  }

  showTaskDropdwnHandler = () => {
    this.setState({
      TaskDropdwn: !this.state.TaskDropdwn,
    });
  };

  showDropdownMenu(event) {
    event.preventDefault();
    this.setState({ displayMenu: true }, () => {
      document.addEventListener("click", this.hideDropdownMenu);
    });
  }
  hideDropdownMenu() {
    this.setState({ displayMenu: false }, () => {
      document.removeEventListener("click", this.hideDropdownMenu);
    });
  }

  componentDidMount() {
    if (window.innerWidth <= 768) {
      this.setState({ hammenu: false });
    }
  }

  Changepasswordbind = () => {
    this.props.history.push({
      pathname: "/ChangepasswordUI",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.state.roleId,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      },
    });
  };

  UserchangeId = (e) => {
    this.setState({ values: e.target.value });
  };

  showTaskdwnHandler = () => {
    this.setState({
      Taskdwn: !this.state.Taskdwn,
    });
  };
  MobilechangeId = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      this.setState({ mobile: e.target.value });
    }
  };

  formatSelection = (e) => {
    this.setState({ value: e.target.value });

    let flag = [];

    for (var i = 0; i < this.state.rformatlist.length; i++) {
      this.state.rformatlist[i] === e.target.value
        ? flag.push(this.state.rformatId[i])
        : this.setState({ valueid: "" });
    }
    this.setState({ valueid: flag });
  };

  componentWillMount() {
    if (
      this.props.location.state === undefined ||
      this.props.location.state === ""
    ) {
      window.location.href = "/";
    } else {
      this.setState({ format_id: this.props.location.state.format_id });
      this.GetFormatMaster();
    }
  }

  componentDidCatch() {
    this.setState({ hasError: true });
  }

  render = () => {
    var that = this;
    if (this.state.hasError) {
      return (
        <div>
          <h2>Error occurred!! please contact administrator</h2>
        </div>
      );
    } else {
      return (
        <Router>
          <div>
            <div
              className={this.state.loading ? "parentDisable" : ""}
              width="100%"
            >
              <div className="overlay-box">
                <Spinner
                  visible={this.state.loading}
                  spinnerColor={"rgba(0, 0, 0, 0.3)"}
                />
                <span
                  style={{
                    "background-color": "#f9f9f9",
                    color: "black",
                    position: "absolute",
                    "margin-top": "50px",
                    "font-weight": "bold",
                  }}
                >
                  {this.state.loadingStatement}
                </span>
              </div>
            </div>
            <div>
              <section id="container">
                <div>
                  <IdleTimer
                    ref={(ref) => {
                      this.idleTimer = ref;
                    }}
                    element={document}
                    onActive={this.onActive}
                    onIdle={this.onIdle}
                    onAction={this.onAction}
                    debounce={250}
                    timeout={1000 * 60 * 10}
                  />
                </div>
                <header class="header black-bg">
                  <a
                    className="mob-show"
                    onClick={() =>
                      this.setState({ hammenu: !this.state.hammenu })
                    }
                  >
                    <i
                      className="fa fa-tasks hammenu"
                      style={{ "margin-top": "25px", padding: "0 15px" }}
                    ></i>
                  </a>

                  <a
                    onClick={this.TaskactiveBind.bind(this)}
                    className="logo"
                    style={{ padding: "0 10px" }}
                  >
                    <img src={require("./img/retail-logo.png")} alt="logo" />
                  </a>
                  <div className="markcenter">
                    <p className="marquee">{this.state.tickerList}</p>
                  </div>
                  <ul className="nav pull-right pos-rel">
                    <li className="dropdown">
                      <a
                        className="dropdown-toggle"
                        data-toggle="dropdown"
                        onClick={this.showDropdownMenu}
                      >
                        {" "}
                        <img
                          src={require("./img/user.png")}
                          className="user-img"
                        />{" "}
                        <b className="name-show">
                          {this.props.location.state.usrnm}
                        </b>
                        ({this.props.location.state.storeNo})
                        <b className="caret" />
                      </a>
                    </li>

                    {this.state.displayMenu ? (
                      <ul className="dropdown-menuuser-dd">
                        <li>Role : {this.props.location.state.roleName}</li>
                        <li className="divider"></li>
                        <li>
                          <a onClick={this.Changepasswordbind}>
                            Change Password
                          </a>
                        </li>
                        <li className="divider"></li>
                        <li>
                          <a onClick={this.logout}>Log Out</a>
                        </li>
                      </ul>
                    ) : null}
                  </ul>
                </header>
                <aside>
                  <div id="sidebar" className="nav-collapse">
                    {this.state.hammenu ? (
                      <ul className="sidebar-menu" id="nav-accordion">
                        {this.props.location.state.menuList["401"] ||
                        this.props.location.state.menuList["402"] ||
                        this.props.location.state.menuList["403"] ||
                        this.props.location.state.menuList["404"] ||
                        this.props.location.state.menuList["405"] ||
                        this.props.location.state.menuList["408"] ||
                        this.props.location.state.menuList["411"] ? (
                          <li
                            className="sub-menu mt"
                            onClick={this.showTaskDropdwnHandler.bind(this)}
                          >
                            {" "}
                            <a href="javascript:;">
                              {" "}
                              <i className="fa fa-tasks"></i> <span>Task</span>{" "}
                            </a>
                            {this.state.TaskDropdwn ? (
                              <div>
                                <ul class="sub">
                                  {this.props.location.state.menuList["401"] ? (
                                    <li>
                                      <a
                                        onClick={this.TaskactiveBind.bind(this)}
                                      >
                                        {
                                          this.props.location.state.menuList[
                                            "401"
                                          ]
                                        }
                                      </a>
                                    </li>
                                  ) : null}
                                  {this.props.location.state.menuList["402"] ? (
                                    <li>
                                      <a onClick={this.getroles.bind(this)}>
                                        {
                                          this.props.location.state.menuList[
                                            "402"
                                          ]
                                        }
                                      </a>
                                    </li>
                                  ) : null}
                                  {this.props.location.state.menuList["403"] ? (
                                    <li>
                                      <a
                                        onClick={this.CompletedTaskListbind.bind(
                                          this
                                        )}
                                      >
                                        {
                                          this.props.location.state.menuList[
                                            "403"
                                          ]
                                        }
                                      </a>
                                    </li>
                                  ) : null}
                                  {this.props.location.state.menuList["404"] ? (
                                    <li>
                                      <a onClick={this.templates.bind(this)}>
                                        {
                                          this.props.location.state.menuList[
                                            "404"
                                          ]
                                        }
                                      </a>
                                    </li>
                                  ) : null}
                                  {this.props.location.state.menuList["405"] ? (
                                    <li>
                                      <a
                                        onClick={this.ArchivedTasks.bind(this)}
                                      >
                                        {
                                          this.props.location.state.menuList[
                                            "405"
                                          ]
                                        }
                                      </a>
                                    </li>
                                  ) : null}
                                  {this.props.location.state.menuList["408"] ? (
                                    <li>
                                      <a onClick={this.StoreTasks.bind(this)}>
                                        {
                                          this.props.location.state.menuList[
                                            "408"
                                          ]
                                        }
                                      </a>
                                    </li>
                                  ) : null}
                                  {this.props.location.state.menuList["411"] ? (
                                    <li>
                                      <a
                                        onClick={this.toGenDashboard.bind(this)}
                                      >
                                        {
                                          this.props.location.state.menuList[
                                            "411"
                                          ]
                                        }
                                      </a>
                                    </li>
                                  ) : null}
                                </ul>
                              </div>
                            ) : null}
                          </li>
                        ) : null}
                        {this.props.location.state.menuList["406"] ? (
                          <div>
                            <li>
                              {" "}
                              <a onClick={this.Reportsbind.bind(this)}>
                                {" "}
                                <i className="fa fa-tasks"></i>{" "}
                                <span>
                                  {this.props.location.state.menuList["406"]}
                                </span>{" "}
                              </a>{" "}
                            </li>
                          </div>
                        ) : null}

                        {this.props.location.state.menuList["414"] &&
                        this.props.location.state.format_id === "102" ? (
                          <div>
                            <li>
                              {" "}
                              <a onClick={this.Defectbind.bind(this)}>
                                {" "}
                                <i className="fa fa-tasks"></i>{" "}
                                <span>
                                  {this.props.location.state.menuList["414"]}
                                </span>{" "}
                              </a>{" "}
                            </li>
                          </div>
                        ) : null}

                        {this.props.location.state.menuList["415"] ? (
                          <div>
                            <li>
                              {" "}
                              <a onClick={this.SmartPointBind.bind(this)}>
                                {" "}
                                <i className="fa fa-tasks"></i>{" "}
                                <span>
                                  {this.props.location.state.menuList["415"]}
                                </span>{" "}
                              </a>{" "}
                            </li>
                          </div>
                        ) : null}

                        {this.props.location.state.menuList["409"] ||
                        this.props.location.state.menuList["410"] ? (
                          <div>
                            <li
                              className="sub-menu"
                              onClick={this.showIssueDropdwnHandler.bind(this)}
                            >
                              {" "}
                              <a href="javascript:;">
                                {" "}
                                <i className="fa fa-tasks"></i>{" "}
                                <span>Issue</span>{" "}
                              </a>
                              {this.state.IssueDropdwn ? (
                                <div>
                                  <ul class="sub">
                                    {this.props.location.state.menuList[
                                      "410"
                                    ] ? (
                                      <li>
                                        <a
                                          onClick={this.IssueRaising.bind(this)}
                                        >
                                          {
                                            this.props.location.state.menuList[
                                              "410"
                                            ]
                                          }
                                        </a>
                                      </li>
                                    ) : null}
                                    {this.props.location.state.menuList[
                                      "409"
                                    ] ? (
                                      <li>
                                        <a
                                          onClick={this.IssueDashboard.bind(
                                            this
                                          )}
                                        >
                                          {
                                            this.props.location.state.menuList[
                                              "409"
                                            ]
                                          }
                                        </a>
                                      </li>
                                    ) : null}
                                  </ul>
                                </div>
                              ) : null}
                            </li>
                          </div>
                        ) : null}

                        {this.props.location.state.menuList["407"] ? (
                          <div>
                            <li
                              className="sub-menu"
                              onClick={this.showTaskdwnHandler.bind(this)}
                            >
                              {" "}
                              <a href="javascript:;">
                                {" "}
                                <i className="fa fa-tasks"></i>{" "}
                                <span>
                                  {this.props.location.state.menuList["407"]}
                                </span>{" "}
                              </a>
                              {this.state.Taskdwn ? (
                                <div>
                                  <ul class="sub">
                                    <li>
                                      {" "}
                                      <a onClick={this.tiker.bind(this)}>
                                        <span>Tiker Creation</span>{" "}
                                      </a>{" "}
                                    </li>
                                    <li>
                                      {" "}
                                      <a onClick={this.userlist.bind(this)}>
                                        <span>User List</span>{" "}
                                      </a>{" "}
                                    </li>
                                    {this.props.location.state.menuList[
                                      "412"
                                    ] ? (
                                      <li>
                                        {" "}
                                        <a
                                          onClick={this.Storecreation.bind(
                                            this
                                          )}
                                        >
                                          <span>
                                            {
                                              this.props.location.state
                                                .menuList["412"]
                                            }
                                          </span>{" "}
                                        </a>{" "}
                                      </li>
                                    ) : null}
                                    {this.props.location.state.menuList[
                                      "413"
                                    ] ? (
                                      <li>
                                        {" "}
                                        <a
                                          onClick={this.ToStoreList.bind(this)}
                                        >
                                          <span>
                                            {
                                              this.props.location.state
                                                .menuList["413"]
                                            }
                                          </span>{" "}
                                        </a>{" "}
                                      </li>
                                    ) : null}
                                  </ul>
                                </div>
                              ) : null}
                            </li>
                          </div>
                        ) : null}
                      </ul>
                    ) : null}
                  </div>
                </aside>
                <section id="main-content">
                  <section class="wrapper">
                    <div class="mt">
                      <h3>
                        <i class="fa fa-angle-right"></i> {this.state.header}
                      </h3>
                      <>
                        <hr />
                        <label>Upload File for Bulk Addition of Users</label>

                        <div class="row">
                          <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                            <div class="upload-holder">
                              <input
                                id="store-list"
                                type="text"
                                class="form-control input-upload"
                                value={this.state.FileName}
                              />
                              <span
                                class="btn-upload"
                                style={{ padding: "7px 25px" }}
                              >
                                Upload
                              </span>
                              <input
                                id="upload-storedata"
                                type="file"
                                name="Store-list-data"
                                class="input-hidden"
                                onChange={this.fileHandler.bind(this)}
                                accept=".xlsx,.xls"
                              />
                            </div>

                            <span class="help-block">
                              Template File :{" "}
                              <a href={require("./doc/BulkAddUsers.xlsx")}>
                                BulkAddUsers.xls
                              </a>
                            </span>

                            <span class="help-block">
                              Role ID Ref File :{" "}
                              <a href={require("./doc/RoleIdRefFile.xlsx")}>
                                RoleIdRefFile.xls
                              </a>
                            </span>
                          </div>

                          {this.state.uploadFlag ? (
                            <div
                              class="col-lg-2 col-md-2 col-sm-2 col-xs-12"
                              style={{ marginTop: "-25px" }}
                            >
                              <label>Action </label>
                              <select
                                class="form-control"
                                id="SelActionType"
                                onChange={() => {
                                  this.setState({ showAction: true });
                                }}
                              >
                                <option id="select">Select</option>
                                <option id="add">Add</option>
                                <option id="delete">Delete</option>
                              </select>
                              {/* <button
                                class="btn btn-primary min-wid-90"
                                onClick={this.CreateUsersFromBulkAdd.bind(this)}
                              >
                                Add
                              </button> */}
                            </div>
                          ) : null}
                          {this.state.showAction ? (
                            <div
                              class="col-lg-2 col-md-2 col-sm-2 col-xs-12"
                              style={{ marginTop: "-25px" }}
                            >
                              <button
                                class="btn btn-primary min-wid-90 mt-25"
                                onClick={this.CreateUsersFromBulkAdd.bind(this)}
                              >
                                Submit
                              </button>
                            </div>
                          ) : null}
                        </div>
                      </>
                      <hr />
                      <div class="row mt">
                        {this.state.header == "User Creation" ? (
                          <div class="col-lg-4 col-md-4 col-sm-5 col-xs-12">
                            <label>Select User Type</label>
                            <span class="required-mark">*</span>
                            <br />

                            <select
                              class="form-control"
                              id="SelQType1"
                              onChange={this.formatSelection.bind(this)}
                            >
                              <option>Select</option>
                              {this.state.formerMasterList.map(function (
                                item,
                                key
                              ) {
                                return <option>{item.formatDesc}</option>;
                              })}
                            </select>
                          </div>
                        ) : null}

                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                          <label>User Id</label>
                          <span class="required-mark">*</span>
                          {this.state.header == "User Creation" ? (
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Enter UserId"
                              input
                              value={this.state.values}
                              id="UserId"
                              onChange={this.UserchangeId}
                              maxlength="8"
                              autoComplete="off"
                            ></input>
                          ) : (
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Enter UserId"
                              input
                              value={this.state.values}
                              id="UserId"
                              onChange={this.UserchangeId}
                              maxlength="8"
                              autoComplete="off"
                              disabled
                            ></input>
                          )}{" "}
                          <br />
                        </div>

                        <div class="clearfix"></div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                          <label>First Name</label>
                          <span class="required-mark">*</span>
                          <input
                            type="FirstName"
                            class="form-control"
                            placeholder="Enter First Name"
                            id="FirstName"
                            maxlength="30"
                            autoComplete="off"
                            value={this.state.firstName}
                            onChange={(f) =>
                              this.setState({ firstName: f.target.value })
                            }
                          ></input>
                          <br />
                        </div>

                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                          <label>Middle Name</label>
                          <input
                            type="MiddleName"
                            class="form-control"
                            placeholder="Enter Middle Name"
                            id="MiddleName"
                            maxlength="30"
                            autoComplete="off"
                            value={this.state.middleName}
                            onChange={(f) =>
                              this.setState({ middleName: f.target.value })
                            }
                          ></input>
                          <br />
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                          <label>Last Name</label>
                          <span class="required-mark">*</span>
                          <br />
                          <input
                            type="LastName"
                            class="form-control"
                            placeholder="Enter Last Name"
                            id="LastName"
                            maxlength="30"
                            autoComplete="off"
                            value={this.state.lastName}
                            onChange={(f) =>
                              this.setState({ lastName: f.target.value })
                            }
                          ></input>
                          <br />
                        </div>

                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                          <label>Mobile No</label>
                          <span class="required-mark">*</span>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Enter Mobile Number"
                            input
                            value={this.state.mobile}
                            id="Mobile"
                            onChange={this.MobilechangeId}
                            maxlength="10"
                            autoComplete="off"
                          ></input>
                          <br />
                        </div>

                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                          <label>Email ID</label>
                          <span class="required-mark">*</span>
                          <input
                            type="email"
                            class="form-control"
                            value={this.state.email}
                            placeholder="Enter EmailId"
                            id="Admin_EmailId"
                            maxlength="40"
                            autoComplete="off"
                            onChange={(f) =>
                              this.setState({ email: f.target.value })
                            }
                          ></input>
                          <br />
                        </div>

                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                          <div class="text-center mt ">
                            {this.state.header == "Update User" ? (
                              <button
                                class="btn btn-primary min-wid-90 "
                                onClick={this.UpdateUser.bind(this)}
                              >
                                Update
                              </button>
                            ) : (
                              <button
                                class="btn btn-primary min-wid-90 "
                                onClick={this.CreateHOUser.bind(this)}
                              >
                                Submit
                              </button>
                            )}
                            <button
                              class="btn btn-primary min-wid-90 ml-20 "
                              onClick={this.userlist.bind(this)}
                            >
                              Back
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </section>
              </section>
            </div>
          </div>
        </Router>
      );
    }
  };

  fileHandler = (event) => {
    this.setState({ loading: true, userList: [], uploadFlag: false });
    const input = document.getElementById("upload-storedata");
    const emailTestReg =
      /^(?:[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+\.)*[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+@(?:(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!\.)){0,61}[a-zA-Z0-9]?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!$)){0,61}[a-zA-Z0-9]?)|(?:\[(?:(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\.){3}(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\]))$/;
    const specialCharTestReg = /^[a-zA-Z]+$/;
    const mobTestReg = /^[0-9\b]+$/;

    readXlsxFile(input.files[0]).then((rows, files) => {
      var userData = [];
      for (let i = 1; i < rows.length; i++) {
        userData.push(rows[i]);
        for (let j = 0; j < userData.length; j++) {
          if (isNaN(userData[j][0])) {
            this.setState({ loading: false });
            confirmAlert({
              message: `Please add appropriate Role ID at line no. ${i + 1}`,
              buttons: [
                {
                  label: "Ok",
                  onClick: () => {
                    window.location.reload(true);
                  },
                },
              ],
            });
            return;
          }

          if (!userData[j][1]) {
            this.setState({ loading: false });
            confirmAlert({
              message: `Please add appropriate EC Code at line no. ${i + 1}`,
              buttons: [
                {
                  label: "Ok",
                  onClick: () => {
                    window.location.reload(true);
                  },
                },
              ],
            });
            return;
          }

          if (!userData[j][2]) {
            this.setState({ loading: false });
            confirmAlert({
              message: `Please add First Name at line no. ${i + 1}`,
              buttons: [
                {
                  label: "Ok",
                  onClick: () => {
                    window.location.reload(true);
                  },
                },
              ],
            });
            return;
          }

          if (userData[j][3] && !specialCharTestReg.test(userData[j][3])) {
            this.setState({ loading: false });
            confirmAlert({
              message: `No special characters or numbers allowed in names! Correct the same for Middle name at line no. ${
                i + 1
              }`,
              buttons: [
                {
                  label: "Ok",
                  onClick: () => {
                    window.location.reload(true);
                  },
                },
              ],
            });
            return;
          }

          if (!userData[j][4]) {
            this.setState({ loading: false });
            confirmAlert({
              message: `Please add Last Name at line no. ${i + 1}`,
              buttons: [
                {
                  label: "Ok",
                  onClick: () => {
                    window.location.reload(true);
                  },
                },
              ],
            });
            return;
          }

          if (
            !specialCharTestReg.test(userData[j][2]) ||
            !specialCharTestReg.test(userData[j][4])
          ) {
            this.setState({ loading: false });
            confirmAlert({
              message: `No special characters or numbers allowed in names! Correct the same at line no. ${
                i + 1
              }`,
              buttons: [
                {
                  label: "Ok",
                  onClick: () => {
                    window.location.reload(true);
                  },
                },
              ],
            });
            return;
          }

          if (
            isNaN(userData[j][5]) ||
            !mobTestReg.test(userData[j][5]) ||
            !userData[j][5] ||
            userData[j][5].toString().length < 10 ||
            userData[j][5].toString().length > 10
          ) {
            this.setState({ loading: false });
            confirmAlert({
              message: `Please add valid Mobile No at line no. ${i + 1}`,
              buttons: [
                {
                  label: "Ok",
                  onClick: () => {
                    window.location.reload(true);
                  },
                },
              ],
            });
            return;
          }

          if (
            userData[j][5].toString().slice(0, 1) != "6" &&
            userData[j][5].toString().slice(0, 1) != "7" &&
            userData[j][5].toString().slice(0, 1) != "8" &&
            userData[j][5].toString().slice(0, 1) != "9"
          ) {
            this.setState({ loading: false });
            confirmAlert({
              message: `Please add valid Mobile No starting with 6, 7, 8 or 9 digits, at line no. ${
                i + 1
              }`,
              buttons: [
                {
                  label: "Ok",
                  onClick: () => {
                    window.location.reload(true);
                  },
                },
              ],
            });
            return;
          }

          if (!emailTestReg.test(userData[j][6]) || !userData[j][6]) {
            this.setState({ loading: false });
            confirmAlert({
              message: `Please add valid Email ID at line no. ${i + 1}`,
              buttons: [
                {
                  label: "Ok",
                  onClick: () => {
                    window.location.reload(true);
                  },
                },
              ],
            });
            return;
          }

          var userObj = {
            userFormatId: userData[j][0].toString(),
            userId: userData[j][1].toString(),
            firstName: userData[j][2].toString(),
            middleName: userData[j][3] ? userData[j][3].toString() : "",
            lastName: userData[j][4].toString(),
            mobileNo: userData[j][5].toString(),
            email: userData[j][6].toString(),
          };
        }
        {
          this.state.userList.push(userObj);
        }
      }
      this.setState({
        loading: false,
        FileName: "File Uploaded Successfully",
        uploadFlag: true,
      });
    });
  };

  CreateUsersFromBulkAdd = async () => {
    if (document.getElementById("SelActionType").value == "Select") {
      confirmAlert({
        title: "Alert !",
        message: "Please select action",
        buttons: [
          {
            label: "Ok",
            onClick: () => {
              return false;
            },
          },
        ],
      });
    } else if (document.getElementById("SelActionType").value == "Add") {
      this.setState({ loading: true });

      for (var i = 0; i < this.state.userList.length; i++) {
        this.setState({ loadingStatement: `Creating User no ${i + 1}` });

        var Request1 = {
          formatId: this.props.location.state.format_id,
          userId: this.state.userList[i].userId,
          email: this.state.userList[i].email,
          firstName: this.state.userList[i].firstName,
          middleName: this.state.userList[i].middleName,
          lastName: this.state.userList[i].lastName,
          mobileNo: this.state.userList[i].mobileNo,
          userFormatId: this.state.userList[i].userFormatId,
          guId: this.props.location.state.guId,
        };

        var EncryptedRequest1 = Encrypt(Request1);

        await fetch("/CreateUser", {
          method: "POST",
          headers: {
            guId: this.props.location.state.guId,
            Authorization:
              "Basic " + basicAuth(this.props.location.state.userid),
          },
          body: EncryptedRequest1,
        })
          .then((response) => response.text())
          .then((response) => {
            var DecryptedResponse = decryptData(response);
            if (DecryptedResponse.errorCode === "00") {
              this.setState({ userId: DecryptedResponse.userId });
            } else {
              this.setState({ userCreationFailStatus: true, loading: false });
              confirmAlert({
                title: "Alert !",
                message: DecryptedResponse.errorMsg,
                buttons: [
                  {
                    label: "Ok",
                  },
                ],
              });
              return;
            }
          })
          .catch((error) => {
            this.setState({
              userCreationFailStatus: true,
              loading: false,
              loadingStatement: "",
            });
            confirmAlert({
              title: "Alert !",
              message: "Session expired",
              buttons: [
                {
                  label: "Ok",
                  onClick: () => {
                    this.logoutnormal();
                  },
                },
              ],
            });
          });
      }
      this.setState({ loading: false, loadingStatement: "" });
      if (!this.state.userCreationFailStatus) {
        confirmAlert({
          title: "Success",
          message: "Users created successfully",
          buttons: [
            {
              label: "Ok",
              onClick: () => window.location.reload(true),
            },
          ],
        });
      }
    } else {
      var userToDel = [];
      for (let l = 0; l < this.state.userList.length; l++) {
        userToDel.push(this.state.userList[l].userId);
      }
      var Request = {
        bulkUserId: userToDel,
        guId: this.props.location.state.guId,
      };
      var EncryptedRequest = Encrypt(Request);
      fetch("/BulkDeleteUser", {
        method: "POST",
        headers: {
          guId: this.props.location.state.guId,
          Authorization: "Basic " + basicAuth(this.props.location.state.userid),
        },
        body: EncryptedRequest,
      })
        .then((response) => response.text())
        .then((response) => {
          var DecryptedResponse = decryptData(response);
          if (DecryptedResponse.errorCode === "00") {
            confirmAlert({
              message: "User  deleted successfully.",
              buttons: [
                {
                  label: "Ok",
                  onClick: () => {
                    window.location.reload(true);
                  },
                },
              ],
            });
            this.forceUpdate();
          } else {
            confirmAlert({
              title: "Alert !",
              message: DecryptedResponse.errorMsg,
              buttons: [
                {
                  label: "Ok",
                },
              ],
            });
          }
        })
        .catch((error) => {
          confirmAlert({
            title: "Alert !",
            message: "Session expired",
            buttons: [
              {
                label: "Ok",
                onClick: () => {
                  this.logoutnormal();
                },
              },
            ],
          });
        });
    }
  };

  showIssueDropdwnHandler = () => {
    this.setState({
      IssueDropdwn: !this.state.IssueDropdwn,
    });
  };

  toGenDashboard = () => {
    this.props.history.push({
      pathname: "/DashboardGeneral",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        data: this.props.location.state.data,
        tskId: this.props.location.state.tskId,
        tskName: this.props.location.state.tskName,
        storeNo: this.props.location.state.storeNo,
        Isroffice: this.props.location.state.Isroffice,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      },
    });
  };

  IssueRaising = () => {
    this.props.history.push({
      pathname: "/IssueRaising",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        data: this.props.location.state.data,
        tskId: this.props.location.state.tskId,
        tskName: this.props.location.state.tskName,
        storeNo: this.props.location.state.storeNo,
        Isroffice: this.props.location.state.Isroffice,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      },
    });
  };

  IssueDashboard = () => {
    this.props.history.push({
      pathname: "/IssueDashboard",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        data: this.props.location.state.data,
        tskId: this.props.location.state.tskId,
        tskName: this.props.location.state.tskName,
        storeNo: this.props.location.state.storeNo,
        Isroffice: this.props.location.state.Isroffice,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      },
    });
  };

  Taskactive() {
    this.props.history.push({
      pathname: "/Taskactive",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.state.roleId,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      },
    });
  }

  userlist = () => {
    this.props.history.push({
      pathname: "/UserList",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      },
    });
  };

  UpdateUser = (e) => {
    var pattern =
      /^(?:[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+\.)*[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+@(?:(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!\.)){0,61}[a-zA-Z0-9]?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!$)){0,61}[a-zA-Z0-9]?)|(?:\[(?:(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\.){3}(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\]))$/;
    var re = /^[a-zA-Z]+$/;

    // var selectType = document.getElementById("SelQType1").value;
    var UserId = document.getElementById("UserId").value;
    var Admin_EmailId = document.getElementById("Admin_EmailId").value;
    var FirstName = document.getElementById("FirstName").value;
    var MiddleName = document.getElementById("MiddleName").value;
    var LastName = document.getElementById("LastName").value;
    var MobileNo = document.getElementById("Mobile").value;

    if (!pattern.test(Admin_EmailId)) {
      confirmAlert({
        message: "Please enter valid email.",
        buttons: [
          {
            label: "Yes",
          },
        ],
      });

      return false;
    } else if (
      !document.getElementById("FirstName").value ||
      !document.getElementById("LastName").value ||
      !document.getElementById("Admin_EmailId").value ||
      !document.getElementById("UserId").value ||
      !document.getElementById("Mobile").value
    ) {
      confirmAlert({
        message: "Please enter required fields",
        buttons: [
          {
            label: "Ok",
            onClick: () => {
              return false;
            },
          },
        ],
      });
    }

    if (MobileNo.length < 10) {
      confirmAlert({
        message: "Please enter valid mobile number",
        buttons: [
          {
            label: "Ok",
            onClick: () => {
              return false;
            },
          },
        ],
      });
    } else if (!re.test(FirstName) || !re.test(LastName)) {
      confirmAlert({
        message: "Special characters & numbers are not allowed.",
        buttons: [
          {
            label: "Ok",
            onClick: () => {
              return false;
            },
          },
        ],
      });
    } else {
      confirmAlert({
        //  title: 'Alert !',
        message: "Are you sure, you want to submit?.",
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              var Request1 = {
                formatId: this.props.location.state.format_id,
                userId: document.getElementById("UserId").value,
                email: document.getElementById("Admin_EmailId").value,
                firstName: document.getElementById("FirstName").value,
                middleName: document.getElementById("MiddleName").value,
                lastName: document.getElementById("LastName").value,
                mobileNo: document.getElementById("Mobile").value,
                guId: this.props.location.state.guId,
              };
              var EncryptedRequest1 = Encrypt(Request1);

              fetch("/UpdateUser", {
                method: "POST",
                headers: {
                  guId: this.props.location.state.guId,
                  Authorization:
                    "Basic " + basicAuth(this.props.location.state.userid),
                },
                body: EncryptedRequest1,
              })
                .then((response) => response.text())
                .then((response) => {
                  var DecryptedResponse = decryptData(response);
                  if (DecryptedResponse.errorCode === "00") {
                    confirmAlert({
                      message: "User details updated successfully",
                      buttons: [
                        {
                          label: "Ok",
                          onClick: () => {
                            this.userlist();
                          },
                        },
                      ],
                    });
                    this.setState({ userId: DecryptedResponse.userId });
                  } else {
                    confirmAlert({
                      title: "Alert !",
                      message: DecryptedResponse.errorMsg,
                      buttons: [
                        {
                          label: "Ok",
                        },
                      ],
                    });
                    this.setState({ loading: false });
                  }
                })
                .catch((error) => {
                  confirmAlert({
                    title: "Alert !",
                    message: "Session expired",
                    buttons: [
                      {
                        label: "Ok",
                        onClick: () => {
                          this.logoutnormal();
                        },
                      },
                    ],
                  });
                });
            },
          },
          {
            label: "No",
            onClick: () => {
              return false;
            },
          },
        ],
      });
    }
  };

  CreateHOUser = (e) => {
    e.preventDefault();

    var pattern =
      /^(?:[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+\.)*[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+@(?:(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!\.)){0,61}[a-zA-Z0-9]?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!$)){0,61}[a-zA-Z0-9]?)|(?:\[(?:(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\.){3}(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\]))$/;
    var re = /^[a-zA-Z]+$/;

    var selectType = document.getElementById("SelQType1").value;
    var UserId = document.getElementById("UserId").value;
    var Admin_EmailId = document.getElementById("Admin_EmailId").value;
    var FirstName = document.getElementById("FirstName").value;
    var MiddleName = document.getElementById("MiddleName").value;
    var LastName = document.getElementById("LastName").value;
    var MobileNo = document.getElementById("Mobile").value;

    if (!pattern.test(Admin_EmailId)) {
      confirmAlert({
        message: "Please enter valid email.",
        buttons: [
          {
            label: "Yes",
          },
        ],
      });

      return false;
    } else if (
      !document.getElementById("FirstName").value ||
      !document.getElementById("LastName").value ||
      !document.getElementById("Admin_EmailId").value ||
      !document.getElementById("UserId").value ||
      !document.getElementById("Mobile").value
    ) {
      confirmAlert({
        message: "Please enter required fields",
        buttons: [
          {
            label: "Ok",
            onClick: () => {
              return false;
            },
          },
        ],
      });
    } else if (MobileNo.length < 10) {
      confirmAlert({
        message: "Please enter valid mobile number",
        buttons: [
          {
            label: "Ok",
            onClick: () => {
              return false;
            },
          },
        ],
      });
    } else if (!re.test(FirstName) || !re.test(LastName)) {
      confirmAlert({
        message: "Special characters & numbers are not allowed.",
        buttons: [
          {
            label: "Ok",
            onClick: () => {
              return false;
            },
          },
        ],
      });
    } else if (this.state.valueid.length === 0) {
      confirmAlert({
        title: "Alert !",
        message: "Please select format type.",
        buttons: [
          {
            label: "OK",
            onClick: () => {
              return false;
            },
          },
        ],
      });
    } else {
      confirmAlert({
        //  title: 'Alert !',
        message: "Are you sure, you want to submit?.",
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              var Request1 = {
                formatId: this.props.location.state.format_id,
                userId: document.getElementById("UserId").value,
                email: document.getElementById("Admin_EmailId").value,
                firstName: document.getElementById("FirstName").value,
                middleName: document.getElementById("MiddleName").value,
                lastName: document.getElementById("LastName").value,
                mobileNo: document.getElementById("Mobile").value,
                userFormatId: this.state.valueid[0],
                guId: this.props.location.state.guId,
              };
              var EncryptedRequest1 = Encrypt(Request1);

              fetch("/CreateUser", {
                method: "POST",
                headers: {
                  guId: this.props.location.state.guId,
                  Authorization:
                    "Basic " + basicAuth(this.props.location.state.userid),
                },
                body: EncryptedRequest1,
              })
                .then((response) => response.text())
                .then((response) => {
                  var DecryptedResponse = decryptData(response);
                  if (DecryptedResponse.errorCode === "00") {
                    confirmAlert({
                      message: "User created successfully",
                      buttons: [
                        {
                          label: "Ok",
                          onClick: () => {
                            this.userlist();
                          },
                        },
                      ],
                    });

                    this.setState({ userId: DecryptedResponse.userId });
                  } else {
                    confirmAlert({
                      title: "Alert !",
                      message: DecryptedResponse.errorMsg,
                      buttons: [
                        {
                          label: "Ok",
                        },
                      ],
                    });
                  }
                })
                .catch((error) => {
                  confirmAlert({
                    title: "Alert !",
                    message: "Session expired",
                    buttons: [
                      {
                        label: "Ok",
                        onClick: () => {
                          this.logoutnormal();
                        },
                      },
                    ],
                  });
                });
            },
          },
          {
            label: "No",
            onClick: () => {
              return false;
            },
          },
        ],
      });
    }
    //   }
  };
  GetFormatMaster = (e) => {
    this.setState({ userid: this.props.location.state.userid });
    this.setState({ usrnm: this.props.location.state.usrnm });
    this.setState({ roleId: this.props.location.state.role_id });
    this.setState({ header: this.props.location.state.header });

    var Request = {
      formatId: this.props.location.state.format_id,
      guId: this.props.location.state.guId,
    };
    var EncryptedRequest = Encrypt(Request);

    fetch("/GetUserFormat", {
      method: "POST",
      headers: {
        guId: this.props.location.state.guId,
        Authorization: "Basic " + basicAuth(this.props.location.state.userid),
      },
      body: EncryptedRequest,
    })
      .then((response) => response.text())
      .then((response) => {
        var DecryptedResponse = decryptData(response);
        if (DecryptedResponse.userFormatList != undefined) {
          this.setState({ formerMasterList: DecryptedResponse.userFormatList });
          this.setState({ roleMasterList: DecryptedResponse.roleMasterList });
          let list = [];
          let list1 = [];

          for (var i = 0; i < DecryptedResponse.userFormatList.length; i++) {
            list.push(DecryptedResponse.userFormatList[i].formatDesc);
            list1.push(DecryptedResponse.userFormatList[i].userFormatId);
          }
          this.setState({ rformatlist: list });
          this.setState({ rformatId: list1 });
        } else {
          confirmAlert({
            title: "Alert !",
            message: DecryptedResponse.errorMsg,
            buttons: [
              {
                label: "Ok",
                onClick: () => {
                  return false;
                },
              },
            ],
          });
        }
      })
      .catch((error) => {
        confirmAlert({
          title: "Alert !",
          message: "Session expired",
          buttons: [
            {
              label: "Ok",
              onClick: () => {
                this.logoutnormal();
              },
            },
          ],
        });
      });

    if (this.props.location.state.header == "Update User") {
      this.setState({ values: this.props.location.state.values });
      var Request1 = {
        userId: this.props.location.state.values,
        guId: this.props.location.state.guId,
      };
      var EncryptedRequest1 = Encrypt(Request1);

      fetch("/GetUser", {
        method: "POST",
        headers: {
          guId: this.props.location.state.guId,
          Authorization: "Basic " + basicAuth(this.props.location.state.userid),
        },
        body: EncryptedRequest1,
      })
        .then((response) => response.text())
        .then((response) => {
          var DecryptedResponse1 = decryptData(response);

          if (DecryptedResponse1.errorCode === "00") {
            this.setState({ userid: DecryptedResponse1.userId });
            this.setState({ email: DecryptedResponse1.email });
            this.setState({ firstName: DecryptedResponse1.firstName });
            this.setState({ middleName: DecryptedResponse1.middleName });
            this.setState({ lastName: DecryptedResponse1.lastName });
            this.setState({ mobile: DecryptedResponse1.mobileNo });
            this.setState({
              loading: false,
            });
          } else {
            this.setState({ loading: false });
            confirmAlert({
              message: DecryptedResponse1.errorMsg,
              buttons: [
                {
                  label: "Ok",
                },
              ],
            });
          }
        })
        .catch((error) => {
          confirmAlert({
            title: "Alert !",
            message: "Session expired",
            buttons: [
              {
                label: "Ok",
                onClick: () => {
                  this.logoutnormal();
                },
              },
            ],
          });
        });
    }
  };

  ToStoreList = () => {
    this.props.history.push({
      pathname: "/StoreList",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        formerMasterList: this.state.formerMasterList,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      },
    });
  };

  Storecreation = () => {
    this.props.history.push({
      pathname: "/Storecreation",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        formerMasterList: this.state.formerMasterList,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      },
    });
  };

  email = (e) => {
    e.preventDefault();
  };

  templates = () => {
    this.props.history.push({
      pathname: "/Templates",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      },
    });
  };

  StoreTasks = () => {
    this.props.history.push({
      pathname: "/StoreTasks",
      state: {
        data: this.state.data,
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      },
    });
  };

  ArchivedTasks = () => {
    this.props.history.push({
      pathname: "/ArchivedTasks",
      state: {
        data: this.state.data,
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
        Isroffice: this.props.location.state.Isroffice,
      },
    });
  };

  addstore = () => {
    var request = {
      storeList: this.state.storeList,
      guId: this.props.location.state.guId,
    };
    var EncryptedRequest = Encrypt(request);

    fetch("/CreateStore", {
      method: "POST",
      headers: {
        guId: this.props.location.state.guId,
        Authorization: "Basic " + basicAuth(this.props.location.state.userid),
      },
      body: EncryptedRequest,
    })
      .then((response) => response.text())
      .then((response) => {
        var DecryptedResponse = decryptData(response);

        if (DecryptedResponse.errorCode === "00") {
          confirmAlert({
            title: "Success",
            message: "Stores added successfully",
            buttons: [
              {
                label: "Ok",
              },
            ],
          });
        } else {
          confirmAlert({
            message: DecryptedResponse.errorMsg,
            buttons: [
              {
                label: "Ok",
                onClick: () => {
                  return false;
                },
              },
            ],
          });
        }
      })
      .catch((error) => {
        confirmAlert({
          title: "Alert !",
          message: "Session expired",
          buttons: [
            {
              label: "Ok",
              onClick: () => {
                this.logoutnormal();
              },
            },
          ],
        });
      });
  };

  getroles(e) {
    this.props.history.push({
      pathname: "/CreatetaskUI",
      state: {
        format_id: this.props.location.state.format_id,
        role_id: this.props.location.state.role_id,
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      },
    });
  }
  tiker = () => {
    this.props.history.push({
      pathname: "/Tiker",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      },
    });
  };

  TaskactiveBind = () => {
    this.props.history.push({
      pathname: "/Taskactive",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      },
    });
  };

  SmartPointBind = () => {
    this.props.history.push({
      pathname: "/SmartPoint",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        data: this.props.location.state.data,
        tskId: this.props.location.state.tskId,
        tskName: this.props.location.state.tskName,
        storeNo: this.props.location.state.storeNo,
        Isroffice: this.props.location.state.Isroffice,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      },
    });
  };

  Defectbind = () => {
    this.props.history.push({
      pathname: "/DefectRaising",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        data: this.props.location.state.data,
        tskId: this.props.location.state.tskId,
        tskName: this.props.location.state.tskName,
        storeNo: this.props.location.state.storeNo,
        Isroffice: this.props.location.state.Isroffice,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      },
    });
  };

  Reportsbind = () => {
    this.props.history.push({
      pathname: "/Reports",
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        data: this.state.data,
        tskId: this.state.tskId,
        tskName: this.state.tskName,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      },
    });
  };

  CompletedTaskListbind = () => {
    this.props.history.push({
      pathname: "/CompletedTaskList",
      state: {
        data: this.state.data,
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        format_id: this.props.location.state.format_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        menuList: this.props.location.state.menuList,
        roleName: this.props.location.state.roleName,
      },
    });
  };
}

export default withRouter(UserCreation);
